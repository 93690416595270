<template>
    <el-dialog v-if="dialogVisible" :title="wbItemTitle" :visible.sync="dialogVisible" append-to-body :close-on-click-modal="false"
      width="950px" top="15vh" @close="Close">
      <div style="height: 40vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
        <el-form label-position="right" label-width="120px" ref="mainterItemForm" :model="mainterItemForm"
          :rules="formRules">
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="维保项名称:" prop="name">
                <el-input v-model="mainterItemForm.name" placeholder="请输入" class="wid90" :disabled="dialogVisibleDis || isEdit" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保类型:" prop="type">
                <el-select disabled class="wid90" v-model="mainterItemForm.type" placeholder="请输入"
                  @change="changeItemType">
                  <el-option v-for="(item, index) in maintainType" :key="index" :label="item.label"
                    :value="Number(item.value)" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" v-if="adminInfo.userName == 'admin' && !dialogVisibleDis">
              <el-form-item label="区域:">
                <el-cascader :disabled="isEdit" :props="props" :options="$mapCode" v-model="mainterItemForm.areaCodes" style="width: 100%"
                  collapse-tags clearable>
                </el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <template v-for="(item, index) in mainterItemForm.mainItemsArr">
              <el-form-item :label="`标题${index + 1}:`" :key="index">
                <div style="display:flex;">
                  <el-form-item
                    class="wid15 mr20"
                    :prop="'mainItemsArr.' + index + '.itemGroup'"
                    :rules="[{
                        required: true,
                        message: '请选择维保项类型',
                        trigger: 'change'}]">
                    <el-select :disabled="dialogVisibleDis" clearable v-model="item.itemGroup" placeholder="请输入">
                      <el-option v-for="itemm in maintainCategory" :key="itemm.value" :label="itemm.label"
                        :value="Number(itemm.value)" />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    class="wid50 mr20"
                    :prop="'mainItemsArr.' + index + '.value'"
                    :rules="[{
                        required: true,
                        message: '标题至少需要中文，字母，数值，特殊符号，长度为5-200',
                        trigger: 'change',
                        min: 5,
                        max: 200 }]">
                      <!-- <el-input v-model="item.value" style="width: 100px" /> -->
                      <template v-if="!dialogVisibleDis">
                        <el-input  v-model="item.value" placeholder="请输入"
                          @change="changeLabel(index)" />
                      </template>
                      <template v-else>
                        <div class="itemClass" :title="item.value">{{item.value}}</div>
                      </template>
                  </el-form-item>
                  <el-button v-if="!dialogVisibleDis" type="text" size="medium" icon="el-icon-delete" class="mr20"
                    style="color: red !important; font-size: 20px" @click="deleteItem(index)"
                    :disabled="dialogVisibleDis || isEdit" />
                  <el-checkbox v-model="item.checked" :disabled="dialogVisibleDis">是否拍照</el-checkbox>
                </div>
              </el-form-item>
              </template>
            </el-col>
            <el-button :disabled="dialogVisibleDis || isEdit" type="primary" icon="el-icon-plus"
              style="padding: 10px 20px; margin-left: 120px" @click="chooseTableItem">
              从模板中选择
            </el-button>
            <el-button :disabled="dialogVisibleDis || isEdit" type="primary" icon="el-icon-plus"
              style="padding: 10px 40px; margin-left: 10px" @click="chooseItem" />
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="!dialogVisibleDis && !isEdit" size="small" type="primary" :loading="loading" @click="dioSub">确
          定</el-button>
        <el-button size="small"  @click="Close">取 消</el-button>
      </span>
      <!-- <WbItem v-if="selectDialogVisible" ref="wbItem" :selectUnitList="maintainItem" @transferUnit="selectOtherResult" /> -->

      <WbItem
      v-if="selectDialogVisible"
      ref="wbItem"
      :selectUnitList="maintainItem"
      :itemGroup="itemGroup"
      :mainItemsArr="mainItemsArr"
      @close="closeItemGroup"
      @transferUnit="selectOtherResult" />
    </el-dialog>
</template>
<script>
  import WbItem from '../../wbxgl/components/wbItem'
  export default {
    components: {
      WbItem,
    },
    props: ['wbItemTitle', 'wbItemType', 'initWbItemId', 'initWbItemInfo', 'isEdit'],
    data() {
      return {
        //所选区域
        props: {
          label: 'name',
          value: 'areaCode',
          checkStrictly: true,
        },
        // 维保项类型
        maintainType: [],
        // 维保项内容
        maintainItem: [],
        // MainItemsArr: [{ value: '', checked: true }], // 已选维保项
        // dialog参数
        dialogVisible: true,
        dialogVisibleDis: false, // 查看禁点
        loading: false,
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        selectRow: [], // 选中维保项
        personnelData: {}, // 维保项数据
        selectDialogVisible: false, // 维保项列表
        mainterItemForm: {
          name: '',
          mainItemsArr: [{
            value: '',
            checked: false,
            itemGroup: ''
          }],
          areaCodes: []
        },
        formRules: {
          name: [{
            required: true,
            message: '维保项名称至少需要中文，字母，数值，特殊符号，长度为2-30',
            trigger: 'blur',
            min: 2,
            max: 30,
          }, ],
          // type: [{
          //   required: true,
          //   message: '维保类型至少选择一项',
          //   trigger: 'blur',
          // }, ],
        },

        // 维保项分类
        maintainCategory: [{
          label: '层门',
          value: 1
        },{
          label: '底坑',
          value: 2
        },{
          label: '机房',
          value: 3
        },{
          label: '轿顶',
          value: 4
        },{
          label: '轿厢',
          value: 5
        }],

        itemGroup: [],
        mainItemsArr: []
      }
    },
    filters: {},
    methods: {
      // 获取维保项方法
      onRefer(id) {
        this.$http.get(`/api/ele/web/maintainItem/getMaintainItemDetail?id=${id}`).then((res) => {

          if (res.data.success) {
            const { data: { data }} = res
            this.edit(data)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 获取字典数据
      getDictionar(code) {
        // code = code.join()
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {

            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res

              this.maintainType = data.MAINTAIN_TYPE
              this.maintainItem = data.MAINTAIN_ITEM
              this.itemGroup = data.MAINTAIN_ITEM_GROUP
              this.maintainItem.forEach((item) => {
                item.checked = false
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      // 弹出框提交
      dioSub(type) {
        // this.loading = true
        this.$refs.mainterItemForm.validate(async (valid) => {
          if (valid) {
                this.mainterItemForm.itemCodes = JSON.stringify(
                  this.mainterItemForm.mainItemsArr
                )
                this.mainterItemForm.areaCode = this.mainterItemForm.areaCodes[this.mainterItemForm.areaCodes.length - 1]
                this.mainterItemForm.areaCodes = JSON.stringify(this.mainterItemForm.areaCodes)
                this.dialogVisible = false
                this.$emit('close', this.mainterItemForm, this.wbItemType)
          }
        })
      },
      Close() {
          this.dialogVisible = false
          this.$emit('close', this.initWbItemInfo, this.wbItemType)
      },

      changeLabel(value) {
        this.mainterItemForm.mainItemsArr[value].label = ''
      },

      // 修改
      edit(item) {
        console.log( '修改')
        this.dialogVisibleDis = false
        this.mainterItemForm = JSON.parse(JSON.stringify(item))
        this.mainterItemForm.areaCodes = item.areaCodes ? JSON.parse(item.areaCodes) : ''
        this.$set(
          this.mainterItemForm,
          'mainItemsArr',
          item.itemCodes ? JSON.parse(item.itemCodes) : '',
        )
      },

      changeItemType(val) {
        let id = 1
        switch (val) {
          case 1:
            id = 1
            break
          case 3:
            id = 2
            break
          case 4:
            id = 4
            break
          case 5:
            id = 3
            break

          default:
            break
        }
        this.$http
          .get(`/api/ele/web/maintainItem/getMaintainItemDetail?id=${id}`)
          .then((res) => {
            if (res.data.success) {
              this.$set(
                this.mainterItemForm,
                'mainItemsArr',
                JSON.parse(res.data.data.itemCodes)
              )
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      // 添加维保项
      chooseItem() {
        if (this.mainterItemForm.mainItemsArr.find((item) => item.value === '')) {
          this.$message.error('已经有空白的维保项')
          return
        }
        this.mainterItemForm.mainItemsArr.push({
          value: '',
          checked: false
        })
      },
      // 删除维保项
      deleteItem(index) {
        if (this.mainterItemForm.mainItemsArr.length === 1) {
          this.$message.error('已经是最后一条维保项了')
          return
        }
        this.mainterItemForm.mainItemsArr.splice(index, 1)
      },
      // 列表添加维保项
      chooseTableItem() {
        console.log('选择维保项')
        this.selectDialogVisible = true
        this.mainItemsArr = this.mainterItemForm.mainItemsArr
        // this.$nextTick(() => {
        //   this.$refs.wbItem.init(this.mainterItemForm.mainItemsArr, (refresh) => {
        //     if (refresh) {}
        //   })
        // })
      },

      closeItemGroup(){
        this.selectDialogVisible = false
      },
      // 选择维保项返回
      selectOtherResult(data) {
        console.log('选择单位返回')
        if (data.length > 1 ) {
            this.mainterItemForm.mainItemsArr = JSON.parse(JSON.stringify(data))
            this.mainterItemForm.mainItemsArr = this.mainterItemForm.mainItemsArr.filter(item => item.value != '')
        } else {
          if (data.length == 1 && data.value != '') {
            this.mainterItemForm.mainItemsArr = JSON.parse(JSON.stringify(data))
          } else {
            this.$set(this.mainterItemForm, 'mainItemsArr', [{
              value: '',
              checked: false
            }])
          }
        }
      },

    },
    created() {
      this.getDictionar(['MAINTAIN_TYPE', 'MAINTAIN_ITEM','MAINTAIN_ITEM_GROUP'])
    },
    mounted() {
      this.$nextTick(() => {
        if (this.initWbItemId) {
          this.onRefer(this.initWbItemId)
        } else if(this.initWbItemInfo.mainItemsArr){
          this.mainterItemForm = JSON.parse(JSON.stringify(this.initWbItemInfo))
          this.mainterItemForm.areaCodes = JSON.parse(this.mainterItemForm.areaCodes)
        } else {
          this.mainterItemForm.type = this.initWbItemInfo.type
          this.changeItemType(this.initWbItemInfo.type)
        }
      })
    }
  }
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchBtn {
    margin-left: 15px;
  }

  .wid90 {
    width: 95%;
  }

  .wid50 {
    width: 50%;
  }
  .wid15{
    width: 15%;
  }

  .mr20 {
    margin-right: 20px;
  }
  .itemClass{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

</style>
