<template>
  <div>
    <!-- <el-progress type="circle" v-show="percentage != 100" :text-inside="true" :stroke-width="26" :percentage="percentage" style="width: 60%; margin: 0 auto; color:#fff"/> -->
    <!-- <div> -->
    <div v-show="percentage != 100" style="position: relative;">
      <span class="span-width">
        <VueCountUp :start-value="0" :end-value="percentage" :duration="1" />%
      </span>
      <img class="rotate" :src="require(`@/assets/img/${percentage}.png`)" style="width: 75px; height: 75px;">
    </div>

    <div v-show="percentage == 100" style="height: 100%;" :style="{'width': isCall ? '88%' : '100%'}">
      <video style="height: 100%;" :style="{'width': isCall ? '95%' : '100%'}" controls autoplay
        :id="`onPlayJk${playNum}`" />
      <video v-show="false" style="width: 100px;height: 100px;" controls autoplay id="mePlayJk" />
    </div>

    <div v-show="percentage == 100" class="conBox" v-if="isCall">
      <el-button :disabled='isDial||isble' @click="dialZlv" title="拨打" type="success"
        style="margin-left: 10px;margin-bottom: 40px;" icon="el-icon-microphone" circle></el-button>
      <el-button :disabled='!isDial||isble' @click="dropped" title="挂断" type="danger" style="margin-bottom: 40px;"
        icon="el-icon-turn-off-microphone" circle></el-button>
      <el-button v-if="isLadder == null" :title="isLadder ? '阻梯' : ''" type="warning" :loading="true"
        circle></el-button>

      <el-image v-else-if="isLadder != -1" fit="contain" :title="type == 1 ? '取消阻梯' : '阻梯'"
        :src="type == 1 ? require(`@/assets/notzuti.png`) : require(`@/assets/zuti.png`)" @click="ladder"
        style="height: 40px; width: 40px; margin-left: 10px; cursor: pointer;" />
      <!-- <el-image fit="contain"
					:title="type == 2 ? '取消阻梯' : '阻梯'"
					:src="type == 2 ? require(`@/assets/notzuti.png`) : require(`@/assets/zuti.png`)"
					@click="ladder"
					style="height: 40px; width: 40px; margin-left: 10px; cursor: pointer;" /> -->
      <!-- <el-button @click="ladder" title="阻梯" type="warning" icon="el-icon-s-release" circle></el-button> -->
    </div>
  </div>
</template>

<script>
  let obj = []
  var userAgent = null
  let one = true
  import VueCountUp from 'vue-countupjs'
  export default {
    props: ['jkData', 'percentage', 'eleInfoId', 'isCall', 'playNum', 'isPrevent', 'isLadder', 'videoStatusId'],
    components: {
      VueCountUp
    },
    data() {
      return {
        timer: null,
        timeCount: null,
        sipsession: null, // 通话实例
        isble: false,
        // 监控
        player: null,
        loading: true,
        timeWebRtc: null,
        // 通话
        isDial: false,
        isMuted: true,
        meData: [],
        meObj: null,
        playTime: 0, // 通话时间
        playWebrtc: '',
        type: 1,

        // percentage: 0
      }
    },
    mounted() {
      let start = 30
      this.timeCount = setInterval(() => {
        start--
        // console.log(this.timer, '成功')
        if (start > -1) {
          if (this.timer == '失败' || this.timer == null) {
            if (start < 1) {
              this.getTimes(2)
            }
          } else {
            this.getTimes(1)
            clearInterval(this.timeCount)
          }
        } else {
          if (this.timer == '成功') {
            this.getTimes(1)
            clearInterval(this.timeCount)
          }
        }
      }, 1000)
      setTimeout(() => {
        console.log('我是c1')
        obj = JSON.parse(sessionStorage.getItem('me_id')).sip_info
        this.init()
        this.timeWebRtc = setInterval(() => {
          this.opJk()
        }, 5000)
      }, 1000)
    },
    beforeDestroy() {
      clearInterval(this.timeCount)
      if (this.jkData) {
        let agentData = {
          act: 'ma_stop_webrtc_call',
          sos_room_id: this.jkData.device_id,
        }
        this.socketApi.sendSock(agentData, (e) => {

        })
        console.log('监控组件销毁了123')
        this.dropped()
        this.stop()
      }
    },
    computed: {
      countWatcher: function() {
        // 在计算属性中可以监听到 props.count 的变化
        return this.isLadder;
      }
    },
    watch: {
      countWatcher: function(newValue, oldValue) {
        // 监听 countWatcher 的变化
        this.type = this.isLadder ? 2 : 1
        console.log("props.count 的值已变为：" + newValue);
      }
    },
    methods: {

      dialZlv() {
        let agentData = {
          v_type: 0,
          act: 'ma_set_sip_info',
          deviceId: this.jkData.device_id
        }
        this.socketApi.sendSock(agentData, (e) => {
          // 下列参数写死
          var host = obj.sip_host; // 域名
          var user = obj.sip_id; // 注册人id
          var pwd = '0000'; // 密码
          var wss = obj.wss_url; // 长连接地址
          var iceHost = obj.stun_host; // stun地址
          var turnUser = obj.turn_user; // turn用户名
          var turnPwd = obj.turn_pwd; // turn密码

          var config = {
            uri: user + '@' + host,
            transportOptions: {
              wsServers: [wss]
            },
            authorizationUser: user,
            password: pwd,
            sessionDescriptionHandlerFactoryOptions: {
              peerConnectionOptions: {
                rtcConfiguration: {
                  iceServers: [{
                      urls: 'stun:' + iceHost
                    },
                    {
                      urls: 'turn:' + iceHost,
                      username: turnUser,
                      credential: turnPwd
                    }
                  ]
                }
              }
            }
          };
          userAgent = new SIP.UA(config);
          userAgent.on('registered', function() {});
          userAgent.on('invite', function(session) {
            var url = session.remoteIdentity.uri.toString() + "--->call";
            //接受来电
            session.accept({
              sessionDescriptionHandlerOptions: {
                constraints: {
                  audio: true,
                  // video: {
                  // 	width: 1280,
                  // 	height: 720
                  // }
                }
              }
            });
            this.sipsession = session;
          });
          this.isble = true
          this.isDial = false
          setTimeout(() => {
            var hostc = obj.sip_host;
            // var toc = this.jkData.sip_id;
            var toc = this.jkData.sip_id
            this.sipsession = userAgent.invite(toc + '@' + hostc, {
              sessionDescriptionHandlerOptions: {
                constraints: {
                  audio: true,
                  // video: {
                  // 	faceMode: 'user', //facemode是一个选择摄像头的约束，在移动设备上效果很好，有user（用户，前置摄像头）、environment（环境，后置摄像头）、left、right四种选项
                  // 	width: 160,
                  // 	height: 120,
                  // 	frameRate: 16,
                  // }
                }
              }
            });
            let _this = this
            setTimeout(() => {
              _this.isble = false
            }, 10000)
            this.sipsession.on('accepted', function() {
              console.log('接通了')
              // 按钮禁点2.5秒防止对象未操作就挂断
              _this.isDial = true
              _this.isble = false
              var pc = _this.sipsession.sessionDescriptionHandler.peerConnection;
              var remoteStream = new MediaStream();
              pc.getReceivers().forEach(function(receiver) {
                remoteStream.addTrack(receiver.track);
              });
              var mePlayJk = document.getElementById('mePlayJk');
              mePlayJk.srcObject = remoteStream;
              mePlayJk.play();
            });
            this.sipsession.on('rejected', (response, cause) => {
              console.log('onRejected - ', response, cause)
            });
            this.sipsession.on('failed', (response, cause) => {
              console.log('onFailed - ', response, cause)
            });
            this.sipsession.on('terminated', (message, cause) => {
              console.log('onTerminated - ', message, cause)
            });
          }, 2000)
        })
      },

      // 阻梯
      ladder() {
        console.log('阻梯');
        let agentData = {
          act: 'ma_change_ele_status',
          deviceId: this.jkData.device_id,
          type: this.type == 1 ? 2 : 1
        }
        this.socketApi.sendSock(agentData, (e) => {
          if (e.cmd == 'ma_change_ele_status' && e.status) {
            this.$message.success(e.msg)
            this.type = this.type == 1 ? 2 : 1
          } else {
            this.$message.error(e.msg)
          }
        })
      },

      dropped() {
        this.isDial = false
        if (this.sipsession) this.sipsession.terminate();
      },
      // 重连
      opJk() {
        this.stop()
        let agentData = {
          act: 'ca_start_rtsp',
          device_id: this.jkData.device_id
        }
        this.socketApi.sendSock(agentData, (e) => {
          if (e.status) {
            if (e.hb) {
              this.$emit('changePercentage', 25)
            }
            if (e.cmd == 'ca_start_rtsp') {
              this.$emit('changePercentage', 50)
              this.getStreamStatus()
            }
          } else {
            this.$message.error(e.msg)
          }
        })
      },

      getStreamStatus() {
        this.$http
          .get(`api/ele/web/getStreamStatus?id=${this.eleInfoId}`)
          .then((res) => {
            if (res.data.success) {
              this.$emit('changePercentage', res.data.data ? 75 : 50)
              this.init()
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      //关闭监控
      stop() {
        clearInterval(this.timeWebRtc)
        if (this.player) {
          this.player.close();
          this.player = null;
          var local = document.getElementById(`onPlayJk${this.playNum}`);
          if (local) {
            local.srcObject = null;
            local.load();
          }
        }
      },
      //更新视频时间
      getTimes(type) {
        // const id = this.videoStatusId ? this.videoStatusId : this.id
        const time = new Date(new Date()).getTime()

        this.$http.post(
          `/api/bike/web/bikeVideo/updateBikeVideoStatus?bikeId=${this.eleInfoId}&videoTime=${time}&visStatus=${type}`
        ).then((res) => {
          this.$emit('update', time, type)
        })
      },
      init() {
        const that = this
        const jswebrtc = document.getElementById(`onPlayJk${this.playNum}`)
        const openUrl = this.jkData.play_url
        const playUrl = openUrl[openUrl.length - 2]

        this.player = new ZLMRTCClient.Endpoint({
          element: jswebrtc, // video 标签
          debug: true, // 是否打印日志
          zlmsdpUrl: playUrl, //流地址
          simulcast: false,
          useCamera: false,
          audioEnable: false,
          videoEnable: false,
          recvOnly: true, //拉流为true 推流为false
          resolution: {
            w: 640,
            h: 480
          }
        });
        this.player.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR, function(e) { // ICE 协商出错
          console.log('ICE 协商出错')
        });
        this.player.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS, function(e) { //获取到了远端流，可以播放
          that.timer == '成功'
          console.log('播放成功1', e.streams)
          clearInterval(that.timeWebRtc)
          that.loading = false
          that.$emit('changePercentage', 100)
        });
        this.player.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, function(
          e) { // offer anwser 交换失败
          that.timer == '失败'
          console.log('offer anwser 交换失败1', e)
        });
        this.player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) { // 获取到了本地流
          jswebrtc.srcObject = s;
          jswebrtc.muted = false;
          that.timer == '成功'
          console.log('获取本地流成功--拉流1')
        });
        this.player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) { // 获取本地流失败
          console.log('获取本地流失败--拉流1')
          that.timer == '失败'
        });
      }
    }
  }
</script>

<style scoped>
  .conBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 6%;
    height: 100%;
  }

  .span-width {
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 50px);
    font-size: 26px;
    font-weight: 600;
    color: #052e54;
    width: 100px;
    line-height: 32px;
    text-align: center;
    z-index: 999;
  }

  .rotate {
    animation: rotate 2s infinite;
  }

  /* 定义旋转动画的关键帧 */
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  /deep/.el-progress-bar__innerText {
    color: #fff !important;
  }
</style>
