<template>
  <div>
    <el-date-picker
      @change="swchange"
      v-model="getTime"
      value-format="timestamp"
      :default-time="['00:00:00', '23:59:59']"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
    >
    </el-date-picker>
    <div style="width:100%;">
      <div id="myCharts" />
    </div>

    <el-dialog
      v-if="switchart"
      :visible.sync="switchart"
      :close-on-click-modal="false"
      append-to-body
      width="1400px"
      top="8vh"
      @close="closeDetail"
    >
      <div style="height: 50vh;padding-right: 20px;">
        <SwitchCharts
          :stime="this.stime"
          :eleId="this.eleId"
          :onlineTime="onlineTime"
          :dataInfo="this.dataInfo"
        />
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SwitchCharts from './switchCharts'
import * as echarts from 'echarts'
export default {
  components: { SwitchCharts },
  props: ['eleId', 'dataInfo'],

  data() {
    return {
      getTime: [
        //默认选择最近一周的时间戳
        new Date(
          new Date(new Date().toLocaleDateString()).getTime() -
            7 * 24 * 3600 * 1000
        ).getTime(),
        new Date(new Date().toLocaleDateString()).getTime() - 1000,
      ],
      switchart: false,
      option: {},
      shsh: [],
      ctime: [],
      logout_time: [],
      login_time: [],
      loading: false,
      onlineTime: null,
    }
  },

  created() {},
  mounted() {
    this.switchGet()
  },

  methods: {
    swchange() {
      this.ctime = []
      this.logout_time = []
      this.login_time = []
      const days = (this.getTime[1] - this.getTime[0]) / 24 / 3600 / 1000

      if (Math.ceil(days) !== 7) {
        this.$message.error('只支持查询7天内的数据')
        return
      }
      this.switchGet()
      this.$emit('dateChange', this.getTime)
    },
    async switchGet() {
      const params = {
        startTime: this.getTime[0],
        endTime: this.getTime[1],
        eleId: this.eleId,
        dateTime: this.getTime
      }
      this.loading = true

      this.$http
        .post('/api/ele/web/loginLog/getEleLoginCount', params)
        .then((res) => {

          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.loading = false
            this.shsh = data
            data.map((item) => {
              // 上下线图表数据
              this.ctime.push(item.ctime)
              this.login_time.push(item.login_time)
              this.logout_time.push(item.logout_time)
            })
            this.option = {
              legend: {
                data: ['当天在线时间(分钟)', '当天离线时间(分钟)'],
                textStyle: {
                  color: '#409EFF',
                },
              },
              xAxis: {
                type: 'category',
                data: this.ctime, // x轴数据
                name: '日期', // x轴名称
                // x轴名称样式
                nameTextStyle: {
                  fontWeight: 1200,
                  fontSize: 18,
                  color: '#409EFF',
                },
                axisLine: {
                  lineStyle: {
                    type: 'solid',
                    color: '#409EFF',
                  },
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#409EFF',
                  },
                },
              },
              yAxis: {
                type: 'value',
                name: '时间', // y轴名称
                // y轴名称样式
                nameTextStyle: {
                  fontWeight: 1200,
                  fontSize: 18,
                  color: '#409EFF',
                },
                axisLine: {
                  lineStyle: {
                    type: 'solid',
                    color: '#409EFF',
                  },
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#409EFF',
                  },
                },
              },
              label: {},
              tooltip: {
                trigger: 'axis',
              },
              series: [
                {
                  name: '当天在线时间(分钟)',
                  barGap: 0,
                  data: this.login_time,
                  type: 'bar',
                },
                {
                  name: '当天离线时间(分钟)',
                  data: this.logout_time,
                  type: 'bar',
                },
              ],
            }
            var myChart = echarts.init(document.getElementById('myCharts'))
            myChart.setOption(this.option)
            myChart.on('click', (params) => {
              this.onlineTime = {
                login_time: this.login_time[params.dataIndex],
                logout_time: this.logout_time[params.dataIndex],
              }
              this.seriesIndex = params.seriesIndex
              // params.name默认是当天08:00
              this.stime =
                Date.parse(new Date(params.name)) / 1000 - 60 * 60 * 8
              this.switchart = true
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    closeDetail() {
      this.switchart = false
    },
  },
}
</script>
<style scoped>
#myCharts {
  margin-top: 20px;
  width: 100%;
  height: 30vh;
}
</style>
