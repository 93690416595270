<template>
  <el-dialog :visible.sync="elevatorVisible" @close='close' title="电梯数据" :close-on-click-modal="false" width="1400px"
    top="8vh">
    <div style="height: 80vh;">
      <div class="distribute">
        <div class="distribute-num">
          <div v-loading="loading" class="board-card">
            <span>总电梯数(台)</span>
            <span>{{eleInfo.dtzs}}</span>
          </div>
          <div v-loading="loading" class="board-card">
            <span>入网电梯数(台)</span>
            <span>{{eleInfo.rwdts}}</span>
          </div>
          <div v-loading="loading" class="board-card">
            <span>未入网电梯数(台)</span>
            <span>{{eleInfo.wrwdts}}</span>
          </div>
          <div v-loading="loading" class="board-card">
            <span>在线电梯(台)</span>
            <span>{{eleInfo.zxdts}}</span>
          </div>
          <div v-loading="loading" class="board-card">
            <span>离线电梯(台)</span>
            <span>{{eleInfo.lxdts}}</span>
          </div>
        </div>
      </div>
      <div>
        <el-form inline :model="searchForm">
          <el-form-item label="电梯名称">
            <el-input placeholder="请输入电梯名称" v-model="searchForm.name"></el-input>
          </el-form-item>
          <el-form-item label="注册代码">
            <el-input placeholder="请输入注册代码" v-model="searchForm.code"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select placeholder="请选择" v-model="searchForm.status">
              <el-option :key="1" label="电梯总数" :value="0"></el-option>
              <el-option :key="2" label="入网电梯数" :value="3"></el-option>
              <el-option :key="3" label="未入网电梯数" :value="4"></el-option>
              <el-option :key="4" label="在线" :value="1"></el-option>
              <el-option :key="5" label="离线" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click='onRefer()'>查询</el-button>
            <el-button type="warning" @click="reset()">重置</el-button>
            <el-button type="success" :disabled="exportType" @click="exportData()">{{exportTitle}}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table ref="multipleTables" v-loading="tableLoading" :data="tableData"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px" :height="$store.state.tabHeight-200">
          <el-table-column type="index" label="序号" width="55px"/>
          <el-table-column label="电梯名称" prop="name" align="center" width="150px"/>
          <el-table-column label="注册代码" prop="code" align="center"  width="150px"/>
          <el-table-column label="添加时间" prop="createTime" align="center"  width="150px"/>
          <el-table-column label="激活时间" prop="activeTime" align="center"  width="150px"/>
          <el-table-column label="AI摄像头ID" prop="deviceCode" align="center"  width="150px"/>
          <el-table-column label="在线状态" align="center">
            <template slot-scope="scope">
              {{scope.row.status == 1 ? '在线' : '离线'}}
            </template>
          </el-table-column>
          <el-table-column label="SD卡总容量(M)" prop="sdTotalSize" align="center"  width="150px"/>
          <el-table-column label="所属区域" prop="mergerName" align="center"  width="150px"/>
          <el-table-column label="物业单位" prop="propertyUnitName" align="center"  width="150px"/>
          <el-table-column label="保险单位" prop="insuranceUnitName" align="center"  width="150px"/>
          <el-table-column label="维保单位" prop="maintainUnitName" align="center"  width="150px"/>
          <el-table-column label="创建人" prop="createUserName" align="center" />
          <el-table-column label="操作" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="checkInfo(scope.row)">查看</el-button>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <!-- <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 200px" />
      </div> -->
      <Pagination :total="dictionarTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
        :pageSizes="[10, 20, 30, 50, 100]" @pagination="dictionarPages" />
      <Action v-if="dialogVisible" :initData="initData" ref="acitonDtzl" @closeActione="closeActione"
        @updata="updata" />
    </div>
  </el-dialog>
</template>

<script>
  import Pagination from '../../../../components/Pagination.vue'
  import Action from '../../../dtsj/dtzl/components/action.vue'
  export default {
    components: {
      Pagination,
      Action
    },
    props: ['eleData'],
    data() {
      return {
        dialogVisible: false,
        initData: {},
        exportType: false,
        exportTitle: '导出',
        eleInfo: {},
        searchForm: {
          current: 1,
          size: 10,
          status: 0,
        },
        elevatorVisible: true,
        loading: false, //加载状态
        tableLoading: false,
        tableData: [],
        dictionarTotal: 0,
      }
    },
    created() {
      this.getDate()
      this.onRefer()
    },
    methods: {
      closeActione(value) {
        this.dialogVisible = false

        // this.onRefer(false,value)
      },
      updata(value) {
        this.dialogVisible = false
        // this.onRefer(false, value)
      },
      checkInfo(item) { //查看
        this.dialogVisible = true
        this.$http
          .get(`/api/ele/web/elevatorInfo/detail?id=${item.id}`)
          .then((res) => {
            if (res.data.success) {
              const data = res.data.data
              data.deviceSosSwitch = res.data.data.deviceSosSwitch ?
                JSON.parse(res.data.data.deviceSosSwitch) : []
              data.sosSwitchStatus = res.data.data.sosSwitchStatus ?
                JSON.parse(res.data.data.sosSwitchStatus) : []
              data.terminalSosSwitch = res.data.data.terminalSosSwitch ?
                JSON.parse(res.data.data.terminalSosSwitch) : []
              data.areaCodeArr = res.data.data.areaCodes ?
                JSON.parse(res.data.data.areaCodes) : []
              this.initData = data
              this.$nextTick(() => {
                this.$refs.acitonDtzl.init('check', data, () => {
                  this.onRefer()
                })
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      async exportData() {
        this.searchForm.current = 1
        this.exportType = true
        this.exportTitle = '正在导出'
        let data = {
          baseUserId: this.eleData.id,
          ...this.searchForm
        }
        // let data = {
        //   ...this.searchForm
        // }
        this.$http.post("/api/ele/web/export/RunningUserData", data, {
          responseType: 'blob'
        }).then((res) => {
          if (res.data.size > 0) {
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            });
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a'); //创建a标签
            link.href = url;
            link.download = '电梯数据.xls'; //重命名文件
            link.click();
            URL.revokeObjectURL(url);
          } else {
            this.$message.error('暂无数据')
          }
          this.exportType = false
          this.exportTitle = '导出'
        })
      },
      getDate() {
        this.loading = true
        const data = {
          baseUserId: this.eleData.id
        }
        this.$http.post(`/api/ele/web/elevatorInfo/getEleUserCount`, data).then((res) => {
          if (res.data.code == 200) {
            this.loading = false
            this.eleInfo = res.data.data ? res.data.data : ''
          }
        })
      },
      close() {
        this.elevatorVisible = false
        this.$emit('changeClose')
      },
      dictionarPages(data) {
        this.searchForm.current = data.page;
        this.searchForm.size = data.limit;
        this.onRefer()
      },
      reset() {
        this.searchForm = {
          current: 1,
          size: 10,
          status: 0,
        }
        this.onRefer()
      },
      onRefer() {
        this.tableLoading = true
        const data = {
          baseUserId: this.eleData.id,
          ...this.searchForm
        }
        this.$http.post(`/api/ele/web/elevatorInfo/getRunningUserList`, data).then((res) => {
          if (res.data.code == 200) {
            this.tableLoading = false
            this.tableData = res.data.data.records ?? []
            this.dictionarTotal = res.data.data.total ? res.data.data.total : 0
          }
        })
      }
    }
  }
</script>

<style scoped>
  .nullDate {
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .distribute {
    width: 100%;
    height: 130px;
  }

  .distribute-num {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-around;
  }
</style>
