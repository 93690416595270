<template>
  <el-dialog
    :title="'选择电梯'"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    width="1400px"
    @close="selectunitDialogClose"
  >
    <div class="admin-project-myproject-container">
      <el-table
        ref="selectUnitList"
        :data="selectUnitList"
        border
        stripe
        fit
        max-height="500px"
        highlight-current-row
        row-key="id"
        @select="handleSelectionChange"
        @select-all="selectAll"
      >
        <el-table-column
          type="selection"
          reserve-selection
          width="55px"
          align="center"
        />
        <el-table-column
          label="序号"
          type="index"
          prop="index"
          width="50px"
          align="center"
        >
        </el-table-column>
        <el-table-column align="center" prop="name" label="电梯名称" />
        <el-table-column align="center" prop="code" label="电梯注册代码" />
        <el-table-column
          align="center"
          prop="deviceCode"
          label="物联网设备编码"
        />
        <el-table-column align="center" prop="rescueCode" label="救援识别码" />
        <el-table-column
          align="center"
          prop="maintainUnitName"
          label="维保单位"
          width="150px"
        />
        <el-table-column align="center" prop="rescueCode" label="救援识别码" />
        <el-table-column
          align="center"
          prop="propertyUnitName"
          label="物业单位"
          width="150px"
        />
      </el-table>
      <Pagination
        v-show="selectUnitListTotal > 0"
        :total="selectUnitListTotal"
        :page.sync="searchForm.current"
        :limit.sync="searchForm.size"
        @pagination="selectUnitListGet"
      />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button v-if="!isEdit" size="small" type="primary" @click="selectunitConfirm"
        >确 定</el-button
      >
      <el-button size="small" @click="selectunitDialogClose">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Pagination from "../../../../components/Pagination";

export default {
  components: {
    Pagination,
  },
  props: ['clooseEleType', 'initClooseEleId', 'initClooseEleInfo', 'propertyUnitId', 'maintainUnitId', 'isEdit'],

  data() {
    return {
      visible: true,
      loading: true,
      selectUnitList: [],
      selectUnitListTotal: 0,
      selectunitCallback: null,
      selectRow: {},
      unitId: [],
      selectData: [],
      initArr: [],

      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },

      type: null, // 生效类型
      rowId: null,
    };
  },
  mounted () {
    this.selectUnitListGet()
    this.unitId = this.initClooseEleId
    this.initArr = this.initClooseEleInfo
  },
  methods: {
    // 全选
    selectAll(selection) {
      let stateArr = [];
      if (selection.length > 0) {
        stateArr = JSON.parse(JSON.stringify(selection));
        const arr = [...selection, ...this.initArr];
        // 去重
        const res = new Map();
        this.initArr = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1));
      } else {
        stateArr = JSON.parse(JSON.stringify(this.selectUnitList));
        stateArr.map((item) => {
          if (this.initArr.find((row) => row.id === item.id)) {
            this.initArr = this.initArr.filter((row) => row.id !== item.id);
          }
        });
      }
    },
    // 选择
    handleSelectionChange(val, row) {
      if (this.initArr.find((item) => item.id === row.id)) {
        this.initArr = this.initArr.filter((item) => item.id !== row.id);
      } else {
        this.initArr.push(row);
      }
    },

    selectUnitListGet() {
      this.loading = true;
      if (this.clooseEleType != 0) {
        let url = ''
        if (this.isEdit == 'add') {
          url = `/api/ele/web/maintainDemandRole/getEleList?type=${this.clooseEleType}&current=${this.searchForm.current}&size=${this.searchForm.size}&propertyUnitId=${this.propertyUnitId}&maintainUnitId=${this.maintainUnitId}`
        } else {
          url = `/api/ele/web/maintainDemandRole/getEleList?type=${this.clooseEleType}&current=${this.searchForm.current}&size=${this.searchForm.size}&propertyUnitId=${this.propertyUnitId}&maintainUnitId=${this.maintainUnitId}&ids=${this.initClooseEleId.join(',')}`
        }
        this.$http
          .get(url)
          .then((res) => {
            if (res.data.success) {
              const {
                data: { data },
              } = res;
              this.loading = false;
              this.selectUnitList = data.records ?? [];
              this.selectUnitListTotal = data.total ?? 0;
              this.selectTableEle()
            }
          });
      } else {
        this.searchForm.propertyUnitId = this.propertyUnitId
        this.searchForm.maintainUnitId = this.maintainUnitId
        this.$http.post('/api/ele/web/elevatorInfo/list', this.searchForm).then((res) => {
            if (res.data.success) {
              const {
                data: { data },
              } = res;
              this.loading = false;
              this.selectUnitList = data.records ?? [];
              this.selectUnitListTotal = data.total ?? 0;
              this.selectTableEle()
            }
          });
      }
    },

    selectTableEle(){
      this.$nextTick(() => {
        if (this.selectUnitListTotal > 0 && this.$refs.selectUnitList) {
          this.$refs.selectUnitList.bodyWrapper.scrollTop = 0;
        }
        if (this.unitId.length > 0) {
          this.selectUnitList.forEach((ele) => {
            this.unitId.forEach((item) => {
              if (item === ele.id) {
                this.$refs.selectUnitList.toggleRowSelection(ele, true);
              }
            });
          });
        }
      });
    },

    async selectunitConfirm() {
      const unitData = this.initArr;

      if (unitData.length > 0) {
        let selectData = []
        unitData.map(item => {
          selectData.push({
            id: item.id,
            name: item.name
          })
        })
        this.$emit("close", unitData, 'notCanle');
        this.visible = false;
      } else {
        this.$message({
          message: "请选择电梯",
          type: "error",
        });
      }
    },

    selectunitDialogClose() {
      this.$refs.selectUnitList.setCurrentRow();
      this.$refs.selectUnitList.clearSelection();
      this.visible = false;
      this.$emit('close', this.initClooseEleId)
    },
  },
};
</script>
