<template>
  <el-dialog
    :title="'修改维保计划'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    top="3vh"
    width="60%"
    center
    @close="addPlanDialogClose">
    <div style="height: 77vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
          <el-form class="addPlanForm" ref="addPlanForm" :model="addPlanForm" :rules="addPlanRules" label-width="150px"
            label-position="right">
              <div class="grid-content bg-purple">
                <el-form-item label="维保计划名称:" prop="planName">
                  <el-input v-model.trim="addPlanForm.planName" type="text" />
                </el-form-item>
              </div>
              <div class="grid-content bg-purple">
                <el-form-item label="电梯品种:" prop="varieties" :style="{'margin-bottom': eleVarieties != addPlanForm.varieties ? '5px' : '32px'}">
                  <el-select v-model="addPlanForm.varieties" placeholder="请选择维保项" clearable filterable
                    style="width: 94%;" @change="changeEleType">
                    <el-option
                      v-for="item in newEleTypeList"
                      :key="item.value" :label="item.label" :value="item.value"/>
                  </el-select>
                  <el-tooltip class="item" effect="dark" placement="top-end">
                    <div slot="content" style="width: 227px;">
                      常见的乘客、载货、客货电梯请选择曳引与强制驱动电梯。若采用液压驱动请选择液压驱动电梯。
                    </div>
                    <i class="el-icon-warning-outline" style="margin-left: 10px;"></i>
                  </el-tooltip>
                  <div v-if="eleVarieties != addPlanForm.varieties" style="line-height: 25px; color: #ea6b6d;">当前设置与该电梯的电梯品种不一致，请进行修改</div>
                </el-form-item>
              </div>
              <div class="grid-content bg-purple">
                <el-form-item label="电梯:" prop="elevator_name">
                  <el-input :placeholder="addPlanForm.eleName || '暂无内容'" v-model="addPlanForm.eleName"
                    class="input-with-select" disabled>
                  </el-input>
                </el-form-item>
              </div>

              <div class="maintenanTypeLabel">
                <div class="InfoLabel">
                  <span style="color: #f56c6c;margin-right: 5px;">*</span>
                  <span>任务记录:</span>
                  <span>(仅支持修改未签到的记录，已维保或已签到的请在执行状态中查看）</span>
                </div>
                <div class="InfoLabel">
                  <el-button size="mini" type="primary" @click="checkConflict('check')" >检查冲突任务</el-button>
                </div>
              </div>

              <el-table
              ref="multipleTable"
              :data="calendarEvents"
              :height="400"
              style="width: 100%; margin-top: 20px; margin-bottom: 20px"
              @sort-change="handleSort"
              @wheel.native="handleWheel"
              >
                <el-table-column align="center"  type="index" label="序号" width="50px" />
                <el-table-column align="center" label="维保计划日期" min-width="150px">
                  <template slot-scope="scope">
                    <el-date-picker
                      :key="tableKey"
                      style="width: 100%"
                      v-model="scope.row.planStartTime"
                      :clearable="false"
                      type="date"
                      format="yyyy / MM / dd"
                      placeholder="请选择首次维保日期"
                      :picker-options="pickerOptions"
                      @input="changeStart(scope.row, scope.$index)"
                    />
                    <span v-if="errorDateKey.includes(scope.$index)" style="color: #f00">请选择日期</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="维保类型" min-width="120px" >
                  <template slot-scope="scope">
                    <el-select
                        v-model="scope.row.maintainType"
                        placeholder="请选择维保类型"
                        :popper-class="`select-dropdown-${scope.$index}`"
                        filterable
                        style="width: 90%; margin: 0 15px"
                        @change="changeType(scope.row)"
                        >
                        <el-option v-for="item in maintenanType" :key="item.value" :label="item.label"
                          :value="item.value" />
                      </el-select>
                      <div v-if="errorTypeKey.includes(scope.$index)" style="color: #f00">请选择维保类型</div>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="维保项" min-width="150px" >
                  <template slot-scope="scope">
                      <el-select
                        :ref="`itemSelect${scope.$index}`"
                        v-model="scope.row.maintainItemId"
                        placeholder="请选择维保项"
                        :popper-class="`select-dropdown-${scope.$index}`"
                        filterable
                        @change="changeItem()"
                        style="width: 90%; margin: 0 15px">
                        <el-option v-for="item in maintenanItemList.filter(maintenanItem => maintenanItem.type == scope.row.maintainType)"
                          :disabled="item.isActive == 0"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id" />
                      </el-select>
                      <span v-if="errorItemIdKey.includes(scope.$index)" style="color: #f00">请选择维保项</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="isConflict" sortable="custom" label="是否冲突" min-width="100px" >
                  <template slot-scope="scope">
                    <span :key="tableKey">{{ scope.row.isConflict == 1  ? '是' : '否'}}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" fixed="right" prop="address" label="操作" width="80px">
                  <template slot-scope="scope">
                    <el-button @click="del(scope.$index)" type="danger"
                      size="small">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div style="text-align: center;">
                <el-button type="success" @click="addPlan">新增一条</el-button>
              </div>
              <div style="text-align: center;margin-top: 20px">
                <el-button v-loading="loading" :disabled="eleVarieties != addPlanForm.varieties" type="primary" @click="addPlanConfirm">确定</el-button>
                <el-button type="info" @click="addPlanDialogClose">取消</el-button>
              </div>
          </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
    </div>
  </el-dialog>
</template>

<script>
  import { formatDate } from '@/util';
  export default {
    components: {
    },
    props: ['planInfo', 'eleTypeList'],

    data() {
      return {
        visible: true,
        calendarEvents: [],
        eventsListTime: [],
        maintenanItemList: [],
        tableKey: 0,
        errorDateKey: [],
        errorTypeKey: [],
        errorItemIdKey: [],
        loading: false,

        maintenanType: [{
          value: 1,
          label: '半月保'
        }, {
          value: 2,
          label: '月度保'
        }, {
          value: 3,
          label: '季度保'
        }, {
          value: 5,
          label: '半年保'
        }, {
          value: 4,
          label: '年度保'
        }],

        // 表格参数
        addPlanForm: {
        },
        addPlanRules: {
          planName: [{
            required: true,
            message: '可输入中文，大小写字母，特殊符号且长度为4至20',
            min: 4,
            max: 20,
            trigger: 'blur',
          }],
          varieties: [{
            required: true,
            message: '请选择电梯品种',
            trigger: 'change',
          }],
        },


        eleVarieties: '',
        newEleTypeList: this.eleTypeList
      }
    },


  computed:{
      pickerOptions() {
          return {
              disabledDate:(time) =>{
                  let date = time.getTime()
                  return this.eventsListTime.includes(date) || date < new Date().getTime()
              }
          }
      },
  },

    created() {
    },
    mounted() {
      this.$nextTick(() => {
        this.addPlanForm = this.planInfo
        
        this.eleVarieties = this.planInfo.eleVarieties
        if (this.eleVarieties != this.planInfo.varieties) {
          this.calendarEvents.map(item => item.maintainItemId = '')
          this.newEleTypeList = this.newEleTypeList.filter(item => item.value == this.planInfo.eleVarieties)
          this.$message.error('当前设置与该电梯的电梯品种不一致，请进行修改')
        }
        this.getList()
      })
    },

    methods: {
      handleWheel(){
        const dropdowns = document.querySelectorAll('.el-select-dropdown');
        dropdowns.forEach((dropdown) => {
          if (dropdown.style.display !== 'none') {
            dropdown.style.display = 'none';
            dropdown.blur();
          }
        });
        const dropdowns1 = document.querySelectorAll('.el-date-picker');
        dropdowns1.forEach((dropdown) => {
          dropdown.blur()
          if (dropdown.style.display !== 'none') {
            dropdown.style.display = 'none';
          }
        });
      },
      // 获取维保项类目
      getList() {
        this.maintenanItemList=[]
        const data = {
          current: 1,
          size: 999,
          isActive: 1,
          varieties: this.eleVarieties
        }
        this.$http.post('/api/ele/web/maintainItem/getList', data).then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.maintenanItemList = data.records ?? []
            this.getPlanList(this.planInfo.id)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      getPlanList(planId){
        const data = { planId }
         this.$http.post('/api/ele/web/maintainTask/getUncompletedTaskList', data)
          .then((res) => {
            if (res.data.code === 200) {
              this.calendarEvents = res.data.data
              this.calendarEvents.map(item => {
                item.maintainItemId = this.maintenanItemList.find(itemList => itemList.id == item.maintainItemId) ? item.maintainItemId : ''
                item.planStartTime = new Date(item.startTime)
                item.isConflict = 0
                this.eventsListTime.push(new Date(item.startTime).getTime())
              })
              this.calendarEvents = this.calendarEvents.sort((a, b) => new Date(a.planStartTime).getTime() - new Date(b.planStartTime).getTime())
              
              if (this.eleVarieties != this.planInfo.varieties) {
                this.calendarEvents.map(item => item.maintainItemId = '')
              }
            }
          })
      },

      changeEleType(){
        if (this.calendarEvents.find(item => item.maintainItemId)) {
          this.$message.warning('修改电梯品种，需重新选择维保项')
        }
        this.getList()
      },

      // 冲突排序
      handleSort(column) {
        if (column.order == 'ascending') {
          this.calendarEvents = this.calendarEvents.sort((a, b) => a.isConflict - b.isConflict)
        } else{
          this.calendarEvents = this.calendarEvents.sort((a, b) => b.isConflict - a.isConflict)
        }
      },

      // 改变日期
      changeStart(row,index){
        this.tableKey++
        this.calendarEvents = this.calendarEvents.sort((a, b) => new Date(a.planStartTime).getTime() - new Date(b.planStartTime).getTime())
        this.getError()
        this.eventsListTime = []
        this.calendarEvents.map(item => {
          this.eventsListTime.push(item.planStartTime.getTime())
        })
      },
      // 改变维保类型
      changeType(row){
        row.maintainItemId = ''
        this.getError()
      },
      // 改变维保类型
      changeItem(){
        this.getError()
      },

      getError(){
        this.errorDateKey = []
        this.errorTypeKey = []
        this.errorItemIdKey = []
        this.calendarEvents.map((item, index) => {
          if (item.planStartTime == '') {
            this.errorDateKey.push(index)
          }
          if (item.maintainType == '') {
            this.errorTypeKey.push(index)
          }
          if (item.maintainItemId == '') {
            this.errorItemIdKey.push(index)
          }
        })
      },

      // 检查冲突任务
      checkConflict(type){
        this.getError()
        if (this.errorDateKey.length > 0) {
          this.loading = false
          this.$message.error('存在未填写的日期')
          return
        }
        if (this.errorTypeKey.length > 0) {
          this.loading = false
          this.$message.error('存在未填写的维保类型')
          return
        }
        if (this.errorItemIdKey.length > 0) {
          this.loading = false
          this.$message.error('存在未填写的维保项')
          return
        }

        let taskList = []
        let taskListUpdate = []
        this.calendarEvents.map(item => {
          taskList.push({
            startTime: item.planStartTime.getTime()
          })
          taskListUpdate.push({
            maintainType: item.maintainType,
            maintainItemId: item.maintainItemId,
            startTime: item.planStartTime.getTime()
          })
        })
        let data = {
          id: this.planInfo.id,
          eleIds: this.planInfo.eleIds,
          taskList
        }
        this.$http
          .post('/api/ele/web/maintainPlan/checkConflict', data)
          .then((res) => {
            if (res.data.code === 200) {
              if (type == 'check') {
                this.calendarEvents.map(item => {
                  item.isConflict = 0
                })
                this.tableKey++
                this.$message.success('未发现冲突维保任务')
              } else {
                 let dataUpdata = {
                    id: this.planInfo.id,
                    eleId: this.planInfo.eleId,
                    planName: this.addPlanForm.planName,
                    taskList: taskListUpdate
                  }
                  this.$http
                    .post('/api/ele/web/maintainPlan/updateNewPlan', dataUpdata)
                    .then((PlanRes) => {
                      if (PlanRes.data.code === 200) {
                        this.loading = false
                        this.$message.success(PlanRes.data.msg)
                        this.addPlanDialogClose()
                      } else {
                        this.loading = false
                        this.$message.error(PlanRes.data.msg)
                      }
                    })
              }
            } else {
              const {
                data: {
                  data
                },
              } = res
              this.calendarEvents.map(item => {
                if (data.find(dataItem => new Date(dataItem).getTime() == item.planStartTime.getTime())) {
                  item.isConflict = 1
                }
              })
              this.tableKey++
              this.loading = false
              this.calendarEvents = this.calendarEvents.sort((a, b) => b.isConflict - a.isConflict)
              this.$message.error('当前计划与已有计划冲突，部分日期已存在维保任务')
            }
          })
      },
      addPlan(){
        this.calendarEvents.push({
          planStartTime: '',
          maintainType: 1,
          maintainItemId: '',
          isConflict: 0
        })
      },
      del(index){
        this.calendarEvents.splice(index, 1)
        this.eventsListTime.splice(index, 1)
        this.errorDateKey = []
        this.errorTypeKey = []
        this.errorItemIdKey = []
        this.calendarEvents.map((item, index) => {
          if (item.planStartTime == '') {
            this.errorDateKey.push(index)
          }
          if (item.maintainType == '') {
            this.errorTypeKey.push(index)
          }
          if (item.maintainItemId == '') {
            this.errorItemIdKey.push(index)
          }
        })
      },
      addPlanConfirm(){
        this.loading = true
        this.checkConflict()
      },

      addPlanDialogClose() {
        this.visible = false
        this.$emit('closeDialog')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .addPlanForm {
    padding-right: 35px;
  }
  .maintenanTypeLabel{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 30px;
  }
  .hidden-select-dropdown {
    display: none !important;
  }

  /deep/.el-form-item {
    margin-bottom: 32px;
  }

  /deep/.el-input-group__prepend {
    width: 40%;
  }

  >>>.el-input-group__append {
    background: #409eff;
    color: #fff;
  }

  >>>.el-input-group__prepend {
    width: 25%;
    padding: 0px;
    background: #409eff;

    .el-select {
      margin: -1px;

      .el-input__inner {
        color: #fff !important;
      }

      ::-webkit-input-placeholder {
        color: #fff;
      }
    }
  }

  .datepicker {
    >>>.el-form-item__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
</style>
