<!-- <template>
  <div class="app-container">
    <div ref="Mychart" :style="{width:'100%',height:'100%'}"></div>
  </div>
</template> -->
<template>
  <div class="echart">
    <div>
      <div class="divType">
        <span>井道信号强度&nbsp;&nbsp;（{{curveInfo.floor}}楼）</span>
        <span>{{ Math.trunc((curveInfo.latitude)*10000000)/10000000}}&nbsp;&nbsp; {{Math.trunc((curveInfo.longitude)*10000000)/10000000}}</span>
      </div>
      <div class="divType">
        <span>运营商：{{Data.networkOperator}}</span>
        <span>BAND:{{Data.band}}</span>
      </div>
      <div class="divType">
        <span>信号曲线</span>
        <div style="font-size: 12px;">
          <span>RSSI:{{rSSIInfo}}</span>
          <span style="margin:0px 20px;">RSRP:{{rSRPInfo}}</span>
          <span>RSRQ:{{rSRQInfo}}</span>
        </div>
      </div>
    </div>
    <div v-if="Data" class="echart" id="mychart" :style="myChartStyle">
    </div>
  </div>
</template>
<script>
  import * as echarts from "echarts";
  export default {
    props: ['curveInfo'],
    data() {
      return {
        myChart: {},
        xData: [],
        //横坐标y
        yRSSI: [],
        yRSRP: [],
        yRSRQ: [],
        rSSIInfo: '',
        rSRPInfo: '',
        rSRQInfo: '',
        yData: [],
        Data: [],
        //人数数据
        myChartStyle: {
          float: "left",
          width: "100%",
          height: "277px"
        }
        //图表样式
      };
    },
    mounted() {
      this.initEcharts();
    },
    methods: {
      initEcharts() {
        this.Data = JSON.parse(this.curveInfo.networkData)
        const list = this.Data.signal

        for (var i = 0; i < list.length; i++) {
          this.xData.push(list[i].date)
          this.yRSSI.push(list[i].rSSI)
          this.yRSRP.push(list[i].rSRP)
          this.yRSRQ.push(list[i].rSRQ)
        }
        this.rSSIInfo = list[list.length - 1].rSSI
        this.rSRPInfo = list[list.length - 1].rSRP
        this.rSRQInfo = list[list.length - 1].rSRQ
        const option = {
          xAxis: {
            data: this.xData,
            triggerEvent: true,
            boundaryGap: false
          },
          yAxis: {
            min: -170,
            max: -40,
          },
          series: [{
            name: 'RSSI',
            data: this.yRSSI,
            type: "line",
            smooth: true,
          }],
        };
        this.myChart = echarts.init(document.getElementById("mychart"));

        this.myChart.getZr().on("click", params => {
          let pointInPixel = [params.offsetX, params.offsetY];
          if (this.myChart.containPixel("grid", pointInPixel)) {
            let xIndex = this.myChart.convertFromPixel({
                seriesIndex: 0
              },
              pointInPixel
            )[0];
            this.rSSIInfo = this.Data.signal[xIndex].rSSI
            this.rSRPInfo = this.Data.signal[xIndex].rSRP
            this.rSRQInfo = this.Data.signal[xIndex].rSRQ
          }
        })

        this.myChart.setOption(option);
        //随着屏幕大小调节图表
        window.addEventListener("resize", () => {
          this.myChart.resize();
        });
      },

    }
  };
</script>

<style scoped>
  .divType {
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;
    padding-top: 5px;
  }
</style>
