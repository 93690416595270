<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" ref="searchForm" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="searchForm.elevatorName" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
        <span slot="label">
            <span class="lab-span">所属区域</span>
        </span>
        <el-select v-model="searchForm.regionCode" placeholder="请输入" clearable filterable>
            <el-option v-for="(item, index) in areaList" :key="index" :label="item.dictLabel" :value="item.dictValue" />
        </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">设备代码</span>
          </span>
          <el-input v-model="searchForm.deviceNo" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
              <span class="lab-span">状态</span>
          </span>
          <el-select v-model="searchForm.status" placeholder="请输入" clearable filterable>
              <el-option label="未接单" :value="0" />
              <el-option label="已接单" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
              <span class="lab-span">添加时间</span>
          </span>
          <el-date-picker
              v-model="searchForm.createTime"
              type="daterange"
              clearable
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

        <template v-if="moreSearch">
          <el-form-item>
            <span slot="label">
              <span class="lab-span">电梯安全码</span>
            </span>
            <el-input v-model="searchForm.securityCode" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">设备注册代码</span>
            </span>
            <el-input v-model="searchForm.deviceSn" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">被困人电话</span>
            </span>
            <el-input v-model="searchForm.trappedPersonTel" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">维保单位名称</span>
            </span>
            <el-input v-model="searchForm.maintCompany" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">救援人员名称</span>
            </span>
            <el-input v-model="searchForm.handler" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">救援人员电话</span>
            </span>
            <el-input v-model="searchForm.handleContactTel" placeholder="请输入" clearable style="width:200px" />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">电梯详细地址</span>
            </span>
            <el-input v-model="searchForm.community" placeholder="请输入" clearable style="width:200px" />
          </el-form-item>
        </template>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="onRefer(true)">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
          <el-button v-if="!moreSearch" type="success" class="searchBtn" @click="moreBtn">高级查询</el-button>
          <el-button v-else type="success" class="searchBtn" @click="packMore">收起</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <div v-if="dictionarTotal != 0">
        <el-table ref="multipleTable" :data="tableData"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px" max-height="550">
          <el-table-column align="center" prop="trappedPersonTel" label="被困人员电话" width="200px" />
          <el-table-column align="center" prop="elevatorName" label="电梯名称" width="150px" />
          <el-table-column align="center" prop="deviceNo" label="设备代码" width="150px" />
          <el-table-column align='center' label="状态" width="180px">
            <template slot-scope="scope">
              <span :class="scope.row.status == 0 ? 'redTxt' : 'greenTxt'">
                {{scope.row.status == 0 ? '未接单' : '已接单'}}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="region" label="电梯所属区域" width="150px" />
				  <el-table-column align='center' prop="community" label="电梯详细地址" min-width="120px"/>
          <el-table-column align="center" prop="createDate" label="报警时间" width="150px" />
          <el-table-column align="center" prop="handler" label="处理人" width="150px" />
          <el-table-column align="center" prop="maintCompany" label="维保单位" width="150px" />
          <el-table-column align="center" prop="handleContactTel" label="人员电话" width="150px" />
          <el-table-column fixed="right" align="center" prop="address" label="操作" width="250px">
            <template slot-scope="scope">
                <el-button @click="getOrderInfo(scope.row)" type="primary" size="small">详情</el-button>
                <el-button @click="openTimeLine()" type="primary" size="small">时间线</el-button>
                <el-button @click="del(scope.row.id)" type="danger" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="dictionarTotal" :page.sync="searchForm.pageNum" :limit.sync="searchForm.pageSize"
          :pageSizes="[10, 20, 30, 50, 100]" @pagination="dictionarPage" />
      </div>
      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </div>
    <OrderInfo v-if="orderInfoVisibile" :orderInfo="orderInfo" @close="colseCheck"/>
    <TimeLine v-if="timeLineVisibile" @close="colseTimeLine"/>

  </div>
</template>
<script>

  import Pagination from '@/components/Pagination'
  import OrderInfo from './components/orderInfo.vue';
  import TimeLine from './components/timeLine.vue';


  export default {
    components: {
        Pagination,
        OrderInfo,
        TimeLine
    },
    data() {
      return {
        searchForm: {
          pageNum: 1,
          pageSize: 10,
        },
        tableData: [],
        
        // 分页参数
        dictionarTotal: 0,
        moreSearch: false,
        areaList: [],
        orderInfo: {},
        orderInfoVisibile: false,
        timeLineVisibile: false
        
      }
    },
    methods: {
      
      // 重置按钮事件
      reset() {
        this.searchForm = {
          pageNum: 1,
          pageSize: 10,
        }
        // this.onRefer()
      },
      moreBtn() {
        this.moreSearch = true
      },
      packMore() {
        this.moreSearch = false
      },
      // 分页
      dictionarPage(data) {
        this.searchForm.pageNum = data.page
        this.searchForm.pageSize = data.limit
        // this.onRefer()
      },

      // 表格方法
      onRefer(ishm) {
        const data = JSON.parse(JSON.stringify(this.searchForm))
        data.createDateStart = this.searchForm.createTime && this.searchForm.createTime.length > 0 ? this.searchForm.createTime[0] : ''
        data.createDateEnd = this.searchForm.createTime && this.searchForm.createTime.length > 0 ? this.searchForm.createTime[1] : ''
        this.$http
          .get('http://117.73.3.135:8080/elevator-96133/work/workOrder/list', {params: data})
          .then((res) => {
            if (res.data.code == 200) {
              this.tableData = res.data.rows
              this.dictionarTotal = res.data.total
              this.$nextTick(() => {
                if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      getOrderInfo(item){
        // this.$http
        //   .get(`http://117.73.3.135:8080/elevator-96133/work/workOrder/${item.id}`)
        //   .then((res) => {
        //     if (res.data.code == 200) {
        //       this.orderInfo = res.data.data
        //       this.orderInfoVisibile = true
        //     } else {
        //       this.$message.error(res.data.msg)
        //     }
        //   })
              this.orderInfoVisibile = true
      },
      colseCheck(){
        this.orderInfoVisibile = false
        // this.onRefer()
      },
      openTimeLine(){
        this.timeLineVisibile = true
      },
      colseTimeLine(){
        this.timeLineVisibile = false
        // this.onRefer()
      },
      del(id){
        this.$confirm('此操作将永久删除选中的数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            // this.$http
            //   .delete(`http://117.73.3.135:8080/elevator-96133/work/workOrder/${id}`)
            //   .then((res) => {
            //     if (res.data.code == 200) {
            //       this.onRefer()
            //       this.$message.success(res.data.msg)
            //     } else {
            //       this.$message.error(res.data.msg)
            //     }
            //   })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      
      // 获取字典
      getNotice() {
        this.$http
          .get(`http://117.73.3.135:8080/elevator-96133/system/dict/data/type/sys_area_code`)
          .then((res) => {
            if (res.data.code == 200) {
              const {
                data: {
                  data
                },
              } = res
              this.areaList = data.rows
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
    },
    created() {
      // this.getNotice();
    },
    mounted() {
      // this.onRefer()
    }
  }
</script>
<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .greenTxt{
    background: #95e0c0;
    color: #115236;
    padding: 3px;
    font-size: 12px;
  }
  .redTxt{
    background: #fff0ef;
    color: #f5365c;
    padding: 3px;
    font-size: 12px;
  }
</style>