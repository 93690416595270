<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" ref="searchForm" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
        <span slot="label">
            <span class="lab-span">所属区域</span>
        </span>
        <el-select v-model="searchForm.areaCode" placeholder="请输入" clearable filterable>
            <el-option v-for="(item, index) in areaList" :key="index" :label="item.dictLabel" :value="item.dictValue" />
        </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">注册代码</span>
          </span>
          <el-input v-model="searchForm.deviceSn" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">设备代码</span>
          </span>
          <el-input v-model="searchForm.deviceNo" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">安全码</span>
          </span>
          <el-input v-model="searchForm.safeCode" placeholder="请输入" clearable />
        </el-form-item>

        <template v-if="moreSearch">
          <el-form-item>
            <span slot="label">
                <span class="lab-span">出厂日期</span>
            </span>
            <el-date-picker
                v-model="searchForm.factoryOutTime"
                type="date"
                clearable
                value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">维保单位名称</span>
            </span>
            <el-input v-model="searchForm.unitName" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">维保人员名称</span>
            </span>
            <el-input v-model="searchForm.handler" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">电梯详细地址</span>
            </span>
            <el-input v-model="searchForm.inVillage" placeholder="请输入" clearable style="width:200px" />
          </el-form-item>
        </template>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="onRefer(true)">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
          <el-button v-if="!moreSearch" type="success" class="searchBtn" @click="moreBtn">高级查询</el-button>
          <el-button v-else type="success" class="searchBtn" @click="packMore">收起</el-button>
          <el-button  type="success" class="searchBtn" @click="addEle">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <div v-if="dictionarTotal != 0">
        <el-table ref="multipleTable" :data="tableData"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px" max-height="550">
          <el-table-column align="center" prop="name" label="电梯名称" width="180px" />
          <el-table-column align="center" prop="inArea" label="所在区域" width="120px" />
          <el-table-column align="center" prop="inVillage" label="详细地点" min-width="150px" />
          <el-table-column align="center" prop="deviceNo" label="电梯设备代码" width="150px" />
          <el-table-column align="center" prop="deviceSn" label="电梯注册代码" width="150px" />
          <el-table-column align="center" prop="unitName" label="维保单位" width="150px" />
          <el-table-column align="center" prop="factoryOutTime" label="出厂日期" width="150px" />
          <el-table-column fixed="right" align="center" prop="address" label="操作" width="250px">
            <template slot-scope="scope">
                <el-button @click="getEleInfo(scope.row, 'detail')" type="primary" size="small">详情</el-button>
                <el-button @click="getEleInfo(scope.row, 'edit')" type="primary" size="small">编辑</el-button>
                <el-button @click="del(scope.row.id)" type="danger" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="dictionarTotal" :page.sync="searchForm.pageNum" :limit.sync="searchForm.pageSize"
          :pageSizes="[10, 20, 30, 50, 100]" @pagination="dictionarPage" />
      </div>
      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </div>
    <EleInfo v-if="eleInfoVisibile" :eleInfo="eleInfo" :actionType="actionType" @close="colseCheck"/>

  </div>
</template>
<script>

  import Pagination from '@/components/Pagination'
  import EleInfo from './components/eleInfo.vue';


  export default {
    components: {
        Pagination,
        EleInfo
    },
    data() {
      return {
        searchForm: {
          pageNum: 1,
          pageSize: 10,
        },
        tableData: [],
        
        // 分页参数
        dictionarTotal: 0,
        moreSearch: false,
        areaList: [],
        eleInfo: {},
        actionType: null,
        eleInfoVisibile: false
        
      }
    },
    methods: {
      
      // 重置按钮事件
      reset() {
        this.searchForm = {
          pageNum: 1,
          pageSize: 10,
        }
        // this.onRefer()
      },
      moreBtn() {
        this.moreSearch = true
      },
      packMore() {
        this.moreSearch = false
      },
      // 分页
      dictionarPage(data) {
        this.searchForm.pageNum = data.page
        this.searchForm.pageSize = data.limit
        // this.onRefer()
      },

      // 表格方法
      onRefer(ishm) {
        const data = JSON.parse(JSON.stringify(this.searchForm))
        this.$http
          .get('http://117.73.3.135:8080/elevator-96133/elevator/info/list', {params: data})
          .then((res) => {
            if (res.data.code == 200) {
              this.tableData = res.data.rows
              this.dictionarTotal = res.data.total
              
              this.dictionarTotal = 1
              this.$nextTick(() => {
                if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      addEle(){
        this.actionType = 'add'
        this.eleInfo = {}
        this.eleInfoVisibile = true
      },
      getEleInfo(item, type){
        this.actionType = type
        // this.$http
        //   .get(`http://117.73.3.135:8080/elevator-96133/elevator/info/${item.id}`)
        //   .then((res) => {
        //     if (res.data.code == 200) {
        //       this.eleInfo = res.data.data
        //       this.eleInfoVisibile = true
        //     } else {
        //       this.$message.error(res.data.msg)
        //     }
        //   })
        this.eleInfoVisibile = true

      },
      colseCheck(){
        this.eleInfoVisibile = false
        // this.onRefer()
      },
      del(id){
        this.$confirm('此操作将永久删除选中的数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            // this.$http
            //   .delete(`/workOrder/work/workOrder/${id}`)
            //   .then((res) => {
            //     if (res.data.code == 200) {
            //       this.onRefer()
            //       this.$message.success(res.data.msg)
            //     } else {
            //       this.$message.error(res.data.msg)
            //     }
            //   })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      
      // 获取字典
      getNotice() {
        this.$http
          .get(`http://117.73.3.135:8080/elevator-96133/system/dict/data/type/sys_area_code`)
          .then((res) => {
            if (res.data.code == 200) {
              const { data: { data} } = res
              this.areaList = data
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
    },
    created() {
      // this.getNotice();
    },
    mounted() {
      // this.onRefer()
    }
  }
</script>
<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .greenTxt{
    background: #95e0c0;
    color: #115236;
    padding: 3px;
    font-size: 12px;
  }
  .redTxt{
    background: #fff0ef;
    color: #f5365c;
    padding: 3px;
    font-size: 12px;
  }
</style>