<template>
  <el-dialog v-if="eleAboutDialog" title="关联电梯" :visible.sync="eleAboutDialog" :close-on-click-modal="false"
    width="1400px" top="10vh" @close="closeDialog">
    <div style="height: 67vh; overflow-y: auto; padding-right: 20px" class="scrollElement">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="formInline.name" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">救援识别码</span>
          </span>
          <el-input v-model="formInline.rescueCode" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯注册代码</span>
          </span>
          <el-input v-model="formInline.code" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
          <el-button type="primary" @click="addEle" v-if="looks != false|| eleType == 'personType'">添加电梯</el-button>
        </el-form-item>
      </el-form>
      <div style="margin-bottom: 20px;">
        <el-button type="danger" v-if="eleType == 'personType'" @click="delArr()">批量移除</el-button>
      </div>
      <div v-if="total!=0">
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData"
          style="width: 100%;margin-bottom: 20px;" :height="$store.state.tabHeight-140"
          @select="changeSelect" @select-all="chengeSelection">
          <el-table-column align='center' type="selection" v-if="selShow"/>
          <el-table-column align='center' type="index" label="序号" width="50px" />
          <el-table-column align='center' prop="name" label="电梯名称" width="150px" />
          <el-table-column align='center' prop="code" label="电梯注册代码" width="150px" />
          <el-table-column align='center' prop="rescueCode" label="救援识别码" width="150px" />
          <el-table-column v-if="!$store.state.isWBJB" align='center' prop="deviceCode" label="AI摄像头ID" width="150px" />
          <el-table-column v-if="!$store.state.isWBJB" align='center' prop="terminalId" label="电梯数字终端ID" width="150px" />
          <el-table-column align='center' prop="plotName" label="所属小区" width="150px" />
          <el-table-column v-if="!$store.state.isWBJB" align='center' label="使用场所" width="150px">
            <template slot-scope="scope">
              {{
                    scope.row.elePlaceType ?
                      (elePlaceList.find(item => item.value == scope.row.elePlaceType) ?
                        elePlaceList.find(item => item.value == scope.row.elePlaceType).label : '-')
                    : '-'
                  }}
            </template>
          </el-table-column>
          <el-table-column align='center' prop="propertyUnitName" label="物业单位" width="150px" />
          <el-table-column align='center' prop="propertyUnitUserNames" label="物业安全员" width="150px" />
          <el-table-column align='center' prop="maintainUnitName" label="维保单位" width="150px" />
          <el-table-column align='center' prop="maintainUserNames" label="维保人员" width="150px" />
          <el-table-column v-if="!$store.state.isWBJB" align='center' prop="insuranceUnitName" label="保险单位" width="150px" />
          <el-table-column v-if="!$store.state.isWBJB" align='center' prop="insuranceUnitUserNames" label="保险人员" width="150px" />
          <el-table-column v-if="!$store.state.isWBJB" align='center' label="楼层学习">
            <template slot-scope="scope">
              {{
                    scope.row.isInit ? '已完成' : '未完成'
                  }}
            </template>
          </el-table-column>
          <el-table-column align='center' prop="eleAddress" label="电梯安装位置" width="200px" />
          <el-table-column align='center' prop="createTime" label="添加时间" />
          <el-table-column align='center' prop="createUserName" label="创建人" />
          <el-table-column v-if="!$store.state.isWBJB" align="center" label="识别状态" width="120px">
            <template slot-scope="scope">
              {{
                    scope.row.recognitionState ?
                      (recongtion.find(item => item.value == scope.row.recognitionState) ?
                        recongtion.find(item => item.value == scope.row.recognitionState).label : '-')
                    : '-'
                  }}
            </template>
          </el-table-column>
        </el-table>
        <Pagination ref="pageCom" :total="total" :page.sync="formInline.current" :limit.sync="formInline.size"
          @pagination="dictionarPage" />
      </div>
      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="dioSub" v-if="selShow">确 定</el-button>
      <el-button size="small" @click="closeDialog" v-if="selShow">取 消</el-button>
    </div>
    <AddEle v-if="addEleDialog" :eleType="eleType" :unitId="unitIds" :userId="userId" :unitType="unitTypes"
      :orgType="orgType" :relaredList="relaredList" :elePlaceList="elePlaceList" :recongtion="recongtion"
      :tableDataArr="eleAbout" @colseAddEle="colseAddEle" />
    <ChangeUnit ref="changeUnit" :personnelData="personnelData" :unitList="unitChangeList"
      :changeUnitType="changeUnitType" />
  </el-dialog>
</template>
<script>
  import Pagination from '@/components/Pagination'
  import AddEle from './addEleFrom';
  import ChangeUnit from '../rygl/components/changeUnit.vue'
  export default {
    components: {
      Pagination,
      AddEle,
      ChangeUnit
    },
    props: ['eleType', 'orgType', 'unitType', 'unitId', 'userId', 'selShow', 'personnelData', 'unitChangeList',
      'changeUnitType'
    ],
    data() {
      return {
        looks: true,
        loading: false,
        formInline: {
          current: 1,
          size: 10
        },
        unitDate: {},
        unitTypes: '',
        unitIds:'',
        eleShow: false,
        changeArr: [], //批量删除数组
        tableData: [], // 关联电梯列表
        total: 0,
        eleAboutDialog: false,
        eleAbout: [], // 勾选关联电梯
        addEleDialog: false,
        recongtion: [], // 识别状态
        elePlaceList: [], // 使用场所
        relaredList: [], // 角色
        tableLoading: true
      };
    },
    filters: {},
    methods: {
      backUnit() {
        this.$http.get(`/api/system/web/appUser/detail?id=${this.personnelData.id}`).then((res) => {
          if (res.data.code == 200) {

            this.unitTypes = this.unitType ? this.unitType : res.data.data.orgType
            this.unitIds = this.unitId ? this.unitId : res.data.data.id
            this.onRefer(true)
          }
        })

      },
      changeSelect(event, row) { //单选
        // console.log(event,'单选')
        if (this.changeArr.find((item) => item.id === row.id)) {
          this.changeArr = this.changeArr.filter((item) => item.id !== row.id);
        } else {
          this.changeArr.push(row);
        }
        console.log(this.changeArr, ' this.changeArr3')
      },
      chengeSelection(event) { //全选
        // console.log(event,'全选')
        // this.changeArr=event
        let stateArr = [];
        if (event.length > 0) {
          stateArr = JSON.parse(JSON.stringify(event));
          const arr = [...event, ...this.changeArr];
          // 去重
          const res = new Map();
          this.changeArr = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1));
          console.log(this.changeArr, 'this.changeArr 1')
        } else {
          stateArr = JSON.parse(JSON.stringify(this.tableData));
          stateArr.map((item) => {
            if (this.changeArr.find((row) => row.id === item.id)) {
              this.changeArr = this.changeArr.filter((row) => row.id !== item.id);
            }
          })
        }
        console.log(this.changeArr, ' this.changeArr')
      },
      delArr() { //批量删除
        console.log(this.changeArr, ' this.changeArr')
        let delArr = []
        this.changeArr.forEach((item) => {
          delArr.push(item.id)
        })
        console.log(this.changeArr, '移除')
        if (this.changeArr.length < 1) {
          this.$message.warning('请选择要移除的电梯')
        } else {
          this.$confirm('此操作将移除选中的电梯, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            const data = {
              appUserId: this.userId,
              eleIdList: delArr
            }
            console.log(data, 'data')
            this.$http.post('/api/ele/web/eleUser/removeEleUserById', data).then((res) => {
              console.log(res, 'res')
              if (res.data.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
                this.changeArr = []
                this.eleAbout = []
                this.onRefer()
                this.onReferInit()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
          })
        }
      },
      // 获取字典
      getNotice(code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.recongtion = data.RECOGNITION_STATE
              this.elePlaceList = data.USE_PLACE
              this.relaredList = data.RELATED_CODES
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 重置按钮事件
      reset() {

        this.formInline = {
          current: 1,
          size: 10
        }
        this.onRefer()
      },
      // 分页
      dictionarPage(data) {
        console.log('分页触发了')
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },

      // 添加绑定电梯列表
      addEle() {
        console.log(this.looks && this.eleShow, 'looks')
        if (this.looks || this.eleShow) {
          this.addEleDialog = true
        } else {
          this.$confirm('当前人员所属单位不存在，请先关联单位或更换单位。是否进行更换单位？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确认',
            type: 'warning'
          }).then(() => {
            this.$refs.changeUnit.init((data) => {
              // this.onRefer(true)
              this.backUnit()
              this.eleShow = true
            });
          }).catch(() => {})
        }
      },
      // 关闭绑定电梯列表
      colseAddEle() {
        this.addEleDialog = false
        this.onRefer()
        this.onReferInit()
      },
      // 表格方法
      onRefer(ishm) {
        if (ishm) {
          this.formInline.current = 1
        }
        const data = JSON.parse(JSON.stringify(this.formInline))
        // 人员关联
        if (this.eleType == 'personType') {
          data.userId = this.userId
        } else if (this.eleType == 'otherType') {
          data.registrationAuthorityId = this.userId
        } else {
          // 单位关联
          switch (this.unitTypes) {
            case 'MAINTAIN_UNIT':
              data.maintainUnitId = this.unitIds
              break;
            case 'TENEMENT_UNIT':
              data.propertyUnitId = this.unitIds
              break;
            case 'INSURANCE_UNIT':
              data.insuranceUnitId = this.unitIds
              break;
            case 'RESCUE_UNIT':
              data.emergencyRescueUnitId = this.unitIds
              break;
          }
        }
        this.tableLoading = true
        this.$http
          .post("api/ele/web/elevatorInfo/list", data)
          .then((res) => {

            this.tableData = res.data.data.records
            this.total = res.data.data.total
            this.$nextTick(() => {
              if (this.total > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
            this.tableLoading = false
          })
      },
      onReferInit() {
        const eleAboutAll = JSON.parse(JSON.stringify(this.formInline))

        console.log(this.unitId, this.eleType);
        // 人员关联
        if (this.eleType == 'personType') {
          eleAboutAll.userId = this.userId
        } else if (this.eleType == 'otherType') {
          eleAboutAll.registrationAuthorityId = this.userId
        } else {
          // 单位关联
          switch (this.unitTypes) {
            case 'MAINTAIN_UNIT':
              eleAboutAll.maintainUnitId = this.unitIds
              break;
            case 'TENEMENT_UNIT':
              eleAboutAll.propertyUnitId = this.unitIds
              break;
            case 'INSURANCE_UNIT':
              eleAboutAll.insuranceUnitId = this.unitIds
              break;
            case 'RESCUE_UNIT':
              eleAboutAll.emergencyRescueUnitId = this.unitIds
              break;
          }
        }
        eleAboutAll.size = 9999
        this.$http
          .post("api/ele/web/elevatorInfo/list", eleAboutAll)
          .then((res) => {
            this.eleAbout = res.data.data.records ?? []
          })
      },
      // 弹出框提交
      dioSub() {
        console.log(this.eleAbout, 'eleAbout');
        this.closeDialog();
      },
      closeDialog() {
        this.eleAboutDialog = false
        this.$emit("colseEleFrom");
      }
    },

    created() {
      this.looks = this.selShow
      this.unitTypes = this.unitType ? this.unitType : ''
      this.unitIds = this.unitId ? this.unitId :""
      this.eleAboutDialog = true
      this.getNotice(['RECOGNITION_STATE', 'USE_PLACE', 'RELATED_CODES'])
      this.onRefer(true)
      this.onReferInit()
    },
    mounted() {
      this.$nextTick(() => {});
    },
    destroyed() {
      console.log('销毁');
    }
  };
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchBtn {
    margin-left: 15px;
  }

  .wid90 {
    width: 95%;
  }
</style>
