<template>
  <div>
    <el-dialog v-if="visible" title="对公转账" :visible.sync="visible" append-to-body :close-on-click-modal="false"
      width="1100px" @close="bakc">
      <div style="height: 50vh;overflow-y: scroll;line-height: 40px;padding: 0px 20px;" class="scrollElement">
        <div style="margin: 0px 30px;">
          <div>应付金额<span class="wordColor" style="font-size: 22px;">{{formList.amount}}</span>元</div>
          <div>充值订单提交成功，请尽快付款！充值订单号：{{formList.orderNumber}}</div>
          <div>请您在 <span class="wordColor">{{orderTime}}</span> 内完成支付，否则充值订单会被自动取消</div>
        </div>
        <el-card>
          <div>线下转账注意事项</div>
          <div style="line-height: 20px;">
            您的转账识别码 <span class="wordColor"> {{formList.transferIdentificationCode}}</span> 。转账时填写用途/备注/摘要栏，便于快速核销款项。
            请务必使用公司对公账号进行转账。
            下单后请尽快转账，务必保证转账金额与充值订单金额一致，请勿多转、少转和分次转账，
            否则影响订单对账进度，3天内未对账系统自动取消订单。
          </div>
          <div>
            <span>收款银行信息：</span>
            <div style="line-height: 30px;">
              <div class="bank_Info">
                <span>收款人户名</span>
                <div>
                  <span>{{bankInfo.bankName}}</span>
                  <el-button type="text" class="text_info" @click="copy(bankInfo.bankName)">复制</el-button>
                </div>
              </div>
              <div class="bank_Info">
                <span>开户银行</span>
                <div>
                  <span>{{bankInfo.bank}}</span>
                  <el-button type="text" class="text_info" @click="copy(bankInfo.bank)">复制</el-button>
                </div>
              </div>
              <div class="bank_Info">
                <span>银行卡号</span>
                <div>
                  <span>{{bankInfo.bankNumber}}</span>
                  <el-button type="text" class="text_info" @click="copy(bankInfo.bankNumber)">复制</el-button>
                </div>
              </div>
              <div class="bank_Info">
                <span>用途/备注/摘要栏</span>
                <div>
                  <span>{{formList.transferIdentificationCode}}</span>
                  <el-button type="text" class="text_info"
                    @click="copy(formList.transferIdentificationCode)">复制</el-button>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="srue">我已转账</el-button>
        <el-button @click="bakc">取消</el-button>
      </span>
      <el-dialog v-if="visibleStock" title="提交转账凭证" :visible.sync="visibleStock" append-to-body
        :close-on-click-modal="false" width="700px" @close="Stockbakc" style="margin-top: 50px;">
        <div style="height: 30vh;overflow-y: scroll;line-height: 40px;padding: 0px 20px;" class="scrollElement">
          <!-- <el-checkbox v-model="checked"> -->
          <div style="display: flex;">
            <!-- <div class="icons_size"> -->
            <i class="el-icon-warning-outline icons_size"></i>
            <!-- </div> -->
            <div style="line-height: 25px; margin-left: 50px;">
              <div>1.请<span class="务必在转账成功">务必在转账成功</span>的情况下填写转账凭证。</div>
              <div>2.提交正确的凭证将大大提升审核对账速度。</div>
              <div>3.规定时间内转账成功，未提交转账凭证，不影响后续的对账过程</div>
            </div>
          </div>
          <el-form label-position="right" style="margin-top: 30px;" label-width="160px" ref="subFrom" :model="subFrom"
            :rules="formRules">
            <el-form-item label="转账单号/交易流水号" prop="payNumber">
              <el-input placeholder="请输入" style="width: 70%;" v-model.trim="subFrom.payNumber"></el-input>
            </el-form-item>
            <el-form-item label="电子回单" prop="electronicReceipt">
              <div style="height: 30px;">
                <FileUpload :md5s.sync="subFrom.electronicReceipt" :imgList="['jpg','jpeg','png']" :multiple="false" :limit="1" :url="url" />
                <div class="img_page">
                  单张图片请选择'jpg','jpeg','png'格式！文件大小不超过2MB</div>
              </div>
              <!-- <span v-else @click="openFile(contractForm.fileUrl )">{{ contractForm.fileUrl }}</span> -->
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submit">确认提交</el-button>
          <el-button @click="Stockbakc">取消</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
  import JSONbig from "json-bigint"
  import FileUpload from '../../../../components/FileUploadPWP.vue'
  export default {
    props: ['datas','code'],
    components: {
      FileUpload
    },
    data() {
      return {
        visible: false,
        url: '/api/file/web/uploadByOSS', // 上传地址
        visibleStock: false,
        formList: [],
        subFrom: {
          payNumber: '',
          electronicReceipt: ''
        },
        // endDate: 3 * 24 * 60 * 60, // 初始化倒计时为三天
        timer: '',
        endDate: '',
        bankInfo: {
          bankName: '中泽信技术（深圳）有限公司',
          bank: '中国工商银行股份有限公司深圳侨香支行',
          bankNumber: '4000051309100245042',
        },
        // days: '',
        // checked: false,
        // hours: '',
        // minutes: '',
        // seconds: '',
        orderTime: '',
        formRules: {
          payNumber: [{
            required: true,
            message: '转账单号/交易流水号不能为空',
            trigger: 'blur'
          }],
          electronicReceipt: [{
            required: true,
            message: '电子回单不能为空',
            trigger: 'blur'
          }]
        }
      }
    },
    mounted() {
      this.getDate()
      this.startCountdown();
      this.visible = true
    },
    methods: {
      startCountdown() {
        const time = this.datas.createTime
        const now = new Date().getTime();
        const enDate = new Date(time).getTime() + 3 * 24 * 60 * 60 * 1000 - 1000
        this.endDate = time ? (enDate - now) / 1000 : 3 * 24 * 60 * 60
        this.timer = setInterval(() => {
          this.endDate--;
          this.orderTime = this.propmpt(this.endDate)
          if (this.endDate == 0) {
            clearTimeout(this.timer)
            return
          }
        }, 1000)

        // const distanceToEnd = enDate - now
        // setInterval(() => {
        //   const distanceToEnd = enDate - now
        //   if (distanceToEnd <= 0) {
        //     clearInterval(timer); // 如果倒计时已经完成，则清除定时器
        //   }
        //   // } else {
        //   //   distanceToEnd = enDate - now; // 否则减少剩余时间
        //   // }
        //   this.orderTime = this.propmpt(distanceToEnd)
        // }, 1000);
        // endCountdown() {
        //   clearInterval(timer); // 清除定时器
        // }
      },
      propmpt(value) {
        const days = Math.floor(value / (60 * 60 * 24))
        const hour = Math.floor((value / (60 * 60)) - (days * 24))
        const minute = Math.floor((value / 60) - (days * 24 * 60) - (hour * 60))
        const second = Math.floor((value) - (days * 24 * 60 * 60) - (hour * 60 * 60) - (minute * 60))
        return days + '天' + hour + '时' + minute + '分' + second + '秒'
      },
      srue() {
        this.visibleStock = true
        // this.$emit('close')
      },
      submit() {
        const data = {
          id: this.formList.id,
          amount: this.formList.amount,
          collectionAccount: this.bankInfo.bankName,
          collectionBank: this.bankInfo.bank,
          collectionCard: this.bankInfo.bankNumber,
          payNumber: this.subFrom.payNumber,
          electronicReceipt: this.subFrom.electronicReceipt
        }
        console.log( '提交')
        // if (this.checked == false) {
        //   return this.$message({
        //     type: 'error',
        //     message: '请勾选后提交'
        //   })
        // }

        this.$refs.subFrom.validate(async (valid) => {
          if (valid) {
            this.$http.post('/api/system/web/recharge/confirmTransfer', data).then(res => {
              if (res.data.code == 200) {
                this.$alert('提交成功，管理员将尽快审核您的转账信息，请耐心等待', '提示', {
                  confirmButtonText: '确定',
                  // cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.visible = false
                  this.visibleStock = false
                  this.$emit('closeDialog')
                }).catch(() => {});
              }else{
                this.$message.error(res.data.msg)
              }
            })
          }
        })

      },
      getDate() {
        const data = {
          crrent: 1,
          size: 1,
          orderNumber: this.datas ? this.datas.orderNumber : '',
          orgId: this.datas ? this.datas.orgId : '',
          code:this.code ? this.code : ''
        }
        // const data = {
        //   crrent: 1,
        //   size: 1,
        //   orgId: this.datas.orgId ? this.datas.orgId : ''
        // }
        this.$http.post('/api/system/web/recharge/getOrgRechargeRecordList', data).then(res => {

          if (res.data.code == 200) {
            this.formList = res.data.data.records[0]
          }
        })
      },
      useCopyToClipboard(val) {
        let isSuccessRef = false;

        //创建input标签
        var input = document.createElement("input");
        //将input的值设置为需要复制的内容
        input.value = val;
        //添加input标签
        document.body.appendChild(input);
        //选中input标签
        input.select();
        //执行复制
        document.execCommand("copy");
        if (document.execCommand("copy")) {
          isSuccessRef = true;
        } else {
          isSuccessRef = false;
        }
        //移除input标签
        document.body.removeChild(input);

        return {
          isSuccessRef
        };
      },
      copy(val) {
        let {
          isSuccessRef
        } = this.useCopyToClipboard(val)
        if (isSuccessRef) {
          this.$message({
            type: 'success',
            message: '复制成功'
          })
        } else {
          this.$message({
            type: 'error',
            message: '复制失败'
          })
        }
      },
      bakc() {
        this.$emit('close')
        this.visible = false
      },
      Stockbakc() {
        this.visibleStock = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .wordColor {
    color: #FF023F;
  }

  .el-checkbox {
    display: flex;
    margin-left: 30px;
  }

  // .icons_size {
  //   color: white;
  //   font-size: 40px;
  // }

  .img_page {
    position: absolute;
    top: 60px;
    width: 250px;
    font-size: 12px;
    line-height: 20px;
  }

  .text_info {
    margin: 0px 15px;
  }

  .bank_Info {
    display: flex;
    width: 60%;
    justify-content: space-between;
  }
</style>
