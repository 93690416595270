<template>
  <el-dialog :title="'电梯列表'" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="1400px"
    top="8vh" @close="closeDialog">
    <div class="searchBox scrollElement" style="height: 75vh;">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">状态</span>
          </span>
          <el-select v-model="searchForm.status " clearable>
            <el-option :key="1" :value="1" label="在线"></el-option>
            <el-option :key="2" :value="2" label="离线"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search" class="searchBtn">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="dataList">
        <el-table :data="tableDate" ref="multipleTable" border stripe fit style="width: 100%; margin-top: 20px"
          max-height="500px">
          <el-table-column type="selection" width="55px" align="center" />
          <el-table-column type="index" label="序号" width="50px" align="center" />
          <el-table-column prop="name" label="电梯名称" min-width="150px" align="center" />
          <el-table-column prop="status" label="状态" min-width="150px" align="center">
            <template slot-scope="scope">
              {{(!scope.row.deviceCode || !scope.row.activeTime) && !scope.row.terminalId ?'--' : (scope.row.status == 1 ? '在线' :'离线') }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="code" label="电梯注册代码" min-width="200px" align="center" /> -->
          <el-table-column v-if="!$store.state.isWBJB" prop="deviceCode" label="AI摄像头ID" min-width="150px"
            align="center" />
          <el-table-column prop="screenStatus " label="公共服务屏" min-width="150px" align="center">
            <template slot-scope="scope">
              {{scope.row.screenStatus === 0 ? '未安装' : (scope.row.screenStatus == 1 ? '已安装' : "--") }}
            </template>
          </el-table-column>
          <el-table-column prop="lastLogoutTime" label="最近离线时间" min-width="150px" align="center" />
          <el-table-column prop="propertyUnitName" label="物业单位" min-width="150px" align="center" />
          <el-table-column prop="insuranceUnitName" label="保险单位" min-width="150px" align="center">
            <template slot-scope="scope">
              {{scope.row.insuranceUnitName ? scope.row.insuranceUnitName : '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="maintainUnitName" label="维保单位" min-width="150px" align="center" />
          <el-table-column prop="createUserName" label="创建人" min-width="120px" align="center" />
          <el-table-column fixed="right" align="center" label="操作" width="150px">
            <template slot-scope="scope" v-if='scope.row.deviceCode && scope.row.activeTime|| scope.row.terminalId '>
              <el-button type="primary" size="small"  @click="check(scope.row)">查看</el-button>
              <el-button type="danger" size="small" @click="restart(scope.row)">重启</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="Total" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="unitPage" />
      </div>
      <MonitorInfo ref="MonitorInfo" v-if="MonitorInfoVisible" :eleInfo='eleInfo' :activeNameProps="'dtjk'"
        :elevatorId="elevatorId" :deviceCode="deviceCode" @closeMonitor="closeMonitor" />
    </div>
  </el-dialog>
</template>

<script>
  import Pagination from '../../../../components/Pagination.vue'
  import MonitorInfo from '../../dtjk/components/monitorInfo.vue'
  export default {
    components: {
      Pagination,
      MonitorInfo
    },
    data() {
      return {
        loading: true,
        visible: true,
        Total: 0,
        tableDate: [],
        searchForm: {
          current: 1,
          size: 10,
          plotId: this.quartersInfo.id,
        },
        eleInfo: null, //总数据
        elevatorId: '', // 电梯id
        deviceCode: '', // 设备号
        MonitorInfoVisible: false,
      };
    },
    props: {
      quartersInfo: {
        type: Object,
        default: () => {
          return {
            id: "",
          };
        },
      },
    },

    created() {
      this.onRefer()
    },
    mounted() {
    },

    methods: {
      //重启
      restart(data) {
        this.$confirm('此操作将重启球机, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            if (this.socketApi.isheart) {
              let agentData = {
                act: 'device_restart',
                device_id: data.deviceCode,
              }
              this.socketApi.sendSock(agentData, (e) => {
                if (e.cmd === 'device_restart' && e.status) {
                  this.$message.success('球机正在重启')
                } else {
                  if (!data.deviceCode) {
                    this.$message.error('未绑定球机')
                  } else {
                    this.$message.error(e.msg)
                  }
                }
                this.loading = false
              })
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消重启',
            })
          })
      },
      //查看
      check(item) {
        this.eleInfo = item
        this.elevatorId = item.id
        this.deviceCode = item.deviceCode
        this.MonitorInfoVisible = true
      },
      closeMonitor() {
        this.MonitorInfoVisible = false
      },
      //搜索
      search() {
        this.onRefer();
      },
      //重置
      reset() {
        this.searchForm = {
          current: 1,
          size: 10,
          plotId: this.quartersInfo.id
        }
        this.onRefer();
      },
      //获取列表数据
      onRefer() {
        this.$http.post('/api/ele/web/elevatorInfo/plotRunningList', this.searchForm).then((res) => {
          if (res.data.code == 200) {
            this.Total = res.data.data.total ?? 0
            this.tableDate = res.data.data.records ?? []
            this.$nextTick(() => {
              if (this.Total > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
        })
      },
      // 电梯分页
      unitPage(data) {
        this.searchForm.current = data.page;
        this.searchForm.size = data.limit;
        this.onRefer();
      },
      // 获取电梯列表
      closeDialog() {
        this.visible = false;
        this.$emit("closeVisible");
      },
    },
  };
</script>

<style>
</style>
