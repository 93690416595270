<template>
  <div>
    <el-row>
      <el-col :span="14">
        <div class="imgBox">
          <div>
            <div v-if="isBegin" class="zlvBox" @click="playJk" style="position: relative;">
              <el-image v-if="jkInfo" fit="contain" :src="jkInfo.frameUrl"
                style="width: 100%;position: absolute; z-index: 0; opacity:0.8;" />
              <img class="playImg" :src="require('@/assets/play.png')" style="width: 10%;z-index: 99;" />
            </div>
            <zlvScheme v-else :jkData="jkData" style="width: 100%; height: 60vh;background-color: black;" />
          </div>
          <!--
					<el-image style="width: 100%; height: 60vh"
					src='https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' fit="cover" />
					<el-button class="boxTime" v-if="phoneType" icon="el-icon-phone-outline" circle
						@click="answer"></el-button>
					<el-button class="boxTime closePhone" v-if="!phoneType" icon="el-icon-phone" circle
						@click="close"></el-button> -->
        </div>
      </el-col>
      <el-col :span="10">
        <el-row class="info">
          <el-col :span="12">
            <p>电压：{{jkInfo ? jkInfo.batV : '-'}}V</p>
          </el-col>
          <el-col :span="12">
            <p>电量：{{jkInfo ? jkInfo.batL : '-'}}%</p>
          </el-col>
          <el-col :span="12">
            <p>温度：{{jkInfo ? jkInfo.tempdata : '-'}}℃</p>
          </el-col>
          <el-col :span="12">
            <p>当月流量：{{jkInfo ? jkInfo.simDataNum : '-'}}M</p>
          </el-col>
          <el-col :span="12">
            <p>网络：{{ jkInfo ? (jkInfo.netType == 1 ? '4G' : 'wifi') : '-'}}</p>
          </el-col>
          <el-col :span="12">
            <p>信号强度：
              <span v-if="jkInfo && !jkInfo.netStrenth">无信号</span>
              <el-image v-if="jkInfo && jkInfo.netStrenth" :src="require(`@/assets/capNet/${jkInfo.netStrenth}.png`)"
                fit="cover" style="width:30px; height:25px" />
            </p>
          </el-col>
          <el-col :span="12">
            <p>GPS信号：
              {{ jkInfo ? (jkInfo.gpsLevel == 0 ? '信号弱' : (jkInfo.gpsLevel == 1 ? '信号强' : '无信号')) : '-'}}
            </p>
          </el-col>
          <el-col :span="12">
            <p>T卡剩余容量：{{jkInfo ? jkInfo.sdAvailableSize : '-'}} M</p>
          </el-col>
          <el-col :span="24">
            <p>版本号：{{jkInfo ? jkInfo.caVer : '-'}} / (apk版本号: {{jkInfo ? jkInfo.appVer : '-'}})</p>
          </el-col>
        </el-row>
        <div class="map" v-if="point">
          <!-- 高德地图 -->
          <Map :height="'38vh'" ref="map" :point="point" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import Map from "@/components/map";
  import zlvScheme from "@/components/cap/zlvScheme";
  let getDeTime
  export default {
    props: ['eleInfo'],
    components: {
      Map,
      zlvScheme
    },
    data() {
      return {
        phoneType: true,
        point: null,
        isBegin: true,
        jkData: null,
        jkInfo: null
      };
    },
    methods: {
      playJk() {
        // console.log('eleInfo', this.eleInfo)
        let agentData = {
          act: 'ma_start_rtsp',
          code: this.eleInfo.code
        }
        this.socketCapsApi.sendCapsSock(agentData, (e) => {
          if (e.status) {
            if (e.cmd == 'ma_start_rtsp' && e.data) {
              let data = JSON.parse(e.data)
              this.jkData = data
              this.isBegin = false
            }
          } else {
            this.$message.error(e.msg)
          }
        })

      },
      // 获取信息
      onRefer() {
        let agentData = {
          act: 'ma_get_cap_info',
          capId: this.eleInfo.capId
        }
        this.socketCapsApi.sendCapsSock(agentData, (e) => {
          if (e.status) {
            if (e.cmd == 'ma_get_cap_info' && e.data) {
              let data = JSON.parse(e.data)
              this.jkInfo = data
              this.point = [data.yPoint, data.xPoint]
            }
          } else {
            // this.$message.error(e.msg)
            clearInterval(getDeTime)
          }
        })
      },

      //
      answer() {
        this.phoneType = false
      },
      close() {
        this.phoneType = true
      }
    },
    created() {},
    mounted() {
      setTimeout(() => {
        this.onRefer()
        getDeTime = setInterval(() => {
          this.onRefer()
        }, 2000)
      }, 1000)
    },
    destroyed() {
      console.log('电梯监控详情销毁')
      clearInterval(getDeTime)
    },
  };
</script>
<style lang="scss" scoped>
  .playImg {
    cursor: pointer;
  }

  // .imgs_type {
  //   width: 100%;
  //   height: 60vh;
  //   background-color: rgba(55, 90, 122, 1);
  //   border-radius: 5px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  .zlvBox {
    width: 100%;
    height: 60vh;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imgBox {
    position: relative;
    overflow: hidden;
    color: #fff;
    margin-right: 15px;

    .boxTime {
      position: absolute;
      bottom: 30px;
      right: 30px;
      margin: 0;
      background: #365a7a;
      padding: 15px;
      font-size: 55px;
      color: #fff;
    }

    .closePhone {
      color: red;
    }
  }

  .info {
    background: #65809b85;
    border-radius: 5px;
    color: #fff;
    height: 22vh;
    margin-bottom: 10px;
    padding: 10px 20px;

    p {
      margin: 7px 0;
    }
  }

  .map {
    width: 100%;
    border-radius: 5px;
    margin-top: 15px;
  }
</style>
