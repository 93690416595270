<template>
  <div>
    <el-form :inline="true" class="el-form-style" :model="searchForm">
      <el-form-item label="小区名称">
        <el-input placeholder="请输入" v-model="searchForm.name" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <span slot="label">
          <span class="lab-span">所属区域</span>
        </span>
        <el-cascader :props="props" :options="$mapCode" v-model="searchForm.areaCodeArr" style="width: 100%"
          collapse-tags clearable>
        </el-cascader>
      </el-form-item>
      <el-form-item>
        <span slot="label">
          <span class="lab-span">物业公司</span>
        </span>
        <el-input placeholder="请输入" v-model="searchForm.propertyUnitName" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="checkInfo">查询</el-button>
        <el-button type="warning" @click="reseat">重置</el-button>
      </el-form-item>
    </el-form>

    <template v-if="noticetotal != 0">
      <el-table :data="tableData" v-loading="tableLoading" ref="multipleTable" :height="$store.state.tabHeight-50"
        style="width:100%; margin: 20px 0;">
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="小区名称" align="center" prop="name"></el-table-column>
        <el-table-column label="关联电梯数" align="center" prop="eleNum"></el-table-column>
        <el-table-column label="所属区域" align="center" prop="mergerName"></el-table-column>
        <el-table-column label="详细地址" align="center" prop="address"></el-table-column>
        <el-table-column label="物业公司" align="center" prop="propertyUnitName"></el-table-column>
        <el-table-column label="楼栋总数" align="center" prop="towerNum"></el-table-column>
        <el-table-column label="创建用户" align="center" prop="createUserName"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="selRela(scope.row)" type="primary" size="small">电梯列表</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="noticetotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
        @pagination=" changPage" />
    </template>

    <div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>
    <DeviceList v-if="deviceListVisible" ref="deviceList" :quartersInfo="quartersInfo" @closeVisible="closeVisible" :buttoneShow="buttoneShow" />
  </div>
</template>

<script>
  import Pagination from '../../../../components/Pagination.vue'
  import DeviceList from '../../xqgl/components/deviceList.vue'
  export default {
    components: {
      Pagination,
      DeviceList
    },
    data() {
      return {
        searchForm: {
          current: 1,
          size: 10
        },
        noticetotal: 0,
        tableData: [],
        tableLoading: false,
        // 电梯列表
        deviceListVisible:false,
        quartersInfo: {}, // 小区信息
        buttoneShow:false,
        props: {
          label: "name",
          value: "areaCode",
          checkStrictly: true,
          children: 'children',
          disabled: false,
          // disabled: this.cascadeDisabled,
        },
      }
    },
    methods: {
      // 电梯列表的方法
      selRela(row) {
        this.deviceListVisible = true;
        this.quartersInfo = row;
        this.buttoneShow = false
        this.$nextTick(() => {
          this.$refs.deviceList.init();
        });
      },
      closeVisible() {
        this.deviceListVisible = false;
      },
      //初始化
      init(callback) {
        this.searchCallback = callback;
        this.loading = false;
        // this.$nextTick(()=>{
        //   this.onRefer()
        // })
      },
      //分页
      changPage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer();
      },
      //查询
      checkInfo(){
        this.searchForm.current = 1
        this.onRefer();
      },
      //重置
      reseat(){
        this.searchForm = {
          current:1,
          size:10
        }
        this.onRefer();
      },
      //信息
      onRefer(){
        if (this.searchForm.areaCodeArr) {
          this.searchForm.areaCodes = null;
          if (this.searchForm.areaCodeArr.length > 0) {
            let obj = JSON.parse(JSON.stringify(this.searchForm.areaCodeArr));
            this.searchForm.areaCodes = obj[obj.length - 1];
          }
        }
        this.tableLoading = true
        this.$http.post('/api/ele/web/plot/getElePlotList',this.searchForm)
          .then((res)=>{
            if(res.data.success){
              this.tableData = res.data.data.records ?? []
              this.noticetotal = res.data.data.total ?? 0
              this.$nextTick(()=>{
                if(this.noticetotal > 0){
                  this.$refs.multipleTable.bodyWrapper.scrolltop = 0
                }
              })
            }else{
              this.$message.error(res.data.data.msg)
            }
            this.tableLoading = false
        })
      }
    },
    created(){
      this.onRefer();
    }
  }
</script>

<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
