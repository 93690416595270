<template>
  <el-dialog :title="$store.state.isWBJB ? '电梯列表' :'设备列表'" :visible.sync="visible" :close-on-click-modal="false"
    append-to-body width="1400px" top="8vh" @close="closeDialog">
    <div class="searchBox scrollElement">
      <el-form :inline="true" :model="unitQuery" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="unitQuery.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchPersonnel" class="searchBtn">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div style="display: flex" v-if="buttoneShow">
        <el-button type="primary" size="small" @click="deviceAdd">新增电梯</el-button>
        <el-button type="danger" size="small" @click="delAll">批量移除</el-button>
      </div>
      <div class="dataList">
        <el-table :data="unitData" ref="unitTable" border stripe fit style="width: 100%; margin-top: 20px"
          max-height="500px" @select="handleSelectionChange" @select-all="selectAll">
          <el-table-column type="selection" width="55px" align="center" v-if="buttoneShow"/>
          <el-table-column type="index" label="序号" width="50px" align="center" v-if="buttoneShow" />
          <el-table-column prop="name" label="电梯名称" min-width="150px" align="center" />
          <el-table-column prop="code" label="电梯注册代码" min-width="200px" align="center" />
          <el-table-column v-if="!$store.state.isWBJB" prop="deviceCode" label="AI摄像头ID" min-width="150px"
            align="center" />
          <el-table-column prop="rescueCode" label="救援识别码" min-width="150px" align="center" />
          <el-table-column prop="maintainUnitName" label="维保单位" min-width="150px" align="center" />
          <el-table-column prop="propertyUnitName" label="物业单位" min-width="150px" align="center" />
          <el-table-column prop="createUserName" label="创建人" min-width="120px" align="center" />
          <el-table-column fixed="right" align="center" label="操作" width="150px" v-if="buttoneShow">
            <template slot-scope="scope">
              <el-button @click="del([scope.row.id])" type="danger" size="small">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="unitTotal" :page.sync="unitQuery.current" :limit.sync="unitQuery.size"
          @pagination="unitPage" />
      </div>
    </div>
    <DeviceAdd ref="deviceAdd" v-if="deviceAddVisible" :quartersInfo="quartersInfo" @closeDeviceAdd="closeDeviceAdd" />
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveChange">确定</el-button>
      <el-button type="indo" @click="closeDialog">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import DeviceAdd from "./deviceAdd";
  import Pagination from "../../../../components/Pagination";
  export default {
    components: {
      Pagination,
      DeviceAdd,
    },
    data() {
      return {
        loading: true,
        visible: false,
        unitData: [],
        type: "",
        unitTotal: 0,
        unitQuery: {
          current: 1,
          size: 10,
          name: "",
        },
        addBrandCallback: null, // 弹窗回调

        initArr: [],

        deviceAddVisible: false, // 添加设备
      };
    },
    props: {
      quartersInfo: {
        type: Object,
        default: () => {
          return {
            id: "",
          };
        },
      },
      buttoneShow:{
        type: Boolean,
        default:true
      }
    },

    mounted() {},

    methods: {
      // 父字典id，父字典数据，回调
      init(callback) {
        this.addBrandCallback = callback;
        this.type = "";
        this.visible = true;
        this.loading = false;
        this.unitQuery.current = 1;
        this.$nextTick(() => {
          this.getPersonnel();
        });
      },
      // 全选
      selectAll(selection) {
        let stateArr = [];
        if (selection.length > 0) {
          stateArr = JSON.parse(JSON.stringify(selection));
          const arr = [...selection, ...this.initArr];
          // 去重
          const res = new Map();
          this.initArr = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1));
        } else {
          stateArr = JSON.parse(JSON.stringify(this.selectElevatorList));
          stateArr.map((item) => {
            if (this.initArr.find((row) => row.id === item.id)) {
              this.initArr = this.initArr.filter((row) => row.id !== item.id);
            }
          });
        }
      },

      handleSelectionChange(val, row) {
        if (this.initArr.find((item) => item.id === row.id)) {
          this.initArr = this.initArr.filter((item) => item.id !== row.id);
        } else {
          this.initArr.push(row);
        }
      },
      // 添加设备
      deviceAdd() {
        this.deviceAddVisible = true;
        this.$nextTick(() => {
          this.$refs.deviceAdd.init();
        });
      },
      closeDeviceAdd() {
        this.deviceAddVisible = false;
        this.getPersonnel()
      },
      // 批量删除
      delAll() {
        if (this.initArr.length === 0) {
          this.$message.error('请勾选移除的电梯');
          return
        }
        const listArr = [];
        this.initArr.map((item) => {
          listArr.push(item.id);
        });
        this.del(listArr);
      },
      // 删除
      del(item, isAll) {
        this.$confirm("此操作将永久移除选中的电梯, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            this.$http
              .get(
                `/api/ele/web/plot/batchSaveOrDel?type=2&id=${
                this.quartersInfo.id
              }&eleIds=${item.join(",")}`
              )
              .then((res) => {
                if (res.data.success) {
                  this.initArr = [];
                  this.$refs.unitTable.clearSelection();
                  this.getPersonnel();
                  this.$message.success(res.data.msg);
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            console.log(item);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消移除",
            });
          });
      },

      // 搜索
      searchPersonnel() {
        this.unitQuery.current = 1;
        this.getPersonnel();
      },

      // 重置
      reset() {
        this.unitQuery = {
          current: 1,
          size: 10,
        };
        this.unitQuery.name = "";
        this.getPersonnel();
      },

      // 电梯分页
      unitPage(data) {
        this.unitQuery.current = data.page;
        this.unitQuery.size = data.limit;
        this.getPersonnel();
      },
      // 保存更换
      saveChange() {
        this.$emit("closeVisible");
        this.visible = false;
      },

      // 获取电梯列表
      getPersonnel() {
        const data = this.unitQuery;
        data.plotId = this.quartersInfo.id;
        this.$http.post("/api/ele/web/plot/getEleList", data).then((res) => {
          const {
            data: {
              data
            },
          } = res;
          if (res.data.code === 200) {
            this.unitData = JSON.parse(JSON.stringify(data.records));
            this.unitTotal = data.total;
            this.$nextTick(() => {
              if (this.unitTotal > 0 && this.$refs.unitTable) {
                this.$refs.unitTable.bodyWrapper.scrollTop = 0;
              }
            })
          }
        });
      },
      closeDialog() {
        this.visible = false;
        this.$emit("closeVisible");
      },
    },
  };
</script>
<style lang="scss" scoped>
  .searchBox {
    /deep/.el-form-item__label {
      color: #00f6ff !important;
      font-size: 14px;
    }

    .searchBtn {
      margin-left: 15px;
    }
  }

  // /deep/.el-table__header-wrapper .el-checkbox {
  //   display: none;
  //   position: relative;
  // }
</style>
