<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">摄像头名称</span>
          </span>
          <el-input placeholder="请输入" v-model="formInline.name" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">车棚名称</span>
          </span>
          <el-input placeholder="请输入" v-model="formInline.carportName" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">小区名称</span>
          </span>
          <el-input placeholder="请输入" v-model="formInline.plotName" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">在线状态</span>
          </span>
          <el-select v-model="formInline.status">
            <el-option :key="1" :value="1" :label="'在线'"></el-option>
            <el-option :key="2" :value="2" :label="'离线'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
          <el-button type="success" :disabled="exportType" @click="exportData()">{{exportTitle}}</el-button>
        </el-form-item>
      </el-form>
      <div style="display: flex">
        <el-button type="success" @click="addDj" size="small">新增</el-button>
      </div>
    </div>
    <div v-if="dictionarTotal != 0">
      <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" style="width: 100%; margin-bottom: 20px"
        :height="$store.state.tabHeight">
        <el-table-column type="index" label="编号" width="50px" />
        <el-table-column label="摄像头名称" width="150px" align="center" prop="name" />
        <el-table-column label="AI摄像头ID" width="150px" align="center" prop="deviceCode" />
        <el-table-column label="在线状态" width="150px" align="center">
          <template slot-scope="scope">
            {{scope.row.status == 1?'在线' :'离线'}}
          </template>
        </el-table-column>
        <el-table-column label="车棚名称" width="150px" align="center" prop="carportName" />
        <el-table-column label="小区名称" width="150px" align="center" prop="plotName" />
        <el-table-column label="激活时间" width="150px" align="center" prop="activatedTime" />
        <el-table-column label="最近离线时间" width="150px" align="center" prop="lastLogoutTime" />
        <el-table-column label="SD卡总容量(M)" width="150px" align="center" prop="sdTotalSize" />
        <el-table-column label="SD卡剩余容量(M)" width="150px" align="center" prop="sdVolume" />
        <el-table-column label="当日流量(M)" width="150px" align="center" prop="dayFlow" />
        <el-table-column label="当月流量(M)" width="150px" align="center" prop="monthFlow" />
        <el-table-column label="创建人" width="150px" align="center" prop="createUserName" />
        <el-table-column fixed="right" align="center" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button @click="check(scope.row)" type="primary" size="small">查看</el-button>
            <el-button @click="upd(scope.row)" type="primary" size="small">编辑</el-button>
            <el-dropdown @command="handleCommand" style="margin: 0px 10px;">
              <el-button type="text" size="small">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{data: scope.row, title: 'setup'}">设置禁停区域</el-dropdown-item>
                <el-dropdown-item :command="{data: scope.row, title: 'reset'}">重启</el-dropdown-item>
                <el-dropdown-item :command="{data: scope.row, title: 'del'}">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>
    <AddEdit @close="detailClose()" v-if="AaddEditDialog" :Tit="tit" :propForm="propForm" @update="updata" />
    <check ref="check" v-if="checkVisible" :Id="Id" :eleInfo="eleInfo" :deviceCode="deviceCode"
      @closeMonitor="detailClose()" />
    <Setup v-if="SetupDialog" @close="detailCloseImg" :Id="Id" :info="eleInfo" />

  </div>
</template>

<script>
  // import Detailed from './components/Detailed'
  import Setup from './components/Setup'
  import AddEdit from './components/AddEdit'
  import check from './components/Check.vue'
  import Pagination from '@/components/Pagination.vue'
  export default {
    components: {
      Setup,
      check,
      AddEdit,
      Pagination
    },
    data() {
      return {
        exportTitle: '导出',
        exportType: false,
        formInline: {
          size: 10,
          current: 1
        },
        Id: '', //设备ID
        dictionarTotal: 0, //总数
        tableData: [], //列表数据
        // deTailDialog: false, //明细
        AaddEditDialog: false, //编辑新增
        checkVisible: false, //查看
        SetupDialog: false, //设置禁停区
        tit: '', //标题
        deviceCode: '', //设备号
        eleInfo: null, //总数据
        propForm: {}, //编辑数据
        tableLoading: true
      }
    },
    created() {
      this.onRefer()
    },
    methods: {
      //导出
      async exportData() {
        this.formInline.current = 1
        this.exportType = true
        this.exportTitle = '正在导出'
        // if((this.formInline.selectType==1&&!this.formInline.allTime)||(this.formInline.selectType==2&&!this.formInline.month)){
        //     this.$message.error('请先选择日期')
        //     return
        // }
        // if (this.formInline.selectType==1) {
        //     this.formInline.startTime = this.formInline.allTime[0]
        //     this.formInline.endTime = this.formInline.allTime[1]
        // } else{
        //     const {start,end} =getMonthSE(this.formInline.month.getFullYear(),this.formInline.month.getMonth())
        //     this.formInline.startTime = start
        //     this.formInline.endTime = end
        // }
        // let nowDate=new Date().getTime()
        // if(this.formInline.endTime>nowDate){
        //     this.formInline.endTime=nowDate
        // }
        this.$http.post("/api/bike/web/export/bikeDeviceInfo", this.formInline, {
          responseType: 'blob'
        }).then((res) => {
          if (res.data.size > 0) {
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            });
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a'); //创建a标签
            link.href = url;
            link.download = '摄像头管理数据统计.xls'; //重命名文件
            link.click();
            URL.revokeObjectURL(url);
          } else {
            this.$message.error('暂无数据')
          }
          this.exportType = false
          this.exportTitle = '导出'
        })
      },
      dictionarPage(data) { //翻页
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      onRefer(ishm) { //获取数据
        if (ishm) {
          this.formInline.current = 1
        }
        this.tableLoading = true
        this.$http.post('/api/bike/web/device/runningList', this.formInline).then((res) => {
          if (res.data.code == 200) {
            this.dictionarTotal = res.data.data.total
            this.tableData = res.data.data.records
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          }
          this.tableLoading = false
        })
      },
      handleCommand(command) { //下拉菜单
        switch (command.title) {
          case "setup":
            this.setUp(command.data)
            break
          case 'reset':
            this.Restart(command.data)
            break
          case 'del':
            this.del(command.data)
            break
          default:
            break
        }
      },
      //设置禁停区
      setUp(data) {
        this.Id = data.id
        this.eleInfo = data
        this.SetupDialog = true
      },
      //重启
      Restart(data) {
        const agentData = {
          act: 'device_restart',
          device_id: data.deviceCode,
        }
        this.socketCarportApi.sendCarportSock(agentData, (e) => {
          if (e.cmd === 'device_restart' && e.status) {
            this.$message.success(e.msg)
          } else {
            this.$message.error(e.msg)
          }
        })
      },
      //删除
      del(data) {
        this.$confirm('此操作将永久删除选中的记录, 是否继续?', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.delete(`/api/bike/web/device/deletedDevice?id=${data.id}`).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.onRefer()
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
        }).catch(() => {})
      },
      reset() { //重置
        this.formInline = {
          current: 1,
          size: 10
        }
        this.onRefer()
      },
      addDj() { //新增
        this.AaddEditDialog = true
        this.tit = '新增'
      },
      upd(item) { //编辑
        this.AaddEditDialog = true
        this.tit = '编辑'
        this.propForm = JSON.parse(JSON.stringify(item))
      },
      check(item) { //查看
        this.eleInfo = item
        this.Id = item.id
        this.deviceCode = item.deviceCode
        this.checkVisible = true
      },
      updata() {
        this.SetupDialog = false
        this.AaddEditDialog = false
        this.checkVisible = false
        this.onRefer()
      },
      detailClose() { //关闭弹窗
        this.SetupDialog = false
        this.AaddEditDialog = false
        this.checkVisible = false
      },
      detailCloseImg(data) {
        this.SetupDialog = false
        this.AaddEditDialog = false
        this.checkVisible = false
        this.check(data)
      }
    }
  }
</script>

<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }
</style>
