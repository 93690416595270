<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" ref="searchForm" :model="searchForm" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯名称</span>
					</span>
					<el-input v-model="searchForm.name" placeholder="请输入" clearable />
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">救援识别码</span>
					</span>
					<el-input v-model="searchForm.rescueCode" placeholder="请输入" clearable />
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯注册代码</span>
					</span>
					<el-input v-model="searchForm.code" placeholder="请输入" clearable />
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">AI摄像头ID</span>
					</span>
					<el-input v-model="searchForm.deviceCode" placeholder="请输入" clearable />
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯数字终端ID</span>
					</span>
					<el-input v-model="searchForm.terminalId" placeholder="请输入" clearable />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" class="searchBtn" @click="onRefer(true)">查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div>
			<el-button v-focus type="warning" @click="dowMode(9)" size="small">下载模版</el-button>
			<el-button v-focus type="danger" size="small" @click="delAll">批量删除</el-button>
			<el-button v-focus type="success" size="small" @click="addDj">新增</el-button>
			<form v-focus name="form1" style="width: 56px; margin: 0 10px; display: inline-block">
				<input type="file" id="docxFile" @change="readFile($event)"
					style="position: absolute; clip: rect(0 0 0 0)" />
				<el-button type="primary" @click="toLead" size="small">导入</el-button>
			</form>
			<div v-if="dictionarTotal != 0">
				<el-table ref="multipleTable" v-loading="tableLoading"  :data="tableData" @selection-change="handleSelectionChange"
					style="width: 100%; margin-top: 20px; margin-bottom: 20px" max-height="550">
					<el-table-column type="selection" width="55px"> </el-table-column>
					<el-table-column type="index" label="序号" width="50px" />
					<el-table-column align="center" prop="name" label="电梯名称" width="200px" />
					<el-table-column align="center" prop="code" label="电梯注册代码" width="150px" />
					<el-table-column align="center" prop="rescueCode" label="救援识别码" width="150px" />
					<el-table-column align="center" prop="deviceCode" label="AI摄像头ID" width="150px" />
					<el-table-column align="center" prop="terminalId" label="电梯数字终端ID" width="150px" />
					<!-- <el-table-column
            align="center"
            prop="mergerName"
            label="所属区域"
            min-width="150"
          /> -->

					<el-table-column align='center' label="所属小区" width="180px" >
						<template slot-scope="scope">
							{{scope.row.plotName ? scope.row.plotName : '-'}}
						</template>
					</el-table-column>
					<el-table-column align="center" prop="elePlace" label="使用场所" width="180px" />
					<el-table-column align="center" prop="propertyUnitName" label="物业单位" width="180px" />
					<el-table-column align="center" prop="propertyUnitUserNames" label="电梯安全员" width="180px" />
					<!-- <el-table-column align="center" prop="areaPidName" label="SD卡剩余容量(M)" width="150px">
						<template slot-scope="scope">
							{{
                scope.row.activeTime ? (scope.row.sdAvailableSize || scope.row.sdAvailableSize === 0 ? scope.row.sdAvailableSize : 'SD卡已损坏') : '-'
              }}
						</template>
					</el-table-column> -->
					<el-table-column align="center" prop="maintainUnitName" label="维保单位" width="180px" />
					<el-table-column align="center" prop="maintainUserNames" label="维保人员" width="200px" />
					<el-table-column align="center" prop="insuranceUnitName" label="保险单位" width="180px" />
					<el-table-column align="center" prop="insuranceUnitUserNames" label="保险人员" width="200px" />
					<el-table-column align="center" label="楼层学习" width="200px" >
						<template slot-scope="scope">
							{{
								scope.row.isInit ? '已完成' : '未完成'
							}}
						</template>
					</el-table-column>
					<!-- <el-table-column align="center" prop="eleAddress" label="电梯安装位置" width="200px" /> -->
					<el-table-column align="center" prop="createTime" label="添加时间" width="200px" />
					<el-table-column align="center" prop="createUserName" label="创建人" width="120px" />
					<el-table-column fixed="right" align="center" prop="address" label="操作" width="520px">
						<template slot-scope="scope">
							<el-button  @click="look(scope.row)" type="primary" size="small">部件信息</el-button>
							<el-button v-focus @click="check(scope.row)" type="primary" size="small">查看</el-button>
							<el-button v-focus @click="changePerson(scope.row)" type="primary" size="small">维保人员
							</el-button>
							<el-button v-focus @click="FloorRecord(scope.row)" type="warning" size="small">楼层记录
							</el-button>
							<el-button v-focus @click="upd(scope.row)" type="warning" size="small">编辑电梯</el-button>
							<el-button v-focus @click="del([scope.row.id])" type="danger" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<Pagination :total="dictionarTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
					@pagination="dictionarPage" />
			</div>
			<div class="nullDate" v-else v-loading="tableLoading">
        		<img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
			</div>
		</div>
		<Action v-if="dialogVisible" :initData="initData" ref="acitonDtzl" />
		<ChangePerson v-if="selectPersonVisible" :eleInfo="eleInfo" ref="changePerson" />
		<FloorRecord v-if="floorRecordVisible" :eleInfo="eleInfo" ref="floorRecord" />
		<!-- 错误信息列表 -->
		<ErrorDio v-if="errorList != null" :errorLists="errorList" @dioClose="dioCloseB" />
		<BJ v-if="BJVisible" :initData="initData" :myVisible="BJVisible" @closeDialog="(value)=>{BJVisible=value}"></BJ>
	</div>
</template>
<script>
	var validateName = (rule, value, callback) => {
		let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{3,15}$/
		// true：必填  false：非必填
		inpYz(
			rule,
			value,
			callback,
			true,
			operation,
			'可输入中文，字母，数值，长度为3-15'
		)
	}
	var validatecertificateCode = (rule, value, callback) => {
		let operation = /^[a-zA-Z0-9]{3,14}$/
		inpYz(rule, value, callback, false, operation, '可输入字母，数值，长度为3-14')
	}
	var validateaddress = (rule, value, callback) => {
		let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,25}$/
		inpYz(
			rule,
			value,
			callback,
			true,
			operation,
			'可输入中文，字母，数值，长度为2-25'
		)
	}
	var validatecontact = (rule, value, callback) => {
		let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,6}$/
		inpYz(
			rule,
			value,
			callback,
			true,
			operation,
			'可输入中文，字母，数值，长度为2-6'
		)
	}
	var validatephone = (rule, value, callback) => {
		let operation = /^1\d{7,10}$/
		inpYz(rule, value, callback, true, operation, '手机号码长度为：8-11')
	}

	// 页面对应的type
	const pageType = 'ELEVATOR_INFO'
	import ErrorDio from '@/components/errorDio.vue'
	import Pagination from '@/components/Pagination'
	import FileUpload from '@/components/FileUpload'
	import Action from '@/views/dtsj/dtzl/components/action'
	import ChangePerson from '@/views/dtsj/dtzl/components/changePerson'
	import FloorRecord from '@/views/dtsj/dtzl/components/floorRecord'
	import BJ from './components/BJ.vue'
	export default {
		components: {
			ErrorDio,
			FileUpload,
			Pagination,
			Action,
			ChangePerson,
			FloorRecord,
			BJ
		},
		data() {
			let _this = this
			return {
				BJVisible:false,
				// 错误信息列表
				errorList: null,
				eleId: '', //选中的电梯
				// 表格参数
				delData: [], //多选的数据
				searchForm: {
					current: 1,
					size: 10,
				},
				tableData: [],
				// 分页参数
				dictionarTotal: 0,
				// dialog参数
				tit: '',
				dialogVisible: false,
				fign: {},
				// 角色下拉框数据
				roleData: [],
				selectPersonVisible: false, // 选择人员弹框
				floorRecordVisible: false, // 楼层学习
				orgId: '', // 单位id
				unitName: '', // 单位name
				initIds: [], // 选择人员id集合
				eleInfo: {}, // 电梯资料
				maintainArr: [],
				// 表单限制
				rules: {
					name: [{
						required: true,
						validator: validateName,
						trigger: 'blur',
					}, ],
				},
				initData: {},
				tableLoading: true
			}
		},
		methods: {
			look(data){
				this.BJVisible=true
				this.initData=data
			},
			// 错误列表回调
			dioCloseB() {
				this.errorList = null
			},
			// 导入
			toLead() {
				document.form1.docxFile.click()
			},
			readFile(file) {
				let _this = this
				let docxFile = file.target.files[0]
				if (docxFile == undefined) {
					return
				}
				let hasFile = false
				let fileName = docxFile.name
				let fileArr = file.target.files[0].name.split('.')
				let files = docxFile
				if (!file) {
					this.$message('请先选择正确的文件类型上传')
				} else {
					let fileData = new FormData()
					fileData.append('file', files)
					fileData.append('type', files.type)
					let config = {
						onUploadProgress: (e) => {
							//属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
							//如果lengthComputable为false，就获取不到e.total和e.loaded
							if (e.lengthComputable) {
								let rate = e.loaded / e.total //已上传的比例
								if (rate < 1) {
									//这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
									//因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
									//等响应回来时，再将进度设为100%
									let uploadRate = (rate * 100).toFixed(2) + '%'
								}
							}
						},
					}
					this.$http
						.post(`/api/ele/web/batch/saveData/${pageType}`, fileData, config)
						.then((res) => {
							if (res.data.success) {
								this.onRefer()
								this.$message.success(res.data.msg)
							} else {
								this.errorList = res.data.data
								this.$message.error(res.data.msg)
							}
							file.target.value = ''
						})
				}
			},
			// 下载模版
			dowMode(type) {
				downloadMode(type)
			},
			// 重置按钮事件
			reset() {
				this.searchForm = {
					current: 1,
					size: 10,
				}
				this.onRefer()
			},
			// 分页
			dictionarPage(data) {
				this.searchForm.current = data.page
				this.searchForm.size = data.limit
				this.onRefer()
			},
			// 弹出框提交
			dioSub() {

				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						let data = JSON.parse(JSON.stringify(this.fign))
						let url = '/api/system/web/org/submit'

						if (data.zTime) {
							data.certificateStartTime = data.zTime[0]
							data.certificateEndTime = data.zTime[1]
						}
						if (data.areaCodeArr) {
							if (data.areaCodeArr.length > 0) {
								data.areaCodes = JSON.stringify(data.areaCodeArr)
								data.areaCode = data.areaCodeArr[data.areaCodeArr.length - 1]
							}
						}
						data.type = pageType

						this.$http.post(url, data).then((res) => {
							if (res.data.success) {
								this.onRefer()
								this.dialogVisible = false
								this.$message.success(res.data.msg)
							} else {
								this.$message.error(res.data.msg)
							}
						})
					} else {
						this.$message.warning('请验证填写内容!')
						return false
					}
				})
			},

			// 表格方法
			onRefer(ishm) {
				if (ishm) {
					this.searchForm.current = 1
				}
				this.tableLoading = true
				this.$http
					.post('/api/ele/web/elevatorInfo/list', this.searchForm)
					.then((res) => {
						if (res.data.success) {
							this.tableData = res.data.data.records
							this.dictionarTotal = res.data.data.total
							this.$nextTick(() => {
								if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
						this.tableLoading = false
					})
			},
			handleSelectionChange(val) {
				this.selectPersonVisible = false
				this.delData = val
			},
			// 更换维保人员
			changePerson(item) {
				if (!item.maintainUnitId) {
					this.$message.warning('请先绑定维保单位')
					return
				}
				this.eleInfo = item
				this.selectPersonVisible = true
				this.$nextTick(() => {
					this.$refs.changePerson.init(() => {
						this.onRefer()
					})
				})
			},

			// 查看
			check(item) {
				this.dialogVisible = true
				this.$http
					.get(`/api/ele/web/elevatorInfo/detail?id=${item.id}`)
					.then((res) => {
						if (res.data.success) {
							const data = res.data.data
							data.deviceSosSwitch = res.data.data.deviceSosSwitch ?
								JSON.parse(res.data.data.deviceSosSwitch) : []
							data.sosSwitchStatus = res.data.data.sosSwitchStatus ?
								JSON.parse(res.data.data.sosSwitchStatus) : []
							data.terminalSosSwitch = res.data.data.terminalSosSwitch ?
								JSON.parse(res.data.data.terminalSosSwitch) : []
							data.areaCodeArr = res.data.data.areaCodes ?
								JSON.parse(res.data.data.areaCodes) : []
							this.initData = data
							this.$nextTick(() => {
								this.$refs.acitonDtzl.init('check', data, () => {
									this.onRefer()
								})
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
			// 修改
			upd(item) {
				this.dialogVisible = true
				this.$http
					.get(`/api/ele/web/elevatorInfo/detail?id=${item.id}`)
					.then((res) => {
						if (res.data.success) {
							const data = res.data.data
							data.deviceSosSwitch = res.data.data.deviceSosSwitch ?
								JSON.parse(res.data.data.deviceSosSwitch) : []
							data.sosSwitchStatus = res.data.data.sosSwitchStatus ?
								JSON.parse(res.data.data.sosSwitchStatus) : []
							data.terminalSosSwitch = res.data.data.terminalSosSwitch ?
								JSON.parse(res.data.data.terminalSosSwitch) : []
							data.areaCodeArr = res.data.data.areaCodes ?
								JSON.parse(res.data.data.areaCodes) : []
							data.simStartTime = res.data.data.simStartTime ? new Date(res.data.data.simStartTime) : ''
							data.simEndTime = res.data.data.simEndTime ? new Date(res.data.data.simEndTime) : ''
							this.initData = data
							this.$nextTick(() => {
								this.$refs.acitonDtzl.init('edit', data, () => {
									this.onRefer()
								})
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
			// 批量删除
			delAll() {
				if (this.delData.length > 0) {
					let arr = []
					this.delData.forEach((qs) => {
						arr.push(qs.id)
					})
					this.del(arr)
				} else {
					this.$message.warning('请选择要删除的单位')
				}
			},
			// 删除
			del(item, isAll) {
				this.$confirm('此操作将永久删除选中的单位, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					})
					.then(() => {
						let data = {
							ids: item,
						}
						this.$http
							.delete(`/api/ele/web/elevatorInfo/remove?ids=${item}`)
							.then((res) => {
								if (res.data.success) {
									this.onRefer()
									this.$message.success(res.data.msg)
								} else {
									this.$message.error(res.data.msg)
								}
							})
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除',
						})
					})
			},

			// 新增
			addDj() {
				this.dialogVisible = true
				this.initData = {}
				this.$nextTick(() => {
					this.$refs.acitonDtzl.init('add', '', () => {
						this.onRefer()
					})
				})
			},
			// 楼层记录
			FloorRecord(item) {
				this.floorRecordVisible = true
				this.eleInfo = item
				this.$nextTick(() => {
					this.$refs.floorRecord.init(() => {
						this.onRefer()
					})
				})
			},
		},
		created() {
			this.onRefer()
		},
	}
</script>
<style scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
