<template>
  <div class="admin-project-myproject-container">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">

      <el-form-item>
        <span slot="label">
          <span class="lab-span">报警类型</span>
        </span>
        <el-select v-if="SosTypeArr.length>0" v-model="formInline.sosType" placeholder="请选择">
          <el-option v-for="(item,index) in SosTypeArr" :key="index" :value="item.value"
            :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <span slot="label">
          <span class="lab-span">报警状态</span>
        </span>
        <el-select v-model="formInline.alarmState" placeholder="请选择">
          <el-option v-for="(item,index) in StateArr" :key="index" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <span slot="label">
          <span class="lab-span">报警时间</span>
        </span>
        <el-date-picker
              v-model="formInline.TimeArr"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期" class="einp">
            </el-date-picker>
       <!-- <el-date-picker v-model="formInline.TimeArr" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" class="einp">
        </el-date-picker> -->
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onRefer(true)">查询</el-button>
        <el-button type="warning" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div v-if="dictionarTotal != 0">
      <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" style="width: 100%; margin-bottom: 20px"
        :height="$store.state.tabHeight-200">
        <el-table-column type="index" label="序号" width="50px" align="center" />
        <el-table-column label="车棚名称" prop="carportName" width="150px" align="center" />
        <el-table-column label="报警类型" prop="sosType" width="150px" align="center">
          <template slot-scope="scope">
            {{SosTypeArr.length>0 ? SosTypeArr.find((item)=>item.value == scope.row.sosType).label : ''}}
          </template>
        </el-table-column>
        <el-table-column label="报警时间" prop="sosTime" width="150px" align="center" />
        <el-table-column label="接警时间" prop="callTime" width="150px" align="center" />
        <el-table-column label="处理时间" prop="handleTime" width="150px" align="center" />
        <el-table-column label="报警状态" prop="alarmState" width="150px" align="center">
          <template slot-scope="scope">
            {{StateArr.length>0 ? StateArr.find((item)=>item.value == scope.row.alarmState).label : ''}}
          </template>
        </el-table-column>
        <el-table-column label="摄像头名称" prop="deviceName" width="150px" align="center" />
        <el-table-column label="AI摄像头ID" prop="deviceId" width="150px" align="center" />
        <el-table-column label="所属小区" prop="plotName" width="150px" align="center" />
        <el-table-column label="连续报警次数" prop="sosCount" width="150px" align="center" />
        <el-table-column label="最后一次报警时间" prop="finallySosTime" width="150px" align="center" />
        <el-table-column label="物业单位" prop="orgName" width="150px" align="center" />
        <el-table-column label="安全员" prop="userNames" width="250px" align="center" />
        <el-table-column label="车棚位置" prop="address" width="150px" align="center" />
        <el-table-column fixed="right" align="center" label="操作" width="150px">
          <template slot-scope="scope">
            <el-button @click="check(scope.row)" type="primary" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>
    <el-dialog v-if='dioDetails' :close-on-click-modal='false' :visible.sync="dioDetails" :append-to-body="true"
      width="1200px" top='15vh' center>
      <div class="tabBut">
        <el-button @click="detailsItem.seleId=1" :type='1 == detailsItem.seleId ? "primary" : ""'>报警消息
        </el-button>
        <el-button @click="detailsItem.seleId=2" :type='2==detailsItem.seleId ? "primary" : ""'>处置流程</el-button>
      </div>
      <div style="height: 60vh;margin-top: 10px;" class="scrollElement"
        :class="[detailsItem.seleId==1?'yeScroll':'noScroll']">
        <alarmMessage :dioData="dioDetailsItem" :seleId="detailsItem.seleId" />
        <!-- <process v-else :dioData="dioDetailsItem" /> -->
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: center;">
        <div v-if="detailsItem.seleId == 1 && dioDetailsItem.sosStatusName === '待处理'">
          <!-- && dioDetailsItem.sosStatus === 1 -->
          <template>
            <el-button size="mini" type="primary" @click="sosClick(2)" :disabled="disabled"
              v-if="dioDetailsItem.sosType != 35">
              <span>人为触发(无故障)</span>
              <p style="margin: 10px 0 0 0;">确认关闭</p>
            </el-button>
            <el-button size="mini" type="warning" @click="sosClick(3)" :disabled="disabled"
              v-if="dioDetailsItem.sosType != 4&&dioDetailsItem.sosType != 35">
              <span>确认火情</span>
              <p style="margin: 10px 0 0 0;">立即通知救援</p>
            </el-button>
            <el-button class="errorSos" size="mini"
              v-if="dioDetailsItem.sosType != 35"
              @click="sosClick(1)" :disabled="disabled">
              <span>误报</span>
              <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
            </el-button>
          </template>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination'
  // import Process from '../../cpbjjl/components/process.vue'
  // import Operational from '../../../bjgl/bjjl/components/operational'
  import AlarmMessage from '../../cpbjjl/components/alarmMessage.vue'
  // import AlarmNotice from '../../../bjgl/bjjl/components/alarmNotice.vue'
  import {
    formatDate
  } from '@/util';
  let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
  let current_time2 = formatDate(new Date(), "yyyy-MM-dd") + " 23:59:59";
  let current_time1 = new Date(current_time).getTime();
  let current_time3 = new Date(current_time2).getTime();
  let starTime = new Date(current_time1 - 30 * 24 * 60 * 60 * 1000);
  let endTime = new Date(current_time2);

  export default {
    components: {
      Pagination,
      // Process,
      // Operational,
      AlarmMessage,
      // AlarmNotice
    },

    props: {
      Id: {
        type: String,
        default: '',
      },
      deviceCode: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        // 报警详情
        StateArr: [{
          value: 0,
          label: '待处理'
        }, {
          value: 1,
          label: '误报'
        }, {
          value: 2,
          label: '人为触发'
        }, {
          value: 3,
          label: '确认火情'
        }], //报警状态
        detailsItem: {
          seleId: 1,
          seleArr: [{
              value: 1,
              label: '报警消息',
            },
            {
              value: 2,
              label: '处置流程',
            },
          ],
        },
        disabled: false,
        SosTypeArr: [],
        dioDetails: false,
        dioDetailsItem: null,
        // 表格参数
        formInline: {
          current: 1,
          size: 10,
          TimeArr: [starTime, endTime],
        },
        tableData: [],
        // 分页参数
        dictionarTotal: 0,
        tableLoading: true,

        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      }
    },
    methods: {
      // 详情操作按钮
      sosClick(type) {
        this.disabled = true
        if (this.dioDetailsItem.submitType === 0) {
          // this.dioDetailsItem.sosStatusName = type == 1?'误报' :
          this.$http
            .post("/api/bike/web/sos/commitSos", {
              id: this.dioDetailsItem.id,
              submitType: type,
              // sosTime: this.dioDetailsItem.sosTime,
            })
            .then((res) => {
              if (res.data.success) {
                // this.$message.success(res.data.msg)
                this.dioDetails = false
                // if(type == 1){
                //   this.$confirm('确认误报成功，是否删除该报警误报记录？','提示',{
                //     cancelButtonText:'取消',
                //     confirmButtonText:'确认',
                //     type:'warning'
                //   }).then(()=>{
                //   }).catch(()=>{
                //   })
                // }
              } else {
                this.$message.error(res.data.msg)
              }
              this.onRefer()

              this.check(this.tableData.find(item => item.id === this.dioDetailsItem.id))
            })
        }
      },
      reset() { //重置
        this.formInline = {
          current: 1,
          size: 10,
          TimeArr: [starTime, endTime]
        }
        this.onRefer()
      },
      check(data) { //查看
        // const data = {
        //   id: data.id,
        //   submitType:data
        // }
        this.$http.get(`/api/bike/web/sos/getSosInfo?id=${data.id}`).then((res) => {
          if (res.data.code == 200) {
            this.detailsItem.seleId = 1
            res.data.data.sosStatusName = this.StateArr.find(item => item.value === res.data.data
                .alarmState) ? this.StateArr.find(item => item.value === res.data.data.alarmState)
              .label : ''
            res.data.data.sosTypeName = this.SosTypeArr.find(item => Number(item.value) === res.data.data
                .sosType) ? this.SosTypeArr.find(item => Number(item.value) === res.data.data.sosType)
              .label : ''

            this.dioDetailsItem = res.data.data
            this.dioDetails = true
          }
        })

      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },

      // 表格方法
      onRefer(ishm) { //获取数据
        if (ishm) {
          this.formInline.current = 1
        }
        const times = this.formInline.TimeArr && this.formInline.TimeArr.length > 0 ? formatDate(new Date(this.formInline.TimeArr[1]),
            "yyyy-MM-dd") +
          " 23:59:59" : ''
        const time1 = this.formInline.TimeArr && this.formInline.TimeArr.length > 0 ? this.formInline.TimeArr[0].getTime() : ''
        const time2 = this.formInline.TimeArr && this.formInline.TimeArr.length > 0 ? new Date(times).getTime() : ''
        const data = {
          ...this.formInline,
          startTime: time1,
          endTime: time2,
          bikeId: this.Id
        }
        this.tableLoading = true
        // this.tableData = [1, 2]
        this.$http.post('/api/bike/web/sos/getSosList', data).then((res) => {
          if (res.data.code == 200) {
            this.tableData = res.data.data.records
            this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          }
          this.tableLoading = false
        })


      },
    },
    created() {
      this.$http
        .post('/api/system/web/dict/dictionary?codes=CARPORT_SOS_TYPE')
        .then((res) => {
          if (res.data.success) {
            const ArrOne = res.data.data.CARPORT_SOS_TYPE
            this.SosTypeArr = this.adminInfo.userName == 'admin' ? ArrOne : ArrOne.filter(item => item.value !== '35')
          }
        })
    },
  }
</script>
<style lang="scss" scoped>
  .errorSos {
    background: #00dcff;
    border-color: #00dcff;
    color: #fff
  }

  .yeScroll {
    overflow-y: scroll;
  }

  .nullDate {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tabBut {
    width: 80%;
    margin-left: 10%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .yeScroll {
    overflow-y: scroll;
  }
</style>
