<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="1400px"
    top="8vh" @close="closeDialog">
    <div class="searchBox scrollElement">
      <template v-if="status == 2">
        <el-form :inline="true" :model="unitQuery" class="demo-form-inline">
          <el-form-item>
            <span slot="label">
              <span class="lab-span">电梯名称</span>
            </span>
            <el-input v-model="unitQuery.name" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">救援识别码</span>
            </span>
            <el-input v-model="unitQuery.rescueCode" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">电梯注册代码</span>
            </span>
            <el-input v-model="unitQuery.code" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchPersonnel" class="searchBtn">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <div class="dataList" v-if="unitTotal != 0">
          <el-table :data="unitData" ref="unitTable" stripe fit style="width: 100%; margin-top: 20px"
            :height="$store.state.tabHeight" max-height="500px" @select="handleSelectionChange" @select-all="selectAll">
            <el-table-column type="selection" width="55px" align="center" />
            <el-table-column type="index" label="序号" width="50px" align="center" />
            <el-table-column prop="name" label="电梯名称" min-width="150px" align="center" />
            <el-table-column prop="code" label="电梯注册代码" min-width="200px" align="center" />
            <el-table-column v-if="!$store.state.isWBJB" prop="deviceCode" label="AI摄像头ID" min-width="150px"
              align="center" />
            <el-table-column prop="rescueCode" label="救援识别码" min-width="150px" align="center" />
            <el-table-column prop="maintainUnitName" label="维保单位" min-width="150px" align="center" />
            <el-table-column prop="propertyUnitName" label="物业单位" min-width="150px" align="center" />
            <el-table-column prop="createUserName" label="创建人" min-width="120px" align="center" />
          </el-table>
          <Pagination :total="unitTotal" :page.sync="unitQuery.current" :limit.sync="unitQuery.size"
            @pagination="unitPage" />
        </div>
        <div class="nullDate" v-else>
          <img src="@/assets/nullDate.png" style="width: 260px;" />
        </div>
      </template>
      <template v-else>
        <el-form :inline="true" :model="formInPersonline" class="demo-form-inline">
          <el-form-item>
            <span slot="label">
              <span class="lab-span">人员名称</span>
            </span>
            <el-input v-model="formInPersonline.name" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getPersonData(true)">查询</el-button>
            <el-button type="warning" @click="Personreset">重置</el-button>
          </el-form-item>
        </el-form>
        <div v-if="personTotal!=0">
          <el-table ref="personTable" :data="personData" @select="handleSelectionChange" @select-all="selectAll"
            style="width: 100%;margin-bottom: 20px;" max-height="500px" :height="$store.state.tabHeight">
            <el-table-column type="selection" width="55px" />
            <el-table-column type="index" align="center" label="序号" width="50px" />
            <el-table-column align='center' prop="name" label="人员名称" />
            <el-table-column align='center' prop="orgName" label="所属单位" />
            <el-table-column align="center" prop="fileUrl" label="是否允许登录" width="150px">
              <template slot-scope="scope">
                <el-switch disabled v-model="scope.row.isLogin" :active-value="0" :inactive-value="1"
                  active-color="#409eff" inactive-color="#909399">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column align='center' label="角色">
              <template slot-scope="scope">
                {{
                    relaredList.find((item) => item.value === scope.row.type)
                      ? relaredList.find(
                          (item) => item.value === scope.row.type
                        ).label
                      : ""
                  }}
              </template>
            </el-table-column>
            <el-table-column align='center' prop="contactWay" label="手机号码" />
            <el-table-column align='center' prop="account" label="app账号" />
            <el-table-column align='center' prop="oriPassword" label="app密码" />
            <el-table-column align='center' prop="certificateCode" label="证书编号" />
            <el-table-column align='center' prop="createTime" label="添加时间" width='200px' />
            <el-table-column align='center' prop="createUserName" label="创建人" />
          </el-table>
          <Pagination ref="pageCom" :total="personTotal" :page.sync="formInPersonline.current"
            :limit.sync="formInPersonline.size" @pagination="dictionarPage" />
        </div>
        <div class="nullDate" v-else>
          <img src="@/assets/nullDate.png" style="width: 260px;" />
        </div>
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <template v-if="status == 1">
        <el-button size="small" @click="back">上一步</el-button>
        <el-button size="small" type="primary" @click="saveChange">确 定</el-button>
      </template>
      <template v-else>
        <el-button v-if="unitTotal!=0" size="small" type="primary" @click="next">下一步</el-button>
        <el-button v-else size="small" @click="closeDialog">取 消</el-button>
      </template>
      <!-- <el-button type="primary" @click="saveChange">确定</el-button>
      <el-button type="info" @click="closeDialog">取消</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
  import Pagination from "../../../../components/Pagination";
  export default {
    components: {
      Pagination,
    },
    data() {
      return {
        loading: true,
        visible: false,
        unitData: [],
        unitTotal: 0,
        status: 2, // 关联电梯步骤  2上一步 1 下一步
        unitQuery: {
          current: 1,
          size: 10,
          name: '',
          code: '',
          rescueCode: '',
        },
        relaredList: [],
        total: 0,
        title: '添加电梯',
        addBrandCallback: null, // 弹窗回调
        initArr: [],
        //人员数据
        personData: [],
        personAbout: [], // 勾选关联人员
        personTotal: 0,
        formInPersonline: {
          current: 1,
          size: 10,
        },
      };
    },
    props: {
      quartersInfo: {
        type: Object,
        default: () => {
          return {
            id: "",
          };
        },
      },
    },

    mounted() {},
    crested() {
      this.getNotice(['RELATED_CODES'])
    },
    methods: {
      dictionarPage(data) {
        this.formInPersonline.current = data.page;
        this.formInPersonline.size = data.limit;
        this.getPersonData();
      },
      Personreset() {
        this.formInPersonline.current = 1
        this.formInPersonline.size = 10
        this.formInPersonline.name = ''
        this.getPersonData();
      },
      // 获取字典
      getNotice(code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.relaredList = data.RELATED_CODES
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 绑定人员
      getPersonData(ishm) {
        if (ishm) {
          this.formInPersonline.current = 1
        }
        const data = JSON.parse(JSON.stringify(this.formInPersonline))
        data.orgId = this.quartersInfo.propertyUnitId
        data.name = this.formInPersonline.name ? `*${this.formInPersonline.name}*` : ''
        // data.orgId = this.unitId
        this.$http
          .post('/api/system/web/appUser/getPage', data)
          .then((res) => {
            if (res.data.success) {
              this.personTotal = res.data.data.total
              this.personData = res.data.data.records ?? []
              this.$nextTick(() => {
                if (this.personTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      next() {
        if (this.initArr.length == 0) {
          this.$message.error('请勾选关联的电梯')
          return
        }
        this.title = '绑定人员'
        this.status = 1
        this.getPersonData()
      },
      back() {
        this.title = '添加电梯'
        this.status = 2
        this.$forceUpdate()
        this.getPersonnel();
      },
      // 父字典id，父字典数据，回调
      init(callback) {
        this.addBrandCallback = callback;
        this.visible = true;
        this.loading = false;
        this.unitQuery.current = 1;
        this.$nextTick(() => {
          this.getPersonnel();
        });
      },
      // 全选
      selectAll(selection) {
        if (this.status == 2) {
          let stateArr = [];
          if (selection.length > 0) {
            stateArr = JSON.parse(JSON.stringify(selection));
            const arr = [...selection, ...this.initArr];
            // 去重
            const res = new Map();
            this.initArr = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1));
          } else {
            stateArr = JSON.parse(JSON.stringify(this.unitData));
            stateArr.map((item) => {
              if (this.initArr.find((row) => row.id === item.id)) {
                this.initArr = this.initArr.filter((row) => row.id !== item.id);
              }
            });
          }
        } else {
          let statePerArr = [];
          if (selection.length > 0) {
            statePerArr = JSON.parse(JSON.stringify(selection));
            const arr = [...selection, ...this.personAbout];
            // 去重
            const res = new Map();
            this.personAbout = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1));
          } else {
            statePerArr = JSON.parse(JSON.stringify(this.tableData));
            statePerArr.map((item) => {
              if (this.personAbout.find((row) => row.id === item.id)) {
                this.personAbout = this.personAbout.filter((row) => row.id !== item.id);
              }
            });
          }
        }
      },

      handleSelectionChange(val, row) {
        console.log(this.status,this.personAbout,555)
        if (this.status == 2) {
          if (this.initArr.find((item) => item.id === row.id)) {
            this.initArr = this.initArr.filter((item) => item.id !== row.id);
          } else {
            this.initArr.push(row);
          }
        } else {
          if (this.personAbout.find((item) => item.id === row.id)) {
            this.personAbout = this.personAbout.filter((item) => item.id !== row.id)
          } else {
            this.personAbout.push(row)
          }
        }
      },

      // 搜索
      searchPersonnel() {
        this.unitQuery.current = 1;
        this.getPersonnel();
      },

      // 重置
      reset() {
        this.unitQuery = {
          current: 1,
          size: 10,
          name: '',
          code: '',
          rescueCode: '',
        }
        this.getPersonnel();
      },

      // 电梯分页
      unitPage(data) {
        this.unitQuery.current = data.page;
        this.unitQuery.size = data.limit;
        this.getPersonnel();
      },
      // 保存更换
      saveChange() {
        console.log(this.initArr, "this.initArr");
        const listArr = [];
        this.initArr.map((item) => {
          listArr.push(item.id);
        });
        const personArr = []
        console.log(this.personAbout,788)
        this.personAbout.map((item) => {
          personArr.push(item.id);
        });
        // if (listArr.length === 0) {
        //   this.$message.error('请勾选需要添加的电梯');
        //   return
        // }
        if (this.personAbout && this.personAbout.length > 0) {
          this.$http
            .get(
              `/api/ele/web/plot/batchSaveOrDel?type=1&id=${
            this.quartersInfo.id
          }&eleIds=${listArr.join(",")}&userIds=${personArr.join(",")}`
            )
            .then((res) => {
              if (res.data.success) {
                this.initArr = [];
                if (this.$refs.unitTable) {
                  this.$refs.unitTable.clearSelection();
                }
                this.visible = false;
                this.$emit("closeDeviceAdd");
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
            });
        } else {
          this.$message.error('请勾选关联的人员')
        }
      },

      // 获取电梯列表
      getPersonnel() {
        this.$http
          .get(
            `/api/ele/web/plot/getEleList?name=${this.unitQuery.name}&current=${this.unitQuery.current}&size=${this.unitQuery.size}&code=${this.unitQuery.code}&rescueCode=${this.unitQuery.rescueCode}`
          )
          .then((res) => {
            const {
              data: {
                data
              },
            } = res;
            if (res.data.code === 200) {
              this.unitData = JSON.parse(JSON.stringify(data.records));
              this.unitTotal = data.total;
              this.$nextTick(() => {
                if (this.unitTotal > 0 && this.$refs.unitTable) {
                  this.$refs.unitTable.bodyWrapper.scrollTop = 0;
                }
                if (this.unitData.length > 0) {
                  this.unitData.map((ele) => {
                    this.initArr.map((item) => {
                      if (item.code === ele.code) {
                        this.$refs.unitTable.toggleRowSelection(ele, true)
                      }
                    })
                  })
                }
              })
            }
          });
      },
      closeDialog() {
        this.visible = false;
        this.$emit("closeDeviceAdd");
      },
    },
  };
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchBox {
    /deep/.el-form-item__label {
      color: #00f6ff !important;
      font-size: 14px;
    }

    .searchBtn {
      margin-left: 15px;
    }
  }
</style>
