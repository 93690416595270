<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="formInline.eleName" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯注册代码</span>
          </span>
          <el-input v-model="formInline.eleCode" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">应急类型</span>
          </span>
          <el-select v-if="!(adminInfo.roleId == '99')" v-model="formInline.sosTypeOption" placeholder="请输入" filterable
            class="einp" multiple clearable @change="checkSosType($event)">
            <div v-for="item in typeDate" :key="item.value">
              <el-option v-if="item.value != 35 || adminInfo.userName == 'admin'" :label="item.label"
                :value="item.value" />
            </div>
          </el-select>
          <el-select v-else v-model="formInline.sosTypeOption" placeholder="请输入" class="einp" multiple clearable
            @change="checkSosType($event)">
            <el-option v-for="item in simpleSosType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <span slot="label">
            <span class="lab-span">报警状态</span>
          </span>
          <el-select v-model="formInline.alarmState" placeholder="请输入" class="einp" clearable>
            <el-option v-for="item in stateType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="!(adminInfo.roleId == '99')">
          <span slot="label">
            <span class="lab-span">所属小区</span>
          </span>
          <el-input v-model="formInline.plotName" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">应急状态</span>
          </span>
          <el-select v-model="formInline.sosStatus" placeholder="请输入" class="einp" clearable>
            <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">报警时间</span>
          </span>
          <el-date-picker v-model="formInline.allTime" :max="maxRange" type="daterange" @change="disableddate"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="einp">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">阻梯</span>
          </span>
          <el-select v-model="formInline.haveBanClosed" clearable>
            <el-option :value="0" label="关闭"></el-option>
            <el-option :value="1" label="开启"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
          <el-button type="success" :disabled="exportType" @click="exportData()">{{exportTitle}}</el-button>
          <el-button type="success" @click="exportList()">导出记录</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button v-focus type="danger" @click="DeleteDate(true)" size="small">批量删除</el-button>
        <!-- <el-button type="danger" v-focus @click="DeleteDate(true)" size="small"
          >批量删除</el-button> -->
      </div>
    </div>
    <div v-if="dictionarTotal!=0">
      <div :style="{left:contextMenuPosition.x+'px',top:contextMenuPosition.y+'px'}"
        v-show="contextMenuPosition.x > 0 || contextMenuPosition.y > 0 " class="contextmenuInfo" style="height: 40xp;">
        <ul class="contextmenus">
          <li @click="cancelTop()" v-if="topShow">置顶</li>
          <li @click="cancelDown()" v-else>取消置顶</li>
          <!-- <li>置顶</li> -->
        </ul>
      </div>
      <el-table :data="tableData" v-loading="tableLoading" ref="multipleTable" style="width: 100%;margin-bottom: 20px;"
        @sort-change="handleSort" :height="$store.state.tabHeight-80" @select="selections" @select-all="selectAll"
        @row-contextmenu="onRowContextMenu">
        <el-table-column type="selection" />
        <el-table-column type="index" label="序号" width="50px" />
        <el-table-column align='center' prop="eleName" label="电梯名称" width="200px" />
        <el-table-column align='center' label="报警时间" width="200px" prop="sosTime" sortable>
          <template slot-scope="scope">
            {{ scope.row.sosTime ? scope.row.sosTime : '-' }}
          </template>
        </el-table-column>
        <el-table-column align='center' prop="sosTypeName" label="应急类型" width="120px">
          <template slot-scope="scope">
            {{scope.row.sosType == -1 ? scope.row.sosText : (scope.row.sosTypeName ? scope.row.sosTypeName : '未知')}}
          </template>
        </el-table-column>
        <el-table-column align='center' label="接警时间" width="200px" prop="callTime" sortable>
          <template slot-scope="scope">
            {{ scope.row.callTime ? scope.row.callTime : '-' }}
          </template>
        </el-table-column>
        <el-table-column align='center' label="到场时间" width="200px">
          <template slot-scope="scope">
            {{scope.row.isAuto== 1 ? '-' : (scope.row.presentTime ? scope.row.presentTime : '-') }}
          </template>
        </el-table-column>
        <el-table-column align='center' label="完成时间" width="200px">
          <template slot-scope="scope">
            {{ scope.row.finishTime ? scope.row.finishTime : '-' }}
          </template>
        </el-table-column>
        <el-table-column align='center' label="报警状态">
          <template slot-scope="scope">
            {{ scope.row.sosTypeName == '电动车识别报警' && scope.row.alarmState == 0 ? '-' : stateType.find((item)=>item.value == scope.row.alarmState).label }}
          </template>
        </el-table-column>
        <el-table-column v-if="!(adminInfo.roleId == '99')" align='center' label="所属小区" width="180px">
          <template slot-scope="scope">
            {{scope.row.plotName ? scope.row.plotName : '-'}}
          </template>
        </el-table-column>
        <!-- <el-table-column align='center' prop="sosFloor" label="报警楼层" width="100px" /> -->
        <el-table-column align='center' label="救援时长" width="200px">
          <template #header>
            <div>
              <span style="margin-right: 5px;">救援时长</span>
              <el-tooltip class="item" effect="dark" content="救援时长 = 完成时间 -  报警时间" placement="top">
                <i class="el-icon-warning-outline" style="color: #00f6ff;font-size: 16px;"></i>
              </el-tooltip>
            </div>
          </template>
          <template slot-scope="scope">
            {{ scope.row.useTime && scope.row.sosStatus == 4 ? scope.row.useTime : '-' }}
          </template>
        </el-table-column>
        <el-table-column align='center' prop="sosStatusName" label="应急状态" width="100px" />
        <el-table-column align='center' prop="sosCount" label="连续报警次数" width="150px" />
        <el-table-column align='center' prop="finallySosTime" label="最后一次报警时间" width="150px" />
        <el-table-column align='center' prop="eleCode" label="电梯注册代码" width="200px" />
        <el-table-column v-if="!(adminInfo.roleId == '99')" align='center' prop="propertyUnitName" label="物业单位"
          width="200px" />
        <el-table-column v-if="!(adminInfo.roleId == '99')" align='center' prop="maintainUnitName" label="维保单位"
          width="200px" />
        <el-table-column v-if="!(adminInfo.roleId == '99')" align='center' prop="maintainUsers" label="维保人员"
          width="200px">
          <template slot-scope="scope">
            {{ scope.row.maintainUsers ? scope.row.maintainUsers.split(';').slice(0,2).join() : '-' }}
          </template>
        </el-table-column>
        <el-table-column align='center' prop="eleAddress" label="电梯安装位置" width="250px" />
        <el-table-column align="center" label="阻梯">
          <template slot-scope="scope">
            {{
							scope.row.haveBanClosed ? '开启' : (scope.row.haveBanClosed == 0 ? '关闭' : '无')
						}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" align='center' prop="address" label="操作" width="200px">
          <template slot-scope="scope">
            <el-button v-focus @click="showDetails(scope.row)" type="primary" size="small">查看</el-button>
            <el-button v-focus @click="Delete(scope.row)" type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
      <Runreport v-if="Listshow" @closeCheck="close" />
    </div>
    <div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>

    <el-dialog v-if='dioDetails' :close-on-click-modal='false' :visible.sync="dioDetails" width="1400px" top='15vh'
      @close="closeDialog">
      <div class="tabBut">
        <!-- <template v-for="item in detailsItem.seleArr">
					<el-button @click="detailsItem.seleId=item.value"
						:type='item.value==detailsItem.seleId?"primary":""'>{{item.label}}</el-button>
				</template> -->
        <el-button @click="detailsItem.seleId=1" :type='1 == detailsItem.seleId ? "primary" : ""'>报警消息
        </el-button>
        <el-button v-show="dioDetailsItem.sosTypeName == '安全回路报警' || dioDetailsItem.sosTypeName == '电梯运行异常告警'"
          @click="detailsItem.seleId=2" :type='2 == detailsItem.seleId ? "primary" : ""'>运行参数</el-button>
        <el-button @click="detailsItem.seleId=3" :type='3==detailsItem.seleId ? "primary" : ""'>处置流程</el-button>
        <el-button v-if="dioDetailsItem.voiceRescordDTOS.length > 0 || dioDetailsItem.eleSmsRescordDTOS.length > 0"
          @click="detailsItem.seleId=4" :type='4==detailsItem.seleId ? "primary" : ""'>报警通知</el-button>
      </div>
      <div style="height: 60vh;margin-top: 10px;" class="scrollElement"
        :class="[detailsItem.seleId==1?'yeScroll':'noScroll']">
        <alarmMessage v-if="detailsItem.seleId==1" :dioData="dioDetailsItem" />
        <operational v-else-if="detailsItem.seleId==2" :dioData="dioDetailsItem" />
        <process v-else-if="detailsItem.seleId==3" :dioData="dioDetailsItem" />
        <AlarmNotice v-else :dioData="dioDetailsItem" />
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: center;">
        <div v-if="detailsItem.seleId == 1 && dioDetailsItem.sosStatus === 1">
          <template v-if="
          	dioDetailsItem.sosType == 95 ||
          	dioDetailsItem.sosType == 0 ||
          	dioDetailsItem.sosType == 10 ||
          	dioDetailsItem.sosType == 7 ||
          	dioDetailsItem.sosType == 3 ||
          	dioDetailsItem.sosType == 333 ||
          	dioDetailsItem.sosType == 4 ||
          	dioDetailsItem.sosType == 5 ||
          	dioDetailsItem.sosType == 11 ||
          	dioDetailsItem.sosType == 12 ||
          	dioDetailsItem.sosType == 13 ||
            dioDetailsItem.sosType == 96 ||
            dioDetailsItem.sosType == 1 ||
            dioDetailsItem.sosType == 2 ||
            dioDetailsItem.sosType == 14 ||
            dioDetailsItem.sosType == 999 ||
            dioDetailsItem.sosType == 21 ||
            dioDetailsItem.sosType == 20 ||
            dioDetailsItem.sosType == 19 ||
            dioDetailsItem.sosType == 109 ||
            dioDetailsItem.sosType == 22 ||
            dioDetailsItem.sosType == 97 ||
            dioDetailsItem.sosType == 35
           ">
            <el-button v-if="dioDetailsItem.sosType != 0&&dioDetailsItem.sosType != 5" :disabled="disabled" size="mini"
              type="primary" @click="sosClick(6)">
              <span>确认</span>
              <p style="margin: 10px 0 0 0;">正常报警非误报</p>
            </el-button>
            <el-button type="primary" size="mini" v-if="dioDetailsItem.sosType == 5 " @click="sosClick(7)"
              :disabled="disabled">
              <span>长时逗留</span>
              <p style="margin: 10px 0 0 0;">无困人非误报</p>
            </el-button>
            <el-button type="primary" size="mini" v-if="dioDetailsItem.sosType == 0" @click="sosClick(8)"
              :disabled="disabled">
              <span>乘客误按</span>
              <p style="margin: 10px 0 0 0;">无困人非误报</p>
            </el-button>
            <el-button size="mini" type="info" v-if="dioDetailsItem.sosType != 13&&dioDetailsItem.sosType != 35&&dioDetailsItem.sosType != 22&& dioDetailsItem.sosType != 21 &&dioDetailsItem.sosType != 21
              " @click="sosClick(2)" :disabled="disabled">
              <span>电梯故障(无困人)</span>
              <p style="margin: 10px 0 0 0;">派发维修工单</p>
            </el-button>
            <el-button size="mini" type="warning"
              v-if="(dioDetailsItem.sosType == 0 || dioDetailsItem.sosType ==5||dioDetailsItem.sosType ==4||dioDetailsItem.sosType ==7||
              dioDetailsItem.sosType ==3 || dioDetailsItem.sosType == 333 || dioDetailsItem.sosType ==11||dioDetailsItem.sosType ==12) && is24Hours(dioDetailsItem) "
              @click="sosClick(3)" :disabled="disabled">
              <span>困人</span>
              <p style="margin: 10px 0 0 0;">立即派发救援工单</p>
            </el-button>
            <el-button class="errorSos" size="mini" @click="sosClick(5)" :disabled="disabled">
              <span>误报</span>
              <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
            </el-button>
          </template>
        </div>
      </span>
    </el-dialog>

  </div>
</template>
<script>
  import process from './components/process'
  import operational from './components/operational'
  import alarmMessage from './components/alarmMessage'
  import AlarmNotice from './components/alarmNotice'
  import Pagination from '@/components/Pagination'
  import Runreport from './components/RunReport.vue'
  import {
    formatDate,
    getTimeSE
  } from "@/util";
  import Terminal from '../../dtsj/dtzl/components/terminal.vue'

  let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
  let current_time1 = new Date(current_time).getTime();
  let starTime = new Date(current_time1 - 14 * 24 * 60 * 60 * 1000);
  let endTime = new Date(current_time1) ;

  export default {
    components: {
      process,
      Pagination,
      operational,
      alarmMessage,
      AlarmNotice,
      Runreport,
      Terminal
    },
    data() {
      return {
        maxRange: 62,
        Listshow: false,
        // 报警详情
        detailsItem: {
          seleId: 1,
          seleArr: [{
            value: 1,
            label: '报警消息'
          }, {
            value: 2,
            label: '运行参数'
          }, {
            value: 3,
            label: '处置流程'
          }, {
            value: 4,
            label: '报警通知'
          }]
        },
        stateType: [],
        dioDetails: false,
        dioDetailsItem: null,
        // 表格参数
        formInline: {
          current: 1,
          size: 10,
          sosStatus: 4,
          allTime: [starTime, endTime],
        },
        tableData: [],
        // 分页参数
        dictionarTotal: 0,
        // 状态
        typeDate: [], //字典表

        simpleSosType: [{
          dictCode: "SOS_TYPE",
          label: "困人报警",
          remark: "",
          value: "5"
        }, {
          dictCode: "SOS_TYPE",
          label: "电动车识别报警",
          remark: "",
          value: "13"
        }],
        stateList: [{
          value: 1,
          label: '待处理'
        }, {
          value: 2,
          label: '已处理'
        }, {
          value: 3,
          label: '进行中'
        }, {
          value: 4,
          label: '已完成'
        }],
        disabled: false,
        exportType: false,
        exportTitle: '导出',
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        isShow: false,
        delDate: [], //批量删除数据
        tableLoading: true,
        contextMenuPosition: {
          x: 0,
          y: 0,
        },
        topShow: true,
        topInfo: null,
      }
    },
    methods: {
      is24Hours(data) {
        let diff = ''
        const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
        console.log(data.lastAlarmTime,data.sosTime, '最后一次报警时间')
        if (data.lastAlarmTime) {
          const lastAlarmDate = new Date(data.lastAlarmTime); // 假设lastAlarmTime是字符串或者Date对象
          const now = new Date();
          diff = now - lastAlarmDate;
        } else if(data.sosTime) {
          const lastAlarmDate = new Date(data.sosTime); // 假设lastAlarmTime是字符串或者Date对象
          const now = new Date();
          diff = now - lastAlarmDate;
        }
        console.log('现在距离报警时间是否超过24小时',diff > oneDay)
        return diff < oneDay;
      },
      //导出记录
      exportList() {
        this.Listshow = true
      },
      close() {
        this.Listshow = false
      },
      //导出
      async exportData(ishm) {
        this.formInline.current = 1
        console.log('adsadasdasdasdasd');
        this.exportType = true
        // this.exportTitle = '正在导出'
        const {
          start,
          end
        } = getTimeSE(this.formInline.timeType)
        this.formInline.startTime = start
        this.formInline.endTime = end
        this.$http.post("/api/ele/web/sos/exportData", this.formInline).then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg);
            this.onRefer()
          } else {
            this.$message.success(res.data.msg);
          }
          this.exportType = false
          // if (res.data.size > 0) {
          //     let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
          //     let url = URL.createObjectURL(blob);
          //     const link = document.createElement('a'); //创建a标签
          //     link.href = url;
          //     link.download = '电梯检验统计.xls'; //重命名文件
          //     link.click();
          //     URL.revokeObjectURL(url);
          // } else {
          //     this.$message.error('暂无数据')
          // }

          // this.exportTitle = '导出'
        })
      },
      //关闭弹框
      closeDialog() {
        this.dioDetails = false
      },
      // 详情操作按钮
      sosClick(type) {
        this.disabled = true
        if (this.dioDetailsItem.submitType === 0) {
          this.dioDetailsItem.sosStatus = 2
          this.$http
            .post("/api/ele/app/sos/submit", {
              id: this.dioDetailsItem.id,
              submitType: type,
              sosTime: this.dioDetailsItem.sosTime,
            })
            .then((res) => {
              if (res.data.success) {
                // this.$message.success(res.data.msg)
                if (type == 5) {
                  this.$confirm('确认误报成功，是否删除该报警误报记录？', '提示', {
                    cancelButtonText: '取消',
                    confirmButtonText: '确认',
                    type: 'warning'
                  }).then(() => {
                    this.$http
                      .post("/api/ele/web/sos/removeSosInfo", {
                        id: this.dioDetailsItem.id,
                        sosTime: this.dioDetailsItem.sosTime,
                      }).then((res) => {
                        if (res.data.code == 200) {
                          this.dioDetails = false
                          this.onRefer()
                          this.$message.success(res.data.msg)
                        } else {
                          this.$message.error(res.data.msg)
                        }
                      })
                  }).catch(() => {
                    this.dioDetails = false
                  })
                } else {
                  this.$message.success(res.data.msg)
                }
              } else {
                this.$message.error(res.data.msg)
              }
              this.$nextTick(() => {
                this.onRefer()
              })
              // this.onRefer()
              // this.showDetails(this.tableData.find(item => item.id == this.dioDetailsItem.id))
            })
        }
      },
      //应急类型
      checkSosType(data) {
        for (var i = 0; i < data.length; i++) {
          if (data[i] == '13') {
            this.formInline.sosStatus = ''
          }
        }
      },
      //批量数据
      selections(data) {
        this.contextMenuPosition.x = 0
        this.contextMenuPosition.y = 0
        console.log(data, 'value')
        this.delDate = []
        data.forEach(item => {
          this.delDate.push(item.id)
        })
        console.log(this.delDate, 'value')
      },
      selectAll(data) {
        // console.log(data,'value')
        this.delDate = []
        data.forEach(item => {
          this.delDate.push(item.id)
        })
        console.log(this.delDate, 'value')
      },
      //批量删除
      DeleteDate() {
        console.log(this.delDate, ' this.delDate')
        if (this.delDate.length < 1) {
          this.$message({
            type: 'warning',
            message: '请选择要删除的电梯'
          })
        } else {
          this.$confirm("此操作将永久删除选中电梯, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then((res) => {
            this.$http.post('api/ele/web/sos/deletedSosLog', this.delDate).then((res) => {
              if (res.data.code == 200) {
                this.$message.success(res.data.msg);
                this.onRefer()
              } else {
                this.$message.success(res.data.msg);
              }
            })
          }).catch((res) => {})
        }
      },
      // 详情
      showDetails(item) {
        this.$http
          .post("/api/ele/web/sos/getInfo", {
            id: item.id,
            sosTime: item.sosTime ? item.sosTime : ''
          })
          .then((res) => {
            console.log(res.data, 'res')
            if (res.data.success) {
              res.data.data.sosVideo.forEach((qs) => {
                let url = qs.videoUrl ? qs.videoUrl.split('?') : '';
                qs.videoUrl = url ? url[0] : ''
                const urlname = url[0].split('/').pop().split('_')
                qs.videoName = url ? urlname.length > 1 ? `${urlname[1]}_${urlname[2]}` : url[0].split('/')
                  .pop() : ''
              })
              console.log(item, 'item')
              res.data.data.sosTypeName = item.sosType == -1 ? (item.sosText != '' ? item.sosText : '未知') : (item
                .sosTypeName != '' ? item.sosTypeName : '未知')
              res.data.data.sosStatusName = this.stateList.find(item => item.value === res.data.data
                  .sosStatus) ? this.stateList.find(item => item.value === res.data.data.sosStatus)
                .label : ''
              res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
              res.data.data.speedarr = res.data.data.speedRecord.split(',');

              this.detailsItem.seleId = 1
              this.dioDetailsItem = res.data.data
              this.dioDetailsItem.emergencyNames = res.data.data.emergencyRescueUserNames.split(';')
              this.dioDetailsItem.maintainNames = res.data.data.maintainUsers.split(';')
              this.dioDetailsItem.propertyNames = res.data.data.propertyUsers.split(';')
              // console.log(res.data.data.emergencyRescueUserNames,'res.data.data.emergencyRescueUserNames');
              this.dioDetails = true
              this.disabled = false
              console.log(this.dioDetailsItem, 'this.dioDetailsItem');
            } else {
              this.$message.error(res.data.msg)
            }
          })

      },
      //删除
      Delete(item) {
        const data = {
          id: item.id,
          sosTime: item.sosTime
        }
        this.$confirm("此操作将删除报警记录，是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            this.$http.post('/api/ele/web/sos/remove', data)
              .then((res) => {
                if (res.data.code == 200) {
                  this.$message.success(res.data.msg);
                  this.onRefer()
                } else {
                  this.$message.success(res.data.msg);
                }
              })
          })
      },
      disableddate(val) {
        const [start, end] = val;
        console.log(starTime, endTime, '时间')
        const days = this.getDays(start, end);
        console.log(days, '天数')
        if (days > this.maxRange) {
          this.$message.error('选择的日期范围不能超过62天！');
          // 自动调整为最大范围
          // const newEnd = this.addDays(start, this.maxRange - 1);
          this.formInline.allTime = [starTime, endTime]
        }
      },
      getDays(start, end) {
        console.log(start, end, '时间')
        const oneDay = 24 * 60 * 60 * 1000; // 每天毫秒数
        const diff = Math.abs(start - end);
        return (diff / oneDay) + 1;
      },
      // 重置按钮事件
      reset() {
        this.formInline = {
          current: 1,
          size: 10,
          allTime: [starTime, endTime]
        }
        this.onRefer()
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      // 获取类型
      getType(type, sta) {
        let obj
        let arr = []
        if (sta == 1) {
          arr = this.typeDate
        } else {
          arr = this.stateList
        }
        arr.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
          }
        })
        return obj
      },
      //排序
      handleSort(column, prop, order) {
        console.log('column', column)
        console.log('prop', prop)
        console.log('order', order)
        if (column.prop == 'sosTime') {
          this.formInline.sosTimeSortSign = column.order === 'descending' ? 'DESC' : 'ASC'
        } else if (column.prop == 'callTime') {
          this.formInline.callTimeSortSign = column.order === 'descending' ? 'DESC' : 'ASC'
        }
        this.onRefer()
      },
      onRowContextMenu(row, column, event) { //右击
        console.log(row, '电梯监控列表数据')
        // event.preventDefault();
        this.topInfo = row
        this.topShow = row.topStatus == 1 ? false : true
        const contextMenuEvent = event || window.event;
        contextMenuEvent.preventDefault();
        this.contextMenuPosition.x = event.clientX;
        this.contextMenuPosition.y = event.clientY;
      },
      cancelTop() {
        const data = {
          objectId: this.topInfo.id,
          type: 2
        }
        this.$http.post('/api/ele/web/information/setTopInfo', data).then((res) => {
          console.log(res, '置顶')
          if (res.data.code == 200) {
            this.contextMenuPosition.x = 0;
            this.contextMenuPosition.y = 0;
            this.$message.success(res.data.msg)
            this.onRefer();
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      cancelDown() {
        const data = {
          objectId: this.topInfo.id,
          type: 2
        }
        this.$http.post('/api/ele/web/information/delTopInfo', data).then((res) => {
          console.log(res, '取消置顶')
          if (res.data.code == 200) {
            this.contextMenuPosition.x = 0;
            this.contextMenuPosition.y = 0;
            this.$message.success(res.data.msg)
            this.onRefer();
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 表格方法
      onRefer(ishm) {
        if (ishm) {
          this.formInline.current = 1
        }
        if (this.formInline.allTime && this.formInline.allTime.length > 0) {
          this.tableLoading = true
          this.tableData = []
          let atime = JSON.parse(JSON.stringify(this.formInline.allTime))

          if (new Date(atime[0]).getTime() < new Date('2022-12-01').getTime()) {
            this.$message.error('查询时间不得早于2022年12月')
            return
          }
          if (new Date(atime[1]).getTime() > new Date(new Date().toLocaleDateString()).getTime()) {
            this.$message.error('查询时间不得晚于当天')
            return
          }
          this.formInline.start = new Date(atime[0]).getTime()
          this.formInline.end = new Date(atime[1]).getTime() + 24 * 60 * 60 * 1000 - 1000
        } else {
          return this.$message.error('请选择查询时间')
        }
        this.formInline.sosTypeStr = this.formInline.sosTypeOption && this.formInline.sosTypeOption.length > 0 ? this
          .formInline.sosTypeOption.join(',') : ''
        this.$http
          .post("/api/ele/web/sos/getList", this.formInline)
          .then((res) => {
            if (res.data.success) {
              res.data.data.records.forEach((qs) => {
                qs.sosTypeName = this.getType(qs.sosType, 1)
                qs.sosStatusName = this.getType(qs.sosStatus, 2)
                // if(qs.sosType == 5 ||qs.sosType == 13){
                //   // this.tableData.push(qs)
                // }
              })
              this.tableData = res.data.data.records
              this.dictionarTotal = res.data.data.total
              this.$nextTick(() => {
                if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
            this.tableLoading = false
          })
      },

    },
    mounted() {
      var that = this
      window.addEventListener('click', function() {
        // console.log('点击', that.contextMenuPosition)
        that.contextMenuPosition.x = 0
        that.contextMenuPosition.y = 0
      })
    },
    created() {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=SOS_TYPE,ALARM_STATE`)
        .then((res) => {
          if (res.data.success) {
            const Arr = res.data.data.SOS_TYPE.filter((item) => {
              if (this.adminInfo.userName != 'admin') {
                return item.value != '21' && item.value != '35' && item.value != '22'
              } else {
                return item
              }
            })
            this.typeDate = Arr
            this.stateType = res.data.data.ALARM_STATE
            this.onRefer()
          }
        })
    }
  }
</script>
<style scoped>
  .contextmenuInfo {
    width: 100px;
    height: 50px;
    background: white;
    position: fixed;
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .contextmenus {
    margin: 0;
    padding: 0px;
    color: #333;
    font-size: 16px;
    line-height: 30px;
    width: 100%;
  }

  .errorSos {
    background: #00dcff;
    border-color: #00dcff;
    color: #fff
  }

  .yeScroll {
    overflow-y: scroll;
  }

  .noScroll {
    overflow-y: none;
  }

  .tabBut {
    width: 80%;
    margin-left: 10%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }

  .page-bom {}
</style>
