<template>
  <el-dialog
    :title="actionType == 'add' ? '新增信息' : eleInfo.name"
    v-if="detailVisible"
    :visible.sync="detailVisible"
    :close-on-click-modal="false"
    top="10vh"
    @close="back"
    width="1000px"
  >
    
    <el-form ref="elevatorForm" :model="elevatorForm" :rules="elevatorFormRules" label-width="150px"
      label-position="right">
      <el-tabs v-model="activeName" style="margin-top: 15px; font-size: 16px;">
        <el-tab-pane label="基础信息" name="first">
          <div class="searchBox scrollElement" style="width: 95%;">
            <el-row>
              <el-col :span="12">
                <el-form-item label="电梯名称:" prop="name">
                  <el-input class="wid90" v-model="elevatorForm.name" :disabled="actionType === 'detail'"
                    placeholder="请输入内容" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所在地区:" prop="floorDong">
                  <!-- <el-select v-model="elevatorForm.plotId" :disabled="actionType === 'detail'" class="wid90" filterable
                    clearable placeholder="请选择" @change="changePlot">
                    <el-option v-for="item in plotArr" :key="item.id" :label="item.name" :value="item.id" />
                  </el-select> -->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="详细地址:" prop="inVillage">
                  <el-input class="wid90" v-model="elevatorForm.inVillage" :disabled="actionType === 'detail'"
                    placeholder="请输入内容" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电梯设备代码:" prop="deviceNo">
                  <el-input class="wid90" v-model="elevatorForm.deviceNo" :disabled="actionType === 'detail'"
                    placeholder="请输入内容" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电梯注册码:" prop="deviceSn">
                  <el-input class="wid90" v-model="elevatorForm.deviceSn" :disabled="actionType === 'detail'"
                    placeholder="请输入内容" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电梯安全码:" prop="safeCode">
                  <el-input class="wid90" v-model="elevatorForm.safeCode" :disabled="actionType === 'detail'"
                    placeholder="请输入内容" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="出厂日期:" prop="factoryOutTime">
                  <el-date-picker
                      v-model="elevatorForm.factoryOutTime"
                      type="date"
                      clearable
                      :disabled="actionType === 'detail'"
                      value-format="yyyy-MM-dd">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="维保信息" name="second">
          <div class="searchBox scrollElement" style="width: 95%;">
            <el-row>
                <el-col :span="24">
                  <el-form-item label="维保单位:" prop="unitName">
                    <el-input class="wid90" v-model="elevatorForm.unitName" :disabled="actionType === 'detail'"
                      placeholder="请输入内容" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="维保人员1:" prop="floorDong">
                    <el-input class="wid90" v-model="elevatorForm.floorDong" :disabled="actionType === 'detail'"
                      placeholder="请输入内容" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="电话:" prop="floorDong">
                    <el-input class="wid90" v-model="elevatorForm.floorDong" :disabled="actionType === 'detail'"
                      placeholder="请输入内容" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="维保人员2:" prop="floorDong">
                    <el-input class="wid90" v-model="elevatorForm.floorDong" :disabled="actionType === 'detail'"
                      placeholder="请输入内容" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="电话:" prop="floorDong">
                    <el-input class="wid90" v-model="elevatorForm.floorDong" :disabled="actionType === 'detail'"
                      placeholder="请输入内容" />
                  </el-form-item>
                </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="back">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: ["eleInfo", "actionType"],
  data() {
    return {
      detailVisible: true,
      activeName: 'first',
      elevatorForm: {},
      elevatorFormRules: {
          name: [{
            required: true,
            message: '电梯名称不能为空',
            trigger: 'blur',
          }],
          inVillage: [{
            required: true,
            message: '详细地址不能为空',
            trigger: 'blur',
          }],
          deviceNo: [{
            required: true,
            message: '电梯设备代码不能为空',
            trigger: 'blur',
          }],
          deviceSn: [{
            required: true,
            message: '电梯注册码不能为空',
            trigger: 'blur',
          }],
          safeCode: [{
            required: true,
            message: '电梯安全码不能为空',
            trigger: 'blur',
          }],
          factoryOutTime: [{
            required: true,
            message: '出厂日期不能为空',
            trigger: 'blur',
          }],
      }
    };
  },
  mounted(){
    this.$nextTick(() => {
      this.elevatorForm = this.eleInfo
    })
  },
  methods: {
    back() {
      this.detailVisible = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.infoBoxTop{
  display: flex;
}
.infoBox{
  width: 25%;
}
/deep/.el-tabs__item{
  font-size: 16px;
}
.tabBox{
  width: 50%;
  margin-bottom: 25px;
}
.infoBoxTitle{
  color: #aaa;
  margin-bottom: 15px;
  font-weight: bolder;
}
.infoLeft{
  padding-left: 30px;
  border-left: 1px solid #ccc;
  height: 60vh;
}
.lineBox{
  margin: 25px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.lineTitle{
  color: #aaa;
  font-weight: bolder;
  width: 65px;
}
.lineMsg{
  margin-left: 35px;
}
</style>
