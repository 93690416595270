<template>
  <div
      class="admin-project-myproject-container scrollElement"
      style="height: 65vh;overflow-y: scroll;"
    >
    <div v-if="dioData.elevatorInfo">
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item>
          <template slot="label"> 电梯注册代码 </template>
          {{ dioData.elevatorInfo.code }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 电梯名称 </template>
          <p :title="dioData.elevatorInfo.name" style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1; height: 20px;overflow: hidden;width: 340px;align-items: center;">{{ dioData.elevatorInfo.name }}</p>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 救援识别码 </template>
          {{ dioData.elevatorInfo.rescueCode }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 物业单位 </template>
          {{ dioData.elevatorInfo.propertyUnitName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 维保单位 </template>
          {{ dioData.elevatorInfo.maintainUnitName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 电梯出厂编号 </template>
          {{ dioData.elevatorInfo.factoryNumber }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 计划时间 </template>
          {{ dioData.startTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 签到时间 </template>
          {{ dioData.signTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 维保结束时间 </template>
          {{ dioData.finishTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 维保时长 </template>
          {{ dioData.useTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 维保类型 </template>
          {{ dioData.maintainTypeName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 签到经纬度 </template>
          {{ dioData.latitude }}&emsp;{{ dioData.longitude }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" :column="1" border>
        <el-descriptions-item>
          <template slot="label"> 维保人员 </template>
          {{ dioData.elevatorInfo.maintainUserNames }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 电梯安装位置 </template>
          {{ dioData.elevatorInfo.eleAddress }}
        </el-descriptions-item>
      </el-descriptions>

      <div class="tabDiv">
        <h3 style="color: white;">维保项:</h3>
        <el-tabs v-model="activeName" @tab-click="handleClick" v-if="isShowTab">
          <el-tab-pane v-for="(item, index) in itemGroup" :key="item.value" :label="item.value" :name="item.label" >
            <template v-for="(item,index) in remarkList">
              <div class="remark-vessel">
                <span>{{index+1}}. {{item.item}}
                  <el-button :type="item.status == 1 ? 'primary' : (item.status== 2 ? 'danger' : 'warning')"
                    size="mini" style="margin-left: 15px">{{item.statusName}}</el-button>
                </span>
                <p v-if="item.memo!=''">备注:{{item.memo}}</p>
                <div v-if="item.images.length>0 && item.images != '[]'" style="margin-top: 10px;">
                  <template v-for="(itm, index) in item.images">
                    <el-image class="gdImg" v-show="itm" :key="index" :src="itm" fit="scale-down"
                      :preview-src-list="item.images">
                    </el-image>
                  </template>
                </div>
              </div>
            </template>
          </el-tab-pane>
        </el-tabs>
        <template v-for="(item,index) in remarkList" v-else>
          <div class="remark-vessel">
            <span>{{index + 1}}. {{item.item}}
              <el-button :type="item.status == 1 ? 'primary' : (item.status== 2 ? 'danger' : 'warning')"
                size="mini">{{item.statusName}}</el-button>
            </span>
            <p v-if="item.memo!=''">备注:{{item.memo}}</p>
            <div v-if="item.images.length>0 " style="margin-top: 10px;">
              <template v-for="(itm, index) in item.images">
                <el-image class="gdImg" :key="index" :src="itm" fit="scale-down"
                  :preview-src-list="item.images">
                </el-image>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  // props: ["dioData", 'remarkList'],
  data() {
    return {
      loading: true,
      addBrandCallback: null, // 弹窗回调
      dioData:{},
      remarkList: [],
      activeName: '1',
      isShowTab: false
    };
  },
  props: {},

  mounted() {
    this.getDictionar(`MAINTAIN_ITEM_GROUP`)
  },
  created() {},

  methods: {
    // 电梯id，回调
    init(data, remarkList, callback) {
      this.dioData = data
      console.log(remarkList,'remarkList');
      if (remarkList.find(item => item.itemGroup == 0) || remarkList.find(item => !item.itemGroup)) {
        this.isShowTab = false
        this.remarkList = remarkList
      } else {
        this.isShowTab = true
        this.newRemarkList = remarkList
        this.remarkList = this.newRemarkList.filter(item => item.itemGroup == 1)
      }
      console.log(this.remarkList,'remarkList');
    },

    handleClick(tab, event){
      this.remarkList = this.newRemarkList.filter(item => item.itemGroup == this.activeName)
    },
    getDictionar(code) {
        // code = code.join()
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {

            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res
              this.itemGroup = data.MAINTAIN_ITEM_GROUP
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
  },
};
</script>
<style lang="scss" scoped>
	.gdImg {
		width: 80px;
		height: 80px;
		border: 1px solid #00f6ff;
		margin-right: 20px;
	}

	.remark-vessel {
		color: white;
		margin-top: 10px;
	}

	.tabDiv {
		padding: 10px;
	}
</style>
