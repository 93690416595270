<template>
	<el-dialog :title="tit" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="800px"
		top="8vh"  @close="closeDialog">
        <div style="height: 50vh; overflow-y: scroll; padding-right: 20px" class="scrollElement" >
        <el-form
          label-position="right"
          label-width="120px"
          ref="dataForm"
          :model="dataForm"
          :rules="rules"
        >
            <el-form-item label="位置:" prop="location" >
                <el-select
                    class="wid90"
                    v-model="dataForm.location"
                    placeholder="请输入"
                >
                    <el-option
                    v-for="(item, index) in positionArr"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="名称:" prop="name"  >
                <el-input
                    class="wid90"
                    v-model="dataForm.name"
                    :disabled="dataForm.type == 3"
                    placeholder="请输入名称，不超过30字"
                />
            </el-form-item>
            <el-form-item label="序号:" prop="ordinal"  >
                <el-input
                    class="wid90"
                    v-model="dataForm.ordinal"
                    placeholder="请输入正整数，展示时按照升序展示。"
                />
            </el-form-item>
            <el-form-item label="跳转地址:" prop="imageUrl"  >
                <el-input
                    class="wid90"
                    v-model="dataForm.imageUrl"
                    placeholder="请输入跳转地址"
                />
            </el-form-item>

            <el-form-item label="上传文件:" prop="image"  >
                <FileUpload  :md5s.sync="dataForm.image" :maxWidth="640" :maxHeight="255" :showBannerTips="true"
                    :multiple="false" :limit="1" url='/api/file/web/uploadByOSS' />
            </el-form-item>
            <el-form-item label="展示状态:" prop="showType">
                <el-switch v-model="dataForm.showType" :active-value="1" :inactive-value="0"/>
            </el-form-item>

          <el-row style="margin: 10px 0">
            <el-col :span="24">
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button size="small" type="primary" :loading="loading" @click="dioSub()" >保 存</el-button>
        <el-button size="small" @click="closeDialog" >取 消</el-button>
        </span>
	</el-dialog>
</template>

<script>
	import FileUpload from '@/components/FileUpload'
    var validateTime = (rule, value, callback) => {
      let operation = /^([1-9][0-9]*)$/
      // true：必填  false：非必填
      inpYz(rule, value, callback, true, operation, "只能输入正整数")
    };
	export default {
		components: {
            FileUpload
		},
        props:{
            tit: {
                type: String,
                default: ''
            },
            actionForm: {
                type: Object,
                default: () => {},
            },
            positionArr: {
                type: Array,
                default: [{
                    label: 'APP首页Banner',
                    value: 'APP_INDEX_BANNER'
                }],
            }
        },
		data() {
			return {
				visible: true,
                loading: false,
                levelData: [],
                level2Data: [],
                dataForm: {
                    status: 0
                },
                uploadUrl: '/api/file/web/uploadByOssFile', // 上传地址
                // 表单限制
				rules: {
					location: [{
						required: true,
                        message: '位置不能为空',
						trigger: 'change',
					}],
					name: [{
						required: true,
                        max: 30,
                        message: '请输入名称，不超过30字',
						trigger: 'change',
					}],
					ordinal: [{
						required: true,
                        validator: validateTime,
						trigger: 'blur',
					}],
					image: [{
						required: true,
                        message: '上传文件不能为空',
						trigger: 'change',
					}],
					showType: [{
						required: true,
						trigger: 'blur',
					}],
				},
                key: 0,
			}
		},
        created(){
        },
        mounted(){
            this.dataForm = JSON.parse(JSON.stringify(this.actionForm))
        },

		methods: {
			dioSub(){
                this.loading = true;
                this.$refs.dataForm.validate(async (valid) => {
                    if (valid) {
                        const data = {
                            ...this.dataForm,
                        }
                        this.$http.post('api/system/web/rotateImg/manageRotateImg', data)
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.visible = false
                                this.$emit('updateList')
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                    }
                });
                this.loading = false;
            },

			closeDialog() {
				this.visible = false
                this.$emit('closeAction')
			},
		},
	}
</script>
<style lang='scss' scoped>
	.searchBox {
		/deep/.el-form-item__label {
			color: #00f6ff !important;
			font-size: 14px;
		}

		.searchBtn {
			margin-left: 15px;
		}
	}
    .wid90{
        width: 90%;
    }
</style>
