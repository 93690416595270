<template>
  <el-dialog title="详情" v-if="detailVisible" :visible.sync="detailVisible" :close-on-click-modal="false" @close="back"
    width="1400px">
    <div style="height: 70vh;">
      <div style="height: 60vh;overflow-y: scroll;" class="scrollElement">
        <div class="print" @click="goStampPage">打印</div>
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label">检查人</template>
            {{forms.userName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">提交时间</template>
            {{forms.createTime}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">所属单位</template>
            {{forms.orgName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">类型</template>
            {{forms.type== 0? '日管控':(forms.type == 1?'周排查' :'月调度')}}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="1" border v-if="tick == 1">
          <el-descriptions-item>
            <template slot="label">本月巡查主要问题</template>
            {{forms.thisMonthTrouble}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">本月整改方案落实情况</template>
            {{forms.thisMonthRemediationAction}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">本月还未解决的问题</template>
            {{forms.thisMonthUnresolvedTrouble}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">月调度相关内容</template>
            <div>
              <ul style=" list-style-type:disc;">
                <li v-for="(item,index) in data" :key="index" class="liInfo">
                  <div>
                    <div>
                      （{{item.checkItem}}）安全风险可控，无较大（{{item.checkContent}}）安全风险隐患（{{item.checkResult == 0 ? '异常' : '正常'}}）
                    </div>
                    <div>处置结果：{{item.dealwithResult}}</div>
                    <div>备注：{{item.comment ? item.comment :'无'}}</div>
                  </div>
                </li>
              </ul>
            </div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">其他使用安全事项</template>
            {{forms.otherUseSafetyMatters}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">会议研究采取的措施</template>
            {{forms.meetingSummarize}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">备注</template>
            {{forms.comments}}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions :column="1" border v-else>
          <el-descriptions-item>
            <template slot="label">上周使用安全风险隐患问题整改核实情况</template>
            {{forms.lastWeekSafeRectify}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">本周主要使用安全风险隐患和整改情况</template>
            {{forms.thisWeekSafeRectify}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">本周使用安全管理情况评价</template>
            <div>
              <ul style=" list-style-type:disc;">
                <li v-for="(item,index) in data" :key="index" class="liInfo">
                  <div>
                    <div>
                      <span v-if="item.checkResult == 1">
                      （{{item.checkItem}}）安全风险可控，无较大（{{item.checkContent}}）安全风险隐患（正常）
                      </span>
                      <span v-else>
                      （{{item.checkItem}}）存在安全风险，（{{item.checkContent}}）存在安全风险隐患（异常）
                      </span>
                    </div>
                    <div>处置结果：{{item.dealwithResult?item.dealwithResult:'无'}}</div>
                    <div>备注：{{item.comment ? item.comment :'无'}}</div>
                  </div>
                </li>
              </ul>
            </div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">下周工作重点</template>
            {{forms.nextWeekWork}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">使用安全管理员意见</template>
            <div v-if="forms.safetyOfficerSuggestion">
              <div v-for="(item,index) in JSON.parse(forms.safetyOfficerSuggestion)" :key="index"
                style="margin: 10px 0px;">
                <div>{{item.opinion}}</div>
                <div style="display: flex;justify-content: space-between;">
                  <div>{{item.userName}}</div>
                  <div>{{item.time}}</div>
                </div>
              </div>
            </div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">使用安全总监意见</template>
            <div v-if="forms.safetyGeneralSuggestion">
              <div v-for="(item,index) in JSON.parse(forms.safetyGeneralSuggestion)" :key="index"
                style="margin: 10px 0px;">
                <div>{{item.opinion}}</div>
                <div style="display: flex;justify-content: space-between;">
                  <div>{{item.userName}}</div>
                  <div>{{item.time}}</div>
                </div>
              </div>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    props: ['forms', 'tick'],
    data() {
      return {
        detailVisible: true,
        data: []
      }
    },
    mounted() {
      this.data = this.forms.riskManageItems ? JSON.parse(this.forms.riskManageItems) : []
    },
    methods: {
      goStampPage() {
        if (this.tick == 1) {
          window.open('#/YddPage/' + this.forms.id + "," + '1', '_blank')
        } else {
          window.open('#/YddPage/' + this.forms.id + "," + '2', '_blank')
          // window.open('#/YddPage/' + this.Id, '_blank')
        }
      },
      back() {
        this.detailVisible = false
        this.$emit("close")
      },
    }
  }
</script>

<style lang="scss" scoped>
  .print {
    float: right;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .liInfo {
    list-style: disc;
    text-align: left;
    padding: 0px 10px;
    margin: 10px 0px;
  }
</style>
