<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">人员名称</span>
          </span>
          <el-input placeholder="请输入" v-model="formInline.userName"></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">日期</span>
          </span>
          <el-date-picker v-model="dateTime" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">单位名称</span>
          </span>
         <el-input placeholder="请输入" v-model="formInline.orgName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <template v-if="dictionarTotal != 0">
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" style="width: 100%;margin: 20px 0;"
          :height="$store.state.tabHeight">
          <el-table-column type="selection" width="55px" />
          <el-table-column type="index" label="序号" width="55px" />
          <el-table-column align='center' prop="userName" label="人员名称" />
          <el-table-column align='center' prop="type" label="类型">
            <template slot-scope="scope">{{scope.row.type == 0? '日管控':(scope.row.type == 1?'周排查' :'月调度')}}</template>
              </el-table-column>
            <el-table-column align='center' prop="typeName" label="职位" />
            <el-table-column align='center' prop="orgName" label="单位名称" />
            <el-table-column align='center' prop="createTime" label="提交时间" />
            <el-table-column fixed="right" align='center' prop="address" label="操作" width="240px">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="Info(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
        </el-table>
        <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
          @pagination="contractPage" />
      </template>
      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>
    <YddStock v-if="visiable" :forms="forms" @close="back()" :tick="2"/>
  </div>
</template>

<script>
  import Pagination from '../../../components/Pagination'
  import YddStock from '../ydd/components/Yddstock.vue'
  import {
    formatDate
  } from "@/util";
  export default {
    components: {
      Pagination,
      YddStock
    },
    data() {
      return {
        visiable: false,
        forms: {},
        dictionarTotal: 0,
        tableData: [], //多选的数据
        formInline: {
          current: 1,
          size: 10,
          userName:''
        },
        dateTime: [],
        tableLoading: true
        // adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      }
    },
    methods: {
      Info(id) {
        this.$http.get(`/api/base/web/baseWeekCheck/getWeekCheckInfo?id=${id}`).then((res) => {
          this.visiable = true
          this.forms = res.data.data
        })
      },
      back() {
        this.visiable = false
      },
      //获取字典数据
      getDictionar(code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res;
              this.sosType = data.POSITION;
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      onRefer(flag) {
        if (flag) {
          this.formInline.current = 1
        }
       let time = this.dateTime != null? formatDate(new Date(this.dateTime[1]), 'yyyy-MM-dd 23:59:59'):''
       const time1 = this.dateTime != null? new Date(this.dateTime[0]).getTime():''
       const time2 = this.dateTime != null? new Date(time).getTime():''
        const data = {
          ...this.formInline,
          startTime: time1 ,
          endTime: time2 ,
        }
        this.tableLoading = true
        this.$http.post('/api/base/web/baseWeekCheck/getWebWeekCheckList', data).then((res) => {
          if(res.data.code == 200){
            res.data.data.records.forEach((qs) => {
              qs.typeName = this.getTName(this.sosType, qs.positionName.split(','))
            })
            this.tableData = res.data.data.records
            this.dictionarTotal = res.data.data.total
          }
          this.tableLoading = false
        })
      },
      getTName(typeDate, obj) {
        let tn = []
        typeDate.forEach(qs => {
          obj.forEach(qs2 => {
            if (qs.value == obj) {
              tn.push(qs.label)
              return
            }
          })
        })
        return tn.join(',')
      },
      contractPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      reset() {
        this.formInline = {
          current: 1,
          size: 10,
          userName: '',
          startTime: '',
          endTime: ''
        }
        this.dateTime=[]
        this.onRefer()
      },
    },
    mounted() {
      this.getDictionar('POSITION');
      this.$nextTick(() => {
        this.onRefer()
      })
    },
    created() {
      // this.getDictionar('POSITION');
      // this.$nextTick(() => {
      //   this.onRefer()
      // })
    },
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
