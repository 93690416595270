<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">品牌名称</span>
          </span>
          <el-input v-model="formInline.brand " placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div style="display: flex;">
        <el-button v-focus type="success" @click="addDj" size="small">新增</el-button>
      </div>
    </div>
    <div v-if="dictionarTotal!=0">
      <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" style="width: 100%;margin-bottom: 20px;"
        :height="$store.state.tabHeight">
        <el-table-column type="index" label="序号" width="50px" />
        <el-table-column align='center' prop="brand" label="品牌名称" />
        <el-table-column align='center' prop="orgNum" label="物业单位数量" >
          <template slot-scope="scope">
            {{scope.row.orgNum ? scope.row.orgNum : '0' }}
          </template>
        </el-table-column>
        <el-table-column align='center' prop="plotNum" label="小区数量" >
          <template slot-scope="scope">
            {{scope.row.plotNum ? scope.row.plotNum : '0' }}
          </template>
        </el-table-column>
        <el-table-column align='center' label="品牌说明" width="350px">
          <template slot-scope="scope">
            <span :title="scope.row.memo" class="memoShow">
              {{scope.row.memo}}
            </span>
          </template>
        </el-table-column>
        <el-table-column align='center' prop="createUserName" label="创建人" v-if="adminInfo.userName == 'admin'"/>
        <el-table-column align='center' prop="updateTime" label="更新时间" />
        </el-table-column>
        <el-table-column fixed="right" align='center' prop="address" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button @click="wyInfo(scope.row)" type="primary" size="small">物业明细</el-button>
            <el-button @click="xqInfo(scope.row)" type="primary" size="small">小区明细</el-button>
            <el-button v-focus @click="upd(scope.row)"
              v-if="adminInfo.id == scope.row.createUser || adminInfo.userName=='admin'" type="primary"
              size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>
    <el-dialog v-if='dialogVisible' :close-on-click-modal='false' :title="tit" :visible.sync="dialogVisible"
      width="650px" top='20vh'>
      <div style="height: 30vh;overflow-y: scroll;" class="scrollElement">
        <el-form :rules="rules" ref="ruleForm" label-position="right" label-width="150px" :model="fign">
          <el-row style="margin:10px 0">
            <el-col :span="24">
              <el-form-item label="物业单位品牌名称:" prop="brand">
                <el-input v-model="fign.brand" placeholder="请输入品牌名称" class="einp">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row style="margin:10px 0">
            <el-col :span="24">
              <el-form-item label="品牌唯一识别码:" prop="uniqueCode">
                <el-input v-model="fign.uniqueCode" placeholder="请输入" class="einp">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row style="margin:10px 0">
            <el-col :span="24">
              <el-form-item label="物业单位品牌说明:" prop="memo">
                <el-input class="einp" type="textarea" :rows="5" resize='none' placeholder="请输入品牌说明" v-model="fign.memo">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="dioSub">确 定</el-button>
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <Infos v-if="visible" @closeDialog="close" :Tit="Title" :propForm="visibleInfo" />

  </div>
</template>
<script>
  var validateName = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9）]{0,20}$/
    // true：必填  false：非必填
    inpYz(rule, value, callback, true, operation, '不可输入特殊符号，长度不可超过20位')
  };
  var validateCode = (rule, value, callback) => {
    let operation = /^[a-zA-Z0-9]{0,6}$/
    // true：必填  false：非必填
    inpYz(rule, value, callback, true, operation, '不支持输入字母数字以外的内容，长度不可超过6位')
  };

  import Pagination from '@/components/Pagination'
  import Infos from './components/Info.vue'


  export default {
    components: {
      Pagination,
      Infos
    },
    data() {
      let _this = this;
      return {
        formInline: {
          current: 1,
          size: 10,
        },
        visible: false,
        visibleInfo: '',
        tableData: [],
        // 分页参数
        dictionarTotal: 0,
        // dialog参数
        tit: '',
        Title: '',
        dialogVisible: false,
        fign: {},
        // 表单限制
        rules: {
          brand: [{
            required: true,
            validator: validateName,
            trigger: 'blur'
          }],
          uniqueCode: [{
            required: true,
            validator: validateCode,
            trigger: 'blur'
          }],
          memo: [{
            required: true,
            max: 200,
            message: '品牌说明长度不可超过200位',
            trigger: 'blur'
          }],
        },
        tableLoading: true,
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      }
    },
    methods: {
      close() {
        this.visible = false
      },
      wyInfo(data) { //物业明细
        this.visible = true
        this.visibleInfo = data
        this.Title = "物业单位明细"
      },
      xqInfo(data) { //小区明细
        this.visible = true
        this.visibleInfo = data
        this.Title = "小区明细"
      },

      // 重置按钮事件
      reset() {

        this.formInline = {
          current: 1,
          size: 10,
        }
        this.onRefer()
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      // 弹出框提交
      dioSub() {
        console.log('this.fign', this.fign)

        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let data = JSON.parse(JSON.stringify(this.fign))
            let url = this.tit == '新增' ? '/api/system/web/brand/submitPropertyBrand' :
              '/api/system/web/brand/updatePropertyBrand'

            console.log('data', data)
            this.$http
              .post(url, data)
              .then((res) => {
                if (res.data.success) {
                  this.onRefer()
                  this.dialogVisible = false
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
              })
          } else {
            this.$message.warning('请验证填写内容!');
            return false;
          }
        })
      },

      // 表格方法
      onRefer(ishm) {
        if (ishm) {
          this.formInline.current = 1
        }
        this.tableLoading = true
        console.log('this.formInline', this.formInline)
        this.$http
          .post("/api/system/web/brand/getBrandPage", this.formInline)
          .then((res) => {

            if (res.data.success) {
              this.tableData = res.data.data.records
              this.dictionarTotal = res.data.data.total
              this.$nextTick(() => {
                if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
            this.tableLoading = false
          })
      },

      // 修改
      upd(item) {
        this.tit = '编辑'
        this.dialogVisible = true
        this.fign = JSON.parse(JSON.stringify(item))
      },

      addDj() {
        this.tit = '新增'
        this.fign = {}
        this.dialogVisible = true
      },
    },
    created() {
      this.onRefer()
    }
  }
</script>
<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }

  .memoShow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
</style>
