<template>
  <el-dialog title="暂停困人报警" :visible.sync="visible" append-to-body :close-on-click-modal="false" width="1400px"
    @close="DialogClose">
    <div style="height: 70vh;">
      <el-form inline :model="searchForm">
        <el-form-item label="状态">
          <el-select placeholder="选择状态" v-model="searchForm.status">
            <el-option key="1" :value="0" label="生效中"></el-option>
            <el-option key="2" :value="1" label="已失效"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="onRefer()">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="success" size="small" @click="add()">新增</el-button>
      </div>
      <!-- v-if="ListTotal > 0" -->
      <div>
        <el-table ref="multipleTable" :data="tableData" :height="$store.state.tabHeight-200" v-loading="loading"
          style="margin: 20px 0px;">
          <el-table-column align="center" type="index" label="序号" min-width="55px" />
          <el-table-column align="center" prop="name" label="电梯名称" min-width="150px" />
          <el-table-column align="center" label="状态" min-width="120px">
            <template slot-scope="scope">
              {{scope.row.status == 0?'生效中' : '已失效'}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="startTime" label="暂停开始时间" min-width="120px" />
          <el-table-column align="center" label="暂停时长" min-width="120px">
            <template slot-scope="scope">
              {{hoursBetween(scope.row.startTime,scope.row.endTime)}}
              <!-- {{ new Date(scope.row.endTime).getHours() - new Date(scope.row.startTime).getHours() }} -->
            </template>
          </el-table-column>
          <el-table-column align="center" prop="endTime" label="暂停结束时间" min-width="150px" />
          <el-table-column align="center" prop="createTime" label="创建时间" min-width="120px" />
          <el-table-column align="center" prop="createUserName" label="创建人" min-width="120px" />
        </el-table>
        <Pagination :total="ListTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="searchFormChange" />
      </div>
      <!-- <div class="nullDate" v-else v-loading="loading">
        <img v-if="!loading" src="@/assets/nullDate.png" style="width: 230px;" />
      </div> -->
    </div>
    <el-dialog v-if="addVisiable" title="新增暂停困人报警" :visible.sync="addVisiable" append-to-body
      :close-on-click-modal="false" width="690px" style="padding: 20px;" @close='addClose'>
      <div style="height: 23vh;margin-top: 6vh;">
        <el-form :model="Formdata" label-width="150px">
          <el-form-item label="电梯名称:">
            <el-input disabled v-model="Formdata.name" style="width: 93%;"></el-input>
          </el-form-item>
          <el-form-item label="请选择暂停时长:">
            <el-select v-model="Formdata.time" style="width: 93%;" @change="changTime">
              <el-option :key="0" :value="1" label="暂停困人报警1小时"></el-option>
              <el-option :key="1" :value="2" label="暂停困人报警2小时"></el-option>
              <el-option :key="2" :value="4" label="暂停困人报警4小时"></el-option>
              <el-option :key="3" :value="8" label="暂停困人报警8小时"></el-option>
              <el-option :key="4" :value="12" label="暂停困人报警12小时"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addSure(Formdata.time)">确定</el-button>
        <el-button @click="addClose">取消</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
  import Pagination from '../../../../components/Pagination'
  export default {
    props: ['propForm'],
    components: {
      Pagination,
    },
    data() {
      return {
        visible: true,
        ListTotal: 0,
        tableData: [],
        selectElevatorCallback: null,
        searchForm: {
          size: 10,
          current: 1
        },
        Formdata: {},
        loading: false,
        addVisiable: false
      }
    },
    mounted() {
      this.onRefer()
      this.Formdata.name = this.propForm.name
      this.Formdata.time = 1
    },
    methods: {
      reset() {
        this.searchForm = {
          size: 10,
          current: 1
        }
        this.onRefer()
      },
      onRefer() {
        const data = {
          eleId: this.propForm.id,
          ...this.searchForm
        }
        this.$http.post('/api/ele/web/sosStopPlan/planList', data).then((res) => {
          if (res.data.code == 200) {
            res.data.data.records.forEach((item, index) => {
              res.data.data.records[index].name = this.propForm.name
            })
            this.tableData = res.data.data.records ? res.data.data.records : []
            this.ListTotal = res.data.data.total ? res.data.data.total : 0
            this.$nextTick(() => {
              if (this.ListTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
          this.loading = false
        })
      },
      searchFormChange(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer()
      },
      add() { //新增
        const info = JSON.parse(this.propForm.deviceSosSwitch)
        if (info.stuckSwitch == 1) {
          this.addVisiable = true
        } else {
          this.$message.warning('困人报警未开启，无需暂停')
        }
      },
      hoursBetween(date1, date2) {
        const oneDay = 24; // 每天小时数
        const time1 = new Date(date1).getTime()
        const time2 = new Date(date2).getTime()
        const diff = Math.abs(time2 - time1); // 计算两个日期差值的绝对值
        return diff / (1000 * 60 * 60); // 将毫秒转换为小时
      },
      changTime(data){
        this.$forceUpdate()
      },
      addSure(type) {
        let time = new Date()
        let end = ''
        if (type == 1) {
          end = time.setHours(time.getHours() + type)
        } else if (type == 2) {
          end = time.setHours(time.getHours() + type)
        } else if (type == 4) {
          end = time.setHours(time.getHours() + type)
        } else if (type == 8) {
          end = time.setHours(time.getHours() + type)
        } else {
          end = time.setHours(time.getHours() + type)
        }
        const data = {
          eleId: this.propForm.id,
          startTime: new Date().getTime(),
          endTime: end
        }
        this.$http.post('/api/ele/web/sosStopPlan/savaPlan', data).then((res) => {
          if (res.data.code == 200) {
            this.addVisiable = false
            this.$message.success(res.data.msg)
            this.Formdata.time = 1
            this.onRefer()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      addClose() {
        this.addVisiable = false
        this.Formdata.time = 1
      },
      DialogClose() {
        this.visible = false
        this.$emit('DialogClose')
      }
    }
  }
</script>

<style scoped>
  .nullDate {
    width: 100%;
    height: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
