<template>
  <el-dialog :title="'修改维保计划'" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="70%" center
    @close="addPlanDialogClose">
    <div style="height: 57vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
      <el-row>
        <el-col :span="12">
          <el-form class="addPlanForm" ref="addPlanForm" :model="addPlanForm" :rules="addPlanRules" label-width="150px"
            label-position="right">
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <el-form-item label="维保计划名称:" prop="planName">
                    <el-input v-model.trim="addPlanForm.planName" type="text" />
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <el-form-item label="电梯:" prop="elevator_name">
                    <el-input :placeholder="addPlanForm.elevator_name || '暂无内容'" v-model="addPlanForm.elevator_name"
                      class="input-with-select" disabled>
                      <el-button disabled slot="append" type="primary" @click="selectElevator()">选择电梯</el-button>
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>

              <!-- 维保时间间隔 -->
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <el-form-item label="维保项:" prop="maintainItemId">
                    <el-select v-model="addPlanForm.maintainItemId" style="width: 100%" filterable placeholder="请选择维保项">
                      <el-option v-for="item in installUnitList" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>

              <!-- 维保时间间隔 -->
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <el-form-item label="维保时间间隔:" prop="timeInterval">
                    <el-input placeholder="请输入内容" v-model="addPlanForm.timeInterval" class="input-with-select"
                      @change="changeTimeDate">
                      <el-select v-model="selectTimeType" slot="prepend" placeholder="请选择" @change="changeTimeType">
                        <el-option label="间隔（天）" value="dayTime"></el-option>
                        <el-option label="每月（日）" value="monthTime"></el-option>
                      </el-select>
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <el-form-item label="起止时间:" prop="end_dats_arr" class="datepicker">
                    <el-date-picker v-model="addPlanForm.startTime" disabled type="date" placeholder="选择日期"
                      format="yyyy 年 MM 月 dd 日" value-format="timestamp" style="width: 45%" />
                    至
                    <el-date-picker v-model="addPlanForm.endTime" disabled type="date" placeholder="选择日期"
                      format="yyyy 年 MM 月 dd 日" value-format="timestamp" style="width: 45%" />
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <div style="text-align: center;">
              <el-button type="primary" @click="addPlanConfirm">确定</el-button>
              <el-button type="info" @click="addPlanDialogClose">取消</el-button>
              <el-button type="warning" @click="reset">重置</el-button>
            </div>
          </el-form>
        </el-col>
        <!-- 日历 -->
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <FullCalendar class="calendar" ref="fullCalendar" style="height: 100%" :options="calendarOptions" />
            <!-- </el-form-item> -->
          </div>
        </el-col>
      </el-row>
      <!-- 选择电梯 -->
      <SelectElevator v-if="selectElevatorVisible" :elevatorId="elevatorId" :initArr="initArr"
        ref="SelectElevatorDialog" @transferElevator="selectElevatorResults" />
    </div>
    <div slot="footer" class="dialog-footer">
    </div>
  </el-dialog>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid' // 日期
  import interactionPlugin from '@fullcalendar/interaction' // 日期点击事件

  import SelectElevator from './selectElevator'

  export default {
    components: {
      FullCalendar,
      SelectElevator,
    },
    props: {
      planInfo: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },

    data() {
      return {
        year: '', //年份
        month: '', //月份
        installUnitList: [], // 维保项数据
        StagingList: [], // 维保项全部数据
        selectTimeType: 'dayTime', // 间隔类型
        loading: true,
        visible: false,
        calendarApi: null,
        selectElevatorVisible: false,
        elevatorId: [], // 选中电梯id
        initArr: [], // 选中电梯

        // 表格参数
        addPlanForm: {
          planName: '', // 计划名称
          eleIds: '', // 电梯id
          elevator_name: '', // 电梯名称
          startTime: '', // 开始时间
          endTime: '', // 结束时间
          timeInterval: '',
        },
        saveInfo: {}, // 缓存信息
        addPlanRules: {
          planName: [{
            required: true,
            message: '可输入中文，大小写字母，特殊符号且长度为4至20',
            min: 4,
            max: 20,
            trigger: 'blur',
          }, ],
          elevator_name: [{
            required: true,
            message: '电梯为必选项',
            trigger: 'blur',
          }, ],
          time_interval: [{
            required: true,
            message: '维保起始时间为必选项',
            trigger: 'blur',
          }, ],
          maintainItemId: [{
            required: true,
            message: '维保类型为必选项',
            trigger: 'blur',
          }, ],
          timeInterval: [{
            required: true,
            validator: this.checkNum,
            trigger: 'blur',
          }, ],
        },
        calendarEvents: [],
        // eventName: '', // 事件名称
        isEditEvents: false, // 修改日历事件
        first_date: null, // 开始时间
        last_date: null, // 结束时间
        start_date: null, // 选中时间的开始时间
        end_date: null, // 选中时间的结束时间
        lastInfo: null, // 结束时间信息
        // 日历参数
        calendarOptions: {
          height: 400,
          plugins: [dayGridPlugin, interactionPlugin],
          editable: false,
          selectable: true,
          navLinks: false,
          handleWindowResize: true, // 是否随窗口大小变化
          initialView: 'dayGridMonth', // 设置默认显示月，可选周、日
          eventClick: this.handleEventClick,
          select: this.handleDateSelect,
          eventColor: '#f00',
          timeZone: 'local',
          // 设置日程
          events: this.getCalendarEvents,
          locale: 'zh', // 设置语言
          headerToolbar: {
            left: 'prevYear,prev,today',
            center: 'title',
            right: 'next,nextYear',
          },
          customButtons: {
            prevYear: {
              text: 'prevYear',
              click: () => {
                this.prevYear()
              },
            },
            prev: {
              text: 'prev',
              click: () => {
                this.prev()
              },
            },
            nextYear: {
              text: 'nextYear',
              click: () => {
                this.nextYear()
              },
            },
            next: {
              text: 'next',
              click: () => {
                this.next()
              },
            },
            today: {
              text: 'today',
              click: () => {
                this.todayClick()
              },
            },
          },
        },
      }
    },

    created() {},
    mounted() {
      this.$nextTick(() => {
        this.year = new Date()
        this.month = new Date()
      })
    },

    methods: {
      //重置
      reset() {
        this.$refs.addPlanForm.resetFields()
        this.isEditEvents = false
        this.addPlanForm = this.planInfo
        this.addPlanForm.elevator_name = this.planInfo.eleName
        this.addPlanForm.startTime = Date.parse(this.saveInfo.startTime)
        this.addPlanForm.endTime = Date.parse(this.saveInfo.endTime)
        this.first_date = this.planInfo.startTime
        this.last_date = this.planInfo.endTime
        this.initArr = []
        this.$set(
          this.addPlanForm,
          'timeInterval',
          this.saveInfo.timeAfter ? JSON.parse(this.saveInfo.timeAfter).time : ''
        )
        this.selectTimeType = this.planInfo.timeAfter ? JSON.parse(this.planInfo.timeAfter).type : 'dayTime'
        this.calendarEvents = []
        this.planInfo.taskList.map((item) => {
          // if (item.status === 0) {
          this.calendarEvents.push({
            title: '维保项',
            start: new Date(Date.parse(item.startTime)),
            allDay: true,
            choose: false,
          })
          // }
        })
        this.$refs.fullCalendar.getApi().refetchEvents()
      },

      init(callback) {
        this.addPlanCallback = callback
        this.visible = true
        this.loading = false
        this.saveInfo = JSON.parse(JSON.stringify(this.planInfo))
        this.addPlanForm = this.planInfo
        this.addPlanForm.elevator_name = this.planInfo.eleName
        this.addPlanForm.startTime = Date.parse(this.planInfo.startTime)
        this.addPlanForm.endTime = Date.parse(this.planInfo.endTime)
        this.first_date = this.planInfo.startTime
        this.last_date = this.planInfo.endTime
        // this.addPlanForm.timeInterval = JSON.parse(this.planInfo.timeAfter).time
        this.$set(
          this.addPlanForm,
          'timeInterval',
          this.planInfo.timeAfter ? JSON.parse(this.planInfo.timeAfter).time : ''
        )
        this.selectTimeType = this.planInfo.timeAfter ? JSON.parse(this.planInfo.timeAfter).type : 'dayTime'
        this.planInfo.taskList.map((item) => {
          // if (item.status === 0) {
          this.calendarEvents.push({
            title: '维保项',
            start: new Date(Date.parse(item.startTime)),
            allDay: true,
            choose: false,
          })
          // }
        })
        // this.addPlanForm.elevator_name = this.planInfo
        this.$nextTick(() => {
          this.getEvent()
        })
      },
      getEvent() {
        const data = {
          id: this.planInfo.id,
        }
        this.$http
          .post('/api/ele/web/maintainPlan/getPlanInfo', data)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.getList(data.maintainItemId)
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      // 获取维保项类目
      getList(id) {
        const data = {
          current: 1,
          size: 9999,
          id,
        }
        this.$http.post('/api/ele/web/maintainItem/getList', data).then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res
            this.installUnitList = data.records ?? []
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 回到今天
      todayClick() {
        this.$refs.fullCalendar.getApi().today()
      },
      // 前一年
      prevYear(event) {
        this.$refs.fullCalendar.getApi().prevYear()
      },
      // 前一个月
      prev(event) {
        this.$refs.fullCalendar.getApi().prev()
      },
      // 下一个月
      next(event) {
        this.$refs.fullCalendar.getApi().next()
      },
      // 下一年
      nextYear(event) {
        this.$refs.fullCalendar.getApi().nextYear()
      },

      // 初始化日历事件
      getCalendarEvents(info, successCallback, failureCallback) {
        const events = [...this.calendarEvents]
        successCallback(events)
      },

      // 选择电梯
      selectElevator() {
        this.selectElevatorVisible = true
        this.$nextTick(() => {
          this.$refs.SelectElevatorDialog.init((refresh) => {})
        })
      },

      // 选择电梯回调
      selectElevatorResults(data) {
        const nameArr = []
        const idArr = []
        data.map((item) => {
          nameArr.push(item.name)
          idArr.push(item.id)
        })
        // this.selectData = data
        this.selectElevatorVisible = false
        this.$set(this.addPlanForm, 'elevator_name', nameArr.join())
        this.initArr = data
        this.elevatorId = idArr
        this.addPlanForm.eleIds = idArr
      },
      // 保存计划
      addPlanConfirm() {
        this.loading = true
        this.$refs.addPlanForm.validate(async (valid) => {
          if (valid) {
            const data = JSON.parse(JSON.stringify(this.addPlanForm))
            const taskTimes = []
            this.calendarEvents.map((item) => {
              taskTimes.push(item.start.getTime())
            })
            if (!this.isEditEvents) {
              data.taskTimes = null
            } else {
              data.taskTimes = taskTimes
            }
            data.timeAfter = JSON.stringify({
              type: this.selectTimeType,
              time: this.addPlanForm.timeInterval,
            })
            this.$http
              .post('/api/ele/web/maintainPlan/edit', data)
              .then((res) => {
                if (res.data.success) {
                  this.reset()
                  this.visible = false
                  this.$emit('closeDialog')
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
              })
          }
        })
        this.loading = false
      },

      // 选择维保类型弹窗
      handleDateSelect(data) {
        if (!this.addPlanForm.maintainItemId) {
          this.$message({
            type: 'error',
            message: '请先选择维保项',
          })
          return
        }
        if (!this.addPlanForm.timeInterval) {
          this.$message({
            type: 'error',
            message: '请先选择填写间隔日期',
          })
          return
        }
        if (new Date(new Date().toLocaleDateString()).getTime() >= new Date(`${data.startStr}`).getTime() - 8 * 3600 *
          1000) {
          this.$message({
            type: 'error',
            message: '维保开始时间必须是明天！请重新选择。',
          })
        } else {
          this.isEditEvents = true
          // this.eventName = this.installUnitList.find(
          //   (item) => item.id === this.addPlanForm.maintainItemId
          // ).name
          this.first_date =
            this.first_date && this.first_date < data.start.getTime() ?
            this.first_date :
            data.start.getTime()
          this.last_date =
            this.last_date && this.last_date > data.end.getTime() ?
            this.last_date :
            data.end.getTime() - 1000
          this.start_date = data.start.getTime()
          this.end_date = data.end.getTime()
          this.lastInfo =
            this.last_date && this.last_date > data.end.getTime() ?
            this.lastInfo :
            data

          this.$set(this.addPlanForm, 'startTime', this.first_date)
          this.$set(this.addPlanForm, 'endTime', this.last_date)
          // 只在一开始自动填充
          if (
            this.calendarEvents.length < 3 ||
            data.end.getTime() - 1000 === this.last_date
          ) {
            if (this.selectTimeType === 'monthTime') {
              this.pushMonthEvent()
            } else {
              this.pushDayEvent()
            }
          }
          // 不重复添加事件
          const eventsArr = this.calendarEvents.find(
            (item) => item.start.getTime() === data.start.getTime()
          )
          if (!eventsArr) {
            this.calendarEvents.push({
              title: '维保项',
              start: data.start,
              allDay: true,
              choose: true,
            })
          } else {
            if (
              data.start.getTime() + 24 * 3600 * 1000 - 1000 !== this.last_date &&
              data.start.getTime() !== this.first_date
            ) {
              this.calendarEvents = this.calendarEvents.filter(
                (item) => item.start.getTime() !== data.start.getTime()
              )
            }
          }
          this.$refs.fullCalendar.getApi().refetchEvents()
        }
      },

      // 日历添加间隔天事件
      pushDayEvent() {
        // 获取开始到结束时间又多少天
        const intervalDay = Math.ceil(
          (this.last_date - this.first_date) / 1000 / 3600 / 24
        )
        // 获取循环次数
        const loopNum = Math.floor(intervalDay / this.addPlanForm.timeInterval)
        let addLoopEventTime
        for (let index = 1; index <= loopNum; index++) {
          addLoopEventTime = new Date(
            this.first_date +
            this.addPlanForm.timeInterval * index * 3600 * 24 * 1000
          )
          const intervalDayArr = this.calendarEvents.find(
            (item) => item.start.getTime() === addLoopEventTime.getTime()
          )
          if (!intervalDayArr && addLoopEventTime.getTime() < this.last_date) {
            this.calendarEvents.push({
              title: '维保项',
              start: addLoopEventTime,
              allDay: true,
              choose: false,
            })
          }
        }
      },
      // 日历添加间隔月事件
      pushMonthEvent() {
        const date = new Date() // 当前时间
        let newData = '' // 下个月同一天时间
        let a = 1
        while (newData <= this.last_date) {
          // 把用户输入的数字转换成日期
          const sedate = new Date(date.setDate(this.addPlanForm.timeInterval))
          let month = sedate.getMonth() + a
          let year = sedate.getFullYear()
          // 每次循环把月份+1
          year =
            month > 12 ?
            year +
            (month % 12 === 0 ?
              parseInt(month / 12) - 1 :
              parseInt(month / 12)) :
            year
          a++
          month = month % 12 === 0 ? 12 : month % 12
          const time5 = year + '-' + month + '-' + sedate.getDate() + ' 00:00:00' // 每个月时间
          const time6 = Date.parse(time5) // 时间转时间戳

          const intervalDayArr = this.calendarEvents.find(
            (item) => item.start === time5
          )
          if (
            !intervalDayArr &&
            time6 < this.last_date &&
            time6 > this.first_date &&
            !this.calendarEvents.find(
              (item) => item.start.getTime() === new Date(time6).getTime()
            )
          ) {
            this.calendarEvents.push({
              title: '维保项',
              start: new Date(time6),
              allDay: true,
              choose: false,
            })
          }
          newData = time6
        }
        this.calendarEvents = this.calendarEvents.slice(1)
        // }
        // 开始时间小于结束时间
      },
      // 改变间隔变化
      changeTimeType() {
        this.calendarEvents = []
        this.$refs.fullCalendar.getApi().refetchEvents()
        this.addPlanForm.startTime = null
        this.addPlanForm.endTime = null
        this.first_date = null
        this.last_date = null
        // if (this.lastInfo) {
        //   if (this.calendarEvents.length > 0) {
        //     this.calendarEvents = this.calendarEvents.filter(
        //       (item) => item.choose
        //     )
        //   }

        //   this.handleDateSelect(this.lastInfo)
        // }
      },
      changeTimeDate() {
        if (this.addPlanForm.timeInterval > 0) {
          if (/(^[1-9]\d*$)/.test(this.addPlanForm.timeInterval)) {
            this.changeTimeType()
          } else {
            this.$message('时间间隔必须是整数')
          }
        } else {
          this.$message('时间间隔不能小于1')
        }
      },

      // 必须数字
      checkNum(rule, value, callback) {
        if (value) {
          if (+value || +value === 0) {
            callback()
          } else {
            callback(new Error('请输入数字值'))
          }
        } else {
          callback()
        }
      },

      checkNums(rule, value, callback) {
        if (value) {
          if (+value || +value === 0) {
            if (value > 31 || value == 0) {
              callback(new Error('请输入（1-31）的整数'))
            } else {
              if (value % 1 == 0) {
                callback()
              } else {
                callback(new Error('请输入（1-31）的整数'))
              }
            }
          } else {
            callback(new Error('请输入数字值'))
          }
        } else {
          callback()
        }
      },

      addPlanDialogClose() {
        this.reset()
        this.visible = false
        this.$emit('closeDialog')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .addPlanForm {
    padding-right: 35px;
  }

  /deep/.el-form-item {
    margin-bottom: 32px;
  }

  /deep/.el-input-group__prepend {
    width: 40%;
  }

  >>>.el-input-group__append {
    background: #409eff;
    color: #fff;
  }

  >>>.el-input-group__prepend {
    width: 25%;
    padding: 0px;
    background: #409eff;

    .el-select {
      margin: -1px;

      .el-input__inner {
        color: #fff !important;
      }

      ::-webkit-input-placeholder {
        color: #fff;
      }
    }
  }

  .datepicker {
    >>>.el-form-item__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
</style>
