<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="formInline.eleName" placeholder="请输入电梯名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">维保单位</span>
          </span>
          <el-input v-model="formInline.maintainUnitName" placeholder="请输入维保单位" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">物业单位</span>
          </span>
          <el-input v-model="formInline.propertyUnitName" placeholder="请输入物业单位" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯注册代码</span>
          </span>
          <el-input v-model="formInline.eleCode" placeholder="请输入电梯注册代码" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">计划时间</span>
          </span>
          <el-date-picker style="width: 220px;" v-model="formInline.allTimeStart" type="daterange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item>
          <span slot="label">
            <span class="lab-span">完成时间</span>
          </span>
          <el-date-picker style="width: 220px;" v-model="formInline.allTimeFinish" type="daterange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <span slot="label">
            <span class="lab-span">订单编号</span>
          </span>
          <el-input placeholder="请输入订单编号" clearable v-model="formInline.id"></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">订单状态</span>
          </span>
          <el-select v-model="formInline.orderStatus" placeholder="请输入订单状态" clearable>
            <el-option v-for="(item,index) in orderList" :key="index" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">合同编号</span>
          </span>
          <el-input placeholder="请输入合同编号" clearable v-model="formInline.maintainContractCode"></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">所属小区</span>
          </span>
          <el-input v-model="formInline.plotName" placeholder="请输入所属小区" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <span slot="label">
            <span class="lab-span">维保状态</span>
          </span>
          <el-select placeholder="请选择维保状态" clearable v-model="formInline.status">
            <el-option v-for="(item,index) in statusList" :key="index" :value="item.value"
              :label="item.label"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <span slot="label">
            <span class="lab-span">维保周期</span>
          </span>
          <el-select v-model="formInline.maintainType" placeholder="请选择维保周期" class="einp" clearable>
            <el-option v-for="item in wbType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="dictionarTotal!=0">
      <el-table :data="tableData" v-loading="tableLoading" ref="multipleTable" style="width: 100%;margin-bottom: 20px;"
        :height="$store.state.tabHeight">
        <el-table-column type="index" label="序号" width="50px" />
        <el-table-column align='center' prop="id" label="订单编号" min-width="150px" />
        <el-table-column align='center' prop="maintainContractCode" label="合同编号" min-width="150px" />
        <el-table-column align='center' prop="orderStatus" label="订单状态" width="200px">
          <template slot-scope="scope">
            {{orderList.filter(item=>item.value == scope.row.orderStatus)[0].label}}
          </template>
        </el-table-column>
        <el-table-column align='center' prop="price" label="任务价格" width="200px" />
        <el-table-column align='center' prop="eleName" label="电梯名称" min-width="150px" />
        <el-table-column align='center' prop="startTime" label="计划时间" width="150px" />
        <!-- <el-table-column align='center' prop="signTime" label="签到时间" width="150px" /> -->
        <!-- <el-table-column align='center' prop="finishTime" label="完成时间" width="150px" /> -->
        <el-table-column align='center' label="所属小区" width="180px">
          <template slot-scope="scope">
            {{scope.row.plotName ? scope.row.plotName : '-'}}
          </template>
        </el-table-column>
        <el-table-column align='center' prop="maintainTypeName" label="计划类型" />
        <!-- <el-table-column align='center' prop="status" label="维保状态">
          <template slot-scope="scope">
            {{statusList.filter(item => item.value == scope.row.status)[0].label}}
          </template>
        </el-table-column> -->
        <el-table-column align='center' prop="eleCode" label="电梯注册代码" width="150px" />
        <el-table-column align='center' prop="eleAddress" label="电梯安装位置" min-width="220px" />
        <el-table-column align='center' prop="propertyUnitName" label="物业单位" width="150px" />
        <el-table-column align='center' prop="maintainUnitName" label="维保单位" width="150px" />
        <el-table-column align='center' prop="maintainUserNames" label="维保人员" width="200px" />
        <el-table-column align='center' prop="createTime" label="创建时间" width="200px" />
        <el-table-column align='center' prop="updateTime" label="更新时间" width="200px" />
        <el-table-column align='center' label="操作" fixed="right" width="300px">
          <template slot-scope="scope">
            <!-- <el-button v-focus @click="showDetails(scope.row)" type="primary" size="small">查看</el-button> -->
            <el-button @click="dindanDetails(scope.row)" v-if="scope.row.maintainContractId" type="primary"
              size="small">订单详情</el-button>
            <el-button @click="showDetails(scope.row)" type="primary" size="small">维保详情</el-button>
           <!-- <el-button v-focus v-if="scope.row.status == 10" @click="checkSure(scope.row)" type="primary"
              size="small">监管确认</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>

    <el-dialog v-if='dioDetails' :close-on-click-modal='false' :title="tit" :visible.sync="dioDetails" width="1200px"
      top='20vh'>
      <div style="height: 50vh;overflow-y: scroll;" class="scrollElement">
        <dioDetails :dioData="dioDetailsItem" :title="tit"></dioDetails>
      </div>
      <!-- <div v-if="tit == '维保监管确认'" style="margin-top: 20px;">
        <template>
          <el-radio v-model="radioValue" label="1">通过</el-radio>
          <el-radio v-model="radioValue" label="2">不通过</el-radio>
        </template>
        <el-input type="textarea" maxlength="250" v-model="textarea" show-word-limit placeholder="请填写不通过的原因,不超过250个字"
          style="margin-top: 15px;"></el-input>
      </div> -->
      <!-- <span slot="footer" class="dialog-footer" v-if="tit == '维保监管确认'">
        <el-button size="small" type="primary" @click="dioDetails = false">确认</el-button>
        <el-button size="small" type="info" @click="dioDetails = false">取消</el-button>
      </span> -->
      <span slot="footer" class="dialog-footer" v-if="tit != '维保监管确认'">
        <el-button size="small" type="primary" @click="dioDetails = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  var valifailureDesc = (rule, value, callback) => {
    let operation = /^.{4,600}$/
    inpYz(rule, value, callback, true, operation, '可输入中文，英文，数值，特殊符号，长度为4至600')
  };

  import Pagination from '@/components/Pagination'
  import dioDetails from '../wbjl/components/dioDetails'
  // import OrderDetails from './components/OrderDetails.vue'
  export default {
    components: {
      dioDetails,
      Pagination,
      // OrderDetails
    },
    data() {
      return {
        // 维修详情
        dioDetails: false,
        dioDetailsItem: null,
        // 表格参数
        formInline: {
          current: 1,
          size: 10,
          onlyOrder:1,
        },
        tableData: [],
        statusList: [{
          //   value: 0,
          //   label: '待处理'
          // }, {
          //   value: 1,
          //   label: '已签到'
          // }, {
          //   value: 2,
          //   label: '已维保（ 待确认）'
          // }, {
          //   value: 3,
          //   label: '已驳回'
          // }, {
            value: 4,
            label: '已完成'
          }, {
            value: 5,
            label: '超期后完成'
          },
          {
            value: 10,
            label: '待监管确认'
          }
        ],
        orderList: [{
          value: 0,
          label: '待付款'
        }, {
          value: 1,
          label: '待派单'
        }, {
          value: 2,
          label: '待接单'
        }, {
          value: 3,
          label: '已接单'
        }, {
          value: 4,
          label: '待维保'
        }, {
          value: 5,
          label: '已维保'
        }, {
          value: 6,
          label: '已结算'
        }, {
          value: 7,
          label: '已退款'
        }],
        // 分页参数
        dictionarTotal: 0,
        // 维保类型
        wbType: [],
        tit: '',
        radioValue: '1',
        textarea: '',
        tableLoading: true
      }
    },
    methods: {
      // 弹框事件
      //订单详情
      dindanDetails(data) {
        this.tit = '维保订单详情'
        this.dioDetailsItem = data
        this.dioDetails = true
      },
      //维保详情
      showDetails(data) {
        this.tit = '维保详情'
        this.dioDetailsItem = data
        this.dioDetails = true
      },
      // checkSure(data) {
      //   this.tit = '维保监管确认'
      //   this.dioDetailsItem = data
      //   this.dioDetails = true
      // },
      // 重置按钮事件
      reset() {
        this.formInline = {
          current: 1,
          size: 10,
          onlyOrder:1,
        }
        this.onRefer()
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      // 表格方法
      onRefer(ishm) {
        if (ishm) {
          this.formInline.current = 1
        }
        // 计划时间
        if (this.formInline.allTimeStart != null && this.formInline.allTimeStart != undefined) {
          let start = new Date(this.formInline.allTimeStart[0]).getTime()
          let end = new Date(this.formInline.allTimeStart[1]).getTime() + 24 * 60 * 60 * 1000 - 1000
          this.formInline.searchStartTime = [start, end]
        } else {
          this.formInline.searchStartTime = null
        }
        // 完成时间
        if (this.formInline.allTimeFinish != null && this.formInline.allTimeFinish != undefined) {
          let start = new Date(this.formInline.allTimeFinish[0]).getTime()
          let end = new Date(this.formInline.allTimeFinish[1]).getTime() + 24 * 60 * 60 * 1000 - 1000
          this.formInline.searchFinishTime = [start, end]
        } else {
          this.formInline.searchFinishTime = null
        }
        if (this.formInline.id && !/^[0-9]*$/.test(this.formInline.id)) {
          this.$message.error('订单编号仅支持数字！')
          return
        }
        this.tableLoading = true
        // this.formInline.status = 7
        this.$http
          .post("/api/ele/web/maintainTask/getList", this.formInline)
          .then((res) => {

            if (res.data.success) {
              res.data.data.records.forEach((qs) => {
                qs.statusName = this.getType(qs.status, 2)
              })
              this.tableData = res.data.data.records
              this.dictionarTotal = res.data.data.total
              this.$nextTick(() => {
                if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
            this.tableLoading = false
          })
      },
      // 获取类型
      getType(type, sta) {
        let obj
        this.wbType.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
          }
        })
        return obj
      }
    },
    created() {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=MAINTAIN_TYPE`)
        .then((res) => {
          if (res.data.success) {
            this.wbType = res.data.data.MAINTAIN_TYPE
            this.onRefer()
          }
        })
    }
  }
</script>

<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }

  .page-bom {}
</style>
