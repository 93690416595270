<template>
  <div>
    <div>
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="boxShow2" style="cursor: default;">
            <div class="boxcard">
              <h3>单位账户信息</h3>
              <div class="accountContent">
                <div>
                  <span>单位名称：</span>
                  <el-select v-model="oderselect" @change="changOder">
                    <el-option v-for="(item,index) in oderList" :key="index" :value="item.id"
                      :label="item.name"></el-option>
                  </el-select>
                </div>
                <div>银行卡号：{{amountList.bankName}}（{{propmt(amountList.bankNumber)}}）</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="boxShow2" style="cursor: default;">
            <div class="boxcard">
              <h3>账户余额</h3>
              <div class="amountContent">
                <div style="margin: 0px 20px;" class="formAmount">{{amountList.amount}}元</div>
                <el-button type="text" @click="Recharge">充值</el-button>
                <el-button type="text" @click="cash">提现</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="账户流水" name="first">
          <WaterPage v-if="activeName=='first'&&oderselect" :Id="oderselect" :money="amountList.amount"
            @checkInfo="getDate" />
        </el-tab-pane>
        <el-tab-pane label="充值记录" name="second">
          <RecharePage v-if="activeName=='second'&&oderselect" :Id="oderselect" @checkInfo="getDate" />
        </el-tab-pane>
        <el-tab-pane label="提现记录" name="third">
          <WithdrawalPage v-if="activeName=='third'&&oderselect" :Id="oderselect" @checkInfo="getDate" />
        </el-tab-pane>
        <el-tab-pane label="结算记录" name="fourth">
          <SettlementPage v-if="activeName=='fourth'&&oderselect" :Id="oderselect" @checkInfo="getDate" />
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <el-dialog v-if="dialogVisible" :title="tit" :visible.sync="dialogVisible" :close-on-click-modal="false"
    	width="50%"> -->
    <RechargeStock v-if="dialogVisible" :tit='tit' ref="RechargeStock" :amountform='amountList' :Id="oderselect"
      @sureclose="closeDialog" @close="back"/>
    <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="srue">确认</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog -->>
    <!-- <DuigongStock v-if="visibleStock"></DuigongStock> -->
  </div>
</template>

<script>
  import WithdrawalPage from './components/WithdrawalPage.vue'
  import RecharePage from './components/RechargePage.vue'
  import WaterPage from './components/WaterPage.vue'
  import SettlementPage from './components/SettlementPage.vue'
  import RechargeStock from './components/RechargeStock.vue'
  // import DuigongStock from './components/DuigongStock.vue'
  export default {
    components: {
      WithdrawalPage,
      RecharePage,
      WaterPage,
      SettlementPage,
      RechargeStock,
      // DuigongStock
    },
    data() {
      return {
        activeName: 'first',
        dialogVisible: false,
        tit: '',
        oderselect: '',
        oderList: [],
        amountList: {},
        orderName:''
      }
    },
    created() {
      this.getOderInfo(false)
      // this.$nextTick(() => {
      //   let Ids = sessionStorage.getItem('orderIds') ? JSON.parse(sessionStorage.getItem('orderIds')) : '';
      //   this.oderselect = Ids ? Ids : ''
      // })
      // this.getOderInfo(false)
    },
    mounted() {
      this.$nextTick(() => {
        let Ids = sessionStorage.getItem('orderIds') ? JSON.parse(sessionStorage.getItem('orderIds')) : '';
        this.oderselect = Ids ? Ids : ""
      })
    },
    methods: {
      getDate() {
        this.$http.get(`/api/system/web/account/getOrgAccountInfo?orgId=${this.oderselect}`).then(res => {
          if (res.data.code == 200) {
            this.amountList = {
              bankName: res.data.data.bankName,
              bankOf: res.data.data.bankOf,
              bankNumber: res.data.data.bankNumber,
              amount: res.data.data.amount,
              orgName: res.data.data.orgName,
              taxCode: res.data.data.taxCode,
              orgId: res.data.data.orgId,
              name:this.orderName
            }
          }
        })
      },
      propmt(str) {
        return str ? str.substring(0, 4) + '********' + str.substring(str.length - 4) : ''
      },
      changOder(index) {
        // this.$confirm(`是否切换到单位账户`, '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        const names = this.oderList.filter(item => item.id == index)
        const h = this.$createElement;
        this.$msgbox({
          title: '消息',
          message: h('p', null, [
            h('span', null, '是否切换到单位账户 '),
            h('i', {
              style: 'color: red'
            }, `${names[0].name}`)
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          sessionStorage.setItem('orderIds', JSON.stringify(index));
          this.oderselect = index
          this.activeName = 'first'
          this.getDate()
        })
      },

      getOderInfo(type) {
        // this.oderList = []
        this.$http.get(`/api/system/web/org/getUserOrgList`).then(res => {
          if (res.data.code == 200) {
            if (!this.oderselect) {
              this.oderselect = res.data.data[0].id
            }
            res.data.data.forEach(item => {
              this.oderList.push({
                id: item.id,
                name: item.name
              })
              let orName= this.oderList.filter(item => item.id == this.oderselect)
             this.orderName = orName[0] ? orName[0].name : ''
            })
            this.getDate()
          }
        })
      },
      handleClick(tab, event) {},
      Recharge() {
        this.tit = "充值"
        this.dialogVisible = true
      },
      cash() {
        this.tit = "提现申请"
        this.dialogVisible = true
      },
      closeDialog() {
        this.dialogVisible = false
        this.getDate()
        this.activeName = 'first'
      },
      back(){
        this.dialogVisible = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .accountContent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 15vh;
    margin-left: 50px;
  }

  .amountContent {
    display: flex;
    align-items: center;
    height: 15vh;
    margin-left: 100px;
  }

  .boxcard {
    padding: 10px 20px 10px 50px;
  }

  .el-button {
    margin-left: 30px;
  }
</style>
