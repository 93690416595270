<template>
  <div>
    <div>
      <el-form inline :model="searchForm">
        <el-form-item label="维保项名称">
          <el-input placeholder="请输入维保项名称" v-model="searchForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="维保类型">
          <el-select placeholder="请选择维保类型" v-model="searchForm.type" clearable>
            <el-option v-for="(item, index) in maintainType" :key="index" :label="item.label"
              :value="Number(item.value)" />
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select placeholder="请选择状态" v-model="searchForm.isActive" clearable>
            <el-option :key="1" :value="1" label="开启"></el-option>
            <el-option :key="2" :value="0" label="关闭"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button v-focus type="success" size="small" @click="addDj">新增</el-button>
      <template v-if="personnelTotal != 0">
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" :height="$store.state.tabHeight"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px">
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="name" label="维保项名称" min-width="150px" />
          <el-table-column align="center" prop="orgName" label="维保类型" min-width="150px">
            <template slot-scope="scope">
              {{
                maintainType.find((item1) => Number(item1.value) === scope.row.type)
                  ? maintainType.find((item1) => Number(item1.value) === scope.row.type)
                      .label
                  : ''
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间" min-width="150px" />
          <el-table-column align="center" prop="updateTime" label="更新时间" min-width="150px" />
          <el-table-column align="center" prop="isDeleted" label="维保项目数量" min-width="150px">
            <template slot-scope="scope">
              {{ JSON.parse(scope.row.itemCodes).length }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="isDeleted" label="电梯品种" min-width="150px">
            <template slot-scope="scope">
              {{ eleVarieties.find(item=>item.value == scope.row.varieties) ? eleVarieties.find(item=>item.value == scope.row.varieties).label : ''}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="isActive" label="状态" width="120px">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isActive" :active-value="1" :inactive-value="0" active-color="#409eff"
                :disabled="adminInfo.userName != 'admin'&&scope.row.createUser != adminInfo.id" inactive-color="#909399"
                @change="isSealedChange(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createUserName" label="创建人" min-width="150px" />
          <el-table-column align="center" prop="createTime" label="创建时间" min-width="150px" />
          <el-table-column align="center" prop="updateTime" label="更新时间" min-width="150px" />
          <el-table-column fixed="right" align="center" label="操作" width="300px">
            <template slot-scope="scope">
              <el-button @click="check(scope.row)" type="warning" size="small">查看
              </el-button>
              <template v-if="scope.row.id > 100&&scope.row.createUser == adminInfo.id">
                <el-button v-focus @click="edit(scope.row)" type="primary" size="small">编辑
                </el-button>
                <el-button v-focus v-if="adminInfo.name == scope.row.createUserName && scope.row.isActive == 0"
                  @click="del(scope.row.id)" type="danger" size="small">删除
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="personnelTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="personnelPage" />
      </template>

      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>

    <el-dialog v-if="dialogVisible" :title="tit" :visible.sync="dialogVisible" :close-on-click-modal="false"
      width="950px" top="15vh">
      <div style="height: 40vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
        <el-form label-position="right" label-width="120px" ref="mainterItemForm" :model="mainterItemForm"
          :rules="formRules">
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="维保项名称:" prop="name">
                <el-input v-model="mainterItemForm.name" placeholder="请输入" class="wid90" :disabled="dialogVisibleDis" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电梯品种:" prop="varieties">
                <el-select v-model="mainterItemForm.varieties" :disabled="dialogVisibleDis" class="wid90"
                  placeholder="请选择" @change="changeVar">
                  <el-option v-for="item in eleVarieties" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-tooltip class="item" effect="dark" placement="top-end">
                  <div slot="content">常见的乘客、载货、客货电梯请选择曳引与强制<br />驱动电梯。若采用液压驱动请选择液压驱动电梯。</div>
                  <i class="el-icon-warning-outline"
                    style="position: absolute;top: 10px;font-size: 18px;margin-left: 10px"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保类型:" prop="type">
                <el-select :disabled="dialogVisibleDis" class="wid90" v-model="mainterItemForm.type" placeholder="请输入"
                  @change="changeItemType">
                  <el-option v-for="(item, index) in maintainType" :key="index" :label="item.label"
                    :value="Number(item.value)" />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12" v-if="adminInfo.userName == 'admin' && !dialogVisibleDis">
              <el-form-item label="区域:">
                <el-cascader :props="props" class="wid90" :options="$mapCode" v-model="mainterItemForm.areaCodes"
                  collapse-tags clearable>
                </el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <template v-for="(item, index) in mainterItemForm.mainItemsArr">

                <el-form-item :label="`标题${index + 1}:`" :key="index">
                  <div style="display:flex;">
                    <el-form-item class="wid15 mr20" :prop="'mainItemsArr.' + index + '.itemGroup'" :rules="[{
                          required: true,
                          message: '请选择维保项类型',
                          trigger: 'change'}]">
                      <el-select :disabled="dialogVisibleDis" clearable v-model="item.itemGroup" placeholder="请输入">
                        <el-option v-for="itemm in maintainCategory" :key="itemm.value" :label="itemm.value"
                          :value="Number(itemm.label)" />
                      </el-select>
                    </el-form-item>
                    <el-form-item class="wid50 mr20" :prop="'mainItemsArr.' + index + '.value'" :rules="[{
                          required: true,
                          message: '标题至少需要中文，字母，数值，特殊符号，长度为5-200',
                          trigger: 'change',
                          min: 5,
                          max: 200 }]">
                      <template v-if="!dialogVisibleDis">
                        <el-input v-model="item.value" placeholder="请输入" @change="changeLabel(index)" />
                      </template>
                      <template v-else>
                        <div class="itemClass" :title="item.value">{{item.value}}</div>
                      </template>
                    </el-form-item>
                    <el-button v-if="!dialogVisibleDis" type="text" size="medium" icon="el-icon-delete" class="mr20"
                      style="color: red !important; font-size: 20px" @click="deleteItem(index)"
                      :disabled="dialogVisibleDis" />
                    <el-checkbox v-model="item.checked" :disabled="dialogVisibleDis">是否拍照</el-checkbox>
                  </div>
                </el-form-item>
              </template>

              <!-- <template v-for="(item, index) in mainterItemForm.mainItemsArr">
              <el-form-item :label="`标题${index + 1}:`" :key="index">
                <div style="display:flex;">
                  <el-form-item
                    class="wid15 mr20"
                    :prop="'mainItemsArr.' + index + '.itemGroup'"
                    :rules="[{
                        required: true,
                        message: '请选择维保项类型',
                        trigger: 'change'}]">
                    <el-select :disabled="dialogVisibleDis" clearable v-model="item.itemGroup" placeholder="请输入">
                      <el-option v-for="itemm in maintainCategory" :key="itemm.value" :label="itemm.label"
                        :value="Number(itemm.value)" />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    class="wid50 mr20"
                    :prop="'mainItemsArr.' + index + '.value'"
                    :rules="[{
                        required: true,
                        message: '标题至少需要中文，字母，数值，特殊符号，长度为5-200',
                        trigger: 'change',
                        min: 5,
                        max: 200 }]">
                      <template v-if="!dialogVisibleDis">
                        <el-input  v-model="item.value" placeholder="请输入"
                          @change="changeLabel(index)" />
                      </template>
                      <template v-else>
                        <div class="itemClass" :title="item.value">{{item.value}}</div>
                      </template>
                  </el-form-item>
                  <el-button v-if="!dialogVisibleDis" type="text" size="medium" icon="el-icon-delete" class="mr20"
                    style="color: red !important; font-size: 20px" @click="deleteItem(index)"
                    :disabled="dialogVisibleDis || isEdit" />
                  <el-checkbox v-model="item.checked" :disabled="dialogVisibleDis">是否拍照</el-checkbox>
                </div>
              </el-form-item>
              </template> -->
            </el-col>
            <el-button :disabled="dialogVisibleDis" type="primary" icon="el-icon-plus"
              style="padding: 10px 20px; margin-left: 120px" @click="chooseTableItem">
              从模板中选择
            </el-button>
            <el-button :disabled="dialogVisibleDis" type="primary" icon="el-icon-plus"
              style="padding: 10px 40px; margin-left: 10px" @click="chooseItem" />
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="!dialogVisibleDis" size="small" type="primary" :loading="loading" @click="dioSub(ItemType)">确
          定</el-button>
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <WbItem v-if="selectDialogVisible" ref="wbItem" :selectUnitList="maintainItem" :itemGroup="itemGroup"
      :mainItemsArr="mainItemsArr" @close="close" @transferUnit="selectOtherResult"
      :mainType="mainterItemForm.varieties" />
  </div>
</template>
<script>
  import Pagination from '../../../components/Pagination'
  import WbItem from './components/wbItem'
  export default {
    components: {
      Pagination,
      WbItem,
    },
    data() {
      return {
        pageType: 'EMPLOYEE_MANAGE',
        // 表格参数
        searchForm: {
          current: 1,
          size: 10,
          type: ''
        },
        code: ['MAINTAIN_TYPE', 'MAINTAIN_ITEM', 'MAINTAIN_ITEM_GROUP', 'SUNDRIES_MAINTAIN_ITEM_GROUP',
          'HYDRAULICALLY_MAINTAIN_ITEM_GROUP', 'ELEVATOR_VARIETIES', 'ESCALATORS_MAINTAIN_ITEM_GROUP'
        ],
        //所选区域
        props: {
          label: 'name',
          value: 'areaCode',
          checkStrictly: true,
          children: 'children',
          disabled: false,
        },
        // 维保项分类
        maintainCategory: [],
        // 维保项类型
        maintainType: [],
        // 维保项内容
        maintainItem: [],
        // MainItemsArr: [{ value: '', checked: true }], // 已选维保项
        // 单位类型
        unitList: [],
        tableLoading: true,
        tableData: [],
        // 分页参数
        personnelTotal: 0,
        // dialog参数
        tit: '',
        eleVarieties: [],
        dialogVisible: false,
        dialogVisibleDis: false, // 查看禁点
        loading: false,
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        ItemType: null, // 新增修改状态
        selectRow: [], // 选中维保项
        personnelData: {}, // 维保项数据
        selectDialogVisible: false, // 维保项列表
        mainterItemForm: {
          name: '',
          mainItemsArr: [{
            itemGroup: 1,
            value: '',
            checked: false
          }],
          varieties: 'TRACTION_AND_FORCE_DRIVE_ELEVATORS',
          areaCodes: []
        },
        formRules: {
          name: [{
            required: true,
            message: '维保项名称至少需要中文，字母，数值，特殊符号，长度为2-30',
            trigger: 'blur',
            min: 2,
            max: 30,
          }, ],
          type: [{
            required: true,
            message: '维保类型至少选择一项',
            trigger: 'change',
          }, ],
          varieties: [{
            required: true,
            message: '电梯品种为必填项',
            trigger: 'change',
          }, ],
        },

        itemGroup: [],
        mainItemsArr: [],
        oldvarieties: ''

      }
    },
    filters: {},
    methods: {
      // 获取字典数据
      getDictionar(code, type) {
        // code = code.join()
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {

            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res

              this.maintainType = data.MAINTAIN_TYPE
              this.maintainItem = data.MAINTAIN_ITEM
              this.eleVarieties = data.ELEVATOR_VARIETIES
              if (type == 'TRACTION_AND_FORCE_DRIVE_ELEVATORS') { //曳引与强制驱动电梯
                this.itemGroup = data.MAINTAIN_ITEM_GROUP
                this.maintainCategory = data.MAINTAIN_ITEM_GROUP
              } else if (type == 'SUNDRIES_ELEVATOR') { //杂物电梯
                this.itemGroup = data.SUNDRIES_MAINTAIN_ITEM_GROUP
                this.maintainCategory = data.SUNDRIES_MAINTAIN_ITEM_GROUP
              } else if (type == 'HYDRAULICALLY_DRIVEN_ELEVATOR') { //液压驱动电梯
                this.itemGroup = data.HYDRAULICALLY_MAINTAIN_ITEM_GROUP
                this.maintainCategory = data.HYDRAULICALLY_MAINTAIN_ITEM_GROUP
              } else {
                this.itemGroup = data.ESCALATORS_MAINTAIN_ITEM_GROUP
                this.maintainCategory = data.ESCALATORS_MAINTAIN_ITEM_GROUP
              }
              this.maintainItem.forEach((item) => {
                item.checked = false
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 分页
      personnelPage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer()
      },
      getFenNumber() {

      },
      changeVar(val) {
        console.log( '选择品种')
        let show = false
        this.mainterItemForm.mainItemsArr.forEach((item) => {
          if (item.value) {
            show = true
          }
        })
        if (show) {
          this.$confirm('更换电梯品种将清空当前已经填写的维保项内容，是否继续？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确认',
            type: 'warning'
          }).then(() => {
            this.oldvarieties = val
            this.mainterItemForm.varieties = val
            this.mainterItemForm.mainItemsArr = [{
              itemGroup: 1,
              value: '',
              checked: false
            }]
            this.getDictionar(this.code, `${val}`)
            this.changeItemType(this.mainterItemForm.type)
          }).catch(() => {
            this.mainterItemForm.varieties = this.oldvarieties
            this.getDictionar(this.code, `${val}`)
          })
        } else {
          this.mainterItemForm.varieties = val
          this.getDictionar(this.code, `${val}`)
        }
      },

      // 弹出框提交
      dioSub(type) {
        // this.loading = true
        let url = ''
        if (type === 'add') {
          url = '/api/ele/web/maintainItem/add'
        } else {
          url = '/api/ele/web/maintainItem/edit'
        }
        this.$refs.mainterItemForm.validate(async (valid) => {
          if (valid) {
            this.mainterItemForm.itemCodes = JSON.stringify(
              this.mainterItemForm.mainItemsArr
            )

            this.mainterItemForm.areaCode = this.mainterItemForm.areaCodes[this.mainterItemForm.areaCodes.length -
              1]
            this.mainterItemForm.areaCodes = JSON.stringify(this.mainterItemForm.areaCodes)
            this.$http.post(url, this.mainterItemForm).then((res) => {
              console.log( '弹出框提交')
              if (res.data.success) {
                this.onRefer()
                this.dialogVisible = false
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        })
        this.loading = false
      },
      // 是否开启
      isSealedChange(val) {
        const data = {
          id: val.id,
          type: val.type,
          name: val.name,
          itemCodes: val.itemCodes,
          isActive: val.isActive,
        }

        this.$http.post('/api/ele/web/maintainItem/edit', data).then((res) => {
          if (res.data.success) {
            this.onRefer()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      reset() {
        this.searchForm = {
          size: 10,
          current: 1
        }
        this.onRefer()
      },
      // 获取维保项方法
      onRefer() {
        const data = JSON.parse(JSON.stringify(this.searchForm))
        this.tableLoading = true
        this.$http.post('/api/ele/web/maintainItem/getList', data).then((res) => {

          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res
            this.tableData = data.records ?? []
            this.personnelTotal = data.total ?? 0
            this.tableLoading = false
            this.$nextTick(() => {
              if (this.personnelTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },

      changeLabel(value) {
        this.mainterItemForm.mainItemsArr[value].label = ''
      },
      // 查看
      check(item) {
        console.log( '查看')
        this.tit = '查看维保项'
        this.dialogVisible = true
        this.dialogVisibleDis = true
        this.mainterItemForm = JSON.parse(JSON.stringify(item))
        this.$set(
          this.mainterItemForm,
          'mainItemsArr',
          JSON.parse(item.itemCodes)
        )
      },

      // 修改
      edit(item) {
        console.log( '修改')
        this.ItemType = 'edit'
        this.tit = '编辑维保项'
        this.dialogVisible = true
        this.dialogVisibleDis = false
        this.mainterItemForm = JSON.parse(JSON.stringify(item))
        this.oldvarieties = this.mainterItemForm.varieties
        this.getDictionar(this.code, `${this.mainterItemForm.varieties}`)
        this.mainterItemForm.areaCodes = item.areaCodes ? JSON.parse(item.areaCodes) : ''
        this.$set(
          this.mainterItemForm,
          'mainItemsArr',
          JSON.parse(item.itemCodes),
        )
      },
      // 删除
      del(id) {
        this.$confirm('此操作将永久删除选中的维保项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          const data = {
            id,
          }
          this.$http
            .post('/api/ele/web/maintainItem/delete', data)
            .then((res) => {

              if (res.data.success) {
                const num = id.indexOf(',') > -1 ? id.split(',').length : 1
                if (this.tableData.length === num) {
                  this.searchForm.current -= 1
                }
                this.onRefer()
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
      },

      // 新增
      addDj() {
        this.tit = '新增维保项'
        this.ItemType = 'add'
        this.dialogVisible = true
        this.dialogVisibleDis = false
        this.getDictionar(this.code, 'TRACTION_AND_FORCE_DRIVE_ELEVATORS')

        this.mainterItemForm = {
          name: '',
          type: '',
          mainItemsArr: [{
            itemGroup: 1,
            value: '',
            checked: false
          }],
          varieties: "TRACTION_AND_FORCE_DRIVE_ELEVATORS",
          areaCodes: []
        }
      },
      changeItemType(val) {
        let id = 1
        if (this.mainterItemForm.varieties == 'TRACTION_AND_FORCE_DRIVE_ELEVATORS') {
          switch (val) {
            case 1:
              id = 1
              break
            case 3:
              id = 2
              break
            case 4:
              id = 4
              break
            case 5:
              id = 3
              break

            default:
              break
          }
        } else if (this.mainterItemForm.varieties == 'SUNDRIES_ELEVATOR') {
          switch (val) {
            case 1:
              id = 6
              break
            case 2:
              id = 7
              break
            case 3:
              id = 8
              break
            case 4:
              id = 10
              break
            case 5:
              id = 9
              break

            default:
              break
          }
        } else if (this.mainterItemForm.varieties == 'HYDRAULICALLY_DRIVEN_ELEVATOR') {
          switch (val) {
            case 1:
              id = 11
              break
            case 2:
              id = 12
              break
            case 3:
              id = 13
              break
            case 4:
              id = 15
              break
            case 5:
              id = 14
              break

            default:
              break
          }
        } else {
          switch (val) {
            case 1:
              id = 16
              break
            case 2:
              id = 17
              break
            case 3:
              id = 18
              break
            case 4:
              id = 20
              break
            case 5:
              id = 19
              break

            default:
              break
          }
        }

        this.$http
          .get(`/api/ele/web/maintainItem/getMaintainItemDetail?id=${id}`)
          .then((res) => {
            if (res.data.success) {
              this.$set(
                this.mainterItemForm,
                'mainItemsArr',
                JSON.parse(res.data.data.itemCodes)
              )
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      // 添加维保项
      chooseItem() {
        if (this.mainterItemForm.mainItemsArr.find((item) => item.value === '')) {
          this.$message.error('已经有空白的维保项')
          return
        }
        this.mainterItemForm.mainItemsArr.push({
          itemGroup: 1,
          value: '',
          checked: false
        })
      },
      // 删除维保项
      deleteItem(index) {
        if (this.mainterItemForm.mainItemsArr.length === 1) {
          this.$message.error('已经是最后一条维保项了')
          return
        }
        this.mainterItemForm.mainItemsArr.splice(index, 1)
      },
      // 列表添加维保项
      chooseTableItem() {
        console.log('选择维保项')
        this.selectDialogVisible = true
        this.mainItemsArr = this.mainterItemForm.mainItemsArr
        // this.$nextTick(() => {
        //   this.$refs.wbItem.init(this.mainterItemForm.mainItemsArr, (refresh) => {
        //     if (refresh) {}
        //   })
        // })
      },
      close() {
        this.selectDialogVisible = false
      },
      // 选择维保项返回
      selectOtherResult(data) {
        this.selectDialogVisible = false
        if (data.length > 1) {
          this.mainterItemForm.mainItemsArr = JSON.parse(JSON.stringify(data))
          this.mainterItemForm.mainItemsArr = this.mainterItemForm.mainItemsArr.filter(item => item.value != '')
        } else {
          if (data.length == 1 && data.value != '') {
            this.mainterItemForm.mainItemsArr = JSON.parse(JSON.stringify(data))
          } else {
            this.$set(this.mainterItemForm, 'mainItemsArr', [{
              itemGroup: 1,
              value: '',
              checked: false
            }])
          }
        }
      },
    },
    created() {
      this.getDictionar(['MAINTAIN_TYPE', 'MAINTAIN_ITEM', 'MAINTAIN_ITEM_GROUP', 'SUNDRIES_MAINTAIN_ITEM_GROUP',
          'HYDRAULICALLY_MAINTAIN_ITEM_GROUP', 'ELEVATOR_VARIETIES', 'ESCALATORS_MAINTAIN_ITEM_GROUP'
        ],
        `${this.mainterItemForm.varieties}`)
    },
    mounted() {
      this.$nextTick(() => {
        this.onRefer()
      })
    },
  }
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-form-item .is-error .is-required {
    margin-bottom: 30px;
  }

  .searchBtn {
    margin-left: 15px;
  }

  .wid90 {
    width: 95%;
  }

  .wid15 {
    width: 15%;
  }

  .wid50 {
    width: 50%;
  }

  .mr20 {
    margin-right: 20px;
  }

  .itemClass {
    width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
