<template>
  <div>
    <el-dialog v-if="AaddEditDialog" :title="`${Tit}电动车棚`" :visible.sync="AaddEditDialog" :close-on-press-escape="false"
      :close-on-click-modal="false" append-to-body width="950px" top="20vh" @close="closeDialog">
      <!-- <div style="height: 25vh;margin: 20px;"> -->
      <div style="height: 25vh;margin: 20px;" class="scrollElement">
        <el-form label-position="right" label-width="100px" :model="elevatorForm" ref='elevatorForm' :rules="rules">
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="车棚名称：" prop="name">
                <el-input placeholder="例如X栋东车棚、1#停车场..." v-model="elevatorForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属小区:" prop="plotId">
                <el-select v-model="elevatorForm.plotId" class="wid90" filterable clearable placeholder="请选择"
                  @change="changePlot">
                  <el-option v-for="item in plotArr" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="物业单位:" prop="propertyUnitId">
                <el-select v-model="elevatorForm.propertyUnitId" disabled class="wid90" filterable clearable
                  placeholder="请选择" @change="changeUnit('property')">
                  <el-option v-for="item in wyUnit" :key="item.id" :label="item.name+'（'+item.unitCode+'）'" :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电梯安全员:" prop="userNames">
                <el-input :placeholder="elevatorForm.userNames || '暂无内容'" v-model="elevatorForm.userNames" class="wid90"
                  disabled>
                  <el-button :disabled="!elevatorForm.propertyUnitId" slot="append" type="primary"
                    @click="selectElevator('TENEMENT_UNIT', elevatorForm.propertyUnitId)">选择人员</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="车棚位置:" prop="address">
                <!-- <el-input
                  class="wid90"
                  v-model="elevatorForm.eleAddress"
                  :disabled="type === 'check'"
                  placeholder="请输入"
                /> -->
                <el-input :placeholder="elevatorForm.Address || '暂无内容'" v-model="elevatorForm.address" class="wid96"
                  disabled>
                  <el-button slot="append" type="primary" @click="chooseAddress">选择安装位置</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <SelectPerson v-if="selectPersonVisible" ref="selectElevator" :unitName="unitName" :initIds="initIds"
        :unitType="unitType" :orgId="orgId" @transferElevator="selectOtherResult" />
      <el-dialog title="选择地址" :visible.sync="addressVisible" :close-on-click-modal="false" append-to-body width="960px"
        top="20vh" center @close="closeAddress">
        <SelectAddress v-if="selectAddressVisible" ref="SelectAddress" :lnglat="elevatorForm.lnglat"
          @clickAddress="clickAddress" />
        <div slot="footer" class="dialog-footer" >
          <el-button type="primary" @click="transfeRaddress">确定</el-button>
          <el-button type="indo" @click="closeAddress">取消</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="dioSub">确 定</el-button>
        <el-button size="small" @click="closeDialog">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import SelectAddress from '../../../dtsj/dtzl/components/selectAddress.vue'
  import SelectPerson from './selectPerson.vue'
  export default {
    props: {
      propForm: {
        type: Object,
        default: () => {},
      },
      upd: {
        type: Number,
        default: 0,
      },
      Tit: {
        type: String,
        default: '',
      }
    },
    components: {
      SelectAddress,
      SelectPerson
    },
    data() {
      return {
        AaddEditDialog: true,
        elevatorForm: {},
        rules: {
          name: [{
            required: true,
            message: '请输入车棚名称',
            trigger: 'blur'
          }, {
            validator: this.nameVolid,
            trigger: 'blur'
          }],
          plotId: [{
            required: true,
            message: '请选择所属小区',
            trigger: 'blur'
          }, ],
          propertyUnitId: [{
            required: true,
            message: '物业单位不能为空',
            trigger: 'blur'
          }, ],
          userNames: [{
            required: true,
            message: '请选择安全员',
            trigger: 'blur'
          }, ],
          address: [{
            required: true,
            message: '请选择车棚位置',
            trigger: 'blur'
          }, ],
        },
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        plotArr: [], //所属小区
        wyUnit: [], //物业单位
        trans: false,
        addressVisible: false,
        selectAddressVisible: false,
        selectPersonVisible: false, // 选择人员弹框
        codesAddress: null,
        propertyArr:[],
        initIds:[],
        unitName:[],
        personType:''
      }
    },
    created() {
    },
    mounted() {
      this.elevatorForm = this.propForm
      this.$nextTick(() => {
        this.getUnit()
        this.getPlot()
        // this.getBrand()
        if (this.propForm.plotId) {
          this.getPlotInfo(this.propForm.plotId)
        }
      })
    },
    methods: {
      nameVolid(rule, value, callback) {
        if (value.length > 50) {
          callback(new Error('输入名称不超过50个字'))
        } else {
          callback()
        }
      },
      // init(type, data, callback) {
      //   if (type == 'edit') {
      //     this.elevatorForm = data
      //   }
      //   this.$nextTick(() => {
      //     this.getUnit()
      //     this.getPlot()
      //     // this.getBrand()
      //     if (data.plotId) {
      //       this.getPlotInfo(data.plotId)
      //     }
      //   })
      // },
      closeAddress() { //关闭安装位置
        this.addressVisible = false
        this.selectAddressVisible = false
      },
      // 选择安装位置
      chooseAddress() {
        this.addressVisible = true
        this.selectAddressVisible = true
      },
      // 点击位置
      clickAddress(data) {
        this.codesAddress = data
        this.trans = true
      },
      // 确定地址
      transfeRaddress() {
        if (this.codesAddress) {
          this.elevatorForm.address = this.codesAddress ? this.codesAddress.address : ''
          this.elevatorForm.areaCode = this.codesAddress ? this.codesAddress.areaCode : ''
          this.elevatorForm.longitude = this.codesAddress ? this.codesAddress.markersPosition[0] : ''
          this.elevatorForm.latitude = this.codesAddress ? this.codesAddress.markersPosition[1] : ''
          this.elevatorForm.lnglat = this.codesAddress ? [
            this.codesAddress.markersPosition[0],
            this.codesAddress.markersPosition[1],
          ] : []
          if (this.trans) {
            this.addressVisible = false
            this.selectAddressVisible = false
            this.trans = false
          } else {
            this.$message({
              type: 'warning',
              message: '请选择地址，再确认'
            })
          }
        } else {
          this.$message({
            type: 'warning',
            message: '请选择地址，再确认'
          })
        }
        // localStorage.setItem('elevatorInfoForm', JSON.stringify(this.elevatorForm))
        // this.$nextTick(() => {
        //   this.$refs.SelectAddress.getInfo()
        // })
      },
      getPlot() { //获取所属小区
        const data = {
          current: 1,
          size: 10000,
          isUse: 0,
          user: '',
        }
        this.$http.post('/api/ele/web/plot/list', data).then((res) => {
          if (res.data.code === 200) {
            const {
              data: {
                data
              },
            } = res
            this.plotArr = data.records ?? []
            console.log(this.plotArr, '小区')
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      changePlot(val) { //选择所属小区
        this.codeName = ''
        // this.elevatorForm.userNames = ''
        // this.elevatorForm.propertyUnitId = []
        this.getPlotInfo(val)
        this.changeUnit()
      },
      changeUnit() {
        console.log(this.elevatorForm.propertyUnitId, 'this.elevatorForm.propertyUnitId')
        // this.elevatorForm.propertyUnitId = []
        this.elevatorForm.userNames = ''
      },
      getPlotInfo(id) {
        const dataObj = {
          current: 1,
          size: 99,
          id: id
        }
        this.$http.post("/api/ele/web/plot/list", dataObj).then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              }
            } = res;
            this.$set(this.elevatorForm, 'propertyUnitId', data.records.length > 0 && this.elevatorForm.plotId ?
              data.records[0]
              .propertyUnitId : '')
            // if (!this.elevatorForm.plotId) {
            //   this.elevatorForm.propertyUnitId = ''
            // }
            this.propertyDisabled = true
            // this.CodeInfo(res.data.data.records[0].areaCodes, data.records[0].name, type)
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      selectElevator(type, id) {
        console.log(type, 'type')
        // 单位id
        this.orgId = id
        // 单位名称
        let unitArr = []
        // 已选人员集合
        let initArr = []
        this.personType = type
        this.unitType = type

        initArr = this.propertyArr
        this.initIds = this.elevatorForm.userIds ?? []
        unitArr = this.wyUnit
        this.unitName = unitArr.find((item) => item.id === id) ?
          unitArr.find((item) => item.id === id).name :
          ''
        this.selectPersonVisible = true
        console.log(this.wyUnit, this.unitName, '小区')
        // this.selectPersonVisible = true
        this.$nextTick(() => {
          this.$refs.selectElevator.init(initArr, (refresh) => {
            if (refresh) {}
          })
        })
      },
      // 选择人员返回
      selectOtherResult(data) {

        const nameArr = []
        const idArr = []
        data.map((item) => {
          nameArr.push(`${item.name}(${item.contactWay})`)
          idArr.push(item.id)
        })
        console.log(data, 'data')
        if (idArr.length <= 20) {
          // this.initArr = data
          this.selectPersonVisible = false
          this.propertyArr = data
          this.$set(this.elevatorForm, 'userNames', nameArr.join())
          this.elevatorForm.userIds = idArr
        } else {
          this.$message({
            type: 'warning',
            message: '最多选择20人'
          })
        }
      },

      closeDialog() { //关闭弹窗
        this.AaddEditDialog = false
        this.$emit('close')
      },
      dioSub() { //提交确定
        this.$refs.elevatorForm.validate(async (valid) => {
          if (valid) {
            this.$http.post('/api/bike/web/carport/saveOrUpdateCarport', this.elevatorForm).then((res) => {
              console.log(res, '提交')
              if (res.data.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
                this.AaddEditDialog = false
                this.$emit("update")
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
          }

        })
      },
      // 获取各单位数据
      getUnit() {
        //维保单位,物业单位,救援单位,安装单位,保险单位,制造单位,产权单位,登记机关,检验单位,厂商单位
        const arr = [
          'TENEMENT_UNIT'
        ]
        console.log(this.propForm.createUser, 'createUser')
        const data = {
          current: 1,
          size: 10000,
          user: '',
        }
        arr.map((item) => {
          data.type = item

          this.$http.post('/api/system/web/org/list', data).then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              switch (item) {
                case 'TENEMENT_UNIT':
                  this.wyUnit = data.records ?? []
                  break
                default:
                  break
              }
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
      },
    }
  }
</script>

<style scoped>
  .wid90 {
    width: 100%;
  }
</style>
