<template>
  <el-dialog :before-close='bclose' :close-on-click-modal='false' title="相关人员" :visible.sync="isDia" width="1200px"
    top='10vh'>
    <el-form :inline="true" :model="formRale" class="demo-form-inline">
      <el-form-item>
        <span slot="label">
          <span class="lab-span">人员名称</span>
        </span>
        <el-input v-model="formRale.orgName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getRela(true)">查询</el-button>
        <el-button type="warning" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div style="display: flex;margin-bottom: 20px;" v-if="looks != false">
      <el-button v-focus type="success" @click="addDj"  size="small">新增</el-button>
      <el-button v-focus type="danger" @click="delDj" size="small">批量删除</el-button>
    </div>
    <div>
      <el-table :data="relaData" ref="multipleTable" v-loading="tableLoading" :height="$store.state.tabHeight" max-height="500px"
        style="width: 100%;margin-bottom: 20px;" @select="handleSelectionChange" @select-all="selectAll" :key="tableKey">
        <el-table-column type="selection" align="center" width="55px" />
        <el-table-column type="index" align="center" label="序号" width="50px" />
        <el-table-column align='center' prop="name" label="人员名称" />
        <el-table-column align='center' prop="orgName" label="所属单位" />
        <el-table-column align='center' prop="typeName" label="角色" />
        <el-table-column align='center' label="职位" v-if="!$store.state.isWBJB">
          <template slot-scope="scope">{{ChangeData.filter((item)=>item.value == scope.row.position)[0].label}}</template>
        </el-table-column>
        <el-table-column align='center' prop="contactWay" label="手机号码" />
        <el-table-column align='center' prop="account" label="app账号" />
        <el-table-column align='center' label="app登录密码" width="150px">
          <template slot-scope="scope">
            <el-button v-if="scope.row.btnText === ''" type="text" @click="checkPwd(scope.row, scope.$index)">点击查看</el-button>
            <el-button v-else type="text">{{scope.row.btnText}}</el-button>
          </template>
        </el-table-column>
        <el-table-column align='center' prop="certificateCode" label="证书编号" />
        <el-table-column align='center' prop="createTime" label="添加时间" width='200px' />
      </el-table>
      <Pagination :total="relaTotal" :page.sync="formRale.current" :limit.sync="formRale.size"
        @pagination="dictionarPage" />

      <!-- 添加人员 -->
      <RelatedAdd v-if="RelatedAdd" @close='xgClose' ref="relatedAdd" :id='id'></RelatedAdd>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="bclose">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import Pagination from '@/components/Pagination'
  import RelatedAdd from './relatedAdd';
  export default {
    props: ["id",'selShow'],
    components: {
      Pagination,
      RelatedAdd
    },
    data() {
      return {
        looks:true,
        isDia: true,
        relaData: [],
        relaTotal: 0,
        formRale: {
          size: 10,
          current: 1,
          orgId: this.id,
          name: '',
          orgName: ''
        },
        typeDate: [],
        ChangeData: [{
          label: '安全员',
          value: 'SAFETY_OFFICER'
        }, {
          label: '安全总监',
          value: 'SAFETY_DIRECTOR'
        }, {
          label: '主要负责人',
          value: 'PERSON_IN_CHARGE'
        }],
        RelatedAdd: false,
        initArr: [],
        tableLoading: true,
        tableKey: 0
      };
    },
    created() {
      this.looks = this.selShow
      // codes=RELATED_CODES,CONTRACT_TYPE
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=RELATED_CODES`)
        .then((res) => {
          if (res.data.success) {
            this.typeDate = res.data.data
            this.getRela()
          }
        })
    },
    methods: {
      // 分页
      dictionarPage(data) {
        this.formRale.current = data.page
        this.formRale.size = data.limit
        this.getRela()
      },

      // 重置按钮事件
      reset() {

        this.formRale = {
          size: 10,
          current: 1,
          orgId: this.id,
          orgName: ''
        }
        this.getRela()
      },
      getRela(ishm) {
        if (ishm) {
          this.formRale.current = 1
        }
        this.formRale.name = this.formRale.orgName ? `*${this.formRale.orgName}*` : ''
        const data = JSON.parse(JSON.stringify(this.formRale))
        delete data.orgName
        this.tableLoading = true
        this.$http
          .post('/api/system/web/appUser/getPage', data)
          .then((res) => {
            if (res.data.success) {
              this.relaTotal = res.data.data.total
              res.data.data.records.forEach((qs) => {
                qs.typeName = this.getTName(this.typeDate.RELATED_CODES, qs.type.split(','))
              })
              this.relaData = res.data.data.records
              this.relaData.map(item => item.btnText = '')
              this.$nextTick(() => {
                if (this.relaData.length > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
            this.tableLoading = false
          })
      },


      // 全选
      selectAll(selection) {
        let stateArr = [];
        if (selection.length > 0) {
          stateArr = JSON.parse(JSON.stringify(selection));
          const arr = [...selection, ...this.initArr];
          // 去重
          const res = new Map();
          this.initArr = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1));
        } else {
          stateArr = JSON.parse(JSON.stringify(this.relaData));
          stateArr.map((item) => {
            if (this.initArr.find((row) => row.id === item.id)) {
              this.initArr = this.initArr.filter((row) => row.id !== item.id);
            }
          });
        }
      },

      handleSelectionChange(val, row) {
        if (this.initArr.find((item) => item.id === row.id)) {
          this.initArr = this.initArr.filter((item) => item.id !== row.id);
        } else {
          this.initArr.push(row);
        }
      },

      // 新增
      addDj() {
        this.RelatedAdd = true
        this.$nextTick(() => {
          this.$refs.relatedAdd.init()
        })
      },
      // 删除
      delDj() {
        const listArr = [];
        this.initArr.map((item) => {
          listArr.push(item.id);
        });
        if (listArr.length === 0) {
          this.$message.error('请勾选需要移除的人员');
          return
        }
        this.$http.get(`/api/system/web/appUser/batchSaveOrDel?type=2&id=${this.id}&userIds=${listArr.join(",")}`)
          .then((res) => {
            if (res.data.success) {
              this.initArr = [];
              this.$refs.multipleTable.clearSelection();
              this.getRela()
              this.$message.success(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },

      xgClose() {
        this.RelatedAdd = false
        this.getRela()
      },
      bclose() {
        this.$emit('close')
      },
      getTName(typeDate, obj) {
        let tn = []
        typeDate.forEach(qs => {
          obj.forEach(qs2 => {
            if (qs.value == qs2) {
              tn.push(qs.label)
              return
            }
          })
        })
        return tn.join(',')
      },

      checkPwd(item, index){
        let data = JSON.parse(JSON.stringify(item))
        data.btnText = '获取中...'
        this.relaData.splice(index, 1, data)
        setTimeout(() => {
          data.btnText = this.decry_runde_RC4(data.password)
          this.relaData.splice(index, 1, data)
        }, 1000)
      },

      //解密
      decry_runde_RC4(data) {
        return this.decry_RC4(this.decry_RC4(data));
      },
      decry_RC4(data) {
        if (!data) return null;
        return this.asString(this.RC4Base(this.HexString2Bytes(data), 'runde_xiaoshayu'));
      },
      asString(buf) {
        return String.fromCharCode(...buf);
      },
      HexString2Bytes(src) {
        let size = src.length;
        let ret = new Uint8Array(size / 2);
        let tmp = new TextEncoder().encode(src);
        for (let i = 0; i < size / 2; i++) {
            ret[i] = this.uniteBytes(tmp[i * 2], tmp[i * 2 + 1]);
        }
        return ret;
      },
      uniteBytes(src0, src1) {
        let _b0 = parseInt(`0x${String.fromCharCode(src0)}`, 16) << 4;
        let _b1 = parseInt(`0x${String.fromCharCode(src1)}`, 16);
        return _b0 ^ _b1;
      },

      // 加密
      // encry_runde_RC4(data) {
      //   return this.encry_RC4_string(this.encry_RC4_string(data));
      // },
      // encry_RC4_string(data) {
      //   if (!data) {
      //       return null;
      //   }
      //   return this.toHexString(this.asString(this.encry_RC4_byte(data)));
      // },
      // encry_RC4_byte(data) {
      //     if (!data) {
      //         return null;
      //     }
      //     const b_data = new TextEncoder().encode(data);
      //     return this.RC4Base(b_data, 'runde_xiaoshayu');
      // },
      // toHexString(s) {
      //   let str = '';
      //   for (let i = 0; i < s.length; i++) {
      //       let ch = s.charCodeAt(i);
      //       let s4 = ch.toString(16);
      //       if (s4.length === 1) {
      //           s4 = '0' + s4;
      //       }
      //       str += s4;
      //   }
      //   return str;
      // },

      RC4Base(input, mKkey) {
        let x = 0;
        let y = 0;
        let key = this.initKey(mKkey);
        let xorIndex;
        let result = new Uint8Array(input.length);


        for (let i = 0; i < input.length; i++) {
          x = (x + 1) & 0xff;
          y = ((key[x] & 0xff) + y) & 0xff;
          let tmp = key[x];
          key[x] = key[y];
          key[y] = tmp;
          xorIndex = ((key[x] & 0xff) + (key[y] & 0xff)) & 0xff;
          result[i] = input[i] ^ key[xorIndex];
        }
        return result;
      },

      initKey(aKey) {
        let b_key = null;
        try {
            b_key = new TextEncoder().encode(aKey);
        } catch (e) {
            console.error(e);
        }

        let state = new Uint8Array(256);
        for (let i = 0; i < 256; i++) {
            state[i] = i;
        }

        let index1 = 0;
        let index2 = 0;

        if (!b_key || b_key.length === 0) {
            return null;
        }

        for (let i = 0; i < 256; i++) {
            index2 = ((b_key[index1] & 0xff) + (state[i] & 0xff) + index2) & 0xff;
            const tmp = state[i];
            state[i] = state[index2];
            state[index2] = tmp;
            index1 = (index1 + 1) % b_key.length;
        }

        return state;
      },
    }
  }
</script>

<style scoped>

</style>
