<template>
  <div>
    <el-row>
      <el-col :span="8">
        <div class="menuBox" style="margin-left:0">
          <div class="rightTop">
            <span>菜单名称</span>
            <span>鼠标按照拖动顺序</span>
          </div>
          <div style="color:#fff">
            <el-tree
              :data="treeData"
              ref="treeRef"
              node-key="id"
              draggable
              @node-drag-start="handleDragStart"
              @node-click="handleNodeClick"
              @node-drop="handleDrop"
              >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                  <el-button type="primary" size="mini" icon="el-icon-s-fold"></el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </el-col>
      <el-col :span="16" >
        <div class="menuBox" >
          <div class="rightTop">
            <span>菜单名称</span>
            <span>鼠标按照拖动顺序</span>
          </div>
          <div style="color:#fff">
            <el-tree
              :data="treeSonData"
              node-key="id"
              draggable
              @node-drag-start="handleDragSonStart"
              @node-drop="handleDropSon"
              >
              <span class="custom-tree-node" slot-scope="{ node }">
                <span>{{ node.label }}</span>
                <span>
                  <el-button type="primary" size="mini" icon="el-icon-s-fold"></el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      treeData: [],
      copyTreeList: [],
      treeSonData: [],
      copyTreeSonList: [],

      currentNodekey:'',
      chooseData: ''

    };
  },
  filters: {},
  methods: {
    // 获取菜单树
    getTree (parentId, type) {
      let num
      this.$http
        .post(`/api/system/web/resource/childrenList?parentId=${type ? parentId : 0}&current=1&size=99`)
        .then((res) => {
          if (type) {
            this.treeSonData = res.data.data.records
            num = this.treeSonData.length
            this.treeSonData.forEach(item => {
              item.label = item.resourceName
              item.sortNum = num--
            });
          } else {
            this.treeData = res.data.data.records
            num = this.treeData.length
            this.treeData.forEach(item => {
              item.label = item.resourceName
              item.sortNum = num--
            });
            this.currentNodekey = this.treeData[0].id;
            this.$nextTick(() => {
              this.$refs.treeRef.setCurrentKey(this.currentNodekey); //一定要加这个选中了否则样式没有出来
            });
            this.getTree(this.currentNodekey, true)
          }
        })
    },
    // 确认移动
    editTree (data) {
      this.$http
        .post('/api/system/web/resource/batchEdit', data)
        .then((res) => {
          if (this.chooseData) {
            this.getTree(this.chooseData.id , true)
            return
          }
          this.getTree();
        })
    },

    // 选中菜单
    handleNodeClick(data) {
      this.chooseData = data
      this.getTree(data.id , true)
    },
    // 开始移动
    handleDragStart(node, ev) {
      this.copyTreeList = JSON.parse(JSON.stringify(this.treeData));
    },
    // 移动结束
    handleDrop(draggingNode, dropNode, dropType, ev) {
      if (dropType == 'inner') {
        this.treeData = this.copyTreeList
        this.$message.error('该目录无法移动到目标目录底下')
        return
      }
      let numIndex = this.treeData.length
      this.treeData.forEach(item => {
        item.label = item.resourceName
        item.sortNum = numIndex--
      });
      this.editTree(this.treeData)
    },

    // 开始子菜单移动
    handleDragSonStart(node, ev) {
      this.copyTreeSonList = JSON.parse(JSON.stringify(this.treeSonData));
    },
    // 子菜单移动结束
    handleDropSon(draggingNode, dropNode, dropType, ev) {
      if (dropType == 'inner') {
        this.treeSonData = this.copyTreeSonList
        this.$message.error('该目录无法移动到目标目录底下')
        return
      }
      let numSonIndex = this.treeSonData.length
      this.treeSonData.forEach(item => {
        item.label = item.resourceName
        item.sortNum = numSonIndex--
      });
      this.editTree(this.treeSonData)
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.getTree();
    });
  },
};
</script>
<style lang="scss" scoped>
.menuBox{
  background: #2b406ca6;
  padding: 15px 25px 25px;
  border-radius: 8px;
  margin-left: 25px ;
  min-height: 80vh;
  .rightTop {
    display: flex;
    color: #fff;
    background: #1282a5e6;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px 15px;
  }
}
/deep/.el-tree-node{
  font-size: 16px;
  line-height: 25px;
  margin: 5px 0;
  padding: 5px 0;
  .custom-tree-node{
    display: flex;
    justify-content: space-between;
    width: 86%;
    align-items: center;
  }
}
/deep/.is-current{
  background: #147b9d96;
}
</style>
