<template>
  <div>
    <div class="page-top">
      <el-form
        :inline="true"
        ref="searchForm"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">AI摄像头ID</span>
          </span>
          <el-input
            v-model="searchForm.deviceCode"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">维保单位</span>
          </span>
          <el-select
            v-model="searchForm.maintainUnitId"
            placeholder="请输入"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in maintainUnit"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <span slot="label">
            <span class="lab-span">物业单位</span>
          </span>
          <el-select
            v-model="searchForm.propertyUnitId"
            placeholder="请输入"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in propertyUnit"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchinit"
            >查询</el-button
          >
          <el-button
            v-if="adminInfo.userName != 'scjg'"
            type="warning"
            @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div>
      <template v-if="tableData != 0">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="$store.state.tabHeight - 50"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px"
          v-loading="loading"
        >
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column
            align="center"
            prop="name"
            label="电梯名称"
            min-width="200px"
          />
          <el-table-column
            align="center"
            prop="deviceCode"
            label="AI摄像头ID"
            min-width="180px"
          />
          <el-table-column
            align="center"
            prop="streetUnitName"
            label="街道单位"
            width="150px"
          />
          <el-table-column align="center" label="物业单位" min-width="150px">
            <template slot-scope="scope">
              {{
                scope.row.propertyUnitName ? scope.row.propertyUnitName : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="maintainUnitName"
            label="维保单位"
            min-width="150px"
          >
            <template slot-scope="scope">
              {{
                scope.row.maintainUnitName ? scope.row.maintainUnitName : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="createUserName"
            label="创建人"
            width="150px"
          />
          <el-table-column
            fixed="right"
            align="center"
            prop="address"
            label="操作"
            width="300px"
          >
            <template slot-scope="scope">
              <el-button
                @click="createReport(scope.row)"
                type="primary"
                size="small"
                >生成报告
              </el-button>
              <el-button
                @click="checkReport(scope.row)"
                type="primary"
                size="small"
                >查看报告
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="maintenRuleTotal"
          :page.sync="searchForm.current"
          :limit.sync="searchForm.size"
          @pagination="maintenRulePage"
        />
      </template>

      <div class="nullDate" v-else v-loading="loading">
      	<img src="@/assets/nullDate.png" v-if="!loading" style="width: 260px;" />
      </div>
    </div>

    <CreateReport
      ref="CreateReport"
      v-if="createReportVisible"
      :elevatorId="elevatorId"
      @closeCreate="closeCreate"
    />

    <CheckReport
      ref="CheckReport"
      v-if="checkReportVisible"
      :elevatorId="elevatorId"
      @closeCheck="closeCheck"
    />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import CreateReport from "./createReport.vue";
import CheckReport from "./checkReport.vue";
export default {
  components: {
    Pagination,
    CreateReport,
    CheckReport,
  },
  data() {
    return {
      // 定时器
      getOlineTime: null,
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
        status: 0,
      },
      tableData: [],
      loading:true,
      // 分页参数
      maintenRuleTotal: 0,
      elevatorId: "", // 电梯id
      maintainUnit: [], // 维保单位
      propertyUnit: [], // 物业单位
      createReportVisible: false,
      checkReportVisible: false,

      adminInfo: JSON.parse(sessionStorage.getItem("adminInfo")),
    };
  },

  filters: {},
  methods: {
    // 单位列表
    getUnit(item) {
      const data = {
        // current: 1,
        // size: 10000,
        type: item,
      };
      // /api/system/web/org/list
      this.$http.post("/api/system/web/org/getRelevanceOrgList", data).then((res) => {
        if (res.data.success) {
          const {
            data: { data },
          } = res;

          switch (item) {
            case "MAINTAIN_UNIT":
              this.maintainUnit = data ?? [];
              break;
            case "TENEMENT_UNIT":
              this.propertyUnit = data ?? [];
              break;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 分页
    maintenRulePage(data) {
      this.searchForm.current = data.page;
      this.searchForm.size = data.limit;
      this.init();
    },

    // 搜索
    searchinit() {
      this.searchForm.current = 1;
      this.init();
    },
    // 重置
    reset() {
      this.searchForm = {
        current: 1,
        size: 10,
      };
      this.init();
    },

    // 获取列表方法
    init() {
      this.$http
        .post("/api/ele/web/elevatorInfo/runningList", this.searchForm)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res;
            this.tableData = data.records ?? [];
            this.tableData.forEach((qs) => {
              qs.objStatus = "-";
            });
            this.maintenRuleTotal = data.total ?? 0;
            this.$nextTick(() => {
              if (this.maintenRuleTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
          this.loading = false
        });
    },
    // 生成报告
    createReport(item) {
      this.createReportVisible = true;
      this.elevatorId = item.id;
    },

    closeCreate() {
      this.createReportVisible = false;
    },

    // 查看报告
    checkReport(item) {
      this.checkReportVisible = true;
      this.elevatorId = item.id;
    },
    closeCheck() {
      this.checkReportVisible = false;
    },
  },
  created() {
    this.getUnit("MAINTAIN_UNIT");
    this.getUnit("TENEMENT_UNIT");
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.init()
    // })
  },
  destroyed() {
    clearInterval(this.getOlineTime);
  },
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBtn {
  margin-left: 15px;
}
</style>
