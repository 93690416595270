<template>
  <div>
    <div class="page-top">
      <el-form inline>
        <el-form-item label="订单编号">
          <el-input placeholder="请输入订单编号" v-model="formList.orderNumber" clearable></el-input>
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select placeholder="请选择支付状态" v-model="formList.status" clearable>
            <el-option v-for="(item,index) in payList" :key="index" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="充值方式">
          <el-select placeholder="请选择充值方式" v-model="formList.type" clearable>
            <el-option v-for="(item,index) in payType" :key="index" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="warning" @click="reast">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="dictionarTotal != 0">
      <el-table :data="tableDate" v-loading="tableLoading" ref="multipleTable" :height="$store.state.tabHeight-325"
        style="width: 100%;margin-bottom: 20px;">
        <el-table-column type="index" label="序号" />
        <el-table-column prop="orderNumber" label="订单编号" align="center" min-width="120px" />
        <el-table-column prop="payNumber" label="支付单号" align="center" min-width="120px" />
        <el-table-column prop="amount" label="充值金额" align="center" min-width="120px" />
        <el-table-column prop="status" label="支付状态" align="center" min-width="120px">
          <template slot-scope="scope">
            {{payList.filter(item=>item.value == scope.row.status)[0].label}}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="充值方式" align="center" min-width="120px">
          <template slot-scope="scope">
            {{payType.filter(item=>item.value == scope.row.type)[0].label}}
          </template>
        </el-table-column>
        <el-table-column prop="subsequentAmount" label="充值后余额" align="center" min-width="120px" />
        <el-table-column prop="createTime" label="创建时间" align="center" min-width="120px" />
        <el-table-column prop="reason" label="不通过原因" align="center" min-width="120px" />
        <el-table-column label="操作" align="center" min-width="120px" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" v-if="scope.row.status == 0" size="small"
              @click="toPay(scope.row)">去付款</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formList.current" :limit.sync="formList.size"
        @pagination="noticePage" />
    </div>
    <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>
    <DuigongStock v-if="visibleStock" ref="DuigongStock" :datas="datas" @close="stockbakc" @closeDialog="stockbakc" />
  </div>
</template>

<script>
  import Pagination from '../../../../components/Pagination.vue'
  import DuigongStock from './DuigongStock.vue'
  export default {
    components: {
      Pagination,
      DuigongStock
    },
    props: ['Id'],
    data() {
      return {
        dictionarTotal: 0,
        current: 1,
        datas: {},
        visibleStock: false,
        tableDate: [],
        payList: [{
          value: 0,
          label: '未支付'
        }, {
          value: 1,
          label: '待确认'
        }, {
          value: 2,
          label: '支付成功'
        }, {
          value: 3,
          label: '支付失败'
        }, {
          value: 4,
          label: '审核不通过'
        }, {
          value: 5,
          label: '超时关闭'
        }, {
          value: 6,
          label: '退款成功'
        }],
        payType: [{
          value: 0,
          label: '未支付'
        }, {
          value: 1,
          label: '微信'
        }, {
          value: 2,
          label: '支付宝'
        }, {
          value: 3,
          label: '对公转账'
        }],
        formList: {
          current: 1,
          size: 10,
        },
        tableLoading: true
      }
    },
    created() {
      this.getDate()
    },
    methods: {
      getDate() {
        this.formList.orgId = this.Id != undefined ? this.Id : ''
        const data = {
          ...this.formList
        }
        this.tableLoading = true
        this.$http.post("/api/system/web/recharge/getOrgRechargeRecordList", data).then((res) => {
          if (res.data.code == 200) {
            this.tableDate = res.data.data.records ? res.data.data.records : ''
            this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                this.$refs.multipleTable.doLayout();
              }
            })
          }
        this.tableLoading = false
        })
      },
      toPay(data) {

        this.datas = data
        this.visibleStock = true
      },
      stockbakc() {
        this.visibleStock = false
        this.$emit('checkInfo')
        this.getDate()
      },
      noticePage(data) {
        this.formList.current = data.page
        this.formList.size = data.limit
        this.getDate()
      },
      search() {
        this.formList.current = 1
        this.getDate()
        this.$emit('checkInfo')
      },
      reast() {
        this.formList = {
          current: 1,
          size: 10,
        }
        this.getDate()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    // text-align:center;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
