<template>
  <div>
    <div class="page-top">
      <!-- 加提交时间和审核时间，默认搜索前三个月 -->
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">AI摄像头ID</span>
          </span>
          <el-input v-model="formInline.deviceId" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="formInline.eleName" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">审核状态</span>
          </span>
          <el-select v-model="formInline.status" placeholder="请输入" class="einp" clearable>
            <el-option label="未审核" :value="0" />
            <el-option label="已通过" :value="1" />
            <el-option label="不通过" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">提交时间</span>
          </span>
          <el-date-picker v-model="formInline.gitTime" type="daterange" range-separator="到" start-placeholder="开始时间"
            end-placeholder="结束时间" />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">审核时间</span>
          </span>
          <el-date-picker v-model="formInline.checkTime" type="daterange" range-separator="到" start-placeholder="开始时间"
            end-placeholder="结束时间" />
        </el-form-item>
        <!-- <el-form-item>
          <span slot="label">
            <span class="lab-span">省市区</span>
          </span>
          <el-cascader :props="props" :options="$mapCode" v-model="formInline.areaCodeArr" style="width: 100%;"
            collapse-tags clearable>
          </el-cascader>
        </el-form-item> -->
          <el-form-item>
            <span slot="label">
              <span class="lab-span">省市区</span>
            </span>
            <el-cascader :props="propCode" :options="$mapCode" v-model="formInline.areaCodeArr"
              style="width: 100%;" collapse-tags clearable>
            </el-cascader>
          </el-form-item>
        <el-form-item>
          <el-button type="success" :disabled="exportType" @click="exportData()">{{exportTitle}}</el-button>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
          <!-- <el-button type="success" @click="downList">导出</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <div>
      <template v-if="dictionarTotal != 0">
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" style="width: 100%;margin: 20px 0;"
          :height="$store.state.tabHeight-50">
          <!-- <el-table-column type="selection" width="55px"/> -->
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align='center' prop="deviceId" label="AI摄像头ID" width="150px" />
          <el-table-column align='center' prop="installAddress" label="设备安装地址" width="250px" />
          <el-table-column align='center' prop="eleName" label="电梯名称" width="200px" />
          <el-table-column align='center' prop="address" label="电梯安装位置" width="250px" />
          <el-table-column align='center' prop="createUserName" label="提交人" width="120px" />
          <el-table-column align='center' prop="contactWay" label="联系方式" width="120px" />
          <el-table-column align='center' prop="planStartTime" label="审核状态">
            <template slot-scope="scope">
              {{ getStatus(scope.row.status) }}
            </template>
          </el-table-column>
          <el-table-column align='center' prop="createTime" label="提交时间" width="180px" />
          <el-table-column align='center' prop="examineTime" label="审核时间" width="180px" />
          <el-table-column fixed="right" align='center' prop="address" label="操作" width="150px">
            <template slot-scope="scope">
              <el-button @click="check(scope.row)" type="primary" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
          @pagination="contractPage" />
      </template>
      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>
    <InstallDetail v-if="installType" ref="install" :imgList="imgList" :eleInfo="eleInfo" @change="changeStatus" />
  </div>
</template>
<script>
  import Pagination from '../../../components/Pagination'
  import InstallDetail from './components/InstallDetail'
  import {
    formatDate
  } from "@/util";
  let current_time = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00")
  let starTime = new Date(current_time.getTime() - (90 * 24 * 3600 * 1000));
  let endTime = new Date(current_time.getTime());
  export default {
    components: {
      Pagination,
      InstallDetail
    },
    data() {
      return {
        formInline: {
          current: 1,
          size: 10,
          gitTime: [starTime, endTime],
        },
        dictionarTotal: 0,
        tableData: [],
        imgList: {},
        installType: false,
        eleInfo: null,
        exportTitle: '导出',
        exportType: false,
        propCode: {
					label: 'name',
					value: 'areaCode',
					checkStrictly: true
				},
        tableLoading: true
      }
    },
    methods: {
      close(){
        this.installType = false
      },
      // 分页
      contractPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      // 重置
      reset() {

        this.formInline = {
          current: 1,
          size: 10,
        }
        this.onRefer()
      },
      // 获取合同方法
      onRefer(ishm) {
        this.installType = false
        if (ishm) {
          this.formInline.current = 1
        }
        if (this.formInline.areaCodeArr) {
          this.formInline.areaCodes = null
          if (this.formInline.areaCodeArr.length > 0) {
            let obj = JSON.parse(JSON.stringify(this.formInline.areaCodeArr))
            this.formInline.areaCodes = '*' + obj[obj.length - 1] + '*'
          }
        }
        // this.formInline.createStartTime = this.formInline.gitTime ? this.formInline.gitTime[0].getTime() : ''
        // this.formInline.createEndTime = this.formInline.gitTime ? this.formInline.gitTime[1].getTime() + (24 * 3600 *
        //   1000 - 1000) : ''
        // this.formInline.examineStartTime = this.formInline.checkTime ? this.formInline.checkTime[0].getTime() : ''
        // this.formInline.examineEndTime = this.formInline.checkTime ? this.formInline.checkTime[1].getTime() + (24 *
        //   3600 * 1000 - 1000) : ''

          this.formInline.createStartTime = this.formInline.gitTime ? this.formInline.gitTime[0].getTime() : ''
          this.formInline.createEndTime = this.formInline.gitTime ? this.formInline.gitTime[1].getTime() + (24 * 3600 * 1000 - 1000) : ''
          this.formInline.examineStartTime = this.formInline.checkTime ? this.formInline.checkTime[0].getTime() : ''
          this.formInline.examineEndTime = this.formInline.checkTime ? this.formInline.checkTime[1].getTime() + (24 * 3600 * 1000 - 1000) : ''
        this.formInline.areaCodes = this.formInline.areaCodeArr && this.formInline.areaCodeArr.length > 0 ? this.formInline.areaCodeArr[this.formInline.areaCodeArr.length - 1] : ''

        this.tableLoading = true
        this.$http
          .post("/api/ele/web/installRecord/getList", this.formInline)
          .then((res) => {
            if (res.data.success) {
              this.tableData = res.data.data.records
              this.dictionarTotal = res.data.data.total
              this.$nextTick(() => {
                if (this.unitTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
						this.tableLoading = false
          })
      },

      check(item) {
        this.eleInfo = item
        var ant = this.eleInfo.antennaImg ? this.eleInfo.antennaImg.split(',') : []
        var end = this.eleInfo.endingImg ? this.eleInfo.endingImg.split(',') : []
        var pow = this.eleInfo.powerImg ? this.eleInfo.powerImg.split(',') : []
        var scr = this.eleInfo.screenImg ? this.eleInfo.screenImg.split(',') : []
        var him = this.eleInfo.hdmiImg ? this.eleInfo.hdmiImg.split(',') : []
        // this.imgList = [...ant, ...end, ...pow, ...scr].filter(item => item !== '');
        this.imgList = {
          antennaImg: [...ant],
          endingImg: [...end],
          hdmiImg: [...him],
          powerImg: [...pow],
          screenImg: [...scr]
        }
        this.installType = true
        this.$nextTick(() => {
          this.$refs.install.init((refresh) => {
            if (refresh) {}
          })
        })
      },

      changeStatus(){
        this.installType = false
        this.onRefer()
      },
       // 导出
      async exportData() {
        this.formInline.current = 1
        this.exportType = true
        this.exportTitle = '正在导出'
        this.$http.post("/api/ele/web/export/installRecord", this.formInline, {
          responseType: 'blob'
        }).then((res) => {
          if (res.data.size > 0) {
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            });
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a'); //创建a标签
            link.href = url;
            link.download = '安装审核.xls'; //重命名文件
            link.click();
            URL.revokeObjectURL(url);
            console.log("下载文件" + res);
          } else {
            this.$message.error('暂无数据')
          }
          this.exportType = false
          this.exportTitle = '导出'
        })
      },
      getStatus(status) {
        let title = "-"
        switch (status) {
          case 0:
            title = '未审核'
            break;
          case 1:
            title = '已通过'
            break;
          case 2:
            title = '不通过'
            break;
          default:
            break;
        }
        return title
      },
    },
    created() {
      this.onRefer()
    }
  }
</script>
<style lang="scss" scoped>
  .searchBtn {
    margin-left: 15px;
  }

  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
