<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">小区名称</span>
          </span>
          <el-input v-model="formInline.name" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">所属区域</span>
          </span>
          <el-cascader :props="props" :options="$mapCode" v-model="formInline.areaCodeArr" style="width: 100%"
            collapse-tags clearable>
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">物业公司</span>
          </span>
          <el-select v-model="formInline.propertyUnitName" filterable placeholder="请输入" clearable>
            <el-option v-for="(item, index) in orgUnitList" :key="index" :label="item.name" :value="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div style="display: flex">
        <el-button v-focus type="warning" @click="dowMode(13)" size="small">下载模版</el-button>
        <el-button v-focus type="success" @click="addDj" size="small">新增</el-button>
        <form v-focus name="form1" style="width: 56px;margin: 0 10px;">
          <input type="file" id="docxFile" @change="readFile($event)" style="position:absolute; clip:rect(0 0 0 0);">
          <el-button type="primary" @click="toLead" size="small">导入</el-button>
        </form>
      </div>
    </div>
    <div v-if="dictionarTotal != 0">
      <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" @selection-change="handleSelectionChange"
        style="width: 100%; margin-bottom: 20px" :height="$store.state.tabHeight" @sort-change="handleChange">
        <el-table-column type="selection" width="55px" />
        <!-- <el-table-column type="index" label="序号" width="50px" /> -->
        <el-table-column align="center" prop="name" sortable="custom" label="小区名称" />
        <el-table-column align="center" prop="eleNum" label="关联电梯数" />
        <el-table-column align="center" prop="mergerName" label="所属区域">
        </el-table-column>
        <el-table-column align="center" prop="address" label="详细地址" />
        <el-table-column align="center" prop="propertyUnitName" label="物业公司" />
        <el-table-column align="center" prop="towerNum" label="楼栋总数" />
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isUse" :active-value="0" :inactive-value="1" active-color="#409eff"
              inactive-color="#909399" @change="isSealedChange(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createUserName" label="创建用户" />
        <el-table-column fixed="right" align="center" label="操作" width="350px">
          <template slot-scope="scope">
            <el-button v-focus v-if="!$store.state.isWBJB" @click="selRela(scope.row)" type="primary"
              size="small">电梯列表</el-button>
            <el-button v-focus v-else @click="selRela(scope.row)" type="primary" size="small">电梯列表</el-button>
            <el-button v-focus @click="upd(scope.row)" type="primary" size="small">编辑</el-button>
            <!-- <el-button
              v-focus
              @click="del([scope.row.id])"
              type="danger"
              size="small"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>
    <!-- <el-dialog v-if="dialogVisible" :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible"
      width="950px" top="20vh">
      <div style="height: 30vh; overflow-y: scroll" class="scrollElement">
        <el-form :rules="rules" ref="ruleForm" label-position="right" label-width="120px" :model="fign">
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="所属区域:" prop="areaCodeArr">
                <el-cascader :props="prps" :options="$mapCode" v-model="fign.areaCodeArr" class="einp" collapse-tags
                  clearable @change="handleActiveItemChange">
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="小区名称:" prop="name">
                <el-input v-model="fign.name" placeholder="例如:幸福花园。请勿包含省市区内容" class="einp">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="物业单位:" prop="propertyUnitName">
                <el-input disabled :placeholder="fign.propertyUnitName || '暂无内容'" v-model="fign.propertyUnitName"
                  class="wid90">
                  <el-button slot="append" type="primary" @click="selectUnit()">选择单位</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电梯安全员:" prop="userIdList">
                <el-select class="wid90" v-model="fign.userIdList" clearable :disabled="propertyListShow" multiple
                  placeholder="请输入" @change="changePower">
                  <el-option v-for="item in propertyList" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="楼栋总数:" prop="towerNum">
                <el-input v-model="fign.towerNum" placeholder="请输入" class="einp">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="详细地址:" prop="address">
                <el-input v-model="fign.address" placeholder="请输入" class="einp">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="状态:">
                <el-select class="wid90" v-model="fign.isUse" placeholder="请输入" @change="changeLogin">
                  <el-option :key="0" label="启用" :value="0" />
                  <el-option :key="1" label="不启用" :value="1" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="!$store.state.isWBJB">
              <el-form-item label="广告合同有效期:">
                <el-date-picker class="wid90" v-model="fign.allTime" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" @input="agreeChange" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" v-if="!$store.state.isWBJB">
              <el-form-item label="信息合同:">
                <el-select class="wid90" v-model="fign.eletricFinanceId" clearable placeholder="请输入">
                  <el-option v-for="item in contributorData" :key="item.value" :label="item.label"
                    :value="Number(item.value)">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="!$store.state.isWBJB">
              <el-form-item label="关联物业品牌">
                <el-select v-model="fign.brand" filterable clearable placeholder="请选择" class="einp">
                  <el-option v-for="item in brandList" :key="item.id" :label="item.brand" :value="item.uniqueCode">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="广告投放合同:">
                <FileUpload :md5s.sync="fign.imgUrl" :multiple="false" :limit="1" :url="url" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="dioSub">确 定</el-button>
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog> -->

    <!-- <ChangeUnit v-if="unitVisible" ref="changeUnit" :unitInfo="unitInfo" @getUnitInfo="getUnitInfo" /> -->
    <!-- 小区管理新增 -->
    <Add v-if="dialogVisible" :tit="tit" @close="AddClose()" :fignInit="fignInit" :propForm="fign" @update="update()" />

    <!-- 设备列表 -->
    <DeviceList v-if="deviceListVisible" :buttoneShow="true" ref="deviceList" :quartersInfo="quartersInfo" @closeVisible="closeVisible" />
    <!-- 错误信息列表 -->
    <errorDio v-if="errorList != null" :errorLists="errorList" @dioClose="dioCloseB"></errorDio>
  </div>
</template>
<script>
  const pageType = "ELE_PLOT"
  import errorDio from "@/components/errorDio.vue";
  import DeviceList from "./components/deviceList";
  // import ChangeUnit from "./components/changeUnit";
  import Pagination from "@/components/Pagination";
  // import FileUpload from '@/components/FileUploadPWP'
  import Add from "./components/Add";
  export default {
    components: {
      errorDio,
      // ChangeUnit,
      Pagination,
      DeviceList,
      // FileUpload,
      Add
    },
    data() {
      let _this = this;
      return {
        orgUnitList: [], // 物业小区列表
        // 错误信息列表
        errorList: null,
        // mapdate:[],
        // 地区选择器
        // prps: {
        //   label: "name",
        //   value: "areaCode",
        //   checkStrictly: true,
        //   children: 'children',
        //   disabled: 'disabled',
        //   // disabled: this.cascadeDisabled,
        // },
        props: {
          label: "name",
          value: "areaCode",
          checkStrictly: true,
          children: 'children',
          disabled: false,
          // disabled: this.cascadeDisabled,
        },
        // 表格参数
        delData: [], //多选的数据
        formInline: {
          current: 1,
          size: 10,
        },
        tableData: [],
        // 分页参数
        dictionarTotal: 0,
        // dialog参数
        tit: "",
        dialogVisible: false,
        fign: {},
        firstDate: {},
        fignInit: {},
        unitVisible: false,
        unitInfo: {}, // 物业信息
        propertyList: [], // 物业人员
        propertyListShow: true,
        // 表单限制
        // rules: {
        //   name: [{
        //     required: true,
        //     validator: validateName,
        //     trigger: "blur",
        //   }, ],
        //   propertyUnitName: [{
        //     required: true,
        //     validator: (rule, value, callback) => {
        //       if (!value) {
        //         callback(new Error('内容不能为空'));
        //       } else {
        //         callback()
        //       }
        //     },
        //     trigger: "blur",
        //   }, ],
        //   address: [{
        //     validator: validateaddress,
        //     trigger: "blur",
        //   }, ],
        //   towerNum: [{
        //     validator: validatephone,
        //     trigger: "blur",
        //   }, ],
        //   areaCodeArr: [{
        //     required: true,
        //     message: "请选择所属区域",
        //     trigger: "change",
        //   }, ],
        //   userIdList: [{
        //     required: true,
        //     message: "请选择电梯安全员",
        //     trigger: "blur",
        //   }, ],
        // },

        // 添加人员
        PersonDialog: false,
        useType: [],
        personnelForm: {
          unitName: "",
        },
        brandList: [],

        // 设备列表
        quartersInfo: {}, // 小区信息
        deviceListVisible: false,
        // url: '/api/file/web/uploadByOSS', // 上传地址
        contributorData: [], // 电费出资
        tableLoading: true
      };
    },
    // computed: {
    //   cascadeDisabled() {
    //     return (node, data) => {
    //       console.log(node, data,'')
    //       // 禁用第1级和第2级
    //       return data && data.level === 1 || data && data.level === 2;
    //     };
    //   },
    // },
    beforeDestroy() {},
    methods: {
      // 下载模版
      dowMode(type) {
        downloadMode(type)
      },
      // 导入
      toLead() {
        document.form1.docxFile.click()
      },
      readFile(file) {
        let _this = this;
        let docxFile = file.target.files[0];
        if (docxFile == undefined) {
          return
        }
        let hasFile = false;
        let fileName = docxFile.name;
        let fileArr = file.target.files[0].name.split(".");
        let files = docxFile;
        if (!file) {
          this.$message("请先选择正确的文件类型上传");
        } else {
          let fileData = new FormData();
          fileData.append("file", files);
          fileData.append("type", files.type);
          let config = {
            onUploadProgress: (e) => {
              //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
              //如果lengthComputable为false，就获取不到e.total和e.loaded
              if (e.lengthComputable) {
                let rate = e.loaded / e.total; //已上传的比例
                if (rate < 1) {
                  //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
                  //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
                  //等响应回来时，再将进度设为100%
                  let uploadRate = (rate * 100).toFixed(2) + "%";
                }
              }
            },
          };
          this.$http
            .post(`/api/ele/web/batch/saveData/${pageType}`, fileData, config)
            .then((res) => {
              if (res.data.success) {
                this.onRefer()
                this.$message.success(res.data.msg)
              } else {
                this.errorList = res.data.data
                this.$message.error(res.data.msg)
              }
              file.target.value = ''
            });
        }
      },
      // handleActiveItemChange() {
      //   // 在这里动态禁用级别1和级别2
      //   console.log(this.$mapCode, 'option11')
      //   this.$mapCode.forEach((option, index) => {
      //     // console.log(option, 'option')
      //     //   // 禁用第一级别的选项
      //     if (option.children && option.children.length > 0) {
      //       option.disabled = true;
      //     } else {
      //       option.disabled = false;
      //     }
      //     //   // 禁用第二级别的选项
      //     if (option.children) {
      //       option.children.forEach((childOption, childIndex) => {
      //         if (childOption.children && childOption.children.length > 0) {
      //           childOption.disabled = true;
      //         } else {
      //           childOption.disabled = false;
      //         }
      //         // if (childIndex === 0) {
      //         // childOption.disabled = true;
      //         // }
      //       });
      //     }
      //   });
      // },
      // getBrand() {
      //   const data = {
      //     current: 1,
      //     size: 9999,
      //   }
      //   this.$http.post(`/api/system/web/brand/getBrandPage`, data)
      //     .then((res) => {
      //       if (res.data.success) {
      //         const {
      //           data: {
      //             data
      //           },
      //         } = res
      //         this.brandList = data.records
      //       } else {
      //         this.$message.error(res.data.msg)
      //       }
      //     })
      // },
      //排序
      handleChange(column, prop, order) {
        console.log('column', column)
        if (column.prop) {
          this.formInline.common = column.order ? 'initial' : ''
          this.formInline.sort = column.order == 'descending' ? 'desc' : (column.order == 'ascending' ? 'asc' : '')
        } else {
          this.formInline.common = ''
          this.formInline.sort = ''
        }
        this.onRefer();
      },
      // // 获取字典
      // getNotice(code) {
      //   this.$http
      //     .post(`/api/system/web/dict/dictionary?codes=${code}`)
      //     .then((res) => {

      //       if (res.data.success) {
      //         const {
      //           data: {
      //             data
      //           },
      //         } = res
      //         this.contributorData = data.CONTRIBUTOR_TYPE
      //       } else {
      //         this.$message.error(res.data.msg)
      //       }
      //     })
      // },

      // 错误列表回调
      dioCloseB() {
        this.errorList = null;
      },
      // 状态改变
      isSealedChange(val) {
        const data = {
          id: val.id,
          address: val.address,
          name: val.name,
          areaCode: val.areaCode,
          areaCodes: val.areaCodes,
          isUse: val.isUse,
          towerNum: val.towerNum,
          propertyUnitId: val.propertyUnitId,
        };
        console.log(data, "data");
        this.$http.post("/api/ele/web/plot/submit", data).then((res) => {
          if (res.data.success) {
            this.onRefer();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },

      // // 物业单位列表
      // selectUnit() {
      //   this.unitVisible = true
      //   this.unitInfo.orgName = this.fign.propertyUnitName;
      //   this.$nextTick(() => {
      //     this.$refs.changeUnit.init()
      //   })
      // },
      // // 物业单位列表返回
      // getUnitInfo(data) {
      //   this.fign.propertyUnitId = data.orgId;
      //   this.$set(this.fign, "propertyUnitName", data.orgName);
      //   if (this.fignInit.propertyUnitName != data.orgName) {
      //     this.fign.userIdList = []
      //   }
      //   console.log(data, 'data')
      //   this.getPropertyData(data.orgId)
      //   this.unitVisible = false
      // },

      // getPropertyData(orgId) {
      //   const dataObj = {
      //     current: 1,
      //     size: 9999,
      //     orgId: orgId,
      //     type: "*TENEMENT_UNIT*"
      //   }
      //   this.$http.post("/api/system/web/appUser/list", dataObj).then((res) => {
      //     if (res.data.success) {
      //       const {
      //         data: {
      //           data
      //         }
      //       } = res;
      //       this.propertyList = data.records
      //       this.propertyListShow = false
      //     } else {
      //       this.$message.error(res.data.msg);
      //     }
      //   });
      // },

      // // 改变启用
      // changeLogin(val) {
      //   this.$set(this.fign, "isUse", val);
      // },
      // // 改变启用
      // changePower(val) {
      //   this.$set(this.fign, "userIdList", val);
      // },

      // agreeChange(val) {
      //   console.log(val, 'val');
      //   this.$forceUpdate()
      // },

      // 设备列表的方法
      selRela(row) {
        this.deviceListVisible = true;
        this.quartersInfo = row;
        this.$nextTick(() => {
          this.$refs.deviceList.init();
        });
      },
      closeVisible() {
        this.deviceListVisible = false;
        this.onRefer();
      },

      // 重置按钮事件
      reset() {
        console.log("重置");
        this.formInline = {
          current: 1,
          size: 10,
        };
        this.onRefer();
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page;
        this.formInline.size = data.limit;

        this.onRefer();
      },
      // 弹出框提交
      // dioSub() {
      //   this.$refs.ruleForm.validate((valid) => {
      //     if (valid) {
      //       const data = JSON.parse(JSON.stringify(this.fign));
      //       // if (this.fign.allTime) {
      //       data.contractStartTime = this.fign.allTime ? this.fign.allTime[0].getTime() : ''
      //       data.contractEndTime = this.fign.allTime ? this.fign.allTime[1].getTime() : ''
      //       // }
      //       data.eletricFinanceId = data.eletricFinanceId ? data.eletricFinanceId : ''
      //       data.towerNum = this.fign.towerNum ? this.fign.towerNum : 0
      //       let url = "/api/ele/web/plot/submit";
      //       if (data.areaCodeArr) {
      //         if (data.areaCodeArr.length > 0) {
      //           data.areaCodes = JSON.stringify(data.areaCodeArr);
      //           data.areaCode = data.areaCodeArr[data.areaCodeArr.length - 1];
      //         }
      //       }
      //       if ((data.name != this.firstDate.name || data.areaCodes != this.firstDate.areaCodes) && this.tit ==
      //         '修改') {
      //         this.$confirm('您正在修改小区所属区域或小区名称，修改成功后，系统将自动匹配修改该小区的电梯名称。是否继续？', '提示', {
      //           cancelButtonText: '取消',
      //           confirmButtonText: '确认',
      //           type: 'warning'
      //         }).then((res) => {
      //           this.$http.post(url, data).then((res) => {
      //             if (res.data.success) {
      //               this.onRefer();
      //               this.dialogVisible = false;
      //               this.$message.success(res.data.msg);
      //             } else {
      //               this.$message.error(res.data.msg);
      //             }
      //           });
      //         })
      //       } else {
      //         this.$http.post(url, data).then((res) => {
      //           if (res.data.success) {
      //             this.onRefer();
      //             this.dialogVisible = false;
      //             this.$message.success(res.data.msg);
      //           } else {
      //             this.$message.error(res.data.msg);
      //           }
      //         });
      //       }
      //     } else {
      //       this.$message.warning("请验证填写内容!");
      //       return false;
      //     }
      //   });
      // },
      AddClose() {
        this.dialogVisible = false
      },
      update() {
        this.dialogVisible = false
        this.onRefer()
      },
      changeName(val) {
        console.log(val, 'val');
      },

      getOrgUnit() {
        const data = {
          current: 1,
          size: 9999,
          type: "TENEMENT_UNIT",
        };
        this.$http.post("/api/system/web/org/list", data).then((res) => {
          console.log("列表", res.data);
          if (res.data.success) {
            this.orgUnitList = res.data.data.records;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },


      // 表格方法
      onRefer(ishm) {
        if (ishm) {
          this.formInline.current = 1;
        }
        if (this.formInline.areaCodeArr) {
          this.formInline.areaCodes = null;
          if (this.formInline.areaCodeArr.length > 0) {
            let obj = JSON.parse(JSON.stringify(this.formInline.areaCodeArr));
            this.formInline.areaCodes = obj[obj.length - 1];
          }
        }
        this.tableLoading = true
        this.$http.post("/api/ele/web/plot/list", this.formInline).then((res) => {
          // console.log("列表", res.data);
          if (res.data.success) {
            this.tableData = JSON.parse(JSON.stringify(res.data.data.records));
            this.dictionarTotal = res.data.data.total;
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg);
          }
          this.tableLoading = false
        });
      },

      handleSelectionChange(val) {
        this.delData = val;
      },
      // 修改
      upd(item) {
        this.firstDate = item
        this.$http
          .get(`/api/ele/web/plot/detail?id=${item.id}`)
          .then((res) => {
            if (res.data.success) {

              this.fign = JSON.parse(JSON.stringify(item));
              this.fignInit = JSON.parse(JSON.stringify(item));
              let codes = JSON.parse(this.fign.areaCodes);
              this.$set(this.fign, "areaCodeArr", codes);
              this.$set(this.fign, "userIdList", res.data.data.propertyUnitUserIds);

              if (this.fign.certificateStartTime != null) {
                let zt = [this.fign.certificateStartTime, this.fign.certificateEndTime];
                this.$set(this.fign, "zTime", zt);
              }
              if (this.fign.contractStartTime && this.fign.contractEndTime) {
                this.fign.allTime = [new Date(this.fign.contractStartTime), new Date(this.fign.contractEndTime)]
              }
              this.fign.eletricFinanceId = this.fign.eletricFinanceId && this.fign.eletricFinanceId != 0 ? this
                .fign
                .eletricFinanceId : ''
              // if (item.propertyUnitId) {
              //   console.log(item, 'item')
              //   this.getPropertyData(item.propertyUnitId)
              // }
              this.tit = "修改";
              this.dialogVisible = true;
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },

      del(item, isAll) {
        this.$confirm("此操作将永久删除选中的小区, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            this.$http
              .delete(`/api/ele/web/plot/remove?ids=${item.join(",")}`)
              .then((res) => {
                if (res.data.success) {
                  this.delData = [];
                  this.$refs.multipleTable.clearSelection();
                  this.onRefer();
                  this.$message.success(res.data.msg);
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            console.log(item);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      },
      addDj() {
        this.tit = "新增";
        this.fign = {
          isUse: 0,
          eletricFinanceId: 2
        };
        this.dialogVisible = true;
        // this.propertyList = []
        // this.propertyListShow = true
      },
    },
    created() {
      // this.mapdate = JSON.parse(JSON.stringify(this.$mapCode))
      this.getOrgUnit();
      this.onRefer();
      // this.getBrand()
      // this.handleActiveItemChange(null)
      // this.getNotice('CONTRIBUTOR_TYPE')
    },
  };
</script>
<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }

  .page-bom {}

  /* .wid90 {
    width: 90%;
  } */
</style>
