<template>
  <el-dialog
    :title="planFormData && planFormData.id ? '编辑维保计划' : '新增维保计划'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="addPlanDialogClose"
    append-to-body
    top="8vh"
    width="70%"
    center
    @close="addPlanDialogClose"
  >
    <div
      style="height: 65vh; overflow-y: scroll; padding-right: 20px"
      class="scrollElement"
    >
      <el-row>
        <!-- 日历 -->
        <el-col :span="12">
          <h3 style="margin: 10px 0;">维保日历</h3>
          <div>
            （请核对每个维保任务的类型和日期，点击维保类型可以修改类型，选择无则取消该项。点击空白日期可新增维保任务。编辑完成后可在右侧进行查看。）
          </div>
          <div class="grid-content bg-purple" style="margin-top: 55px">
            <FullCalendar
              class="calendar"
              ref="eventDialogue"
              style="height: 100%"
              :options="calendarOptions"
            />
            <!-- </el-form-item> -->
          </div>
        </el-col>
        <el-col :span="12">
          <div style="margin-left: 20px">
            <div class="planTitleBox">
              <h3 style="margin: 10px 0;">任务记录</h3>
              <el-button size="mini" type="primary" @click="checkConflict('check')" >检查冲突任务</el-button>
            </div>
            <div class="planTitleBox" style="margin: 10px 0">
              <div v-for="item in calendarEventsStyle.slice(1).sort((a, b) => a.sort - b.sort)" :key="item.value" class="planItemBox" :style="{'background': item.color}">
                {{ item.label }}({{item.num}}次)
              </div>
            </div>
            <div>
            <el-table
            ref="multipleTable"
            :data="calendarEvents"
            :height="500" style="width: 100%; margin-top: 20px; margin-bottom: 20px"
            @wheel.native="handleWheel"
            @sort-change="handleSort">
              <el-table-column align="center"  type="index" label="序号" width="50px" />
              <el-table-column align="center" label="维保计划日期" min-width="180px">
                <template slot-scope="scope">
                  <el-date-picker
                    style="width: 90%"
                    v-model="scope.row.start"
                    :clearable="false"
                    type="date"
                    format="yyyy / MM / dd"
                    placeholder="请选择首次维保日期"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    @change="changeStart(scope.row)"
                  />
                </template>
              </el-table-column>
              <el-table-column align="center" label="维保类型" min-width="120px" >
                <template slot-scope="scope">
                  <el-select
                      v-model="scope.row.title"
                      placeholder="请选择维保项"
                      filterable
                      @change="changeEvent(scope.row)"
                      style="width: 90%; margin: 0 15px">
                      <el-option v-for="item in eventChoose.slice(1)" :key="item.value" :label="item.name"
                        :value="item.name" />
                    </el-select>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="isConflict" sortable="custom" label="是否冲突" min-width="100px" >
                <template slot-scope="scope">
                  <span>{{ scope.row.isConflict == 1  ? '是' : '否'}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" fixed="right" prop="address" label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button @click="del(scope.row)" type="danger"
                    size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            </div>
          </div>
        </el-col>
      </el-row>

      <div :style="{left:sLeft+'px',top:sTop+'px'}" v-show="isCarte" class="contextmenu">
				<ul class="contextmenus">
					<li v-for="item in eventChoose" :key="item.value" @click="editEvent(item.sort)">
            {{ item.name }}
          </li>
				</ul>
			</div>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <div style="text-align: center;">
        <el-button
          type="info"
          @click="addPlanDialogClose"
        >上一步</el-button>
        <el-button
          type="info"
          @click="closePlan"
        >关&nbsp;&nbsp;闭</el-button>
        <el-button
          v-loading="loading"
          :disabled="loading"
          type="primary"
          @click="addPlanConfirm"
        >确认提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid' // 日期
import interactionPlugin from '@fullcalendar/interaction' // 日期点击事件
import { formatDate } from '@/util';


export default {
  components: {
    FullCalendar,
  },
  props: ['planFormData'],

  data() {
    return {
      year: '', //年份
      month: '', //月份
      loading: false,
      visible: true,
      calendarApi: null,
      timeList: [15, 30, 90, 180, 360],
      //选中日期
      eventChooseData: '',
      //事件下拉列表
      eventChoose: [],
       //日历事件样式
      calendarEventsStyle: [{
          label: '无',
          value: 0,
          color: '#f00',
          sort: 1,
          type: 0,
        }, {
          label: '半月保',
          value: 1,
          color: '#0dc160',
          sort: 2,
          type: 1,
        }, {
          label: '月度保',
          value: 2,
          color: '#63a103',
          sort: 3,
          type: 2,
        }, {
          label: '季度保',
          value: 3,
          color: '#02a7f0',
          sort: 4,
          type: 3,
        }, {
          label: '半年保',
          value: 4,
          color: '#8080ff',
          sort: 5,
          type: 5,
        }, {
          label: '年度保',
          value: 5,
          color: '#a36717',
          sort: 6,
          type: 4,
        }],
      planDetail: {
        1:{},
        2:{},
        3:{},
        4:{},
        5:{},
      },
      // 日历下拉参数
      sLeft: 0,
      sTop: 0,
      isCarte: false,
      eventsListTime: [],
      accordNum: 0, // 勾选了几个维保类型

      // 表格参数
      planForm: {},
      calendarEvents: [],
      // 日历参数
      calendarOptions: {
        height: 400,
        plugins: [dayGridPlugin, interactionPlugin],
        editable: false,
        selectable: true,
        navLinks: false,
        handleWindowResize: true, // 是否随窗口大小变化
        initialView: 'dayGridMonth', // 设置默认显示月，可选周、日


        // initialDate: moment().format('YYYY-MM-DD'),
        // select: this.handleDateSelect,

        timeZone: 'local',

        dateClick: this.handleDateClick, // 日期点击
        eventClick: this.handleEvents, // 事件点击
        events: this.getCalendarEvents, // 设置日程
        locale: 'zh', // 设置语言
        headerToolbar: {
          left: 'prevYear,prev,today',
          center: 'title',
          right: 'next,nextYear',
        },
        customButtons: {
          prevYear: {
            text: 'prevYear',
            click: () => {
              this.prevYear()
            },
          },
          prev: {
            text: 'prev',
            click: () => {
              this.prev()
            },
          },
          nextYear: {
            text: 'nextYear',
            click: () => {
              this.nextYear()
            },
          },
          next: {
            text: 'next',
            click: () => {
              this.next()
            },
          },
          today: {
            text: 'today',
            click: () => {
              this.todayClick()
            },
          },
        },
      },
    }
  },

  computed:{
      pickerOptions() {
          return {
              disabledDate:(time) =>{
                  let date= formatDate(time, 'yyyy-MM-dd')
                  return this.eventsListTime.includes(date) || time.getTime() < new Date().getTime()
              }
          }
      },
  },

  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.year = new Date()
      this.month = new Date()
      this.planForm = this.planFormData
      this.eventChoose = this.planFormData.maintenanItemData.filter(item => item.type)
      this.eventChoose.unshift({
        type: false,
        maintainItemId: '',
        num: '',
        name:'无',
        value: 0
      })
      this.getEvent()
    })
  },

  methods: {

    handleWheel(){
      const dropdowns = document.querySelectorAll('.el-select-dropdown');
      dropdowns.forEach((dropdown) => {
        if (dropdown.style.display !== 'none') {
          dropdown.style.display = 'none';
        }
      });
      const dropdowns1 = document.querySelectorAll('.el-date-picker');
      dropdowns1.forEach((dropdown) => {
        if (dropdown.style.display !== 'none') {
          dropdown.style.display = 'none';
        }
      });
    },

    // 回到今天
    todayClick() {
      this.$refs.eventDialogue.getApi().today()
    },
    // 前一年
    prevYear(event) {
      this.$refs.eventDialogue.getApi().prevYear()
    },
    // 前一个月
    prev(event) {
      this.$refs.eventDialogue.getApi().prev()
    },
    // 下一个月
    next(event) {
      this.$refs.eventDialogue.getApi().next()
    },
    // 下一年
    nextYear(event) {
      this.$refs.eventDialogue.getApi().nextYear()
    },


    // 初始化日历事件
    getCalendarEvents(info, successCallback, failureCallback) {
      const events = [...this.calendarEvents]
      successCallback(events)
    },

    // 将首次维保置前
    getNewMaintenanItemData(){
      let temporaryArry = []
      let data = JSON.parse(JSON.stringify(this.planFormData.maintenanItemData))
      data = data.sort((a, b) => b.sort - a.sort)
      //重新渲染数组
      data = temporaryArry.concat(data);
      data.map(item => {
        if (item.type) {
          this.accordNum++
        }
      })
      return data
    },

    //获取日历事件
    getEvent(){
      this.$refs.eventDialogue.getApi().gotoDate(this.planFormData.firstMaintainTime)
      let starTime = new Date(this.planFormData.firstMaintainTime).getTime()
      // let position = this.planFormData.isFirst == 5 ? 4 : (this.planFormData.isFirst == 4 ? 5 : this.planFormData.isFirst)
      this.calendarEvents.push({
        title: this.calendarEventsStyle[this.planFormData.isFirst].label,
        start: formatDate(starTime, 'yyyy-MM-dd'),
        allDay: true,
        choose: true,
        backgroundColor: this.calendarEventsStyle[this.planFormData.isFirst].color,
        maintenanItem: this.planFormData.isFirst,
        isConflict: 0
      })
      let newMaintenanItemData = this.getNewMaintenanItemData()

      newMaintenanItemData.map((item, ItemDataIndex) => {
        if (item.type) {
          for (let index = 0; index < (item.sort == this.planFormData.isFirst ? item.num - 1 : item.num); index++) {
            let time = this.planFormData.isFirst == 1 ? 15 : 0
            let ishalfMonth = this.planFormData.isFirst == 1 ? index + 1 : index
            let starTimeNew = formatDate(new Date(starTime + (-time + ishalfMonth * this.timeList[item.sort - 1]) * 24 * 3600 * 1000), 'yyyy-MM-dd')
            let isSplice = this.calendarEvents.find(eventsItem => eventsItem.start == starTimeNew)
            if (isSplice) {
                let newEvent = this.getNewEvent(item, starTimeNew)
                this.calendarEvents.push(newEvent)
            } else {
              this.calendarEvents.push({
                title: this.calendarEventsStyle[item.sort].label,
                start: starTimeNew,
                allDay: true,
                choose: true,
                backgroundColor: this.calendarEventsStyle[item.sort].color,
                maintenanItem: item.sort,
                isConflict: 0
              })
            }
          }
        }
      })
      this.calendarEvents = this.calendarEvents.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
      this.getNewEventnumber()
      this.$refs.eventDialogue.getApi().refetchEvents()
      this.checkConflict()
    },

    // 延后同时间事件
    getNewEvent(itemTypeInfo, timeNew){
      let starTimeBack = this.getNewStarTime(itemTypeInfo.sort, timeNew)
      let newEvent = {
        title: this.calendarEventsStyle[itemTypeInfo.sort].label,
        start: starTimeBack,
        allDay: true,
        choose: true,
        backgroundColor: this.calendarEventsStyle[itemTypeInfo.sort].color,
        maintenanItem: itemTypeInfo.sort,
        isConflict: 0
      }

      return newEvent
    },

    // 获取延后日期
    getNewStarTime(type, time){
      let newTime = ''
      if (this.calendarEvents.find(eventsItem => eventsItem.start == time)) {
        let dateTime = formatDate(new Date(new Date(time).getTime() + this.timeList[type - 1] * 24 * 3600 * 1000), 'yyyy-MM-dd')
        newTime = this.getNewStarTime(type, dateTime)
      } else {
        newTime = time
      }
      return newTime
    },

    changeStart(row){
      this.getNewEventnumber()
      this.$refs.eventDialogue.getApi().refetchEvents()
    },

    // 改变维保类型
    changeEvent(data){
      let type = this.eventChoose.find(item => item.name == data.title).sort
      this.eventChooseData = new Date(data.start)
      this.editEvent(type)
    },

    //列表删除事件
    del(row){
      this.eventChooseData = new Date(row.start)
      this.editEvent(0)
    },

    // 日历事件下拉
    openMenu(e, tag ,index) {
      this.isCarte = true
      this.sLeft = e.pageX
      this.sTop = e.pageY
    },

    // 计算维保次数
    getNewEventnumber(){
      this.eventsListTime = []
      this.calendarEventsStyle = this.calendarEventsStyle.map(item => {
        item.num = this.calendarEvents.filter(events => events.title == item.label).length
        return item
      })
      this.calendarEvents.map(item => {
        this.eventsListTime.push(item.start)
      })
    },

    // 修改事件
    editEvent(index){
      this.isCarte = false
      let eventTime = formatDate(this.eventChooseData, 'yyyy-MM-dd')
      let calendarIndex = this.calendarEvents.findIndex(item => item.start == eventTime)
      if (index > 0) {
        let newEvent = {
          title: this.calendarEventsStyle[index].label,
          start: eventTime,
          allDay: true,
          choose: true,
          backgroundColor: this.calendarEventsStyle[index].color,
          maintenanItem: this.calendarEventsStyle[index].value,
          isConflict: calendarIndex > -1 ? this.calendarEvents[calendarIndex].isConflict : 0
        }
        console.log('修改');
        if (calendarIndex > -1) {
          this.calendarEvents.splice(calendarIndex, 1, newEvent)
        } else {
          this.calendarEvents.push(newEvent)
        }
      } else {
        console.log('删除');
        this.calendarEvents.splice(calendarIndex, 1)
      }
      this.calendarEvents = this.calendarEvents.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
      this.getNewEventnumber()
      this.$refs.eventDialogue.getApi().refetchEvents()
    },

    dayRender(event){
      this.calendarEvents.map(item => {
          if(event.start.format('YYYY-MM-DD') == item.start) {
            element.addClass('highlight');
          }
        }
      )
      this.$refs.eventDialogue.getApi().refetchEvents()
    },

    // 日程事件点击
    handleEvents (info) {
      this.openMenu(info.jsEvent)
      this.eventChooseData = info.el.fcSeg.eventRange.range.start
      // this.currentEvents = events
    },
    // 日期点击
    handleDateClick (selectInfo) {
      if (selectInfo.date.getTime() < new Date(formatDate(new Date(), 'yyyy-MM-dd') + ' 23:59:59').getTime()) return
      this.openMenu(selectInfo.jsEvent)
      this.eventChooseData = selectInfo.dateStr
    },

    // 选择维保类型弹窗
    handleDateSelect(data) {
        this.calendarEvents.push({
          title: '维保项',
          start: data.start,
          allDay: true,
          choose: true,
        })
        this.$refs.eventDialogue.getApi().refetchEvents()
    },

    handleSort(column) {
      if (column.order == 'ascending') {
        this.calendarEvents = this.calendarEvents.sort((a, b) => a.isConflict - b.isConflict)
      } else{
        this.calendarEvents = this.calendarEvents.sort((a, b) => b.isConflict - a.isConflict)
      }
    },

    // 检查冲突任务
    checkConflict(type){
      if (this.calendarEvents.length == 0) {
        this.loading = false
        this.$message.error('请设置一个维保类型')
        return
      }
      
      const {id, planName, varieties, eleIds, firstMaintainTime, maintenanItemData } = this.planFormData
      // let data = ['2024-09-05', '2024-10-05']

      this.calendarEvents = this.calendarEvents.sort((a, b) => new Date(`${a.start} 00:00:00`).getTime() - new Date(`${b.start} 00:00:00`).getTime())
      let taskList = []
      this.calendarEvents.map(item => {
        taskList.push({
          startTime: new Date(`${item.start} 00:00:00`).getTime()
        })
      })
      let data = {
        eleIds: this.planFormData.eleIds,
        taskList
      }
      data.id = id ?? ''
      this.$http
        .post('/api/ele/web/maintainPlan/checkConflict', data)
        .then((res) => {
          if (res.data.code === 200) {
            this.calendarEvents.map(item => {
              item.isConflict = 0
              item.backgroundColor = this.calendarEventsStyle.find(eventItem => eventItem.label == item.title).color
            })
            this.$refs.eventDialogue.getApi().refetchEvents()
            if (type == 'check') {
              this.$message.success('未发现冲突维保任务')
            }
            if (type == 'isConfirm') {
                this.loading = true
                let taskListUpdate = []
                this.calendarEvents.map(item => {
                  taskListUpdate.push({
                    maintainType: this.calendarEventsStyle.find(eventItem => eventItem.value == item.maintenanItem).type,
                    startTime: new Date(`${item.start} 00:00:00`).getTime()
                  })
                })
                let newMaintenanItemData = maintenanItemData.sort((a, b) => a.sort - b.sort)
                for (var key in this.planDetail) {
                  let keyNum = this.calendarEventsStyle.find(eventItem => eventItem.value == key).type
                  // 以字符串作为键名
                  this.planDetail[key] = {
                    maintainItemId: newMaintenanItemData[keyNum - 1].type ? newMaintenanItemData[keyNum - 1].maintainItemId : '',
                    num: newMaintenanItemData[keyNum - 1].type ? this.calendarEventsStyle[keyNum].num : 0,
                    isFirst: this.calendarEvents[0].maintenanItem == keyNum
                  }
                }
                const planData = {
                  planName,
                  eleIds,
                  varieties,
                  firstMaintainTime: taskListUpdate[0].startTime,
                  planDetail: JSON.stringify(this.planDetail),
                  taskList: taskListUpdate
                }
                let url = this.planFormData.id ? '/api/ele/web/maintainPlan/editNewPlan' : '/api/ele/web/maintainPlan/addNewPlan'
                planData.id = id ?? ''

                this.$http
                  .post(url, planData)
                  .then((res) => {
                    if (res.data.code === 200) {
                      this.loading = false
                      this.visible = false
                      this.$emit('nextPlanClose', 'success')
                      this.$message.success(res.data.msg)
                    } else {
                      this.loading = false
                      this.$message.error(res.data.msg)
                    }
                  })

            }
          } else {
            const {
              data: {
                data
              },
            } = res
            let conflictList = []
            this.calendarEvents.map(item => {
              if (data.find(dataItem => dataItem.split(' ')[0] == item.start)) {
                item.isConflict = 1
                conflictList.push(item.start)
                item.backgroundColor = '#f00'
              }
            })
            this.calendarEvents = this.calendarEvents.sort((a, b) => b.isConflict - a.isConflict)
            this.$refs.eventDialogue.getApi().refetchEvents()
            // if (type == 'check') {
            this.$message.error('当前计划与已有计划冲突，部分日期已存在维保任务')
            this.loading = false
            // } else {
            //   this.$message.error(res.data.msg)
            // }
          }
        })
    },


    // 保存计划
    async addPlanConfirm() {
      this.loading = true
      this.checkConflict('isConfirm')
    },



    addPlanDialogClose() {
      this.$confirm('返回上一步将不再保存当前页面修改，是否继续？','提示',{
        cancelButtonText:'取消',
        confirmButtonText:'确认',
        type:'warning'
      }).then(()=>{
        this.visible = false
        this.$emit('nextPlanClose')
      }).catch(()=>{
      })
    },
    closePlan(){
      this.visible = false
      this.$emit('nextPlanClose', 'success')
    }
  },
}
</script>

<style lang="scss" scoped>

.hidden-select-dropdown {
  display: none !important;
}
.grid-content{
  margin-top: 10px;
}
.contextmenu{
  height: auto
}
.addPlanForm {
  padding-right: 35px;
}
.planTitleBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.planItemBox{
  padding: 3px;
  color: #fff
}
.highlight {
  background-color: red;
}
/deep/.el-form-item {
  margin-bottom: 32px;
}
/deep/.el-input-group__prepend {
  width: 40%;
}
>>> .el-input-group__append {
  background: #409eff;
  color: #fff;
}
>>> .el-input-group__prepend {
  width: 25%;
  padding: 0px;
  background: #409eff;
  .el-select {
    margin: -1px;
    .el-input__inner {
      color: #fff !important;
    }
    ::-webkit-input-placeholder {
      color: #fff;
    }
  }
}
.datepicker {
  >>> .el-form-item__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
