<template>
  <div>
    <div v-if="!stockVisiable">
      <el-dialog :title="'安装审核'" :visible.sync="visible" append-to-body :close-on-click-modal="false" width="1400px"
        top="8vh" @close="closeVisiable">
        <div class="admin-project-myproject-container ">
          <el-row :gutter="20" class="searchBox scrollElement"
            style="height: 65vh;overflow-y: scroll;padding-bottom: 20px;">
            <!-- 监控 -->
            <el-col :span="12" style="border-radius: 6px;">
              <monitor v-if="visible" :eleInfo="eleInfo" style="width: 100%;height: 350px;" />
            </el-col>
            <!-- 曲线图 -->
            <el-col :span="12">
              <div style="width: 100%;height: 350px;">
                <curveLine v-if="visible" :curveInfo="eleInfo" />
              </div>
            </el-col>
            <!-- 信息 -->
            <el-col :span="12" style="margin-top: 20px;border-radius: 6px;">
              <el-descriptions class="margin-top" :column="2" border>
                <el-descriptions-item label="提交人">
                  <span>{{eleInfo.createUserName}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="提交时间">
                  <span>{{eleInfo.createTime}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="AI摄像头ID">
                  <span>{{eleInfo.deviceId}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="电梯名称">
                  <span>{{eleInfo.eleName}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="联系方式">
                  <span>{{eleInfo.contactWay}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="状态">
                  <span>{{eleStatus(eleInfo.status)}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="是否安装广告屏" :span="2">
                  <!-- <span>{{eleInfo.address}}</span> -->
                  <el-form :inline="true" :model="formInline">
                    <el-form-item style="margin-bottom: 0;">
                      <el-radio-group v-model="formInline.screenStatus" :disabled="eleInfo.status != 0">
                        <el-radio :label="1">已安装</el-radio>
                        <el-radio :label="0">未安装</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-form>
                </el-descriptions-item>
                <el-descriptions-item label="电梯安装位置" :span="2">
                  <span>{{eleInfo.address}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="设备安装地址" :span="2">
                  <span>{{eleInfo.installAddress}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="审核通过信息" :span="2">
                  <div v-if="eleInfo.status == 1">
                    <div>
                      <span>通过视频文件：{{showUrl && showUrl.split('/').pop()}}</span>
                      <el-button type="text" @click="showVideo" style="margin-left: 5px">查看</el-button>
                    </div>
                    <div>
                      阻梯检查：{{eleInfo.staggerCondition === 0 ? '正常阻梯' : (eleInfo.staggerCondition == 2 ? '非正常阻梯' : (eleInfo.staggerCondition == 1 ? '未安装' : '-'))}}
                    </div>
                    <div>
                      备注：{{eleInfo.remarks ? eleInfo.remarks : '-'}}
                    </div>
                  </div>
                  <div v-else>
                    -
                  </div>
                  <!-- <span>{{eleInfo.address}}</span> -->
                </el-descriptions-item>
              </el-descriptions>
            </el-col>
            <!-- 图片 -->
            <el-col :span="12" style="overflow-y: auto;height: 450px;">
              <div>
                <p>天线安装拍照{{imgList.antennaImg.length > 0 ? '' : ': 无'}}</p>
                <div v-if="imgList.antennaImg.length > 0 ">
                  <el-image v-for="(item,index) in imgList.antennaImg"
                    style="width: 115px; margin: 0px 10px 10px 0px; border-radius: 6px;" :src="item" :key="index"
                    :zoom-rate="1.2" :preview-src-list="imgList.antennaImg" fit="cover" />
                </div>
              </div>
              <div>
                <p>电源装配拍照{{imgList.powerImg.length > 0 ? '' : ': 无'}}</p>
                <div v-if="imgList.powerImg.length > 0 ">
                  <el-image v-for="(item,index) in imgList.powerImg"
                    style="width: 115px; margin: 0px 10px 10px 0px; border-radius: 6px;" :src="item" :key="index"
                    :zoom-rate="1.2" :preview-src-list="imgList.powerImg" fit="cover" />
                </div>
              </div>
              <div>
                <!-- <p>球机HDMI线连接处拍照{{imgList.hdmiImg.length > 0 ? '' : ': 无'}}</p>
                  <div v-if="imgList.hdmiImg.length > 0 "> -->
                <p>球机HDMI线连接处拍照{{eleInfo.hdmiImg ? '' : ': 无'}}</p>
                <div v-if="eleInfo.hdmiImg.length > 0">
                  <el-image v-for="(item,index) in imgList.hdmiImg"
                    style="width: 115px; margin: 0px 10px 10px 0px; border-radius: 6px;" :src="item" :key="index"
                    :zoom-rate="1.2" :preview-src-list="imgList.hdmiImg" fit="cover" />
                </div>
              </div>
              <div>
                <p>安装完位置拍照{{imgList.endingImg.length > 0 ? '' : ': 无'}}</p>
                <div v-if="imgList.endingImg.length > 0 ">
                  <el-image v-for="(item,index) in imgList.endingImg"
                    style="width: 115px; margin: 0px 10px 10px 0px; border-radius: 6px;" :src="item" :key="index"
                    :zoom-rate="1.2" :preview-src-list="imgList.endingImg" fit="cover" />
                </div>
              </div>
              <div>
                <p>广告屏拍照{{imgList.screenImg.length > 0 ? '' : ': 无'}}</p>
                <div v-if="imgList.screenImg.length > 0 ">
                  <el-image v-for="(item,index) in imgList.screenImg"
                    style="width: 115px; margin: 0px 10px 10px 0px; border-radius: 6px;" :src="item" :key="index"
                    :zoom-rate="1.2" :preview-src-list="imgList.screenImg" fit="cover" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer" v-if="eleInfo.status == 0">
          <el-button size="small" type="primary" @click="selectElevatorConfirm">通过</el-button>
          <el-button size="small" @click="disPass(eleInfo)">不通过</el-button>
        </span>
        <span v-else slot="footer" class="dialog-footer">
        </span>
      </el-dialog>
    </div>
    <div v-if="stockVisiable">
      <el-dialog :title="stokcTit" :visible.sync="stockVisiable" append-to-body :close-on-click-modal="false"
        :width="stokcTit == '设备安装审核通过' ? '800px' : '550px'">
        <div v-if="stokcTit == '设备安装审核通过'" style="height:25vh">
          <el-form label-width="150px" label-position="right" :model="subForm" ref="subForm" :rules="subrules">
            <el-form-item label="阻梯检查：" prop="staggerCondition">
              <el-radio-group v-model="subForm.staggerCondition" ref="radio" @input="changeRadio">
                <el-radio :label="0">可正常阻梯</el-radio>
                <el-radio :label="1">不可正常阻梯</el-radio>
                <el-radio :label="2">未安装阻梯</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input type="textarea" v-model="subForm.remarks" resize="none" :autosize="{ minRows: 4, maxRows: 4}"
                placeholder="请填写审核备注内容，不超过100字" maxlength="100" style="width:93%"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div v-else style="height:10vh;padding: 20px;">
          <el-input type="textarea" v-model="remarks" placeholder="请输入原因" maxlength="100" resize="none"
            :autosize="{ minRows: 4, maxRows: 4}" />
          <span style="font-size: 12px;">(输入长度不超过100字)</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" v-if="stokcTit == '设备安装审核通过'" @click="submit()">提交</el-button>
          <el-button size="small" type="primary" v-else @click="sure(remarks)">确认</el-button>
          <el-button size="small" @click="close">取消</el-button>
        </span>
      </el-dialog>
    </div>
    <el-dialog v-if="videoLook" title="查看视频" :visible.sync="videoLook" append-to-body :close-on-click-modal="false"
      width="800px" @close="videoLook = false">
      <div style="display: flex;align-items: center;justify-content: center; margin-top: 10px">
        <video style="width: 90%" v-show="showUrl" class="showVo" :src="showUrl" controls autoplay muted loop>
          <source :src="showUrl" type="video/mp4">
          您的浏览器不支持 video 标签。
        </video>
        <div v-show="!showUrl" style="display: flex;height: 288px;">
          <el-image fit="contain" :src="require(`@/assets/noVideo.png`)" style="height:283px;width:300px;margin:auto" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // import { getEleStatus } from '../../../../components/useEscape.js'
  import monitor from './monitor/index.vue'
  import curveLine from './curveLine.vue'
  export default {
    props: ['eleInfo', 'imgList'],
    components: {
      monitor,
      curveLine,
    },

    data() {
      return {
        subForm: {
          staggerCondition: 1
        },
        subrules: {
          staggerCondition: [{
            required: true,
            message: '请选择阻梯检查',
            trigger: 'change'
          }]
        },
        visible: false,
        loading: true,
        videoLook: false, //视频弹窗
        Tits: '', //标题
        showUrl: null, //视频地址
        stokcTit: '', //审核标题
        // imgList:[],
        remarks: '',
        stockVisiable: false,
        colseId: '',
        selectElevatorCallback: null,
        formInline: {
          screenStatus: 0
        }
      }
    },
    mounted() {
      this.getUrl()
    },
    methods: {
      changeRadio(){
        this.$refs.radio.$children.forEach((item) => {
          item.$refs.radio.removeAttribute("aria-hidden");
        });
      },
      getUrl() {
        this.$http.get(`/api/ele/web/installRecord/getInstallInfo?id=${this.eleInfo.id}`).then((res) => {
          if (res.data.code == 200) {
            this.showUrl = res.data.data.installVideo[0]?.videoUrl
          }
        })
      },
      init(callback) {
        this.selectElevatorCallback = callback
        this.visible = true
        this.loading = false
        this.formInline.screenStatus = this.eleInfo.screenStatus
      },
      showVideo(data) { //查看视频
        // this.Tits = '视频文件名称例如：2024-05-12/15-11-31.mp4'
        this.videoLook = true
      },
      eleStatus(value) {
        if (value && value == 1) {
          return '已通过'
        } else if (value && value == 2) {
          return '不通过'
        } else {
          return '未审核'
        }
      },

      selectElevatorListGet() {},

      selectElevatorConfirm() {
        let agentData = {
          act: 'ca_start_rtsp',
          device_id: this.eleInfo.deviceId
        }
        this.socketApi.sendSock(agentData, (e) => {
          if (e.status) {
            this.stockVisiable = true
            this.subForm = {
              staggerCondition: 1,
              remarks: ''
            }
            this.stokcTit = '设备安装审核通过'
          } else {
            this.$message({
              type: 'warning',
              message: '设备当前不在线，不可通过审核'
            })
          }
        })

      },
      submit() { //审核通过提交
        if (this.subForm.staggerCondition == 1) {
          this.$message.warning('选择不可正常阻梯无法进行提交')
        } else {
          this.$refs.subForm.validate((valid) => {
            if (valid) {}
          })
          const data = {
            id: this.eleInfo.id,
            status: 1,
            screenStatus: this.formInline.screenStatus,
            ...this.subForm
          }

          this.$http.post('/api/ele/web/installRecord/check', data)
            .then((res) => {
              if (res.data.success) {
                this.visible = false
                this.stockVisiable = false
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
                this.$emit('change')
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
        }
      },

      disPass() {
        this.stokcTit = '审核不通过原因'
        this.stockVisiable = true
        this.remarks = ''
        // this.visible = false
      },
      sure(item) {
        const data = {
          id: this.eleInfo.id,
          status: 2,
          remarks: item,
          screenStatus: this.formInline.screenStatus
        }
        if (item) {
          if (item.length <= 200) {

            this.$http.post('/api/ele/web/installRecord/check', data)
              .then((res) => {
                if (res.data.success) {
                  this.stockVisiable = false
                  this.visible = false
                  this.$message({
                    type: 'success',
                    message: res.data.msg
                  })
                  this.$emit('change')
                } else {
                  this.$message({
                    type: 'error',
                    message: res.data.msg
                  })
                }
              })
          } else {
            this.$message.error('输入长度大于200字')
          }
        } else {
          this.$message.error('请填写不通过的原因')
        }
      },
      close() {
        this.stockVisiable = false
        // this.$emit('change')
      },
      closeVisiable() {
        this.stockVisiable = false
        this.$emit('change')
      }
    }
  }
</script>
<style lang="scss" scoped>
  /deep/.el-radio {
    color: #fff;
  }

  /deep/.el-radio__input.is-disabled.is-checked .el-radio__inner {
    border-color: #409EFF;
    background: #409EFF;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #409EFF;
  }

  /deep/.el-textarea__inner {
    height: 350px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
</style>
<style scoped>
  .showVo {
    width: 100%;
    height: 285px;
    background: #00000070;
  }

  /deep/.el-textarea__inner {
    height: 350px;
  }

  /* .el-textarea__inner {
    height: 350px;
  } */
</style>
