<template>
  <div>
    <div class="dataList">
      <template v-if="dictionarData.length != 0">
        <el-table :data="dictionarData" v-loading="tableLoading" style="width: 100%; margin-top: 20px">
          <el-table-column prop="dictValue" label="报警文字" min-width="150px" align='center'/>
          <el-table-column prop="remark" label="再次识别阙值" min-width="200px" align='center'/>
          <el-table-column fixed="right" prop="dictKey" label="拼音列表" min-width="120px" align='center'/>
        </el-table>
        <!-- <Pagination
          :total="dictionarTotal"
          :page.sync="dictionarQuery.current"
          :limit.sync="dictionarQuery.size"
          @pagination="dictionarPage"
        /> -->
      </template>
      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>
  </div>
</template>

<script>
// import Pagination from '../../../components/Pagination'
export default {
  components: {
    // Pagination
  },
  data () {
    return {
      dictionaryForm: {
        dictValue: ''
      },
      search: false,
      dictionarData: [],
      dictionarTotal: 0,
      dictionarQuery: {
        current: 1,
        size: 10
      },
      childDictionary: {},
      tableLoading: true
    }
  },
  created () {
    this.getNotice('关键字', ['KEYWORD'])
  },
  methods: {
    // 词典分页
    dictionarPage (data) {
      this.dictionarQuery.current = data.page
      this.dictionarQuery.size = data.limit
      this.getDictionary()
    },
   // 获取字典数据
    getNotice (type, code) {
      this.$http
        .get(`/api/system/web/dict/dictionary?code=${code}`)
        .then((res) => {
          if (res.data.code === 200) {
            const {
              data: { data }
            } = res
            this.dictionarData = data
            this.tableLoading = false
          } else {
            this.$message.error(res.data.msg)
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchBox{
  /deep/.el-form-item__label{
    color: #00F6FF  !important;
    font-size: 14px;
  }
  .searchBtn{
    margin-left: 15px;
  }
}

</style>
