<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" ref="searchForm" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">救援识别码</span>
          </span>
          <el-input v-model="searchForm.rescueCode" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯注册代码</span>
          </span>
          <el-input v-model="searchForm.code" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item v-if="!$store.state.isWBJB">
          <span slot="label">
            <span class="lab-span">AI摄像头ID</span>
          </span>
          <el-input v-model="searchForm.deviceCode" placeholder="请输入" clearable />
        </el-form-item>

        <el-form-item v-if="!$store.state.isWBJB">
          <span slot="label">
            <span class="lab-span">识别状态</span>
          </span>
          <el-select v-model="searchForm.recognitionState" placeholder="请输入" clearable filterable>
            <el-option v-for="(item, index) in recongtion" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <!-- 测试专用 -->
        <el-form-item v-if="isShow && !$store.state.isWBJB">
          <span slot="label">
            <span class="lab-span">电梯数字终端ID</span>
          </span>
          <el-input v-model="searchForm.terminalId" placeholder="请输入" clearable />
        </el-form-item>
        <!-- 测试专用 -->

        <template v-if="moreSearch">
          <!-- <el-form-item>
						<span slot="label">
							<span class="lab-span">电梯数字终端ID</span>
						</span>
						<el-input v-model="searchForm.terminalId" placeholder="请输入" clearable />
					</el-form-item> -->
          <el-form-item v-if="isShow">
            <span slot="label">
              <span class="lab-span">所属小区</span>
            </span>
            <el-input v-model="searchForm.plotName" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item v-if="isShow">
            <span slot="label">
              <span class="lab-span">物业单位</span>
            </span>
            <el-select v-model="searchForm.propertyUnitId" placeholder="请输入" clearable filterable>
              <el-option v-for="(item, index) in propertyUnit" :key="index" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="isShow">
            <span slot="label">
              <span class="lab-span">维保单位</span>
            </span>
            <el-select v-model="searchForm.maintainUnitId" placeholder="请输入" clearable filterable>
              <el-option v-for="(item, index) in maintainUnit" :key="index" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="isShow">
            <span slot="label">
              <span class="lab-span">登记机关</span>
            </span>
            <el-select v-model="searchForm.registrationAuthorityId" placeholder="请输入" clearable filterable>
              <el-option v-for="(item, index) in registrationUnit" :key="index" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="isShow">
            <span slot="label">
              <span class="lab-span">维保人员</span>
            </span>
            <el-input v-model="searchForm.maintainUserNames" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item v-if="isShow">
            <span slot="label">
              <span class="lab-span">电梯安装位置</span>
            </span>
            <el-input v-model="searchForm.eleAddress" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">创建人名称</span>
            </span>
            <el-input v-model="searchForm.createUserName" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item v-if="!$store.state.isWBJB">
            <span slot="label">
              <span class="lab-span">SD卡剩余容量</span>
            </span>
            <el-input v-model="searchForm.sdMin" placeholder="请输入最小值" clearable style="width:200px" />
            <span class="lab-span" style="margin:0 15px">至</span>
            <el-input v-model="searchForm.sdMax" placeholder="请输入最大值" clearable style="width:200px" />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">添加时间</span>
            </span>
            <el-date-picker v-model="searchForm.createTime" type="daterange" clearable range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">省市区</span>
            </span>
            <el-cascader :props="propCode" :options="$mapCode" v-model="searchForm.areaCodeArr" style="width: 100%;"
              collapse-tags clearable>
            </el-cascader>
          </el-form-item>
          <el-form-item v-if="!$store.state.isWBJB">
            <span slot="label">
              <span class="lab-span">楼层学习</span>
            </span>
            <el-select v-model="searchForm.isInit" clearable>
              <el-option :value="0" label="未完成"></el-option>
              <el-option :value="1" label="已完成"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="!$store.state.isWBJB">
            <span slot="label">
              <span class="lab-span">阻梯</span>
            </span>
            <el-select v-model="searchForm.haveBanClosed" clearable>
              <el-option :value="0" label="关闭"></el-option>
              <el-option :value="1" label="开启"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="!$store.state.isWBJB">
            <span slot="label">
              <span class="lab-span">信号检测终端ID</span>
            </span>
            <el-input placeholder="请输入" v-model="searchForm.signalCode" clearable />
          </el-form-item>
        </template>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
          <el-button type="success" v-focus v-if="!$store.state.isWBJB" :disabled="exportType"
            @click="exportData()">{{exportTitle}}</el-button>
          <el-button type="success" v-focus v-if="!$store.state.isWBJB" @click="exportList()">导出记录</el-button>
          <el-button v-if="!moreSearch" type="success" class="searchBtn" @click="moreBtn">更多条件</el-button>
          <el-button v-else type="success" class="searchBtn" @click="packMore">收起更多</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button v-focus type="success" size="small" @click="addDj">新增</el-button>
      <el-button v-focus type="danger" size="small" @click="editAll">批量修改</el-button>
      <el-button v-focus type="danger" size="small" @click="delAll">批量删除</el-button>
      <!-- <el-button v-focus v-if="isShow" type="warning" @click="dowMode(9)" size="small">下载模版</el-button> -->
      <el-dropdown @command="handleToLead">
        <el-button size="small" type="primary" style="margin-left: 10px" v-focus>导入<i
            class="el-icon-arrow-down el-icon--right"></i></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="{title: 'toLead'}">新增导入</el-dropdown-item>
          <el-dropdown-item :command="{title: 'updataLead'}">更新导入</el-dropdown-item>
          <el-dropdown-item v-if="isShow" :command="{title: 'dowMode'}">下载模版</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <form v-focus v-if="isShow" name="form1" style="width: 56px; margin: 0 10px; display: inline-block">
        <input type="file" id="docxFile"
          @change="readFile($event, !$store.state.isWBJB ? 'ELEVATOR_INFO' :'EASY_ELEVATOR_INFO')"
          style="position: absolute; clip: rect(0 0 0 0)" />
        <!-- <el-button type="primary" @click="toLead" size="small">导入</el-button> -->
      </form>
      <form v-focus v-if="isShow" name="form2" style="width: 56px; margin: 0 10px; display: inline-block">
        <input type="file" id="docxFile"
          @change="readFile($event, !$store.state.isWBJB ? 'ELEVATOR_UPDATE_INFO' :'EASY_ELEVATOR_UPDATE_INFO')"
          style="position: absolute; clip: rect(0 0 0 0)" />
        <!-- <el-button type="primary" @click="toLead" size="small">更新导入</el-button> -->
      </form>
      <div v-if="dictionarTotal != 0">
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" id="myTable"
          @selection-change="handleSelectionChange" style="width: 100%; margin-top: 20px; margin-bottom: 20px"
          max-height="550" @sort-change="handleChange" :height="$store.state.tabHeight">
          <el-table-column type="selection" width="55px"> </el-table-column>
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="name" sortable="custom" label="电梯名称" width="200px" />
          <el-table-column align="center" prop="code" label="电梯注册代码" width="150px" />
          <el-table-column align="center" prop="rescueCode" label="救援识别码" width="150px" />
          <el-table-column v-if="!$store.state.isWBJB" align="center" prop="deviceCode" label="AI摄像头ID" width="150px" />
          <el-table-column v-if="isShow && !$store.state.isWBJB" align="center" prop="terminalId" label="电梯数字终端ID"
            width="150px" />
          <el-table-column v-if="isShow && !$store.state.isWBJB" align="center" prop="streetUnitName" label="街道单位"
            width="150px" />
          <!-- <el-table-column
            align="center"
            prop="mergerName"
            label="所属区域"
            min-width="150"
          /> -->

          <el-table-column v-if="isShow" align='center' label="所属小区" width="180px">
            <template slot-scope="scope">
              {{scope.row.plotName ? scope.row.plotName : '-'}}
            </template>
          </el-table-column>
          <el-table-column v-if="isShow && !$store.state.isWBJB" align="center" prop="elePlace" label="使用场所"
            width="180px" />
          <el-table-column v-if="isShow" align="center" prop="propertyUnitName" label="物业单位" width="180px" />
          <el-table-column v-if="isShow" align="center" label="电梯安全员" width="200px">
            <template slot-scope="scope">
              {{ scope.row.propertyUnitUserNames ? scope.row.propertyUnitUserNames.split(';').slice(0,2).join() : '-' }}
            </template>
          </el-table-column>
          <el-table-column v-if="!$store.state.isWBJB" align="center" label="SD卡总容量(M)" width="120px">
            <template slot-scope="scope">
              {{
							scope.row.sdTotalSize ? scope.row.sdTotalSize : '-'
						}}
            </template>
          </el-table-column>
          <el-table-column v-if="!$store.state.isWBJB" align="center" prop="areaPidName" label="SD卡剩余容量(M)"
            width="150px">
            <template slot-scope="scope">
              {{
								scope.row.activeTime ? (scope.row.sdAvailableSize || scope.row.sdAvailableSize === 0 ? scope.row.sdAvailableSize : 'SD卡已损坏') : '-'
							}}
            </template>
          </el-table-column>
          <el-table-column v-if="isShow" align="center" prop="maintainUnitName" label="维保单位" width="180px" />
          <el-table-column v-if="isShow" align="center" label="维保人员" width="200px">
            <template slot-scope="scope">
              {{ scope.row.maintainUserNames ? scope.row.maintainUserNames.split(';').slice(0,2).join() : '-' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="registrationAuthorityName" label="登记机关" width="180px" />
          <el-table-column v-if="isShow && !$store.state.isWBJB" align="center" prop="insuranceUnitName" label="保险单位"
            width="180px" />
          <el-table-column v-if="isShow && !$store.state.isWBJB" align="center" label="保险人员" width="200px">
            <template slot-scope="scope">
              {{ scope.row.insuranceUnitUserNames ? scope.row.insuranceUnitUserNames.split(';').slice(0,2).join() : '-' }}
            </template>
          </el-table-column>
          <el-table-column v-if="!$store.state.isWBJB" align="center" label="楼层学习" width="200px">
            <template slot-scope="scope">
              {{
								scope.row.isInit ? '已完成' : '未完成'
							}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="eleAddress" label="电梯安装位置" width="200px" />
          <!-- <el-table-column align="center" prop="sdAvailableSize" label="SD卡剩余容量" width="200px" /> -->
          <el-table-column align="center" prop="createTime" label="添加时间" width="200px" />
          <el-table-column align="center" prop="createUserName" label="创建人" width="120px" />
          <el-table-column v-if="!$store.state.isWBJB" align="center" label="识别状态" width="120px">
            <template slot-scope="scope">
              {{
								scope.row.recognitionState ?
									(recongtion.find(item => item.value == scope.row.recognitionState) ?
										recongtion.find(item => item.value == scope.row.recognitionState).label : '-')
								: '-'
							}}
            </template>
          </el-table-column>
          <el-table-column v-if="!$store.state.isWBJB" align="center" label="阻梯" width="120px">
            <template slot-scope="scope">
              {{
								scope.row.haveBanClosed ? '开启' : (scope.row.haveBanClosed == 0 ? '关闭' : '无')
							}}
            </template>
          </el-table-column>
          <el-table-column v-if="!$store.state.isWBJB" align="center" prop="signalCode" label="信号检测终端ID"
            width="120px" />
          <el-table-column fixed="right" align="center" prop="address" label="操作" width="200px">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand">
                <el-button type="primary">
                  选项<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isShow&&!$store.state.isWBJB"
                    :command="{data: scope.row, title: 'look'}">部件信息</el-dropdown-item>
                  <el-dropdown-item v-if="isShow" :command="{data: scope.row, title: 'print'}">打印电梯标志</el-dropdown-item>
                  <el-dropdown-item v-focus :command="{data: scope.row, title: 'check'}">查看</el-dropdown-item>
                  <el-dropdown-item v-focus v-if="isShow"
                    :command="{data: scope.row, title: 'terminalSetting'}">数字终端</el-dropdown-item>
                  <el-dropdown-item v-focus v-if="isShow"
                    :command="{data: scope.row, title: 'changePerson'}">人员</el-dropdown-item>
                  <el-dropdown-item v-focus :command="{data: scope.row, title: 'FloorRecord'}">楼层记录</el-dropdown-item>
                  <el-dropdown-item v-focus v-if="scope.row.deviceCode"
                    :command="{data: scope.row, title: 'PauseAlarm'}">暂停困人报警</el-dropdown-item>
                  <el-dropdown-item v-focus :command="{data: scope.row, title: 'signal'}">信号检测终端</el-dropdown-item>
                  <el-dropdown-item v-focus :command="{data: scope.row, title: 'upd'}">编辑电梯</el-dropdown-item>
                  <el-dropdown-item v-focus :command="{data: scope.row, title: 'del'}">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="dictionarTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          :pageSizes="[10, 20, 30, 50, 100]" @pagination="dictionarPage" />
      </div>
      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>
    <Runreport v-if="Listshow" @closeCheck="close" />
    <Terminal v-if="terminalVisible" :initData="initData" ref="terminalDialog" @terminalClose="terminalClose" />
    <Action v-if="dialogVisible" :initData="initData" ref="acitonDtzl" @closeActione="closeActione" @updata="updata" />
    <ChangePerson v-if="selectPersonVisible" :eleInfo="eleInfo" ref="changePerson" @closePerson="closePerson" />
    <FloorRecord v-if="floorRecordVisible" :eleInfo="eleInfo" ref="floorRecord" />
    <PauseAlarm v-if="PauseVisiable" ref="PauseAlarm" @DialogClose="DialogClose" :propForm="PauseForm" />
    <!-- 信息检测终端 -->
    <signal v-if="signalVisiable" ref="signal" @signalClose="signalClose" @update="signalUpdate"
      :propForm="signalForm" />
    <!-- 错误信息列表 -->
    <ErrorDio v-if="errorList != null" :errorLists="errorList" @dioClose="dioCloseB" />
    <BJ v-if="BJVisible" :initData="initData" :myVisible="BJVisible" @closeDialog="(value)=>{BJVisible=value}"></BJ>


    <BatchEdit v-if="batchEditVisible" ref="terminalDialog" :editAllData="editAllData"
      @batchEditClose="batchEditClose" />

  </div>
</template>
<script>
  var validateName = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{3,15}$/
    // true：必填  false：非必填
    inpYz(
      rule,
      value,
      callback,
      true,
      operation,
      '可输入中文，字母，数值，长度为3-15'
    )
  }
  var validatecertificateCode = (rule, value, callback) => {
    let operation = /^[a-zA-Z0-9]{3,14}$/
    inpYz(rule, value, callback, false, operation, '可输入字母，数值，长度为3-14')
  }
  var validateaddress = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,25}$/
    inpYz(
      rule,
      value,
      callback,
      true,
      operation,
      '可输入中文，字母，数值，长度为2-25'
    )
  }
  var validatecontact = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,6}$/
    inpYz(
      rule,
      value,
      callback,
      true,
      operation,
      '可输入中文，字母，数值，长度为2-6'
    )
  }
  var validatephone = (rule, value, callback) => {
    let operation = /^1\d{7,10}$/
    inpYz(rule, value, callback, true, operation, '手机号码长度为：8-11')
  }

  // 页面对应的type
  const pageType = 'ELEVATOR_INFO'
  import ErrorDio from '@/components/errorDio.vue'
  import Pagination from '@/components/Pagination'
  import FileUpload from '@/components/FileUpload'
  import Action from './components/action'
  import ChangePerson from './components/changePerson'
  import FloorRecord from './components/floorRecord'
  import BJ from '@/views/pjsc/dtbj/components/BJ.vue'
  import Terminal from './components/terminal.vue';
  import BatchEdit from './components/batchEdit.vue';
  import Runreport from './components/RunReport.vue';
  import PauseAlarm from './components/PauseAlarm.vue'
  import signal from './components/signal.vue'
  import {
    formatDate,
    getTimeSE
  } from "@/util";


  export default {
    components: {
      PauseAlarm,
      signal,
      ErrorDio,
      FileUpload,
      Pagination,
      Action,
      ChangePerson,
      FloorRecord,
      BJ,
      signal,
      Terminal,
      BatchEdit,
      Runreport
    },
    data() {
      let _this = this
      return {
        //信息检测
        signalVisiable: false,
        signalForm: null,
        //暂停困人报警
        PauseVisiable: false,
        PauseForm: null,

        Listshow: false,
        exportType: false,
        exportTitle: '导出',
        BJVisible: false,
        initData: {},
        // 错误信息列表
        errorList: null,
        eleId: '', //选中的电梯
        // 表格参数
        delData: [], //多选的数据
        searchForm: {
          current: 1,
          size: 10,
        },
        tableData: [],
        // 分页参数
        dictionarTotal: 0,
        // dialog参数
        tit: '',
        dialogVisible: false,
        fign: {},
        // 角色下拉框数据
        roleData: [],
        selectPersonVisible: false, // 选择人员弹框
        floorRecordVisible: false, // 楼层学习
        orgId: '', // 单位id
        unitName: '', // 单位name
        initIds: [], // 选择人员id集合
        eleInfo: {}, // 电梯资料
        maintainArr: [],
        // 表单限制
        rules: {
          name: [{
            required: true,
            validator: validateName,
            trigger: 'blur',
          }],
        },
        usePlace: [], // 使用场所

        moreSearch: false,

        propertyUnit: [], // 物业单位
        maintainUnit: [], //维保单位
        registrationUnit: [], //登记机关
        recongtion: [], //识别状态

        eleType: [],
        terminalVisible: false,

        propCode: {
          label: 'name',
          value: 'areaCode',
          checkStrictly: true
        },
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        isShow: false,

        batchEditVisible: false,
        editAllData: [],
        tableLoading: true,
      }
    },
    methods: {
      //导出记录
      exportList() {
        this.Listshow = true
      },
      close() {
        this.Listshow = false
      },
      async exportData(ishm) {
        this.searchForm.current = 1
        this.exportType = true
        this.$http.post("/api/ele/web/elevatorInfo/exportEleInfo", this.searchForm).then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg);
            this.onRefer()
          } else {
            this.$message.success(res.data.msg);
          }
          this.exportType = false
          // if (res.data.size > 0) {
          //     let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
          //     let url = URL.createObjectURL(blob);
          //     const link = document.createElement('a'); //创建a标签
          //     link.href = url;
          //     link.download = '电梯检验统计.xls'; //重命名文件
          //     link.click();
          //     URL.revokeObjectURL(url);
          // } else {
          //     this.$message.error('暂无数据')
          // }

          // this.exportTitle = '导出'
        })
      },

      look(data) {
        this.BJVisible = true
        this.initData = data
      },
      // 错误列表回调
      dioCloseB() {
        this.errorList = null
      },
      // 导入
      toLead() {
        document.form1.docxFile.click()
      },
      // 更新导入
      updataLead() {
        document.form2.docxFile.click()
      },
      readFile(file, type) {
        let _this = this
        let docxFile = file.target.files[0]
        if (docxFile == undefined) {
          return
        }
        let hasFile = false
        let fileName = docxFile.name
        let fileArr = file.target.files[0].name.split('.')
        let files = docxFile
        if (!file) {
          this.$message('请先选择正确的文件类型上传')
        } else {
          let fileData = new FormData()
          fileData.append('file', files)
          fileData.append('type', files.type)
          let config = {
            onUploadProgress: (e) => {
              //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
              //如果lengthComputable为false，就获取不到e.total和e.loaded
              if (e.lengthComputable) {
                let rate = e.loaded / e.total //已上传的比例
                if (rate < 1) {
                  //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
                  //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
                  //等响应回来时，再将进度设为100%
                  let uploadRate = (rate * 100).toFixed(2) + '%'
                }
              }
            },
          }
          this.$http
            .post(`/api/ele/web/batch/saveData/${type}`, fileData, config)
            .then((res) => {
              if (res.data.success) {
                this.onRefer()
                this.$message.success(res.data.msg)
              } else {
                this.errorList = res.data.data
                this.$message.error(res.data.msg)
              }
              file.target.value = ''
            })
        }
      },
      // 下载模版
      dowMode(type) {
        downloadMode(type)
      },
      // 重置按钮事件
      reset() {
        this.searchForm = {
          current: 1,
          size: 10,
        }
        this.onRefer()
      },
      moreBtn() {
        this.moreSearch = true
      },
      packMore() {
        this.moreSearch = false
      },
      // 分页
      dictionarPage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer()
      },
      // 弹出框提交
      dioSub() {

        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let data = JSON.parse(JSON.stringify(this.fign))
            let url = '/api/system/web/org/submit'

            if (data.zTime) {
              data.certificateStartTime = data.zTime[0]
              data.certificateEndTime = data.zTime[1]
            }
            if (data.areaCodeArr) {
              if (data.areaCodeArr.length > 0) {
                data.areaCodes = JSON.stringify(data.areaCodeArr)
                data.areaCode = data.areaCodeArr[data.areaCodeArr.length - 1]
              }
            }
            data.type = pageType

            this.$http.post(url, data).then((res) => {
              if (res.data.success) {
                this.onRefer()
                this.dialogVisible = false
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            this.$message.warning('请验证填写内容!')
            return false
          }
        })
      },

      // 表格方法
      onRefer(ishm, value) {
        this.isShow = !(this.adminInfo.roleId == '99')
        if (ishm) {
          this.searchForm.current = 1
        }
        if (Number(this.searchForm.sdMax) < Number(this.searchForm.sdMin)) {
          this.$message.error('SD卡剩余容量最小值不得高于最大值')
          return
        }
        if (this.searchForm.sdMax) {
          if (!this.searchForm.sdMin) {
            this.$message.error('请填写SD卡剩余容量最小值')
            return
          }
        }
        if (this.searchForm.sdMin) {
          if (!this.searchForm.sdMax) {
            this.$message.error('请填写SD卡剩余容量最大值')
            return
          }
        }
        this.tableLoading = true
        this.searchForm.areaCodes = this.searchForm.areaCodeArr && this.searchForm.areaCodeArr.length > 0 ? this
          .searchForm.areaCodeArr[this.searchForm.areaCodeArr.length - 1] : ''
        this.searchForm.startTime = this.searchForm.createTime ? new Date(this.searchForm.createTime[0]).getTime() : ''
        this.searchForm.endTime = this.searchForm.createTime ? new Date(this.searchForm.createTime[1]).getTime() + 24 *
          3600 * 1000 - 1000 : ''
        const data = JSON.parse(JSON.stringify(this.searchForm))
        delete data.createTime
        this.$http
          .post('/api/ele/web/elevatorInfo/list', data)
          .then((res) => {
            if (res.data.success) {
              this.tableData = res.data.data.records
              this.tableData.forEach(item => {
                item.elePlace = this.usePlace.find(row => row.value === item.elePlaceType)?.label
              })
              this.dictionarTotal = res.data.data.total
              if (value != 'edit') {
                this.$nextTick(() => {
                  if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                    this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                  }
                })
              }
            } else {
              this.$message.error(res.data.msg)
            }
            this.tableLoading = false
          })
      },
      //排序
      handleChange(column, prop, order) {
        if (column.prop) {
          this.searchForm.initial = column.order ? 'initial' : ''
          this.searchForm.sort = column.order == 'descending' ? 'desc' : (column.order == 'ascending' ? 'asc' : '')
        } else {
          this.searchForm.initial = ''
          this.searchForm.sort = ''
        }
        this.onRefer();
      },
      handleSelectionChange(val) {
        this.selectPersonVisible = false
        this.delData = val
      },
      closePerson() {
        this.selectPersonVisible = false
      },
      // 更换维保人员
      changePerson(item) {
        if (!item.maintainUnitId) {
          this.$message.warning('请先绑定维保单位')
          return
        }
        this.eleInfo = JSON.parse(JSON.stringify(item))

        this.eleInfo.elevatorType = item.eleType ? (this.eleType.find(item => item.value == this.eleInfo.eleType) ? this
          .eleType.find(item => item.value == this.eleInfo.eleType).label : '') : ''
        this.selectPersonVisible = true
        this.$nextTick(() => {
          this.$refs.changePerson.init(() => {
            this.onRefer()
          })
        })
      },

      // 查看
      check(item) {
        this.dialogVisible = true
        this.$http
          .get(`/api/ele/web/elevatorInfo/detail?id=${item.id}`)
          .then((res) => {
            if (res.data.success) {
              const data = res.data.data
              data.deviceSosSwitch = res.data.data.deviceSosSwitch ?
                JSON.parse(res.data.data.deviceSosSwitch) : []
              data.sosSwitchStatus = res.data.data.sosSwitchStatus ?
                JSON.parse(res.data.data.sosSwitchStatus) : []
              data.terminalSosSwitch = res.data.data.terminalSosSwitch ?
                JSON.parse(res.data.data.terminalSosSwitch) : []
              data.areaCodeArr = res.data.data.areaCodes ?
                JSON.parse(res.data.data.areaCodes) : []
              this.initData = data
              this.$nextTick(() => {
                this.$refs.acitonDtzl.init('check', data, () => {
                  this.onRefer()
                })
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      // 数字终端
      terminalSetting(data) {
        this.terminalVisible = true
        this.initData = data
        this.$nextTick(() => {
          this.$refs.terminalDialog.init(data, () => {})
        })
      },
      terminalClose() {
        this.terminalVisible = false
        this.onRefer()
      },
      closeActione(value) {
        this.dialogVisible = false

        // this.onRefer(false,value)
      },
      updata(value) {
        this.dialogVisible = false
        this.onRefer(false, value)
      },
      // 修改
      upd(item) {
        this.dialogVisible = true
        this.$http
          .get(`/api/ele/web/elevatorInfo/detail?id=${item.id}`)
          .then((res) => {
            if (res.data.success) {
              const data = JSON.parse(JSON.stringify(res.data.data))
              data.deviceSosSwitch = res.data.data.deviceSosSwitch ?
                JSON.parse(res.data.data.deviceSosSwitch) : []
              data.sosSwitchStatus = res.data.data.sosSwitchStatus ?
                JSON.parse(res.data.data.sosSwitchStatus) : []
              data.terminalSosSwitch = res.data.data.terminalSosSwitch ?
                JSON.parse(res.data.data.terminalSosSwitch) : []
              data.areaCodeArr = res.data.data.areaCodes ?
                JSON.parse(res.data.data.areaCodes) : []
              data.registrationDate = res.data.data.registrationDate ? new Date(res.data.data.registrationDate) : ''
              data.installTransformDate = res.data.data.installTransformDate ? new Date(res.data.data
                .installTransformDate) : ''
              data.inspectionDate = res.data.data.inspectionDate ? new Date(res.data.data.inspectionDate) : ''
              data.simStartTime = res.data.data.simStartTime ? new Date(res.data.data.simStartTime) : ''
              data.simEndTime = res.data.data.simEndTime ? new Date(res.data.data.simEndTime) : ''
              data.propertyUnitId = res.data.data.propertyUnitId ? res.data.data.propertyUnitId : ''
              this.initData = data
              this.$nextTick(() => {
                this.$refs.acitonDtzl.init('edit', data, () => {
                  this.onRefer()
                })
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 批量删除
      delAll() {
        if (this.delData.length > 0) {
          let arr = []
          this.delData.forEach((qs) => {
            arr.push(qs.id)
          })
          this.del(arr, 1)
        } else {
          this.$message.warning('请选择要删除的电梯')
        }
      },
      // 删除
      del(item, isAll) {
        this.$confirm('此操作将永久删除选中的电梯, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            let data = isAll == 1 ? item : item.id
            this.$http
              .delete(`/api/ele/web/elevatorInfo/remove?ids=${data}`)
              .then((res) => {
                if (res.data.success) {
                  this.onRefer()
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },

      editAll() {
        if (this.delData.length > 0) {
          let arr = []
          this.delData.forEach((qs) => {
            arr.push(qs.id)
          })
          this.batchEditVisible = true
          this.editAllData = arr
          // this.del(arr,1)
        } else {
          this.$message.warning('请选择要修改的电梯')
        }
      },
      batchEditClose() {
        this.batchEditVisible = false
        this.delData = []
        this.onRefer()
      },

      // 新增
      addDj() {
        this.dialogVisible = true
        this.title = ""
        this.initData = {}
        this.$nextTick(() => {
          this.$refs.acitonDtzl.init('add', '', () => {
            this.onRefer()
          })
        })
      },
      // 楼层记录
      FloorRecord(item) {
        this.floorRecordVisible = true
        this.eleInfo = item
        this.$nextTick(() => {
          this.$refs.floorRecord.init(() => {
            this.onRefer()
          })
        })
      },

      // 打印
      print(item) {
        window.open('#/elevatorSigns/' + item.id, '_blank');
      },

      // 下拉导入事件
      handleToLead(command) {
        switch (command.title) {
          case 'updataLead':
            this.updataLead()
            break
          case 'toLead':
            this.toLead()
            break
          case 'dowMode':
            if (this.$store.state.isWBJB) {
              this.dowMode(91)
            } else {
              this.dowMode(9)
            }
            break
          default:
            break
        }
      },

      // 下拉菜单事件
      handleCommand(command) {
        switch (command.title) {
          case 'look':
            this.look(command.data)
            break
          case 'terminalSetting':
            this.terminalSetting(command.data)
            break
          case 'check':
            this.check(command.data)
            break
          case 'changePerson':
            this.changePerson(command.data)
            break
          case 'FloorRecord':
            this.FloorRecord(command.data)
            break
          case 'PauseAlarm':
            this.PauseAlarm(command.data)
            break
          case 'signal':
            this.signal(command.data)
            break
          case 'upd':
            this.upd(command.data)
            break
          case 'del':
            this.del(command.data)
            break
          case 'print':
            this.print(command.data)
            break
          default:
            break
        }
      },
      signal(item) {
        this.signalVisiable = true
        this.signalForm = item
      },
      signalClose() {
        this.signalVisiable = false
      },
      signalUpdate() {
        this.signalVisiable = false
        this.onRefer()
      },
      PauseAlarm(data) {
        this.PauseVisiable = true
        this.PauseForm = data
      },
      DialogClose() {
        this.PauseVisiable = false
      },

      getUnit() {
        const data = {
          // current: 1,
          // size: 10000,
          type: 'TENEMENT_UNIT'
        }
        this.$http.post('/api/system/web/org/getRelevanceOrgList', data).then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              }
            } = res
            this.propertyUnit = data ?? []
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      getRegsit() {
        const data = {
          // current: 1,
          // size: 10000,
          type: 'REGISTER_UNIT'
        }
        this.$http.post('/api/system/web/org/getRelevanceOrgList', data).then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              }
            } = res
            this.registrationUnit = data ?? []
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      getMain() {
        const data = {
          // current: 1,
          // size: 10000,
          type: 'MAINTAIN_UNIT'
        }
        // /api/system/web/org/list
        this.$http.post('/api/system/web/org/getRelevanceOrgList', data).then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              }
            } = res
            this.maintainUnit = data ?? []
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 获取字典
      getNotice(code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.usePlace = data.USE_PLACE
              this.recongtion = data.RECOGNITION_STATE
              this.eleType = data.ELEVATOR_TYPE
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
    },
    created() {
      this.getNotice(["USE_PLACE", 'RECOGNITION_STATE', 'ELEVATOR_TYPE']);
      this.getUnit()
      this.getRegsit()
      this.getMain()
    },
    mounted() {
      this.onRefer()
    }
  }
</script>
<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contextmenuInfo {
    width: 100px;
    height: 50px;
    background: white;
    position: fixed;
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .contextmenus {
    margin: 0;
    padding: 0px;
    color: #333;
    font-size: 16px;
    line-height: 30px;
    width: 100%;
  }
</style>
