<template>
	<div style="display:flex;justify-content: space-between;">
		<div :style="{'width': rowNum != 5 ? '83%' : '80%'}">
			<div ref="row" style="height: 5%">
				<el-select v-model="rowNum" placeholder="请选择" @change="changeType" style="margin-left: 10px">
					<el-option v-for="item in screenArr" :key="item.value" :label="item.label" :value="item.value" />
				</el-select>
				<el-button type="primary" style="margin-left: 15px" @click="handleFullScreen">全屏</el-button>
			</div>
			<div ref="screenList" id="screenList"
				:style="[{'background': isFull ? '#1d2d4e' : ''}]"
				style="height: 95%;width: 100%;" class="playList">
					<div v-for="item in screenType" :key="item" class="screenBox"
						:class="rowNum == 3 ? 'padBigWidth' : 'padSmallWidth'"
						:style="[{'height': compHeight }, {'width': compWidth }]">
						<div class="playBox">
							<div v-if="showMonitorArr[item - 1] && showMonitorArr[item - 1].id"
								style="width: 100%; height: 100%; position: relative">
								<MonitorBox :eleInfo="showMonitorArr[item - 1]" :playNum="item" :screenType="screenType"
									@delMMonitor="delMMonitor" />
							</div>
							<div v-else class="defaultBox">
								<el-image fit="contain" :src="require('@/assets/Monitor1.png')" style="width: 50%" />
								<span class="eleStatusTips"
									:class="screenType > 4 ? (screenType > 9 ? 'eleStatusTipssmall' : 'eleStatusTipsmiddle') : 'eleStatusTipsbig'">点击右侧列表查看监控</span>
							</div>
						</div>
					</div>
			</div>
		</div>
		<div style="margin:0 10px" :style="{'width': rowNum != 5 ? '17%' : '20%'}">
			<div class="page-top">
				<el-form ref="searchForm" :model="searchForm" class="demo-form-inline">
					<el-form-item style="margin-bottom: 0px;display: flex;" class="rightFromTitle">
						<!-- <span slot="label">
                <span class="lab-span">电梯名称: </span>
              </span> -->
						<el-input class="search input-with-select" placeholder="请输入" clearable v-model="searchName"
							@keyup.enter.native="searchOnRefer">
							<el-select v-model="searchType" slot="prepend" placeholder="请选择" style="width:120px">
								<el-option label="电梯名称" :value="1"></el-option>
								<el-option label="AI摄像头ID" :value="2"></el-option>
							</el-select>
							<!-- <template slot="prepend">电梯名称</template> -->
							<el-button slot="append" icon="el-icon-search" @click="searchOnRefer"></el-button>
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<div>
				<template v-if="tableData != 0">
					<el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" :height="height" highlight-current-row
						style="width: 100%; margin-top: 18px; margin-bottom: 20px" :row-class-name="tableRowClassName"
						@row-click="addMonitor" v-load-table-more="infiniteScrollLoad">
						<el-table-column align="left" label="电梯列表 " min-width="200px">
							<template slot-scope="scope">
								<span class="overName" :title="scope.row.name ? scope.row.name : ''">
									电梯名称: {{ scope.row.name ? scope.row.name : "-" }}
								</span><br />
								<span>
									AI摄像头ID: {{ scope.row.deviceCode ? scope.row.deviceCode : "" }}
								</span>
							</template>
						</el-table-column>
					</el-table>
					<el-button v-if="isMore" slot="append" type="primary" :loading="isMore" style="width:100%"
						@click="infiniteScrollLoad()">加载中</el-button>
				</template>

				<div class="nullDate" v-else v-loading="tableLoading">
      				<img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import MonitorBox from "./monitorBox.vue";
	export default {
		components: {
			MonitorBox
		},
		data() {
			return {
				// 定时器
				getOlineTime: null,
				// 表格参数
				searchForm: {
					current: 1,
					size: 15,
					status: 1,
				},
				screenType: 4,
				rowNum: 2,
				screenArr: [{
						label: "两行",
						value: 2,
					},
					{
						label: "三行",
						value: 3,
					},
					{
						label: "四行",
						value: 4,
					},
					{
						label: "五行",
						value: 5,
					},
				],
				showMonitorArr: [],
				tableData: [],
				// 分页参数
				maintenRuleTotal: 0,
				searchType: 1,
				searchName: '',

				isMore: false,
				isFull: false,
				height: '', // 监控列表高度
				width: '', // 监控列表宽度

				compHeight: '', // 监控高度
				compWidth: '', // 监控宽度
				colNum: '', // 列数

				proportion: ' ', // 比例



				adminInfo: JSON.parse(sessionStorage.getItem("adminInfo")),
				tableLoading: true
			};
		},
		computed: {},

		filters: {},
		methods: {
			//高亮
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (this.showMonitorArr.find(item => item.deviceCode == row.deviceCode)) {
					return 'highlight';
				}
				return '';
			},

			loadMoreData() {
			},
			//全屏方法
			handleFullScreen() {
				// 此处可根据获取节点进行区域全屏事件
				let element = document.getElementById('screenList');
				if (this.isFull) {
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.webkitCancelFullScreen) {
						document.webkitCancelFullScreen();
					} else if (document.mozCancelFullScreen) {
						document.mozCancelFullScreen();
					} else if (document.msExitFullscreen) {
						document.msExitFullscreen();
					}
				} else {
					if (element.requestFullscreen) {
						element.requestFullscreen();
					} else if (element.webkitRequestFullScreen) {
						element.webkitRequestFullScreen();
					} else if (element.mozRequestFullScreen) {
						element.mozRequestFullScreen();
					} else if (element.msRequestFullscreen) {
						// IE11
						element.msRequestFullscreen();
					}
				}
				this.isFull = !this.isFull;
			},

			//监听屏幕是否全屏
			fullScreenChangeFn(e) {
				if (this.$refs.screenList.offsetWidth < window.innerWidth) {
					this.isFull = false;
				} else {
					this.isFull = true;
				}
				this.widthComp()
			},
			// 搜索
			searchOnRefer() {
				this.isMore = false
				this.searchForm.current = 1
				this.onRefer();
			},
			widthComp() {
				if (this.isFull) {
					this.compHeight = window.innerHeight / this.rowNum - (this.rowNum * (this.rowNum == 5 ? 5 : (this.rowNum == 4 ? 15 : 10)))
					this.compWidth = this.compHeight / 0.75 + "px"
					if (Math.trunc(window.innerWidth / (this.compHeight / 0.75)) < this.colNum) {
						this.compWidth = window.innerWidth / this.colNum - (this.colNum * 10)
						this.compHeight = this.compWidth * 0.75 + "px"
						this.screenType = this.rowNum * this.colNum
						this.compWidth = this.compWidth + "px"
					} else {
						this.colNum = Math.trunc(window.innerWidth / (this.compHeight / 0.75 + 10))
						this.screenType = this.rowNum * this.colNum
						this.compHeight = this.compHeight + "px"
					}
					this.showMonitorArr = this.showMonitorArr.slice(0, this.screenType);
				} else {
					this.compHeight = this.height / this.rowNum - (this.rowNum != 5 ? 10 : 0)
					this.compWidth = this.compHeight / 0.75 + "px"
					this.colNum = Math.trunc(this.width / (this.compHeight / 0.75 + 10))
					this.screenType = this.rowNum * this.colNum
					this.compHeight = this.compHeight + "px"
					this.showMonitorArr = this.showMonitorArr.slice(0, this.screenType);
				}
			},

			// 改变多屏类型
			changeType() {
				this.widthComp()
			},
			// 加载更多
			infiniteScrollLoad() {
				if (this.isMore) return;
				if (this.searchForm.current * this.searchForm.size < this.maintenRuleTotal) {
					this.searchForm.current++;
					this.isMore = true
					this.onRefer();
				} else {
					this.isMore = false;
				}
			},
			// 加入监控
			addMonitor(row) {
				if (this.showMonitorArr.find((item) => item.id == row.id)) {
					this.$message.error("不可重复打开监控");
					return;
				}
				if (this.showMonitorArr.find((item) => !item.id)) {
					for (var index = 0; index < this.showMonitorArr.length; index++) {
						if (!this.showMonitorArr[index].id) {
							this.showMonitorArr.splice(index, 1, row);
							break;
						}
					}
					return;
				}
				if (this.showMonitorArr.length >= this.screenType) {
					this.$message.error("暂时无法打开更多的监控");
					return;
				}
				this.showMonitorArr.push(row);
				this.$forceUpdate();
			},
			// 移除监控
			delMMonitor(index) {
				this.showMonitorArr.splice(index - 1, 1, {});
			},

			// 获取监控列表方法
			onRefer() {
				if (this.searchType == 1) {
					this.searchForm.name = this.searchName
				} else {
					this.searchForm.deviceCode = this.searchName
				}
				this.tableLoading = true
				this.$http
					.post("/api/ele/web/elevatorInfo/runningList", this.searchForm)
					.then((res) => {
						if (res.data.success) {
							const {
								data: {
									data
								},
							} = res;
							if (this.isMore) {
								this.tableData =
									data.records.length > 0 ?
									[...this.tableData, ...data.records] :
									this.tableData;
								setTimeout(() =>
									this.isMore = false, 500)
							} else {
								this.tableData = data.records
							}
							this.maintenRuleTotal = data.total
						} else {
							this.$message.error(res.data.msg);
						}
						this.tableLoading = false
					});
			},
			// 分页
		},
		created() {
			this.proportion = window.innerHeight / window.innerWidth
		},
		mounted() {
			this.onRefer();
			// 阻止F11键默认事件，用HTML5全屏API代替
			window.addEventListener('keydown', this.fullScreenKeydown)
			window.addEventListener('resize', this.fullScreenChangeFn)

			this.$nextTick(() => {
				this.height = this.$refs.screenList.offsetHeight
				this.width = this.$refs.screenList.offsetWidth

				// window.innerHeight / window.innerWidth
				this.widthComp()
			})
		},
		destroyed() {
			clearInterval(this.getOlineTime);
			window.removeEventListener('resize', this.fullScreenKeydown)
			window.removeEventListener('resize', this.fullScreenChangeFn)
		},
	};
</script>
<style lang="scss" scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.playList {
		display: flex;
		flex-wrap: wrap;
		margin-top: 15px;
		// justify-content: space-around;
		justify-content: center;
		align-items: center;
		align-content: center;
	}

	.padSmallWidth {
		padding: 6px;
	}

	.padBigWidth {
		// padding: 3px 10px;
		padding: 6px;
	}

	.eleStatusTipssmall {
		margin-top: 10px;
		transform: scale(0.6)
	}

	.eleStatusTipsmiddle {
		margin-top: 15px;
		transform: scale(0.8)
	}

	.eleStatusTipsbig {
		margin-top: 15px;
		transform: scale(1)
	}

	.overName {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	::v-deep.search {

		.el-input-group__append,
		.el-input-group__prepend {
			background-color: #3c92ed;
			color: #fff;
		}
	}

	.searchBtn {
		margin-left: 15px;
	}

	.rightFromTitle {
		/deep/.el-form-item__label {
			width: 100px;
		}
	}
	/deep/.highlight {
		background-color: #265a6a !important;
	}

</style>
