<template>
  <el-dialog :title="'选择维保项'" :visible.sync="visible" append-to-body :close-on-click-modal="false" width="1400px"
    @close="selectunitDialogClose">
    <div class="admin-project-myproject-container">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="item in itemGroup" :key="item.value" :label="item.value" :name="item.label">
          <el-table ref="selectUnitList" :data="tableData" border stripe fit max-height="500px" highlight-current-row
            row-key="id" v-loading="loading" @select="handleSelectionChange" @select-all="selectAll">
            <el-table-column type="selection" reserve-selection width="55px" align="center" />
            <el-table-column label="序号" type="index" prop="index" width="70px" align="center"/>
            <el-table-column label="分类" width="150px" align="center">
              <template>
                {{itemGroup.find(item=> item.label == activeName).value}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="value" label="维保项描述" min-width="300" />
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="selectunitConfirm">确 定</el-button>
      <el-button size="small" @click="visible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    components: {},

    data() {
      return {
        visible: false,
        loading: true,
        // selectunitCallback: null,
        // unitId: [],
        // selectData: [],
        initArr: [],
        updateArr: [],
        activeName: '1',
        tableData: []
      }
    },
    props: {
      selectUnitList: {
        type: Array,
        default: () => {
          return []
        },
      },
      itemGroup: Array,
      mainItemsArr: Array,
      mainType: String
    },

    created() {},

    mounted() {
      if (this.mainType == 'SUNDRIES_ELEVATOR') {
        this.getDictionar(`SUNDRIES_MAINTAIN_ITEM_GROUP_${this.activeName}`)
      } else if (this.mainType == 'HYDRAULICALLY_DRIVEN_ELEVATOR') {
        this.getDictionar(`HYDRAULICALLY_MAINTAIN_ITEM_GROUP_${this.activeName}`)
      } else if (this.mainType == 'ESCALATORS_AND_AUTOMATED_WALKWAYS') {
        this.getDictionar(`ESCALATORS_MAINTAIN_ITEM_GROUP_${this.activeName}`)
      } else {
        this.getDictionar(`MAINTAIN_ITEM_GROUP_${this.activeName}`)
      }
      this.visible = true
      this.loading = true
      this.initArr = JSON.parse(JSON.stringify(this.mainItemsArr))
      this.updateArr = JSON.parse(JSON.stringify(this.mainItemsArr))
    },
    methods: {

      // 获取字典数据
      getDictionar(code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {

            if (res.data.code === 200) {
              const {
                data: {
                  data
                }
              } = res
              switch (code) {
                case 'MAINTAIN_ITEM_GROUP_1':
                  this.tableData = data.MAINTAIN_ITEM_GROUP_1
                  break;
                case 'MAINTAIN_ITEM_GROUP_2':
                  this.tableData = data.MAINTAIN_ITEM_GROUP_2
                  break;
                case 'MAINTAIN_ITEM_GROUP_3':
                  this.tableData = data.MAINTAIN_ITEM_GROUP_3
                  break;
                case 'MAINTAIN_ITEM_GROUP_4':
                  this.tableData = data.MAINTAIN_ITEM_GROUP_4
                  break;
                case 'MAINTAIN_ITEM_GROUP_5':
                  this.tableData = data.MAINTAIN_ITEM_GROUP_5
                  break;
                case 'SUNDRIES_MAINTAIN_ITEM_GROUP_1':
                  this.tableData = data.SUNDRIES_MAINTAIN_ITEM_GROUP_1
                  break;
                case 'SUNDRIES_MAINTAIN_ITEM_GROUP_2':
                  this.tableData = data.SUNDRIES_MAINTAIN_ITEM_GROUP_2
                  break;
                case 'SUNDRIES_MAINTAIN_ITEM_GROUP_3':
                  this.tableData = data.SUNDRIES_MAINTAIN_ITEM_GROUP_3
                  break;
                case 'SUNDRIES_MAINTAIN_ITEM_GROUP_4':
                  this.tableData = data.SUNDRIES_MAINTAIN_ITEM_GROUP_4
                  break;
                case 'SUNDRIES_MAINTAIN_ITEM_GROUP_5':
                  this.tableData = data.SUNDRIES_MAINTAIN_ITEM_GROUP_5
                  break;
                case 'HYDRAULICALLY_MAINTAIN_ITEM_GROUP_1':
                  this.tableData = data.HYDRAULICALLY_MAINTAIN_ITEM_GROUP_1
                  break;
                case 'HYDRAULICALLY_MAINTAIN_ITEM_GROUP_2':
                  this.tableData = data.HYDRAULICALLY_MAINTAIN_ITEM_GROUP_2
                  break;
                case 'HYDRAULICALLY_MAINTAIN_ITEM_GROUP_3':
                  this.tableData = data.HYDRAULICALLY_MAINTAIN_ITEM_GROUP_3
                  break;
                case 'HYDRAULICALLY_MAINTAIN_ITEM_GROUP_4':
                  this.tableData = data.HYDRAULICALLY_MAINTAIN_ITEM_GROUP_4
                  break;
                case 'HYDRAULICALLY_MAINTAIN_ITEM_GROUP_5':
                  this.tableData = data.HYDRAULICALLY_MAINTAIN_ITEM_GROUP_5
                  break;
                case 'ESCALATORS_MAINTAIN_ITEM_GROUP_1':
                  this.tableData = data.ESCALATORS_MAINTAIN_ITEM_GROUP_1
                  break;
                case 'ESCALATORS_MAINTAIN_ITEM_GROUP_2':
                  this.tableData = data.ESCALATORS_MAINTAIN_ITEM_GROUP_2
                  break;
                case 'ESCALATORS_MAINTAIN_ITEM_GROUP_3':
                  this.tableData = data.ESCALATORS_MAINTAIN_ITEM_GROUP_3
                  break;
                case 'ESCALATORS_MAINTAIN_ITEM_GROUP_4':
                  this.tableData = data.ESCALATORS_MAINTAIN_ITEM_GROUP_4
                  break;

                default:
                  break;
              }
              this.tableData = this.tableData.sort((a, b) => {
                return Number(a.label) - Number(b.label)
              })
              this.$nextTick(() => {
                if (this.mainItemsArr.length > 0) {
                  this.$refs.selectUnitList[this.activeName - 1].clearSelection()
                  this.tableData.forEach((ele) => {
                    this.updateArr.forEach((item) => {
                      if (item.value === ele.value && item.itemGroup == this.activeName) {
                        this.$refs.selectUnitList[this.activeName - 1].toggleRowSelection(ele, true)
                      }
                    })
                  })
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
            this.loading = false
          })
      },

      handleClick(tab, event) {
        this.loading = true
        if (this.mainType == 'SUNDRIES_ELEVATOR') {
          this.getDictionar(`SUNDRIES_MAINTAIN_ITEM_GROUP_${this.activeName}`)
        } else if (this.mainType == 'HYDRAULICALLY_DRIVEN_ELEVATOR') {
          this.getDictionar(`HYDRAULICALLY_MAINTAIN_ITEM_GROUP_${this.activeName}`)
        } else if (this.mainType == 'ESCALATORS_AND_AUTOMATED_WALKWAYS') {
          this.getDictionar(`ESCALATORS_MAINTAIN_ITEM_GROUP_${this.activeName}`)
        } else {
          this.getDictionar(`MAINTAIN_ITEM_GROUP_${this.activeName}`)
        }
        // this.getDictionar(`MAINTAIN_ITEM_GROUP_${this.activeName}`)
      },

      //全选
      selectAll(selection) {
        let stateArr = []
        if (selection.length > 0) {
          stateArr = selection.map(item => {
            item.itemGroup = this.activeName * 1
            return item
          })
          const arr = [...this.updateArr, ...selection]
          // 去重
          this.updateArr = Array.from(new Set(arr.map(JSON.stringify)), JSON.parse);
        } else {
          stateArr = JSON.parse(JSON.stringify(this.tableData))
          stateArr.map((item) => {
            if (this.updateArr.find((row) => row.value == item.value)) {
              this.updateArr = this.updateArr.filter(
                (row) => row.value !== item.value
              )
            }
          })
        }
      },

      handleSelectionChange(val, row) {
        if (this.updateArr.find((item) => item.value === row.value && item.itemGroup)) {
          if (this.updateArr.find((item) => item.itemGroup)) {
            this.updateArr = this.updateArr.filter((item) => item.itemGroup != this.activeName * 1 || item.value !== row
              .value)
          } else {
            this.updateArr = this.updateArr.filter((item) => item.value !== row.value)
          }
        } else {
          this.updateArr.push({
            itemGroup: this.activeName * 1,
            value: row.value,
            checked: false
          })
        }
      },

      async selectunitConfirm() {
        if (this.updateArr.length > 0) {
          this.$emit('transferUnit', this.updateArr)
          this.visible = false
        } else {
          this.$message({
            message: '请选择维保项',
            type: 'error',
          })
        }
      },

      selectunitDialogClose() {
        this.$emit('close')
        this.visible = false
      },
    },
  }
</script>
