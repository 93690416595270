<template>
  <el-dialog title="详情" v-if="detailVisible" :visible.sync="detailVisible" :close-on-click-modal="false" @close="back"
    width="1400px">
    <div style="height: 70vh ;">
      <div style="height: 60vh; overflow-y: scroll;" class="scrollElement">
        <div style="float: right;margin-bottom: 10px; cursor: pointer;" @click="goStampPage">打印</div>
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label" class="labelInfo">检查人</template>
            {{formList.userName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label" class="labelInfo">提交时间</template>
            {{formList.createTime}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label" class="labelInfo">所属单位</template>
            {{formList.orgName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label" class="labelInfo">类型</template>
            {{formList.type == 0? '日管控':(formList.type == 1?'周排查' :'月调度')}}
          </el-descriptions-item>
        </el-descriptions>
        <template v-if="dictionarTotal != 0">
          <el-table ref="multipleTable" v-loading="tableLoading" :data="Tabledata" style="width: 100%;" border>
            <el-table-column type="index" label="序号" width="55px" align="center" />
            <el-table-column label="检查项目" align="center" prop="checkItem" />
            <el-table-column label="检查内容" align="center" prop="checkContent" />
            <el-table-column label="检查结果" align="center" prop="checkResult">
              <template slot-scope="scope">{{scope.row.checkResult == 0? '异常':'正常'}}</template>
            </el-table-column>
            <el-table-column label="处置结果" align="center" prop="dealwithResult" />
            <el-table-column label="备注" align="center" prop="comment" />
          </el-table>
        </template>
        <div class="footerContent">
          <div style="height: 50px;">
            <span>采取的防范措施：{{formList.countermeasure}}</span>
          </div>
          <div style="float: right;">
            安全员：{{formList.userName}}
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    props: ['Id'],
    data() {
      return {
        dictionarTotal: 0,
        detailVisible: true,
        formList: {},
        Tabledata: [],
        tableLoading: true
      }
    },
    created() {
      this.getInfo()
    },
    methods: {
      goStampPage() {
        window.open('#/YddPage/' + this.Id + "," + '3', '_blank')

        // this.$router.push({
        // 	path: '/stampPage',
        // 	query: {
        // 		id: this.dioData.id
        // 	}
        // })
      },
      getInfo() {
        this.tableLoading = true
        this.$http.get(`/api/base/web/getWebDayMCInfoById?id=${this.Id}`).then((res) => {
          this.formList = res.data.data
          this.Tabledata = res.data.data.riskManageItems ? JSON.parse(res.data.data.riskManageItems) : [],
            this.dictionarTotal = this.Tabledata.length
          this.tableLoading = false
        })
      },
      back() {
        this.detailVisible = false
        this.$emit('close')
      },
    }
  }
</script>

<style lang="scss" scoped>
  .footerContent {
    border: 1px solid #FFFFFF;
    height: 70px;
    padding: 20px;
    color: #FFFFFF;
  }
</style>
