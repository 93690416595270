<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="formInline.eleName" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯注册代码</span>
          </span>
          <el-input v-model="formInline.eleCode" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">状态</span>
          </span>
          <el-select v-model="formInline.taskStatus" placeholder="请输入" class="einp" clearable>
            <el-option v-for="item in qtType" :key="item.val" :label="item.lab" :value="item.val">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">报告时间</span>
          </span>
          <el-date-picker style="width: 220px;" v-model="formInline.allTime" type="daterange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">所属小区</span>
          </span>
          <el-input v-model="formInline.plotName" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item>
					<span slot="label">
						<span class="lab-span">查询时间</span>
					</span>
					<el-date-picker v-model="formInline.checkTime" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期"
						class="einp">
					</el-date-picker>
				</el-form-item> -->

        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>

      <el-button v-focus type="success" @click="addDj" size="small">新增</el-button>
    </div>
    <div v-if="dictionarTotal!=0">
      <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" :height="$store.state.tabHeight-40"
        style="width: 100%;margin-bottom: 20px;">
        <el-table-column type="index" label="序号" width="50px" />
        <el-table-column align='center' prop="eleName" label="电梯名称" width="200px" />
        <el-table-column align='center' prop="eleCode" label="电梯注册代码" width="200px" />
        <el-table-column align='center' prop="failureDesc" label="故障描述" width="300px" />
        <el-table-column align='center' prop="statusName" label="处理状态" width="120px" />
        <el-table-column align='center' prop="sourceName" label="故障来源" width="120px" />
        <el-table-column align='center' prop="declarantName" label="故障申报人" width="120px" />
        <el-table-column align='center' label="故障报告时间" width="200px">
          <template slot-scope="scope">
            {{ scope.row.failureTime ? scope.row.failureTime : '-' }}
          </template>
        </el-table-column>
        <el-table-column align='center' label="电梯停运时间" width="200px">
          <template slot-scope="scope">
            {{ scope.row.outageTime ? scope.row.outageTime.split(' ')[0] : '-' }}
          </template>
        </el-table-column>
        <el-table-column align='center' label="完成时间" width="200px">
          <template slot-scope="scope">
            {{ scope.row.finishTime ? scope.row.finishTime : '-' }}
          </template>
        </el-table-column>
        <el-table-column align='center' label="所属小区" width="180px">
          <template slot-scope="scope">
            {{scope.row.plotName ? scope.row.plotName : '-'}}
          </template>
        </el-table-column>
        <el-table-column align='center' prop="propertyUnitName" label="物业单位" width="200px" />
        <el-table-column align='center' prop="maintainUnitName" label="维保单位" width="200px" />
        <el-table-column align='center' prop="eleAddress" label="电梯安装位置" width="250px" />
        <el-table-column align='center' prop="createTime" label="创建时间" width="200px" />
        <el-table-column fixed="right" align='center' prop="address" label="操作" width="200px">
          <template slot-scope="scope">
            <el-button v-focus @click="showDetails(scope.row)" type="primary" size="small">查看</el-button>
            <el-button type="danger" @click="del(scope.row)" size="small" v-if="adminInfo.userName == 'admin'">删除</el-button>
            <!-- <el-button v-if="scope.row.status === 14 || scope.row.status === 22 ||scope.row.status === 30"
							@click="showProcess(scope.row)" type="primary" size="small">平台审核</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>
    <!-- 详情 -->
    <el-dialog v-if='dioDetails' :close-on-click-modal='false' :title="tit" :visible.sync="dioDetails" width="80%"
      top='10vh'>
      <div style="height: 65vh;overflow-y: scroll;" class="scrollElement">
        <dioDetails :dioData="dioDetailsItem" :flowList="flowList"></dioDetails>
      </div>
      <div slot="footer" class="dialog-footer"
        style="display: flex;justify-content: space-between;align-items: center;margin-left: -6%;">
        <el-form v-if="process && processType" :inline="true" :model="formProcess" class="demo-form-inline">

          <el-form-item label="平台审核意见:" prop="name">
            <!-- 现场确认 -->
            <el-radio-group v-if="dioDetailsItem.status === 14" v-model="formProcess.status" @input="changeRadio">
              <el-radio :label="16">通过</el-radio>
              <el-radio :label="15">不通过</el-radio>
            </el-radio-group>
            <!-- 保险确认 -->
            <el-radio-group v-else-if="dioDetailsItem.status === 22" v-model="formProcess.status" @input="changeRadio">
              <el-radio :label="24">通过</el-radio>
              <el-radio :label="23">不通过</el-radio>
            </el-radio-group>
            <!-- 维修确认 -->
            <el-radio-group v-else-if="dioDetailsItem.status === 30" v-model="formProcess.status" @input="changeRadio">
              <el-radio :label="32">通过</el-radio>
              <el-radio :label="31">不通过</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="意见理由" v-if="fallShow">
            <el-input type="textarea" style="width:30vh" v-model="formProcess.dismissDesc" />
          </el-form-item>
        </el-form>
        <!-- 站位 -->
        <div v-else />
        <div>
          <el-button v-if="process && processType" size="small" type="success" @click="submitProcess">提
            交</el-button>
          <el-button size="small" type="primary" @click="closeDetails">关 闭</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog v-if='dialogVisible' :close-on-click-modal='false' :title="tit" :visible.sync="dialogVisible"
      width="950px" top='20vh'>
      <div style="height: 28vh;overflow-y: scroll;" class="scrollElement">
        <el-form :rules="rules" ref="ruleForm" label-position="right" label-width="120px" :model="fign">
          <el-row style="margin:10px 0">
            <el-col :span="12">
              <el-form-item label="电梯名称:" prop="eleName">
                <el-input :placeholder="fign.eleName || '暂无内容'" disabled v-model="fign.eleName" class="einp">
                  <el-button slot="append" type="primary" @click="selectElevator()">选择电梯</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="故障来源:" prop="sourceType">
                <el-select v-model="fign.sourceType" placeholder="请输入" class="einp" clearable>
                  <el-option v-for="item in gzType" v-show="item.value !== 5" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin:10px 0">
            <el-col :span="12">
              <el-form-item label="电梯停运时间:" prop="outageTime">
                <el-date-picker class="einp" v-model="fign.outageTime" type="datetime" placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="故障报告时间:" prop="failureTime">
                <el-date-picker class="einp" v-model="fign.failureTime" type="datetime" placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin:10px 0">
            <el-form-item label="故障描述:" prop="failureDesc">
              <el-input type="textarea" resize="none" :autosize="{ minRows: 5}" placeholder="请输入内容"
                v-model="fign.failureDesc" style="width: 95%;">
              </el-input>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" :disabled="disabled" @click="dioSub">确 定</el-button>
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <SelectElevator v-if="selectOtherDialogVisible" ref="SelectElevator" @transferElevator="selectOtherResult" />
  </div>
</template>
<script>
  var valifailureDesc = (rule, value, callback) => {
    let operation = /^[\s\S]{4,400}$/
    inpYz(
      rule,
      value,
      callback,
      true,
      operation,
      '可输入中文，英文，数值，特殊符号，长度为4至400'
    )
  }

  import dioDetails from './components/dioDetails'
  import Pagination from '@/components/Pagination'
  import SelectElevator from './components/selectElevator'
  import {
    formatDate
  } from "@/util";

  let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
  let current_time1 = new Date(current_time).getTime();
  let starTime = new Date(current_time1 - 30 * 24 * 60 * 60 * 1000);
  let endTime = new Date(current_time1);
  export default {
    components: {
      dioDetails,
      Pagination,
      SelectElevator,
    },
    data() {
      return {
        // 维修详情
        dioDetails: false,
        dioDetailsItem: null,
        // 审核
        formProcess: {
          status: 0, // 审核通过
          dismissDesc: '',
        },
        process: false,
        fallShow: false, // 不通过理由
        // 选择电梯参数
        selectOtherDialogVisible: false, // 选择电梯
        // 表格参数
        formInline: {
          current: 1,
          size: 10,
          taskStatus: 3
          // checkTime: [starTime, endTime]
        },
        tableData: [],
        // 分页参数
        dictionarTotal: 0,
        // dialog参数
        tit: '',
        dialogVisible: false,
        fign: {},
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        // 表单限制
        rules: {
          eleName: [{
            required: true,
            message: '请选择电梯',
            trigger: 'change',
          }, ],
          failureDesc: [{
            required: true,
            validator: valifailureDesc,
            trigger: 'blur',
          }, ],
          sourceType: [{
            required: true,
            message: '请选择故障来源',
            trigger: 'change',
          }, ],
          outageTime: [{
            type: 'date',
            required: true,
            message: '请选择电梯停运时间',
            trigger: 'change',
          }, ],
          failureTime: [{
            type: 'date',
            required: true,
            message: '请选择故障报告时间',
            trigger: 'change',
          }, ],
        },
        // 两个下拉框
        gzType: [
          // {
          // 		value: 1,
          // 		label: '维保发现',
          // 	},
          // 	{
          // 		value: 2,
          // 		label: '救援发现',
          // 	},
          // 	{
          // 		value: 3,
          // 		label: '巡逻发现',
          // 	},
          // 	{
          // 		value: 4,
          // 		label: '乘梯发现',
          // 	},
          // 	{
          // 		value: 5,
          // 		label: '平台发现',
          // 	},
          // 	{
          // 		value: 6,
          // 		label: '监控发现',
          // 	},
        ],
        clType: [
          // {
          //   val: 1,
          //   lab: '待处理',
          // },
          // {
          //   val: 2,
          //   lab: '已签到',
          // },
          // {
          //   val: 3,
          //   lab: '待确认',
          // },
          // {
          //   val: 4,
          //   lab: '待维修',
          // },
          // {
          //   val: 5,
          //   lab: '待审核',
          // },
          // {
          //   val: 10,
          //   lab: '已完成',
          // },
        ],
        qtType: [{
            val: 1,
            lab: '待处理',
          },
          {
            val: 2,
            lab: '进行中',
          },
          {
            val: 3,
            lab: '已完成',
          },
          {
            val: 4,
            lab: '待审核',
          },
          {
            val: 5,
            lab: '已放弃',
          },
        ],
        flowList: null,
        processType: true,
        disabled: false,
        tableLoading: true
      }
    },
    methods: {
      //删除
      del(item) {

        this.$confirm('此操作将永久删除记录，是否继续', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.delete(`/api/ele/web/failureTask/deleteTask?id=${item.id}`).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.onRefer();
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
        })
      },
      // 详情
      showDetails(item) {
        this.tit = '电梯维修详情'
        this.dioDetailsItem = item
        this.getDetail(item)
        this.dioDetails = true
        this.process = false
      },
      // 平台审核
      showProcess(item) {
        this.tit = '平台审核'
        this.dioDetailsItem = item
        this.getDetail(item)
        this.dioDetails = true
        this.process = true
      },
      closeDetails() {
        if (!this.processType) {
          this.onRefer()
        }
        this.dioDetails = false
      },

      getDetail(item) {
        let data = {
          taskId: item.id,
        }
        let url = '/api/ele/web/failureTask/detail'
        this.$http.post(url, data).then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              let num = res.data.data[res.data.data.length - 1].status
              if (num != 14 && num != 22 && num != 7) {
                this.processType = false
              } else {
                this.processType = true
              }
            }
            // 排除新状态
            res.data.data.forEach((qs) => {
              if (qs.partsList && qs.partsList != '') {
                qs.partsList = qs.partsList !== '请选择' ? JSON.parse(qs.partsList) : qs
                  .partsList
              }
              if (qs.afterServiceImgs && qs.afterServiceImgs != '') {
                qs.afterServiceImgs = JSON.parse(qs.afterServiceImgs)
              }
              if (qs.signServiceImgs && qs.signServiceImgs != '') {
                qs.signServiceImgs = JSON.parse(qs.signServiceImgs)
              }
            })
            this.flowList = res.data.data.filter((item) => item.status < 10)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      changeRadio(data) {

        if (data === 15 || data === 23 || data === 31) {
          this.fallShow = true
        } else {
          this.fallShow = false
        }
      },
      // 选择电梯返回
      selectOtherResult(data) {
        this.fign.eleData = data
        this.fign.eleId = data.id
        this.$set(this.fign, 'eleName', data.lab)
      },
      selectElevator() {
        // 选择电梯
        console.log('选择电梯')
        this.selectOtherDialogVisible = true
        this.$nextTick(() => {
          this.$refs.SelectElevator.init(this.fign.eleData, (refresh) => {
            if (refresh) {}
          })
        })
      },
      // 重置按钮事件
      reset() {

        this.formInline = {
          current: 1,
          size: 10,
          // checkTime: [starTime, endTime]
        }
        this.onRefer()
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      // 弹出框提交
      dioSub() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.disabled = true
            let data = JSON.parse(JSON.stringify(this.fign))
            let url = '/api/ele/web/failureTask/add'
            data.outageTime = new Date(data.outageTime).getTime()
            data.failureTime = new Date(data.failureTime).getTime()
            this.$http.post(url, data).then((res) => {
              if (res.data.success) {
                this.onRefer()
                this.dialogVisible = false
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
              this.disabled = false
            })
          } else {
            this.$message.warning('请验证填写内容!')
            return false
          }
        })
      },
      // 平台意见提交
      submitProcess() {
        this.formProcess.taskId = this.dioDetailsItem.id
        if (
          this.formProcess.status === 15 ||
          this.formProcess.status === 23 ||
          this.formProcess.status === 31
        ) {
          if (this.formProcess.dismissDesc === '') {
            this.$message.error('请填写不通过的意见理由')
            return
          }
        }

        if (this.formProcess.status === 0) {
          this.$message.error('请选择审核意见')
          return
        }
        this.$http
          .post('/api/ele/web/failureTask/submit', this.formProcess)
          .then((res) => {
            if (res.data.success) {

              this.$message.success(res.data.msg)
              this.onRefer()
              this.dialogVisible = false
              this.formProcess = {}
              this.dioDetails = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 表格方法
      onRefer(ishm) {
        if (ishm) {
          this.formInline.current = 1
        }
        if (
          this.formInline.allTime != null &&
          this.formInline.allTime != undefined
        ) {
          this.formInline.searchFailureTime = [new Date(this.formInline.allTime[0]).getTime(), new Date(this.formInline
            .allTime[1]).getTime() + 24 * 3600 * 1000 - 1000]
          // this.formInline.start = new Date(this.formInline.allTime[0]).getTime()
          // this.formInline.end =
          // 	new Date(this.formInline.allTime[1]).getTime() +
          // 	24 * 3600 * 1000 -
          // 	1000
        } else {
          this.formInline.searchFailureTime = []
        }
        // if (this.formInline.checkTime) {
        // 	let atime = JSON.parse(JSON.stringify(this.formInline.checkTime))
        // 	this.formInline.start = new Date(atime[0]).getTime()
        // 	this.formInline.end = new Date(atime[1]).getTime() + 24 * 60 * 60 * 1000 - 1000
        // } else {
        // 	this.$message.error('请选择查询时间')
        // 	return
        // }
        this.tableLoading = true
        this.$http
          .post('/api/ele/web/failureTask/getList', this.formInline)
          .then((res) => {

            if (res.data.success) {
              res.data.data.records.forEach((qs) => {
                qs.statusName = this.getType(qs.status, 2)
                qs.sourceName = this.getType(qs.sourceType, 1)
              })
              this.tableData = res.data.data.records
              this.dictionarTotal = res.data.data.total
              this.$nextTick(() => {
                if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
            this.tableLoading = false
          })
      },
      addDj() {
        this.tit = '新增电梯维修'
        this.fign = {}
        this.dialogVisible = true
      },
      // 获取类型
      getType(type, sta) {
        let obj
        let arr = []
        if (sta == 1) {
          arr = this.gzType
        } else {
          arr = this.clType
        }
        arr.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
          }
        })
        return obj
      },
      // 获取字典数据
      getDictionar(code) {
        // code = code.join()
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {

            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res
              this.gzType = data.FAILURE_SOURCE_TYPE
              this.clType = data.FAILURE_STATUS
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
    },
    created() {
      this.getDictionar(['FAILURE_STATUS', 'FAILURE_SOURCE_TYPE'])
      this.onRefer()
    },
  }
</script>
<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }

  .el-radio {
    color: #fff;
  }
</style>
