<template>
  <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body width="960px"
    :show-close="false" top="8vh" @close="closeDialog">
    <el-descriptions class="reportBox" style="color: #00f6ff; margin: 0 15px" title="生成报告" :column="1" border>
      <template slot="extra">
        <el-button type="text" @click="openPreview">报告示例</el-button>
      </template>
      <el-descriptions-item>
        <template slot="label"> 报告时段 </template>
        <div class="descriptionsItem">
          <el-date-picker v-model="time" style="margin: 0 10%; width: 80%" type="daterange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 参数类型 </template>
        <div class="descriptionsItem">
          <div class="radioGroup">
            <el-checkbox :true-label="1" :false-label="0" v-model="parameter.export" label="导出参数"
              @change="changeType('export')"></el-checkbox>
            <el-checkbox :true-label="1" :false-label="0" v-model="parameter.detail" label="导出数据属性"
              @change="changeType('detail')"></el-checkbox>
          </div>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 运行报告 </template>
        <div class="descriptionsItem">
          <div class="radioGroup" style="margin: 0px 0 10px">
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.yxsj.export"
              label="运行时间"></el-checkbox>
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.yxsj.detail"
              label="详细数据"></el-checkbox>
          </div>
          <div class="radioGroup" style="margin: 10px 0">
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.yxcs.export"
              label="运行次数"></el-checkbox>
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.yxcs.detail"
              label="详细数据"></el-checkbox>
          </div>
          <div class="radioGroup" style="margin: 10px 0">
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.lcqtcs.export"
              label="楼层启停次数"></el-checkbox>
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.lcqtcs.detail"
              label="详细数据"></el-checkbox>
          </div>
          <div class="radioGroup" style="margin: 10px 0">
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.mrctrs.export"
              label="每日乘梯人数"></el-checkbox>
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.mrctrs.detail"
              label="详细数据"></el-checkbox>
          </div>
          <div class="radioGroup" style="margin: 10px 0">
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.dtmrzwcs.export"
              label="电梯每日折弯次数"></el-checkbox>
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.dtmrzwcs.detail"
              label="详细数据"></el-checkbox>
          </div>
          <div class="radioGroup" style="margin: 10px 0 0">
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.yxlc.export"
              label="运行里程"></el-checkbox>
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.yxlc.detail"
              label="详细数据"></el-checkbox>
          </div>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 维保报告 </template>
        <div class="descriptionsItem">
          <div class="radioGroup">
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.wbtj.export"
              label="电梯维保"></el-checkbox>
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.wbtj.detail"
              label="详细数据"></el-checkbox>
          </div>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 故障分析 </template>
        <div class="descriptionsItem">
          <div class="radioGroup">
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.gzfx.export"
              label="报警报告"></el-checkbox>
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.gzfx.detail"
              label="详细数据"></el-checkbox>
          </div>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 维修统计 </template>
        <div class="descriptionsItem">
          <div class="radioGroup">
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.wxtj.export"
              label="故障报告"></el-checkbox>
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.wxtj.detail"
              label="详细数据"></el-checkbox>
          </div>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 电梯保险 </template>
        <div class="descriptionsItem">
          <div class="radioGroup">
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.dtbx.export"
              label="保险报告"></el-checkbox>
            <el-checkbox :true-label="1" :false-label="0" v-model="parameterType.dtbx.detail"
              label="详细数据"></el-checkbox>
          </div>
        </div>
      </el-descriptions-item>
    </el-descriptions>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" size="small" @click="sure">确 定</el-button>
      <el-button size="small" @click="closeDialog">关 闭</el-button>
    </span>

    <ReportMode ref="ReportMode" v-if="openPreviewVisible" :elevatorId="elevatorId" :parameterType="parameterType"
      @closeCheck="closeMode" />
  </el-dialog>
</template>

<script>
  import {
    formatDate
  } from "@/util";
  import ReportMode from "../../../reportMode";
  export default {
    components: {
      ReportMode,
    },
    data() {
      return {
        dialogVisible: true,
        time: "",
        parameterType: {
          yxsj: {
            export: 1,
            detail: 0
          },
          yxcs: {
            export: 1,
            detail: 0
          },
          lcqtcs: {
            export: 1,
            detail: 0
          },
          mrctrs: {
            export: 1,
            detail: 0
          },
          dtmrzwcs: {
            export: 1,
            detail: 0
          },
          yxlc: {
            export: 1,
            detail: 0
          },
          // dtwb: { export: 1, detail: 0 },
          // bjbg: { export: 1, detail: 0 },
          // gzbg: { export: 1, detail: 0 },
          // bxbg: { export: 1, detail: 0 },
          // jybg: { export: 1, detail: 0 },
          wbtj: {
            export: 1,
            detail: 0
          },
          gzfx: {
            export: 1,
            detail: 0
          },
          wxtj: {
            export: 1,
            detail: 0
          },
          dtbx: {
            export: 1,
            detail: 0
          },
        },
        parameter: {
          export: 1,
          detail: 0,
        },
        openPreviewVisible: false,

        pickerOptions: {
          disabledDate(time) {
            // return time.getTime() > new Date(formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00").getTime();
            return time.getTime() > Date.now() - 8.64e7
          },
        },

      };
    },
    props: {
      eleInfo: {
        type: Object,
        default: () => ({}),
      },
      elevatorId: {
        type: String,
        default: "",
      },
    },

    created() {},
    mounted() {},
    destroyed() {

    },

    methods: {
      openPreview() {
        this.openPreviewVisible = true;
      },
      closeMode() {
        this.openPreviewVisible = false;
      },


      changeType(type) {
        for (let i in this.parameterType) {
          this.parameterType[i][type] = this.parameter[type];
        }
      },
      sure() {
         const monthsDiff = Math.abs(this.time[1].getMonth() - this.time[0].getMonth()) + 12 * Math.abs(this.time[1].getFullYear() - this.time[0].getFullYear());

        if ( monthsDiff > 2) {
          this.$message.error("报告时段仅能设置在三个月内");
          return;
        }
        const data = {
          elevatorId: this.elevatorId,
          startTime: this.time[0].getTime(),
          endTime: this.time[1].getTime() + 24 * 3600 * 1000 - 1000,
          parameterType: JSON.stringify(this.parameterType),
        };
        this.$http
          .post("/api/ele/web/operationalReport/save", data)
          .then((res) => {
            if (res.data.success) {
              this.$message.success(res.data.msg);
              this.closeDialog();
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      closeDialog() {
        this.dialogVisible = false;
        this.$emit("closeCreate");
      },
    },
  };
</script>
<style lang="scss" scoped>
  // .el-tabs__item
  // .el-radio
  // .el-checkbox {
  // color: #adb0b5  !important;
  // }

  /deep/.reportBox /deep/.el-tabs__item {
    width: 120px;
    text-align: center;
    padding-left: 0;
  }

  /deep/.reportBox {

    .radioGroup {
      font-size: 0;
      width: 100%;
      display: flex;
      justify-content: space-around;

      .el-checkbox {
        width: 180px;
      }
    }
  }
</style>
