<template>
  <div
    :class="className"
    :style="{width:width, height:height,margin:'0 auto'}"
  />
</template>

<script>
import echarts from 'echarts'

export default {
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      // default: 'calc(100% - 30px)'
      default: '650px',
    },
    lineType: {
      type: String,
      default: '',
    },
    trendColor: {
      type: Array,
      default: () => [],
    },
    sosLineData: {
      type: Array,
      default: () => []
      // default: () => {
      //   return {
      //     rescueNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 救援数据
      //     failureNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 故障数据
      //     maintainNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 维保数据
      //     claimNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 理赔数据
      //     sosNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 报警数据
      //     dateList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 日期
      //   }
      // },
    },
  },

  data() {
    return {
      chart: null,
      legendName: []
    }
  },

  // 监听data里面对应的
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },

  beforeDestroy() {
    if (!this.chart) return
    // 释放图表实例
    this.chart.dispose()
    this.chart = null
  },

  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$el)
      this.setOptions(this.sosLineData)
    },
    setOptions(data) {

      const dateList = data[0].timeData
      const series = data.map((item, index) => {
        this.legendName.push(item.eleName)
        let obj = {
            name: item.eleName,
            type: 'line',
            data: this.lineType == 'num' ? item.numData : item.runningTimeData,
            itemStyle: {
              normal: {
                color: this.trendColor[index],
              },
            },
            lineStyle: {
              smooth: true,
            },
          }
          return obj
      })

      const options = {
        tooltip: {
          trigger: 'axis',
          // 在这里设置
          formatter: (params) => {
            let value = ``
            let time = ''
            params.map((item) => {
              time = `${item.name}号`
              value += `${item.seriesName}：${item.value} ${this.lineType == 'num' ? '次' : '小时'} <br>`
            })
            return `${time}<br>${value}`
          },
        },
        textStyle: {
          color: '#409eff', // 所有文字的颜色
        },
        grid: {
          left: '10%',
          right: '4%',
          top: '12%',
          bottom: '20%',
          containLabel: false,
          borderColor: '#fff',
        },
        xAxis: {
          type: 'category',
          show: true,
          data: dateList,
          boundaryGap: false,
          splitLine: { show: false }, // 去除网格线,
          axisLine: {
            lineStyle: {
              color: '#4dc1ed', // 坐标轴颜色
            },
          },
          axisLabel: {
            interval: 6,
            rotate: 0,
            textStyle: {
              fontSize: 14,
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          name: '',
          show: true,
          axisLine: {
            lineStyle: {
              color: '#4dc1ed',
            },
          },
          scale: true, //自适应
          axisTick: {
            show: false,
          },
        },
        series: [...series]
      }
      this.chart.setOption(options)
    },
  },
}
</script>
