<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">标题</span>
          </span>
          <el-input v-model="searchForm.title" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">信息类型</span>
          </span>
          <el-select v-model="searchForm.type" placeholder="请输入" clearable>
            <el-option v-for="(item, index) in noticeAdminSearch" :key="index" :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">更新时间</span>
          </span>
          <el-date-picker v-model="searchForm.updateDate" style="width: 100%" type="date" placeholder="请输入" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button type="success" size="small" v-focus @click="addDj">新增</el-button>
      <template v-if="noticeTotal != 0">
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" :height="$store.state.tabHeight"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px">
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="title" label="标题" min-width="250px" />
          <el-table-column align="center" prop="createName" label="创建人" min-width="150px" />
          <el-table-column align="center" prop="type" label="信息类型" width="130px">
            <template slot-scope="scope">
              {{
                noticeAdmin.find((item) => item.value === scope.row.type)
                  ? noticeAdmin.find((item) => item.value === scope.row.type).label
                  : ''
              }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            align="center"
            prop="status"
            label="状态"
            min-width="100px"
          >
            <template slot-scope="scope">
              {{ scope.row.status ? '发布' : '草稿箱' }}
            </template>
          </el-table-column> -->
          <el-table-column align="center" label="展示时间" min-width="300px"><template slot-scope="scope">
              <span v-if="scope.row.type == 'LOGIN_NOTICE' || scope.row.type == 'APP_NOTICE'">
                {{ scope.row.startTime }} - {{ scope.row.endTime }}
              </span>
              <span v-else> - </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="发布时间" min-width="150px"><template slot-scope="scope">
              {{ scope.row.status ? scope.row.pushTime : '-' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="updateTime" label="更新时间" min-width="150px" />
          <el-table-column fixed="right" align="center" prop="address" label="操作" class="fixedRight" width="300px">
            <template slot-scope="scope">
              <el-button @click="check(scope.row)" type="primary" size="small" v-focus>查看
              </el-button>
              <el-button v-show="!scope.row.onlyRead" @click="edit(scope.row)" type="warning" size="small" v-focus>编辑
              </el-button>
              <el-button v-show="!scope.row.onlyRead" @click="del(scope.row)" type="danger" size="small" v-focus>删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="noticeTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="noticePage" />
      </template>
      <div class="nullDate" v-else v-loading="tableLoading">
        	<img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>
    <!-- 新增修改 -->
    <el-dialog :title="tit" v-if="dialogVisible" :visible.sync="dialogVisible" :close-on-click-modal="false"
      width="950px" top="20vh" @close="infoElevatorDialogClose">
      <div style="height: 40vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
        <el-form v-if="!noticeFormDisabled" label-position="right" label-width="120px" ref="noticeForm"
          :model="noticeForm" :rules="formRules">
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="标题:" prop="title">
                <el-input class="wid90" v-model="noticeForm.title" placeholder="请输入" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="信息类型:" prop="type">
                <el-select class="wid90" v-model="noticeForm.type" placeholder="请输入" @change="changType">
                  <template v-if="roleId == 1">
                    <el-option label="系统首页公告" value="SYSTEM_NOTICE" />
                    <el-option label="登录弹窗公告" value="LOGIN_NOTICE" />
                    <el-option label="APP首页公告" value="APP_NOTICE" />
                  </template>
                  <template v-else-if="roleId == 2">
                    <el-option v-for="(item, index) in NoticeTypeSup" :key="index" :label="item.label"
                      :value="item.value" />
                  </template>
                  <template v-else>
                    <el-option v-for="(item, index) in NoticeTypeCommon" :key="index" :label="item.label"
                      :value="item.value" />
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0" v-if="noticeForm.type == 'LOGIN_NOTICE' || noticeForm.type == 'APP_NOTICE'">
            <el-col :span="24" :key="key">
              <el-form-item label="展示时间段:" prop="allTime">
                <el-date-picker v-model="noticeForm.allTime" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" style="width: 95%;" @change="changeData"
                  @blur="changeData">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin: 10px 0">
            <el-col :span="24">
              <el-form-item label="公告内容:" prop="detail">
                <quill-editor  @change="onChange($event)" v-model="noticeForm.detail" :options="editorOption" class="quillClass wid90"
                  style="width: 95%;" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div v-else class="noticeFormInfo">
          <el-row class="noticeFormDetail" style="text-align: center;">
            <el-col :span="6">信息类型：{{
                noticeAdmin.find((item) => item.value === noticeForm.type)
                  ? noticeAdmin.find((item) => item.value === noticeForm.type)
                      .label
                  : ''
              }}</el-col>
            <el-col :span="9">发布时间：
              <span v-if="noticeForm.status">
                {{ noticeForm.pushTime }}
              </span>
            </el-col>
            <el-col :span="9">更新时间：
              <span v-if="noticeForm.status">
                {{ noticeForm.updateTime }}
              </span>
            </el-col>
          </el-row>
          <el-row class="noticeFormDetail">
            <el-col :span="24">
              <div class="gundong">
                <quill-editor @focus="focus($event)" v-model="noticeForm.detail" :options="editorOption"
                  class="quillClass wid90" style="margin: 0 auto" />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" v-if="!noticeFormDisabled" @click="dioSub('发布')">发 布</el-button>
        <el-button size="small" type="warning" :loading="loading" v-if="!noticeFormDisabled && !noticeForm.status"
          @click="dioSub('保存')">保 存</el-button>
        <el-button size="small" @click="infoElevatorDialogClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import Pagination from '../../../components/Pagination'
  export default {
    components: {
      Pagination,
    },
    data() {
      var validateTitle = (rule, value, callback) => {
        inputApp(
          rule,
          value,
          true,
          /^[\u4e00-\u9fa5_a-zA-Z0-9]{4,50}$/,
          '标题必须是中文，字母，数值，不可输入特殊符号，长度4-50',
          callback
        )
      }
      var validateTime = (rule, value, callback) => {
        if (!value || value.length == 0) {
          callback(new Error('展示时间段不能为空!'))
        }
        callback()
      }
      return {
        // 富文本编辑器
        editorOption: {
          modules: {
            clipboard: {
              // 粘贴版，处理粘贴时候带图片
              matchers: [
                [Node.ELEMENT_NODE, this.handleCustomMatcher]
              ],
            },
            toolbar: [
              ['bold'], // toggled buttons

              [{
                size: ['small', false, 'large', 'huge']
              }], // custom dropdown
            ],
          },
        },
        timer: null,
        // 表格参数
        searchForm: {
          current: 1,
          size: 10,
          type: '',
        },
        // 公告类型
        noticeAdminSearch: [],
        noticeAdmin: [],
        // 公告弹窗类型
        NoticeTypeCommon: [],
        NoticeTypeSup: [],
        tableData: [],
        // 分页参数
        noticeTotal: 0,
        // dialog参数
        tit: '',
        dialogVisible: false,
        loading: false,
        noticeForm: {},
        formRules: {
          title: [{
            required: true,
            validator: validateTitle,
            trigger: 'blur'
          }],
          allTime: [{
            required: true,
            validator: validateTime,
            trigger: 'change'
          }],
          type: [{
            required: true,
            message: '公告类型不能为空',
            trigger: 'blur'
          }, ],
          detail: [{
            required: true,
            message: '公告内容不能为空',
            trigger: 'blur'
          }, ],
        },
        selectOtherDialogVisible: false, // 选择电梯
        noticeFormDisabled: false, // 查看禁点
        roleId: JSON.parse(sessionStorage.getItem('adminInfo')).roleId,
        isChange: false, // 是否修改了

        key: 0,
        tableLoading: true
      }
    },
    filters: {},
    methods: {
      handleCustomMatcher(node, Delta) {
        let ops = []
        Delta.ops.forEach(op => {
          if (op.insert && typeof op.insert === 'string') {
            // 如果粘贴了图片，这里会是一个对象，所以可以这样处理
            ops.push({
              insert: op.insert,
            })
          } else {
            this.$message({
              message: '不允许粘贴图片,请手动上传',
              type: 'warning'
            })
          }
        })
        Delta.ops = ops
        return Delta
      },

      // 获取字典数据
      getNotice(type, code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res
              this.noticeAdmin = data.NOTICE_ADMIN
              this.noticeAdminSearch = [
                ...[{
                  label: '全部',
                  value: ''
                }],
                ...data.NOTICE_ADMIN
              ]
              this.NoticeTypeCommon = data.NOTICE_TYPE_COMMON
              this.NoticeTypeSup = data.NOTICE_TYPE_SUP
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 分页
      noticePage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer()
      },

      // 弹出框提交
      dioSub(type) {
        this.loading = true
        this.$refs.noticeForm.validate(async (valid) => {
          if (valid) {
            this.noticeForm.status = type === '发布' ? 1 : 0
            const data = JSON.parse(JSON.stringify(this.noticeForm))
            if (this.noticeForm.allTime && this.noticeForm.allTime.length > 0) {
              data.startTime = this.noticeForm.allTime[0].getTime()
              data.endTime = this.noticeForm.allTime[1].getTime() + 24 * 3600 * 1000 - 1000
            }
            this.$http
              .post('/api/system/web/announcement/submit', data)
              .then((res) => {
                if (res.data.success) {
                  if (type) {
                    this.dialogVisible = false
                    this.$message.success(res.data.msg)
                  }
                  if (type !== '发布') {
                    this.noticeForm.id = res.data.data
                  }
                  this.onRefer()
                } else {
                  this.$message.error(res.data.msg)
                }
              })
          }
        })
        this.loading = false
      },

      changType() {
        if (this.noticeForm.type == this.noticeForm.typeInit) {
          this.noticeForm.allTime = this.noticeForm.allTimeInit
        } else {
          this.noticeForm.allTime = null
        }
        this.key++
      },

      searchOnRefer() {
        const regExp = /^[\u4e00-\u9fa5_a-zA-Z0-9\n]+$/
        if (!regExp.test(this.searchForm.title)) {
          this.$message.error('标题仅可输入字母，数值，中文字符!')
          return
        }
        this.searchForm.current = 1
        this.onRefer()
      },
      // 重置
      reset() {

        this.searchForm = {
          current: 1,
          size: 10,
        }
        this.onRefer()
      },

      // 获取公告
      onRefer() {
        const data = JSON.parse(JSON.stringify(this.searchForm))
        data.title = this.searchForm.title ? `${this.searchForm.title}` : ''
        if (this.searchForm.updateDate) {
          data.updateDate = new Date(
            new Date(Date.parse(this.searchForm.updateDate)).getTime() +
            8 * 60 * 60 * 1000
          )
        }
        this.tableLoading = true
        this.$http.post('/api/system/web/announcement/list', data).then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res
            this.tableData = data.records ?? []
            this.noticeTotal = data.total ?? 0
            this.$nextTick(() => {
              if (this.noticeTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
          this.tableLoading = false
        })
      },

      // 查看公告
      check(item) {
        this.tit = item.title
        this.dialogVisible = true
        this.noticeFormDisabled = true
        this.noticeForm = JSON.parse(JSON.stringify(item))
        this.isChange = false
        if (this.timer !== null) {
          clearTimeout(this.timer)
        }
      },
      // 修改公告
      edit(item) {
        this.tit = '编辑公告'
        this.dialogVisible = true
        this.noticeFormDisabled = false
        this.noticeForm = JSON.parse(JSON.stringify(item))
        this.noticeForm.allTime = [new Date(item.startTime), new Date(item.endTime)]
        this.noticeForm.typeInit = item.type
        this.noticeForm.allTimeInit = [new Date(item.startTime), new Date(item.endTime)]
        if (this.timer !== null) {
          clearTimeout(this.timer)
        }
      },

      // 查看电梯明细
      del(item) {
        this.$confirm('此操作将永久删除选中的公告, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.tit = '删除公告'
          const data = {
            ids: item.id,
          }
          this.$http
            .delete(`/api/system/web/announcement/remove?ids=${item.id}`)
            .then((res) => {
              if (res.data.success) {
                if (this.tableData.length === 1) {
                  this.searchForm.current -= 1
                }
                this.onRefer()
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
      },

      addDj() {
        this.tit = '新增公告'
        this.dialogVisible = true
        this.noticeFormDisabled = false
        this.noticeForm = {
          status: 0,
        }
      },

      changeData(value) {
        this.key++
      },
      //获取焦点事件
      focus(event) {
        event.enable(false) //设置富文本编辑器不可编辑
      },
      onChange(event) {
        // event.quill.enable(false) //设置富文本编辑器不可编辑
      },
      infoElevatorDialogClose() {
        this.noticeForm = {}
        this.dialogVisible = false
      },
    },
    // watch: {
    //   noticeForm: {
    //     handler(newRoom, oldRoom) {
    //       if (oldRoom.title && newRoom.title) {
    //         this.isChange = true
    //       }
    //     },
    //     deep: true,
    //   },
    // },
    created() {
      this.getNotice('公告类型', [
        'NOTICE_ADMIN',
        'NOTICE_TYPE_COMMON',
        'NOTICE_TYPE_SUP',
      ])
      this.onRefer()
    },
    updated() {
      if (
        this.dialogVisible &&
        this.noticeForm.status === 0 &&
        this.tit !== this.noticeForm.title
      ) {
        if (
          this.noticeForm.title &&
          this.noticeForm.type &&
          this.noticeForm.detail &&
          this.isChange
        ) {
          if (this.timer !== null) {
            clearTimeout(this.timer)
          }
          this.timer = setTimeout(() => {
            this.isChange = false
            this.dioSub()
            clearTimeout(this.timer)
          }, 10000)
        }
      } else {
        this.isChange = false
      }
    },
  }
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchBtn {
    margin-left: 15px;
  }

  .wid90 {
    width: 90%;
  }

  /deep/.el-dialog__header span {
    color: #00f6ff !important;
    width: 80%;
    text-align: center;
  }
</style>
