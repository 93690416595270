<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">类型</span>
          </span>
          <el-select v-model="searchForm.type" placeholder="请输入" clearable>
            <el-option label="全部" value="" />
            <el-option label="一级目录" value="1" />
            <el-option label="二级目录" value="2" />
            <el-option label="文件" value="3" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">展示状态</span>
          </span>
          <el-select v-model="searchForm.status" placeholder="请输入" clearable>
            <el-option label="全部" value="" />
            <el-option label="展示" value="0" />
            <el-option label="不展示" value="1" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button type="success" size="small" v-focus @click="addDj">新增</el-button>
      <template v-if="noticeTotal != 0">
        <el-table ref="multipleTable" :data="tableData" :height="$store.state.tabHeight" v-loading='loading'
          style="width: 100%; margin-top: 20px; margin-bottom: 20px">
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="name" label="名称" min-width="250px" />
          <el-table-column align="center" prop="type" label="类型" width="100px">
            <template slot-scope="scope">
              {{
                scope.row.type == 1 ? '一级目录' : (scope.row.type == 2 ? '二级目录' : '文件')
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="parentDirectory" label="上级目录" min-width="200px" />
          <el-table-column align="center" prop="number" label="序号" min-width="100px" />
          <el-table-column align="center" prop="status" label="展示状态" min-width="100px">
            <template slot-scope="scope">
              {{scope.row.type == 3 ? (scope.row.status == 1 ? '不展示' : '展示') : '' }}
            </template>
          </el-table-column>

          <el-table-column align="center" prop="createTime" label="创建时间" min-width="150px" />
          <el-table-column fixed="right" align="center" label="操作" class="fixedRight" width="300px">
            <template slot-scope="scope">
              <el-button v-if="scope.row.url" @click="download(scope.row.url)" type="primary" size="small" v-focus>下载
              </el-button>
              <el-button v-if="scope.row.type == 3" @click="collection(scope.row)" type="primary" size="small"
                v-focus>收藏用户
              </el-button>
              <el-button @click="edit(scope.row)" type="warning" size="small" v-focus>编辑
              </el-button>
              <el-button @click="del(scope.row)" type="danger" size="small" v-focus>删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="noticeTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="noticePage" />
      </template>
      <div class="nullDate" v-else v-loading='loading'>
        <img v-if="!loading" src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </div>
    <CollectionTable v-if="collectionVisibile" ref="collectionVisibile" @closeDialog="closeDialog" />
    <ZlzxAction v-if="actionVisibile" ref="actionVisibile" :tit="tit" :actionForm="actionForm" @updateList="onRefer"
      @closeAction="closeAction" />
  </div>
</template>
<script>
  import Pagination from '../../../components/Pagination'
  import CollectionTable from './components/collectionTable.vue'
  import ZlzxAction from './components/zlzxAction.vue'
  export default {
    components: {
      Pagination,
      CollectionTable,
      ZlzxAction
    },
    data() {
      return {
        // 表格参数
        loading: true,
        searchForm: {
          current: 1,
          size: 10,
          type: '',
          status: ''
        },
        tableData: [],
        // 分页参数
        noticeTotal: 0,
        collectionVisibile: false,
        tit: '',
        actionForm: {},
        actionVisibile: false

      }
    },
    methods: {
      // 分页
      noticePage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer()
      },

      searchOnRefer() {
        this.searchForm.current = 1
        this.onRefer()
      },
      // 重置
      reset() {

        this.searchForm = {
          current: 1,
          size: 10,
          type: '',
          status: ''
        }
        this.onRefer()
      },

      // 获取公告
      onRefer() {
        const data = JSON.parse(JSON.stringify(this.searchForm))
        this.$http.post('/api/base/data/getDataCenterList', data).then((res) => {

          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res
            this.tableData = data.records ?? []
            this.noticeTotal = data.total ?? 0
            this.$nextTick(() => {
              if (this.noticeTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
          this.loading = false
        })
      },

      addDj() {
        this.tit = '新增资料'
        this.actionForm = {}
        this.actionVisibile = true
      },
      // 修改公告
      edit(item) {
        this.tit = '编辑资料'
        this.$http.get(`/api/base/data/getDataCenterDetail?id=${item.id}`)
          .then((res) => {
            if (res.data.success) {

              this.actionForm = res.data.data
              this.actionVisibile = true
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      closeAction() {
        this.actionVisibile = false
      },

      // 查看电梯明细
      del(item) {
        this.$confirm('是否继续当前操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$http
            .delete(`/api/base/data/deletedDataCenter?id=${item.id}`)
            .then((res) => {
              if (res.data.success) {
                if (this.tableData.length === 1) {
                  this.searchForm.current -= 1
                }
                this.onRefer()
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
      },

      download(url) {
        window.open(url, '_blank')
      },

      infoElevatorDialogClose() {
        this.noticeForm = {}
        this.dialogVisible = false
      },


      // 收藏用户
      collection(data) {
        this.collectionVisibile = true
        this.$nextTick(() => {
          this.$refs.collectionVisibile.init(data.id)
        })
      },
      closeDialog() {
        this.collectionVisibile = false
      }
    },
    created() {
      this.onRefer()
    },
    updated() {

    },
  }
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchBtn {
    margin-left: 15px;
  }

  .wid90 {
    width: 90%;
  }

  /deep/.el-dialog__header span {
    color: #00f6ff !important;
    width: 80%;
    text-align: center;
  }
</style>
