<template>
  <el-dialog :title="'选择电梯'" :visible.sync="visible" append-to-body :close-on-click-modal="false" width="1400px">
    <div class="admin-project-myproject-container">
      <el-form inline :model="selectElevatorListQuery">
        <el-form-item label="电梯名称">
          <el-input v-model="selectElevatorListQuery.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selectElevatorListGet">查询</el-button>
          <el-button type="warning" @click="reset()">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table class="table-style" ref="multipleTable" v-loading="loading" :data="selectElevatorList" max-height="500px" row-key="id"
        @row-click="rowClick">
        <el-table-column label width="35">
          <template slot-scope="scope">
            <el-radio :label="scope.row.id" v-model="initArr.id">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="50px" />
        <el-table-column align="center" prop="name" label="电梯名称" min-width="150px" />
        <el-table-column align="center" prop="code" label="电梯注册代码" min-width="120px" />
        <el-table-column align="center" prop="deviceCode" label="AI摄像头ID" min-width="120px" />
        <el-table-column align="center" prop="rescueCode" label="救援识别码" min-width="120px" />
        <el-table-column align="center" prop="maintainUnitName" label="维保单位" min-width="150px" />
        <el-table-column align="center" prop="propertyUnitName" label="物业单位" min-width="120px" />
        <el-table-column align="center" prop="createUserName" label="创建人" min-width="120px" />
      </el-table>
      <Pagination v-show="selectElevatorListTotal > 0" :total="selectElevatorListTotal"
        :page.sync="selectElevatorListQuery.current" :limit.sync="selectElevatorListQuery.size"
        @pagination="selectElevatorListGet" />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="selectElevatorConfirm">确 定</el-button>
      <el-button size="small" @click="visible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import Pagination from '../../../../components/Pagination'

  export default {
    components: {
      Pagination,
    },

		data() {
			return {
				visible: false,
				loading: true,
				selectElevatorListQuery: {
					current: 1,
					size: 100,
				},
				selectElevatorList: [],
				selectElevatorListTotal: 0,
				selectElevatorCallback: null,
				selectRow: {},
				initArr: {
					lab: null,
					id: null
				},
			}
		},
		methods: {
			rowClick(row) {
				this.initArr = {
					lab: row.name,
					id: row.id
				}
			},
			init(data, callback) {
				this.selectElevatorCallback = callback
				this.initArr = {}
				if (data) {
					this.initArr = {
						lab: data.name,
						id: data.id
					}
				}
				this.visible = true
				this.loading = false
				this.selectElevatorListQuery = {
					current: 1,
					size: 10,
				}
				this.$nextTick(() => {
					this.selectElevatorListGet()
				})
			},

      reset() {
        this.selectElevatorListQuery = {
          current: 1,
          size: 10,
        }
        this.selectElevatorListGet()
      },


			selectElevatorListGet() {
				const params = this.selectElevatorListQuery
				this.loading = true
				this.$http.post('/api/ele/web/elevatorInfo/list', params).then((res) => {
					if (res.data.success) {
						const {
							data: {
								data
							},
						} = res
						this.selectElevatorList = data.records ?? []
						this.selectElevatorListTotal = data.total ?? 0
						this.$nextTick(() => {
							if (this.selectElevatorListTotal > 0 && this.$refs.multipleTable) {
								this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
							}
						})
					}
					this.loading = false
				})
			},

      async selectElevatorConfirm() {
        const elevatorData = this.initArr

        if (elevatorData != null) {
          this.$emit('transferElevator', elevatorData)
          this.visible = false
        } else {
          this.$message({
            message: '请选择电梯',
            type: 'error',
          })
        }
      },
    },
  }
</script>
