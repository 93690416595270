<template>
  <el-dialog
    :title="`${orderInfo.elevatorName}(安全码：${orderInfo.securityCode})`"
    v-if="detailVisible"
    :visible.sync="detailVisible"
    :close-on-click-modal="false"
    top="10vh"
    @close="back"
    width="1000px"
  >
    <div
      class="scrollElement"
      style="height: 65vh; width: 90%; margin: auto; overflow-y: scroll; font-size: 16px;"
    >
    <el-row>
      <el-col span="15">
        <div class="infoBoxTop">
          <div class="infoBox">
            <div class="infoBoxTitle">工单状态</div>
            <div>{{orderInfo.status == 0 ? '未接单' : '已接单'}}</div>
          </div>
          <div class="infoBox" style="width: 30%;">
            <div class="infoBoxTitle">工单生成时间</div>
            <div>{{orderInfo.createDate}}</div>
          </div>
        </div>
        <el-tabs v-model="activeName" style="margin-top: 15px; font-size: 16px;">
          <el-tab-pane label="基础信息" name="first">
            <div style="display: flex; flex-flow: wrap;">
              <div class="tabBox">
                <div class="infoBoxTitle">电梯名称</div>
                <div>{{orderInfo.elevatorName}}</div>
              </div>
              <div class="tabBox">
                <div class="infoBoxTitle">所在地区</div>
                <div>{{orderInfo.region}}</div>
              </div>
              <div class="tabBox">
                <div class="infoBoxTitle">详细地址</div>
                <div>{{orderInfo.community}}</div>
              </div>
              <div class="tabBox">
                <div class="infoBoxTitle">电梯设备代码</div>
                <div>{{orderInfo.deviceNo}}</div>
              </div>
              <div class="tabBox">
                <div class="infoBoxTitle">电梯注册代码</div>
                <div>{{orderInfo.deviceSn}}</div>
              </div>
              <div class="tabBox">
                <div class="infoBoxTitle">电梯安全码</div>
                <div>{{orderInfo.securityCode}}</div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="维保信息" name="second">
            <div style="display: flex; flex-flow: wrap;">
              <div class="tabBox" style="width: 100%">
                <div class="infoBoxTitle">维保单位</div>
                <div>{{orderInfo.maintCompany}}</div>
              </div>
              <div class="tabBox">
                <div class="infoBoxTitle">维保人员1</div>
                <div>{{orderInfo.maintFristPerson}}</div>
              </div>
              <div class="tabBox">
                <div class="infoBoxTitle">电话</div>
                <div>{{orderInfo.maintfristTel}}</div>
              </div>
              <div class="tabBox">
                <div class="infoBoxTitle">维保人员2</div>
                <div>{{orderInfo.maintSecondPerson}}</div>
              </div>
              <div class="tabBox">
                <div class="infoBoxTitle">电话</div>
                <div>{{orderInfo.maintSecondTel}}</div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        
        <div>
            <div class="infoBoxTitle">通话录音</div>
            <audio style="height: 40px;margin-top: 20px;width:100%;" :src="orderInfo.callRecording" controls autoplay>
              您的浏览器不支持 audio 标签。
            </audio>
            <el-button size="small" type="primary" icon="el-icon-download" style="margin-top: 20px" @click="download">下载录音</el-button>
        </div>
      </el-col>
      <el-col span="9" class="infoLeft">
          <h3>救援信息</h3>
          <div class="lineBox">
            <div class="lineTitle">被困人员</div>
            <div class="lineMsg">{{orderInfo.trappedPersonTel}}</div>
          </div>
          <div class="lineBox">
            <div class="lineTitle">被困时间</div>
            <div class="lineMsg">{{orderInfo.createTime}}</div>
          </div>
          <h3>处理信息</h3>
          <div class="lineBox">
            <div class="lineTitle">处理人</div>
            <div class="lineMsg">{{orderInfo.handler}}</div>
          </div>
          <div class="lineBox">
            <div class="lineTitle">联系电话</div>
            <div class="lineMsg">{{orderInfo.handleContactTel}}</div>
          </div>
          <div class="lineBox">
            <div class="lineTitle">处理部门</div>
            <div class="lineMsg">{{orderInfo.maintCompany}}</div>
          </div>
          <div class="lineBox">
            <div class="lineTitle">处理时间</div>
            <div class="lineMsg">{{orderInfo.handleTime}}</div>
          </div>
      </el-col>
    </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="back">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: ["orderInfo"],
  data() {
    return {
      detailVisible: true,
      activeName: 'first'
    };
  },
  mounted(){
  },
  methods: {
    download(){
      window.open(this.orderInfo.callRecording)
    },
    back() {
      this.detailVisible = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.infoBoxTop{
  display: flex;
}
.infoBox{
  width: 25%;
}
/deep/.el-tabs__item{
  font-size: 16px;
}
.tabBox{
  width: 50%;
  margin-bottom: 25px;
}
.infoBoxTitle{
  color: #aaa;
  margin-bottom: 15px;
  font-weight: bolder;
}
.infoLeft{
  padding-left: 30px;
  border-left: 1px solid #ccc;
  height: 60vh;
}
.lineBox{
  margin: 25px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.lineTitle{
  color: #aaa;
  font-weight: bolder;
  width: 65px;
}
.lineMsg{
  margin-left: 35px;
}
</style>
