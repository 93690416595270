<template>
  <el-dialog title="时间轴" v-if="detailVisible" :visible.sync="detailVisible" :close-on-click-modal="false" @close="back"
    width="800px">
    <div class="scrollElement" style="height: 60vh;width: 80%;margin: auto; overflow-y: scroll;">
        <el-timeline v-if="rescue.length > 0">
            <el-timeline-item
                style="width: 100%;text-align: left;"
                v-for="(activity, index) in rescue"
                :key="index">
                <div class="messageBox">
                    <div class="flexBox">
                        <h3 style="margin: 0;">
                            {{activity.nodeName}}
                        </h3>
                        <h3>
                            <span style="font-size: 14px;">
                                {{activity.createTime}}
                            </span>
                        </h3>
                    </div>
                    <div class="flexBox" :style="{'justify-content': activity.type == 4 ? 'flex-end' : 'space-between'}">
                        <p class="titleBox" v-show="activity.type != 4">
                            {{activity.contentName}}
                        </p>
                        <div :style="{'color': activity.type == 1 ? '#798086' : (activity.isSuccess == 0 ? '#fe9736' : '#1abe6b')}">
                            <i v-if="activity.type == 1" class="el-icon-edit"/>
                            <i v-else :class="activity.isSuccess == 0 ? 'el-icon-warning-outline' : 'el-icon-circle-check'"/>
                            <span v-if="activity.type == 1">发出工单</span>
                            <span v-else>
                                <span v-if="activity.type != 3">
                                    {{ activity.isSuccess == 0 ? '未完成' : '已完成'}}
                                </span>
                                <span v-else>
                                    {{ activity.isSuccess == 0 ? '未接通' : '已接通'}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <p class="titleBox phoneBox" v-if="activity.type == 3">{{activity.contentRemark}}</p>


                </div>


            </el-timeline-item>
        </el-timeline>
        <div class="nullDate" v-else>
            <img src="@/assets/nullDate.png" style="width: 260px" />
        </div>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="back">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    components:{
    },
    props: ['order'],
    data() {
      return {
        detailVisible: true,
        rescue: [],
      }
    },
    created() {
      // this.getDate()
    },
    methods: {
      getDate() {
        this.$http.get('http://117.73.3.135:8080/elevator-96133/work/node/list').then(res => {
          if (res.data.code == 200) {
            const {data} = res
            this.rescue = data.rows
          }
        })
      },
      back() {
        this.detailVisible = false
        this.$emit('close')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.messageBox{
    margin: 0 auto;
    width: 90%;
}
.flexBox{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.titleBox{
    margin: 0;
    padding: 5px 20px;
    background: #e7e7e7;
    display: inline-block;
    border-radius: 10px;
    font-weight: bold;
    color: #666;
}
.phoneBox{
    margin-top: 15px;
    display: grid;
    border-radius: 4px;
}
</style>
