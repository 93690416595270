<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">品牌名称</span>
          </span>
          <el-input v-model="formInline.name" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="primary" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div style="display: flex;">
        <el-button v-focus type="warning" @click="dowMode(pageType)" size="small">下载模版</el-button>
        <el-button v-focus type="success" @click="addDj" size="small">新增</el-button>
        <form v-focus name="form1" style="width: 56px;margin: 0 10px;">
          <input type="file" id="docxFile" @change="readFile($event)" style="position:absolute; clip:rect(0 0 0 0);">
          <el-button type="primary" @click="toLead" size="small">导入</el-button>
        </form>

      </div>
    </div>
    <div v-if="dictionarTotal!=0">
      <el-table :data="tableData" v-loading="tableLoading" ref="multipleTable" style="width: 100%;margin-bottom: 20px;"
        :height="$store.state.tabHeight">
        <el-table-column type="index" label="序号" width="50px" />
        <el-table-column align='center' prop="name" label="品牌名称" />
        <el-table-column align='center' label="品牌说明">
          <template slot-scope="scope">
            <span :title="scope.row.explainName" class="memoShow">
              {{scope.row.explainName}}
            </span>
          </template>
        </el-table-column>
        <el-table-column align='center' prop="eleNum" label="电梯数量" />
        <el-table-column align='center' label="备注">
          <template slot-scope="scope">
            <span :title="scope.row.memo" class="memoShow">
              {{scope.row.memo}}
            </span>
          </template>
        </el-table-column>
        <el-table-column align='center' prop="updateTime" label="更新时间" />
        <el-table-column align='center' prop="createUserName" label="创建人"  v-if=" adminInfo.userName=='admin' "/>
        <el-table-column fixed="right" align='center' prop="address" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="checkDet(scope.row)">电梯明细</el-button>
            <el-button v-focus @click="show(scope.row)" type="primary" size="small">查看</el-button>
            <el-button v-focus v-if=" adminInfo.userName=='admin' || adminInfo.id == scope.row.createUser "
              @click="upd(scope.row)" type="primary" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>
    <el-dialog v-if='dialogVisible' :close-on-click-modal='false' :title="tit == '查看' ? '电梯品牌详情' : tit+'电梯品牌'"
      :visible.sync="dialogVisible" width="700px" top='20vh'>
      <div style="height: 32vh;overflow-y: auto;" class="scrollElement">
        <el-form :rules="rules" ref="ruleForm" label-position="right" label-width="150px" :model="fign">
          <el-row style="margin:10px 0">
            <el-col :span="24">
              <el-form-item label="电梯品牌名称:" prop="name">
                <el-input :disabled="tit=='查看'" v-model="fign.name" placeholder="请输入电梯品牌名称" class="einp">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label=" 电梯品牌说明:" prop="explainName">
                <el-input :disabled="tit=='查看'" type="textarea" :rows="3" resize='none' v-model="fign.explainName"
                  placeholder="请输入电梯品牌说明" class="einp">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注:" prop="memo">
                <el-input :disabled="tit=='查看'" type="textarea" :rows="3" resize='none' v-model="fign.memo"
                  placeholder="请输入备注" class="einp">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="tit!='查看'" size="small" type="primary" @click="dioSub">确 定</el-button>
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 错误信息列表 -->
    <errorDio v-if="errorList!=null" :errorLists="errorList" @dioClose='dioCloseB'></errorDio>
    <CheckEle v-if="checkVisible" ref="CheckEle" @colseEleFrom="colseEleFrom"></CheckEle>
  </div>
</template>
<script>
  var validateName = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,16}$/
    // true：必填  false：非必填
    inpYz(rule, value, callback, true, operation, '可输入中文，字母，数值，长度为2-16')
  };

  var validateMemo = (rule, value, callback) => {
    let operation = /^.{2,50}$/
    // true：必填  false：非必填
    inpYz(rule, value, callback, true, operation, '可输入中文，字母，数值，长度为2-50')
  };

  import errorDio from '@/components/errorDio.vue'
  import Pagination from '@/components/Pagination'
  import FileUpload from '@/components/FileUpload'
  import CheckEle from './components/CheckEle.vue'
  export default {
    components: {
      errorDio,
      FileUpload,
      Pagination,
      CheckEle
    },
    data() {
      let _this = this;
      return {
        // 页面对应的type
        pageType: 'ELEVATOR_BRAND',
        // 错误信息列表
        errorList: null,
        //电梯明细
        checkVisible: false,
        // 地区选择器
        props: {
          label: 'name',
          value: 'areaCode',
          checkStrictly: true
        },
        // 表格参数
        formInline: {
          current: 1,
          size: 10,
        },
        tableData: [],
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        // 分页参数
        dictionarTotal: 0,
        // dialog参数
        tit: '',
        dialogVisible: false,
        fign: {},
        // 表单限制
        rules: {
          name: [{
            required: true,
            validator: validateName,
            trigger: 'blur'
          }],
          // 产品说暂定
          // explainName: [{
          // 	required: false,
          // 	validator: validateMemo,
          // 	trigger: 'blur'
          // }],
          // memo: [{
          // 	required: false,
          // 	validator: validateMemo,
          // 	trigger: 'blur'
          // }]
        },
        tableLoading: true
      }
    },
    methods: {
      // 错误列表回调
      dioCloseB() {
        this.errorList = null
      },
      // 导入
      toLead() {
        document.form1.docxFile.click()
      },
      readFile(file) {
        let _this = this;
        let docxFile = file.target.files[0];
        if (docxFile == undefined) {
          return
        }
        let hasFile = false;
        let fileName = docxFile.name;
        let fileArr = file.target.files[0].name.split(".");
        let files = docxFile;
        if (!file) {
          this.$message("请先选择正确的文件类型上传");
        } else {
          let fileData = new FormData();
          fileData.append("file", files);
          fileData.append("type", files.type);
          let config = {
            onUploadProgress: (e) => {
              //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
              //如果lengthComputable为false，就获取不到e.total和e.loaded
              if (e.lengthComputable) {
                let rate = e.loaded / e.total; //已上传的比例
                if (rate < 1) {
                  //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
                  //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
                  //等响应回来时，再将进度设为100%
                  let uploadRate = (rate * 100).toFixed(2) + "%";
                }
              }
            },
          };
          this.$http
            .post(`/api/ele/web/batch/saveData/${this.pageType}`, fileData, config)
            .then((res) => {
              if (res.data.success) {
                this.onRefer()
                this.$message.success(res.data.msg)
              } else {
                this.errorList = res.data.data
                this.$message.error(res.data.msg)
              }
              file.target.value = ''
            });
        }
      },
      // 下载模版
      dowMode(type) {
        downloadMode(type)
      },
      // 重置按钮事件
      reset() {

        this.formInline = {
          current: 1,
          size: 10
        }
        this.onRefer()
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      // 弹出框提交
      dioSub() {
        console.log('this.fign', this.fign)

        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let data = JSON.parse(JSON.stringify(this.fign))
            let url = '/api/ele/web/brand/submit'
            this.$http
              .post(url, data)
              .then((res) => {
                if (res.data.success) {
                  this.onRefer()
                  this.dialogVisible = false
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
              })
          } else {
            this.$message.warning('请验证填写内容!');
            return false;
          }
        })
      },
      //电梯明细
      checkDet(item) {
        this.checkVisible = true
        console.log(item)
        this.$nextTick(() => {
          this.$refs.CheckEle.init(item.id, (refresh) => {
            if (refresh) {}
          })
        })
      },
      colseEleFrom() {
        this.checkVisible = false
      },
      // 表格方法
      onRefer(ishm) {
        if (ishm) {
          this.formInline.current = 1
        }
        this.tableLoading = true
        this.$http
          .post("/api/ele/web/brand/list", this.formInline)
          .then((res) => {

            if (res.data.success) {
              this.tableData = res.data.data.records
              this.dictionarTotal = res.data.data.total
              this.$nextTick(() => {
                if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
            this.tableLoading = false
          })
      },
      // 编辑
      upd(item) {
        this.tit = '编辑'
        this.fign = JSON.parse(JSON.stringify(item))
        this.dialogVisible = true
        console.log('item', this.fign)
      },
      addDj() {
        this.tit = '新增'
        this.fign = {}
        this.dialogVisible = true
      },
      show(item) {
        this.tit = '查看'
        this.fign = JSON.parse(JSON.stringify(item))
        this.dialogVisible = true
      }
    },
    created() {
      this.onRefer()
    }
  }
</script>
<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }


  .memoShow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
</style>
