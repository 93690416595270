<template>
	<div v-loading="loading">
		<div style="width: 88%;height: 100%;">
			<video style="width: 95%;height: 100%;" controls autoplay muted :id="`onPlayJk${playNum}`" />
			<video v-show="false" style="width: 100px;height: 100px;" controls autoplay id="mePlayJk" />
		</div>
		<div class="conBox" v-if="isCall">
			<el-button :disabled='isDial||isble' @click="dialZlv" title="拨打" type="success"
				style="margin-left: 10px;margin-bottom: 40px;" icon="el-icon-microphone" circle></el-button>
			<el-button :disabled='!isDial||isble' @click="dropped" title="挂断" type="danger" style="margin-bottom: 40px;"
				icon="el-icon-turn-off-microphone" circle></el-button>
			<el-button @click="ladder" title="阻梯" type="warning" icon="el-icon-s-release" circle></el-button>
		</div>
	</div>
</template>

<script>
	let obj = []
	var userAgent = null
	export default {
		props: ['jkData', 'isCall', 'playNum'],
		data() {
			return {
				sipsession: null, // 通话实例
				player: null,
				loading: true,
				// 通话
				isDial: false,
				isble: false,
			}
		},
		mounted() {
			console.log('我是c1')
			setTimeout(() => {
				obj = JSON.parse(sessionStorage.getItem('me_id')).sip_info
				this.init()
			}, 1000)
		},
		beforeDestroy() {
			if (this.player) {
				this.player.destroy()
				this.player = null
				console.log('卸载jk')
			}
		},
		methods: {
			dialZlv() {
				let agentData = {
					v_type: 0,
					act: 'ma_set_sip_info',
					deviceId: this.jkData.device_id
				}
				this.socketApi.sendSock(agentData, (e) => {
					// 下列参数写死
					var host = obj.sip_host; // 域名
					var user = obj.sip_id; // 注册人id
					var pwd = obj.sip_pwd; // 密码
					var wss = obj.wss_url; // 长连接地址
					var iceHost = obj.stun_host; // stun地址
					var turnUser = obj.turn_user; // turn用户名
					var turnPwd = obj.turn_pwd; // turn密码

					var config = {
						uri: user + '@' + host,
						transportOptions: {
							wsServers: [wss]
						},
						authorizationUser: user,
						password: pwd,
						sessionDescriptionHandlerFactoryOptions: {
							peerConnectionOptions: {
								rtcConfiguration: {
									iceServers: [{
											urls: 'stun:' + iceHost
										},
										{
											urls: 'turn:' + iceHost,
											username: turnUser,
											credential: turnPwd
										}
									]
								}
							}
						}
					};
					userAgent = new SIP.UA(config);
					userAgent.on('registered', function() {
						console.log('registered')
					});
					userAgent.on('invite', function(session) {
						var url = session.remoteIdentity.uri.toString() + "--->call";
						//接受来电
						session.accept({
							sessionDescriptionHandlerOptions: {
								constraints: {
									audio: true,
									video: {
										width: 1280,
										height: 720
									}
								}
							}
						});
						this.sipsession = session;
					});
					this.isble = true
					this.isDial = false
					setTimeout(() => {
						var hostc = obj.sip_host;
						var toc = this.jkData.sip_id;
						console.log('我等了两秒', toc)
						this.sipsession = userAgent.invite(toc + '@' + hostc, {
							sessionDescriptionHandlerOptions: {
								constraints: {
									audio: true,
									video: {
										faceMode: 'user', //facemode是一个选择摄像头的约束，在移动设备上效果很好，有user（用户，前置摄像头）、environment（环境，后置摄像头）、left、right四种选项
										width: 160,
										height: 120,
										frameRate: 16,
									}
								}
							}
						});
						let _this = this
						setTimeout(() => {
							_this.isble = false
						}, 10000)
						this.sipsession.on('accepted', function() {
							console.log('接通了')
							// 按钮禁点2.5秒防止对象未操作就挂断
							_this.isDial = true
							_this.isble = false
							var pc = _this.sipsession.sessionDescriptionHandler.peerConnection;
							var remoteStream = new MediaStream();
							pc.getReceivers().forEach(function(receiver) {
								remoteStream.addTrack(receiver.track);
							});
							var mePlayJk = document.getElementById('mePlayJk');
							mePlayJk.srcObject = remoteStream;
							mePlayJk.play();
						});
						this.sipsession.on('rejected', (response, cause) => {
							console.log('onRejected - ', response, cause)
						});
						this.sipsession.on('failed', (response, cause) => {
							console.log('onFailed - ', response, cause)
						});
						this.sipsession.on('terminated', (message, cause) => {
							console.log('onTerminated - ', message, cause)
						});
					}, 2000)
				})
			},

			// 阻梯
			ladder() {
				console.log('阻梯');
				let agentData = {
					act: 'ma_change_ele_status',
					deviceId: this.jkData.device_id,
					type: this.type
				}
				this.socketApi.sendSock(agentData, (e) => {
					if (e.cmd == 'ma_change_ele_status' && e.status) {
						this.$message.success(e.msg)
						this.type = this.type == 1 ? 2 : 1
					} else {
						this.$message.error(e.msg)
					}
				})
			},

			dropped() {
				this.isDial = false
				if (this.sipsession) this.sipsession.terminate();
			},
			// 监控播放400, 查看监控播放状态
			onFail() {
				setTimeout(() => {
					if (this.player) {
						this.player.destroy()
						this.player = null
					}
					let agentData = {
						act: 'ca_start_rtsp',
						device_id: this.jkData.device_id
					}
					this.socketApi.sendSock(agentData, (e) => {
						if (e.status) {
							if (e.cmd == 'ca_start_rtsp') {
								this.init()
							}
						} else {
							this.$message.error('流文件信息或长连接错误！')
						}
					})
				}, 4 * 1000)
			},
			// 监控开始播放
			startPlay() {
				this.loading = false
			},
			init() {
				const jswebrtc = document.getElementById(`onPlayJk${this.playNum}`)
				const openUrl = this.jkData.play_url
				const playUrl = openUrl[openUrl.length - 1]
				let apiUrl = 'https://tlw-test.runde.pro/rtc/v1/play/'

				let curIP = window.location.host
				if (curIP.indexOf('localhost') == -1 && curIP.indexOf('192.168.') == -1) {
					apiUrl = 'https://' + curIP + '/rtc/v1/play/'
				}
				this.player = new JSWebrtc.Player(apiUrl, playUrl, {
					video: jswebrtc,
					autoplay: true,
					onPlay: this.startPlay,
					onFail: this.onFail
				})
				jswebrtc.muted = false
			}
		}
	}
</script>

<style scoped>
	.conBox {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 6%;
		height: 100%;
	}
</style>
