<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" ref="searchForm" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <!-- <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯注册代码</span>
          </span>
          <el-input
            v-model="searchForm.code"
            placeholder="请输入"
            clearable
          />
        </el-form-item> -->
        <el-form-item>
          <span slot="label">
            <span class="lab-span">AI摄像头ID</span>
          </span>
          <el-input v-model="searchForm.deviceCode" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">识别状态</span>
          </span>
          <el-select v-model="searchForm.recognitionState" placeholder="请输入" clearable filterable>
            <el-option v-for="(item, index) in recongtion" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">状态</span>
          </span>
          <el-select v-if="adminInfo.userName != 'scjg'" v-model="searchForm.status" placeholder="请输入" clearable>
            <el-option v-if="adminInfo.userName == 'admin'" label="真实在线" :value="-1" />
            <el-option label="全部" :value="0" />
            <el-option label="在线" :value="1" />
            <el-option label="离线" :value="2" />
            <el-option v-if="adminInfo.userName == 'admin'" label="24小时在线" :value="10" />
          </el-select>
          <el-select v-else v-model="searchForm.status" placeholder="请输入" clearable>
            <el-option label="全部" :value="1" />
            <!-- <el-option label="在线" :value="1" /> -->
          </el-select>
        </el-form-item>
        <!-- 测试专用 -->
        <el-form-item v-if="isShow">
          <span slot="label">
            <span class="lab-span">电梯数字终端ID</span>
          </span>
          <el-input v-model="searchForm.terminalId" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item v-if="isShow">
          <span slot="label">
            <span class="lab-span">维保单位</span>
          </span>
          <el-select v-model="searchForm.maintainUnitId" placeholder="请输入" clearable filterable>
            <el-option v-for="(item, index) in maintainUnit" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item v-if="isShow">
          <span slot="label">
            <span class="lab-span">物业单位</span>
          </span>
          <el-select v-model="searchForm.propertyUnitId" placeholder="请输入" clearable filterable>
            <el-option v-for="(item, index) in propertyUnit" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="isShow">
          <span slot="label">
            <span class="lab-span">保险单位</span>
          </span>
          <el-select v-model="searchForm.insuranceUnitId" placeholder="请输入" clearable filterable>
            <el-option v-for="(item, index) in insuranceUnit" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="isShow">
          <span slot="label">
            <span class="lab-span">小区名称</span>
          </span>
          <el-select v-model="searchForm.plotName" filterable clearable placeholder="请选择">
            <el-option v-for="item in selectDate" :key="item.id" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
          <!-- <el-input v-model="searchForm.plotName" placeholder="请输入" clearable /> -->
        </el-form-item>
        <template v-if="moreSearch">
          <el-form-item>
            <span slot="label">
              <span class="lab-span">SIM卡号</span>
            </span>
            <el-input v-model="searchForm.phoneNumber" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item v-if="isShow">
            <span slot="label">
              <span class="lab-span">公共服务屏</span>
            </span>
            <el-select v-model="searchForm.screenStatus" clearable>
              <el-option :key="1" value="" label="全部"></el-option>
              <el-option :key="2" value="0" label="未安装"></el-option>
              <el-option :key="3" value="1" label="已安装"></el-option>
              <el-option :key="4" value="2" label="无"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="isShow">
            <span slot="label">
              <span class="lab-span">服务屏类型</span>
            </span>
            <el-select v-model="searchForm.screenType" clearable>
              <el-option :key="1" value="" label="全部"></el-option>
              <el-option :key="2" value="0" label="单屏"></el-option>
              <el-option :key="3" value="1" label="双屏"></el-option>
              <el-option :key="4" value="2" label="无"></el-option>
            </el-select>

          </el-form-item>
          <el-form-item v-if="isShow">
            <span slot="label">
              <span class="lab-span">服务屏视角</span>
            </span>
            <el-select v-model="searchForm.screenAngle" clearable>
              <el-option :key="1" :value="0" label="未知"></el-option>
              <el-option :key="2" :value="1" label="清晰"></el-option>
              <el-option :key="3" :value="2" label="模糊"></el-option>
              <el-option :key="4" :value="3" label="视角外"></el-option>
              <el-option :key="5" :value="4" label="黑屏"></el-option>
              <el-option :key="6" :value="5" label="花屏"></el-option>
              <el-option :key="7" :value="6" label="其它"></el-option>
            </el-select>

          </el-form-item>
          <!-- <el-form-item >
          <span slot="label">
            <span class="lab-span">SIM卡号</span>
          </span>
          <el-input placeholder="请输入SIM卡号"></el-input>
        </el-form-item> -->
          <!-- 测试专用 -->
          <!-- <el-form-item>
            <span slot="label">
              <span class="lab-span">电梯数字终端ID</span>
            </span>
            <el-input
              v-model="searchForm.terminalId"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">小区名称</span>
            </span>
            <el-input
              v-model="searchForm.plotName"
              placeholder="请输入"
              clearable
            />
          </el-form-item> -->
          <el-form-item v-if="adminInfo.userName == 'admin'">
            <span slot="label">
              <span class="lab-span">软件版本</span>
            </span>
            <el-select v-model="searchForm.caVer" placeholder="请输入" filterable clearable>
              <el-option v-for="(item, index) in softwareData" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="adminInfo.userName == 'admin'">
            <span slot="label">
              <span class="lab-span">APK版本</span>
            </span>
            <el-select v-model="searchForm.appVer" placeholder="请输入" filterable clearable>
              <el-option v-for="(item, index) in APKData" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>

          <!-- <el-form-item>
              <span slot="label">
                <span class="lab-span">维保单位</span>
              </span>
              <el-select
                v-model="searchForm.maintainUnitId"
                placeholder="请输入"
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in maintainUnit"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item> -->

          <!-- <el-form-item>
              <span slot="label">
                <span class="lab-span">物业单位</span>
              </span>
              <el-select
                v-model="searchForm.propertyUnitId"
                placeholder="请输入"
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in propertyUnit"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item> -->

          <!-- <el-form-item>
              <span slot="label">
                <span class="lab-span">保险单位</span>
              </span>
              <el-select
                v-model="searchForm.insuranceUnitId"
                placeholder="请输入"
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in insuranceUnit"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item> -->
          <el-form-item>
            <span slot="label">
              <span class="lab-span">救援识别码</span>
            </span>
            <el-input v-model="searchForm.rescueCode" placeholder="请输入" clearable />
          </el-form-item>
          <!-- <el-form-item>
            <span slot="label">
              <span class="lab-span">维保人员名称</span>
            </span>
            <el-input
              v-model="searchForm.maintainUserNames"
              placeholder="请输入"
              clearable
            />
          </el-form-item> -->
          <el-form-item>
            <span slot="label">
              <span class="lab-span">创建人名称</span>
            </span>
            <el-input v-model="searchForm.createUserName" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">当日流量</span>
            </span>
            <el-input v-model="searchForm.daySimMin" placeholder="请输入最小值" clearable style="width:200px" />
            <span class="lab-span" style="margin:0 15px">至</span>
            <el-input v-model="searchForm.daySimMax" placeholder="请输入最大值" clearable style="width:200px" />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">当月流量</span>
            </span>
            <el-input v-model="searchForm.monthSimMin" placeholder="请输入最小值" clearable style="width:200px" />
            <span class="lab-span" style="margin:0 15px">至</span>
            <el-input v-model="searchForm.monthSimMax" placeholder="请输入最大值" clearable style="width:200px" />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">SD卡剩余容量</span>
            </span>
            <el-input v-model="searchForm.sdMin" placeholder="请输入最小值" clearable style="width:200px" />
            <span class="lab-span" style="margin:0 15px">至</span>
            <el-input v-model="searchForm.sdMax" placeholder="请输入最大值" clearable style="width:200px" />
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">最近离线时间</span>
            </span>
            <el-date-picker v-model="searchForm.offlineTime" type="daterange" clearable range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">激活时间</span>
            </span>
            <el-date-picker v-model="searchForm.tactivationTime" type="daterange" clearable range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">省市区</span>
            </span>
            <el-cascader :props="propCode" :options="$mapCode" v-model="searchForm.areaCodeArr" style="width: 100%;"
              collapse-tags clearable>
            </el-cascader>
          </el-form-item>
          <el-form-item v-if="adminInfo.userName == 'admin'">
            <span slot="label">
              <span class="lab-span">电池健康</span>
            </span>
            <el-input v-model="searchForm.minBattery" placeholder="请输入最小值" clearable style="width:200px" />
            <span class="lab-span" style="margin:0 15px">至</span>
            <el-input v-model="searchForm.maxBattery" placeholder="请输入最大值" clearable style="width:200px" />
          </el-form-item>
        </template>

        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer">查询</el-button>
          <el-button v-if="adminInfo.userName != 'scjg'" type="warning" @click="reset">重置</el-button>
          <el-button type="success" :disabled="exportType" @click="exportData()">{{exportTitle}}</el-button>
          <el-button v-if="!moreSearch" type="success" class="searchBtn" @click="moreBtn">更多条件</el-button>
          <el-button v-else type="success" class="searchBtn" @click="packMore">收起更多</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <template v-if="tableData != 0">
        <div :style="{left:contextMenuPosition.x+'px',top:contextMenuPosition.y+'px'}"
          v-show="contextMenuPosition.x > 0 || contextMenuPosition.y > 0 " class="contextmenuInfo"
          style="height: 40xp;">
          <ul class="contextmenus">
            <li @click="cancelTop()" v-if="topShow">置顶</li>
            <li @click="cancelDown()" v-else>取消置顶</li>
            <!-- <li>置顶</li> -->
          </ul>
        </div>
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" :height="$store.state.tabHeight-50"
          @sort-change="handleSort" style="width: 100%; margin-top: 20px; margin-bottom: 20px"
          @row-contextmenu="onRowContextMenu" >
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="name" label="电梯名称" min-width="200px" sortable="custom" />
          <el-table-column v-if="isShow" align='center' label="所属小区" width="180px">
            <template slot-scope="scope">
              {{scope.row.plotName ? scope.row.plotName : '-'}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="objStatus" label="状态" min-width="150px" />
          <el-table-column align="center" prop="rescueCode" label="救援识别码" min-width="180px" />
          <el-table-column align="center" prop="deviceCode" label="AI摄像头ID" min-width="180px">
            <template slot-scope="scope">
              {{
                scope.row.deviceCode ? scope.row.deviceCode : '-'
              }}
            </template>
          </el-table-column>
          <el-table-column v-if="isShow" align="center" prop="terminalId" label="电梯数字终端ID" min-width="150px">
            <template slot-scope="scope">
              {{
                scope.row.terminalId ? scope.row.terminalId : '-'
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="caVer" label="软件版本" width="180px" />
          <el-table-column align="center" prop="appVer" label="APK版本" width="150px" />
          <el-table-column v-if="isShow" align="center" prop="streetUnitName" label="街道单位" width="150px" />
          <el-table-column v-if="isShow" align="center" prop="screenType" label="公共服务屏" width="150px">
            <template slot-scope="scope">
              {{
              scope.row.screenStatus ? (scope.row.screenStatus==0 ? '未安装' : '已安装') : '无'
            }}
            </template>
          </el-table-column>
          <el-table-column v-if="isShow" align="center" prop="screenStatus" label="服务屏类型" width="150px">
            <template slot-scope="scope">
              <!-- {{scope.row.screenType ? (scope.row.screenType == 0 ? '单屏' : '双屏') : '无'}} -->
              {{
              scope.row.hdmiPlugStatus ?
              (scope.row.screenType ? (scope.row.screenType == 0 ? '单屏' : '双屏') : '无')
              : '未接入'
            }}
            </template></el-table-column>
          <el-table-column v-if="isShow" align="center" prop="screenAngle" label="服务屏视角" width="150px">
            <template slot-scope="scope">
              {{screenName(scope.row.screenAngle)}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="activeTime" label="激活时间" min-width="150px" sortable="custom">
            <template slot-scope="scope">
              {{
                scope.row.activeTime ? scope.row.activeTime : '-'
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="lastLogoutTime" label="最近离线时间" min-width="150px" sortable="custom">
            <template slot-scope="scope">
              {{
                scope.row.deviceCode ? (scope.row.lastLogoutTime ? scope.row.lastLogoutTime : '-' ): '-'
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" sortable="custom" label="SD卡总容量(M)" width="150px">
            <template slot-scope="scope">
              {{
                scope.row.sdTotalSize ? scope.row.sdTotalSize : '-'
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sdAvailableSize" label="SD卡剩余容量(M)" min-width="150px">
            <template slot-scope="scope">
              <!-- {{
                // scope.row.activeTime ? (scope.row.sdAvailableSize ? scope.row.sdAvailableSize : 'SD卡已损坏') : '-'
                }}
              -->
              {{
                scope.row.activeTime != '' ? (scope.row.isSdMounted == 1 ? scope.row.sdAvailableSize : ( scope.row.isSdMounted == '' ? '-' :'SD卡已损坏')) : '-'
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="daySimData" sortable="custom" label="当日流量(M)" min-width="150px">
            <template slot-scope="scope">
              {{
                scope.row.daySimData || scope.row.daySimData !== 0 ? (scope.row.daySimData >= 0 ? scope.row.daySimData : '获取数据失败') : '-'
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="monthSimData" sortable="custom" label="当月流量(M)" min-width="150px">
            <template slot-scope="scope">
              {{
                scope.row.monthSimData || scope.row.monthSimData === 0 ? (scope.row.monthSimData >= 0 ? scope.row.monthSimData : '获取数据失败') : '-'
              }}
            </template>
          </el-table-column>
          <el-table-column v-if="isShow" align="center" prop="batteryHealth" label="电池健康" min-width="150px">
            <template slot-scope="scope">
              {{
                scope.row.batteryHealth
              }}
            </template>
          </el-table-column>
          <el-table-column v-if="isShow" align="center" label="物业单位" min-width="150px">
            <template slot-scope="scope">
              {{
                scope.row.propertyUnitName ? scope.row.propertyUnitName : '-'
              }}
            </template>
          </el-table-column>
          <el-table-column v-if="isShow" align="center" label="保险单位" min-width="150px">
            <template slot-scope="scope">
              {{
                scope.row.insuranceUnitName ? scope.row.insuranceUnitName : '-'
              }}
            </template>
          </el-table-column>
          <el-table-column v-if="isShow" align="center" prop="maintainUnitName" label="维保单位" min-width="150px">
            <template slot-scope="scope">
              {{
                scope.row.maintainUnitName ? scope.row.maintainUnitName : '-'
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="eleAddress" label="电梯安装位置" min-width="180px" />
          <!-- <el-table-column
            align="center"
            prop="maintainUserNames"
            label="维保人员"
            min-width="200px"
          >
            <template slot-scope="scope">
              {{
                scope.row.maintainUserNames ? scope.row.maintainUserNames : '-'
              }}
            </template>
          </el-table-column> -->
          <el-table-column align="center" prop="createUserName" label="创建人" width="150px" />
          <el-table-column align="center" label="识别状态" width="150px">
            <template slot-scope="scope">
              {{
            		scope.row.recognitionState ?
            			(recongtion.find(item => item.value == scope.row.recognitionState) ?
            				recongtion.find(item => item.value == scope.row.recognitionState).label : '-')
            		: '-'
            	}}
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" prop="address" label="操作" width="300px">
            <template slot-scope="scope">
              <el-button @click="check(scope.row)" type="primary" size="small">查看
              </el-button>
              <!-- <el-button
                @click="reset(scope.row)"
                type="warning"
                size="small"
              >复位激活时间
              </el-button> -->
              <el-button v-focus @click="restart(scope.row)" type="danger" size="small">重启
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="maintenRuleTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="maintenRulePage" />
      </template>

      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>
    <MonitorInfo ref="MonitorInfo" v-if="MonitorInfoVisible" :eleInfo='eleInfo' :DetailsInfo="DetailsInfo" :activeNameProps="'dtjk'"
      :elevatorId="elevatorId" :deviceCode="deviceCode" @closeMonitor="closeMonitor" />
  </div>
</template>
<script>
  import Pagination from '../../../components/Pagination'
  import MonitorInfo from './components/monitorInfo'
  export default {
    components: {
      Pagination,
      MonitorInfo,
    },
    data() {
      var validateFirstParty = (rule, value, callback) => {
        inpFirstPartyName(rule, value, callback)
      }
      return {
        // 定时器
        getOlineTime: null,
        // 表格参数
        searchForm: {
          current: 1,
          size: 10,
          status: 1,
          screenStatus: '',
          screenType: ''
        },
        searchForm1: {
          current: 1,
          size: 10,
          status: 0,
        },
        softwareData: [],
        APKData: [],
        tableData: [],
        // 分页参数
        maintenRuleTotal: 0,
        eleInfo: null, //总数据
        DetailsInfo:null,
        recongtion: [], //识别状态
        elevatorId: '', // 电梯id
        deviceCode: '', // 设备号
        MonitorInfoVisible: false,
        maintainUnit: [], // 维保单位
        insuranceUnit: [], // 保险单位
        propertyUnit: [], // 物业单位

        moreSearch: false,

        exportTitle: '导出',
        exportType: false,
        propCode: {
          label: 'name',
          value: 'areaCode',
          checkStrictly: true
        },

        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        isShow: false,
        tableLoading: true,
        selectDate: [],
        contextMenuPosition: {
          x: 0,
          y: 0,
        },
        topShow:true,
        topInfo: null,
      }
    },

    filters: {},
    methods: {
      closeMonitor() {
        this.MonitorInfoVisible = false
      },
      screenName(type) {
        let name = '-'
        switch (type) {
          case 0:
            name = '未知'
            break
          case 1:
            name = '清晰'
            break
          case 2:
            name = '模糊'
            break
          case 3:
            name = '视角外'
            break
          case 4:
            name = '黑屏'
            break
          case 5:
            name = '花屏'
            break
          case 6:
            name = '其它'
            break
        }

        return name
      },
      // 获取字典数据
      getDictionar(code) {
        // code = code.join()
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res
              this.softwareData = data.SOFTWARE_VERSION
              this.recongtion = data.RECOGNITION_STATE
              this.APKData = data.APK_VERSION
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      StatusChange(val) {
        if (val && val == 0) {
          return '单屏'
        } else if (val && val == 1) {
          return '双屏'
        } else {
          return '无'
        }
      },
      // 排序
      handleSort(column, prop, order) {
        let common = ''
        if (column.prop == 'daySimData') {
          common = 'day_sim_data'
        } else if (column.prop == 'monthSimData') {
          common = 'month_sim_data '
        } else if (column.prop == 'activeTime') {
          common = 'active_time'
        } else if (column.prop == 'lastLogoutTime') {
          common = 'last_logout_time'
        } else if (column.prop == 'name') {
          common = 'initial'
        } else if (column.prop == 'sdTotalSize') {
          common = 'sd_total_size'
        }
        // this.searchForm.common = common
        this.searchForm.common = column.order ? common : ''
        // this.searchForm.sort = column.order === 'descending' ? 'desc' : 'asc'
        this.searchForm.sort = column.order == 'descending' ? 'desc' : (column.order == 'ascending' ? 'asc' : '')
        this.onRefer()
      },
      onRowContextMenu(row, column, event) { //右击
        // event.preventDefault();
        this.topInfo = row
        this.topShow = row.topStatus == 1 ? false : true
        const contextMenuEvent = event || window.event;
        contextMenuEvent.preventDefault();
        this.contextMenuPosition.x = event.clientX;
        this.contextMenuPosition.y = event.clientY;
      },

      cancelTop() {
        const data = {
          objectId: this.topInfo.id,
          type:1
        }
        this.$http.post('/api/ele/web/information/setTopInfo', data).then((res) => {
          console.log( '置顶')
          if (res.data.code == 200) {
            this.contextMenuPosition.x = 0;
            this.contextMenuPosition.y = 0;
            this.$message.success(res.data.msg)
            this.onRefer();
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      cancelDown() {
        const data = {
          objectId: this.topInfo.id,
          type:1
        }
        this.$http.post('/api/ele/web/information/delTopInfo', data).then((res) => {
          console.log( '取消置顶')
          if (res.data.code == 200) {
            this.contextMenuPosition.x = 0;
            this.contextMenuPosition.y = 0;
            this.$message.success(res.data.msg)
            this.onRefer();
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      getUnit(item) {
        const data = {
          // current: 1,
          // size: 10000,
          type: item
        }
        // /api/system/web/org/list
        this.$http.post('/api/system/web/org/getRelevanceOrgList', data).then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res

            switch (item) {
              case 'MAINTAIN_UNIT':
                this.maintainUnit = data ?? []
                break
              case 'TENEMENT_UNIT':
                this.propertyUnit = data ?? []
                break
              case 'INSURANCE_UNIT':
                this.insuranceUnit = data ?? []
                break
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 分页
      maintenRulePage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer()
      },

      // 搜索
      searchOnRefer() {
        this.searchForm.current = 1
        this.onRefer()
      },
      // 重置
      reset() {
        this.searchForm = {
          current: 1,
          size: 10,
        }
        this.onRefer()
      },

      moreBtn() {
        this.moreSearch = true
      },
      packMore() {
        this.moreSearch = false
      },
      // 从长连接获取在线列表
      getOlineFun() {
        this.getOlineTime = setInterval(() => {
          if (this.socketApi.isheart && this.tableData.length > 0) {
            let agentData = {
              act: 'ma_get_online_list',
            }
            this.socketApi.sendSock(agentData, (e) => {
              if (e.cmd == 'ma_get_online_list' && e.status) {

                // let data = JSON.parse(e.data)
                let data = e.data.substring(1, e.data.length - 1).split(',')
                this.tableData.forEach((qs1, ind) => {
                  // 强制刷新状态
                  this.$delete(this.tableData[ind], 'objStatus')
                  let objStatus = '离线'
                  data.forEach((qs2) => {
                    if (this.bigNumCompare(qs1.id, qs2) == 0) {
                      objStatus = '在线'
                      return
                    }
                  })
                  this.$set(this.tableData[ind], 'objStatus', objStatus)
                  // qs1.objStatus = objStatus
                })
              }
            })
          }
        }, 5000)
      },

      getMidNum(str, start, len) {
        if (start + len > 0) {
          return +str.substr(start < 0 ? 0 : start, start < 0 ? start + len : len)
        } else {
          return 0
        }
      },
      // 大数比较 -1小于 ，0相等， 1大于
      bigNumCompare(a, b) {
        let back = 0
        let max = Math.ceil(Math.max(a.length, b.length) / 15)
        //分成多少段,从左边开始
        for (let i = max; i > 0; i--) {
          let num1 = this.getMidNum(a, a.length - i * 15, 15)
          let num2 = this.getMidNum(b, b.length - i * 15, 15)
          //15位数字相减
          let cur = num1 - num2
          if (cur < 0) {
            back = -1
            break
          } else if (cur > 0) {
            back = 1
            break
          }
        }
        return back
      },

      // 获取监控列表方法
      onRefer() {
        this.isShow = !(this.adminInfo.roleId == '99')
        if (Number(this.searchForm.maxBattery) < Number(this.searchForm.minBattery)) {
          this.$message.error('电池健康最小值不得高于最大值')
          return
        }
        if (this.searchForm.maxBattery) {
          if (!this.searchForm.minBattery) {
            this.$message.error('请填写电池健康最小值')
            return
          }
          if (this.searchForm.minBattery > 100 || this.searchForm.minBattery < 1 || !/^\d+$/.test(this.searchForm
              .minBattery)) {
            this.$message.error('电池健康最小值数值在1-100之间，请输入正确的数值')
            return
          }
        }
        if (this.searchForm.minBattery) {
          if (!this.searchForm.maxBattery) {
            this.$message.error('请填写电池健康最大值')
            return
          }
          if (this.searchForm.maxBattery > 100 || this.searchForm.maxBattery < 1 || !/^\d+$/.test(this.searchForm
              .minBattery)) {
            this.$message.error('电池健康最大值数值在1-100之间，请输入正确的数值')
            return
          }
        }
        if (Number(this.searchForm.sdMax) < Number(this.searchForm.sdMin)) {
          this.$message.error('SD卡剩余容量最小值不得高于最大值')
          return
        }
        if (this.searchForm.sdMax) {
          if (!this.searchForm.sdMin) {
            this.$message.error('请填写SD卡剩余容量最小值')
            return
          }
        }
        if (this.searchForm.sdMin) {
          if (!this.searchForm.sdMax) {
            this.$message.error('请填写SD卡剩余容量最大值')
            return
          }
        }
        if (Number(this.searchForm.daySimMax) < Number(this.searchForm.daySimMin)) {
          this.$message.error('当日流量最小值不得高于最大值')
          return
        }
        if (Number(this.searchForm.monthSimMax) < Number(this.searchForm.monthSimMin)) {
          this.$message.error('当月流量最小值不得高于最大值')
          return
        }
        this.tableLoading = true
        this.searchForm.lastLogoutStartTime = this.searchForm.offlineTime ? new Date(this.searchForm.offlineTime[0])
          .getTime() : ''
        this.searchForm.lastLogoutEndTime = this.searchForm.offlineTime ? new Date(this.searchForm.offlineTime[1])
          .getTime() + 24 * 3600 * 1000 - 1000 : ''
        this.searchForm.activeStartTime = this.searchForm.tactivationTime ? new Date(this.searchForm.tactivationTime[0])
          .getTime() : ''
        this.searchForm.activeEndTime = this.searchForm.tactivationTime ? new Date(this.searchForm.tactivationTime[1])
          .getTime() + 24 * 3600 * 1000 - 1000 : ''
        this.searchForm.areaCodes = this.searchForm.areaCodeArr && this.searchForm.areaCodeArr.length > 0 ? this
          .searchForm.areaCodeArr[this.searchForm.areaCodeArr.length - 1] : ''
        this.$http
          .post('/api/ele/web/elevatorInfo/runningList', this.searchForm)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                }
              } = res
              this.tableData = data.records ?? []
              this.tableData.forEach((qs) => {
                // if (qs.status == 1 || qs.status == 10) {
                //   qs.objStatus = '在线'
                // } else {
                //   qs.objStatus = '离线'
                // }
                qs.objStatus = '-'
              })
              this.maintenRuleTotal = data.total ?? 0
              this.$nextTick(() => {
                if (this.maintenRuleTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
              if (this.getOlineTime == null) {
                this.getOlineFun()
              }
            } else {
              this.$message.error(res.data.msg)
            }
            this.tableLoading = false
          })
      },
      check(item) {
        this.eleInfo = item
        this.elevatorId = item.id
        this.deviceCode = item.deviceCode
        this.MonitorInfoVisible = true
        // this.$http.get(`/api/ele/web/elevatorInfo/getMaEleDetail?id=${item.id}`).then((res)=>{
        //   if(res.data.code == 200){
        //     this.eleInfo = item
        //     this.elevatorId = item.id
        //     this.deviceCode = item.deviceCode
        //     this.MonitorInfoVisible = true
        //     this.DetailsInfo = res.data.data
        //   }else{
        //     this.$message.error(res.data.msg)
        //   }
        // })

      },
      // 重启
      restart(data) {
        this.$confirm('此操作将重启球机, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            if (this.socketApi.isheart) {
              let agentData = {
                act: 'device_restart',
                device_id: data.deviceCode,
              }
              this.socketApi.sendSock(agentData, (e) => {
                if (e.cmd === 'device_restart' && e.status) {
                  this.$message.success('球机正在重启')
                } else {
                  if (!data.deviceCode) {
                    this.$message.error('未绑定球机')
                  } else {
                    this.$message.error(e.msg)
                  }
                }
                this.loading = false
              })
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消重启',
            })
          })
      },


      // 导出
      async exportData() {
        this.searchForm.current = 1
        this.exportType = true
        this.exportTitle = '正在导出'
        this.$http.post("/api/ele/web/export/eleRunningData", this.searchForm, {
          responseType: 'blob'
        }).then((res) => {
          if (res.data.size > 0) {
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            });
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a'); //创建a标签
            link.href = url;
            link.download = '电梯监控.xls'; //重命名文件
            link.click();
            URL.revokeObjectURL(url);
          } else {
            this.$message.error('暂无数据')
          }
          this.exportType = false
          this.exportTitle = '导出'
        })
      },
      getplot() { //获取小区
      // api/ele/web/plot/list
        this.$http.post('/api/ele/web/plot/getRelevancePlotList', {}).then((res) => {
          if (res.data.code == 200) {
            this.selectDate = res.data.data?? []
          }
        })
      },
    },
    created() {
      this.getDictionar(['APK_VERSION', 'SOFTWARE_VERSION', 'RECOGNITION_STATE'])
      this.getUnit('MAINTAIN_UNIT')
      this.getUnit('TENEMENT_UNIT')
      this.getUnit('INSURANCE_UNIT')
      this.getplot()
    },
    mounted() {

      // selectDate
      // this.$nextTick(() => {
      this.onRefer()
      var that = this
      window.addEventListener('click', function() {
        that.contextMenuPosition.x=0
        that.contextMenuPosition.y=0
      })
      // })
    },
    destroyed() {
      clearInterval(this.getOlineTime)
    },
  }
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contextmenuInfo {
    width: 100px;
    height: 50px;
    background: white;
    position: fixed;
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .contextmenus {
    margin: 0;
    padding: 0px;
    color: #333;
    font-size: 16px;
    line-height: 30px;
    width: 100%;
  }

  .searchBtn {
    margin-left: 15px;
  }
</style>
