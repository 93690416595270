<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯名称</span>
					</span>
					<el-input v-model="formInline.eleName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯注册代码</span>
					</span>
					<el-input v-model="formInline.eleCode" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">维保类型</span>
					</span>
					<el-select v-model="formInline.maintainType" placeholder="请输入" class="einp" clearable>
						<el-option v-for="item in wbType" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">计划时间</span>
					</span>
					<el-date-picker style="width: 220px;" v-model="formInline.allTime" type="daterange"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">所属小区</span>
					</span>
					<el-input v-model="formInline.plotName" placeholder="请输入" clearable></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onRefer(true)">查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div v-if="dictionarTotal!=0">
			<el-table :data="tableData" v-loading="tableLoading" ref="multipleTable" style="width: 100%;margin-bottom: 20px;" :height="$store.state.tabHeight">
				<el-table-column fixed="left" type="index" label="序号" width="50px" />
				<el-table-column align='center' prop="eleName" label="电梯名称" />
				<el-table-column align='center' label="维保状态">
					<template slot-scope="scope">
						<span style="color: #D9001B;">已超期</span>
					</template>
				</el-table-column>
				<el-table-column align='center' prop="exceedDay" label="超期天数" />
				<el-table-column align='center' label="所属小区" width="180px" >
					<template slot-scope="scope">
						{{scope.row.plotName ? scope.row.plotName : '-'}}
					</template>
				</el-table-column>
				<el-table-column align='center' prop="maintainTypeName" label="维保类型" />
				<el-table-column align='center' prop="startTime" label="计划时间" />
				<el-table-column align='center' prop="eleCode" label="电梯注册代码" />
				<el-table-column v-if="!$store.state.isWBJB" align='center' prop="deviceCode" label="AI摄像头ID" />
				<el-table-column align='center' prop="propertyUnitName" label="物业单位" />
				<el-table-column align='center' prop="maintainUnitName" label="维保单位" />
				<el-table-column align='center' prop="maintainUserNames" label="维保人员" />
			</el-table>
			<Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
				@pagination="dictionarPage" />
		</div>
		<div class="nullDate" v-else v-loading="tableLoading">
      		<img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
		</div>
	</div>
</template>
<script>
	var valifailureDesc = (rule, value, callback) => {
		let operation = /^.{4,600}$/
		inpYz(rule, value, callback, true, operation, '可输入中文，英文，数值，特殊符号，长度为4至600')
	};

	import Pagination from '@/components/Pagination'
	export default {
		components: {
			Pagination,
		},
		data() {
			return {
				// 表格参数
				formInline: {
					current: 1,
					size: 10,
				},
				tableData: [],
				// 分页参数
				dictionarTotal: 0,
				// 维保类型
				wbType: [],
				tableLoading: true
			}
		},
		methods: {
			// 重置按钮事件
			reset() {
				this.formInline = {
					current: 1,
					size: 10,
				}
				this.onRefer()
			},
			// 分页
			dictionarPage(data) {
				this.formInline.current = data.page
				this.formInline.size = data.limit
				this.onRefer()
			},
			// 表格方法
			onRefer(ishm) {
				if (ishm) {
					this.formInline.current = 1
				}
				if (this.formInline.allTime != null && this.formInline.allTime != undefined) {
					let start = new Date(this.formInline.allTime[0]).getTime()
					let end = new Date(this.formInline.allTime[1]).getTime()
					this.formInline.searchStartTime = [start, end]
				} else {
					this.formInline.searchStartTime = null
				}
				this.tableLoading = true
				this.$http
					.post("/api/ele/web/maintainTask/getExceedList", this.formInline)
					.then((res) => {

						if (res.data.success) {
							res.data.data.records.forEach((qs) => {
								qs.statusName = this.getType(qs.status, 2)
							})
							this.tableData = res.data.data.records
							this.dictionarTotal = res.data.data.total
							this.$nextTick(() => {
								if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
						this.tableLoading = false
					})
			},
			// 获取类型
			getType(type, sta) {
				let obj
				this.wbType.forEach((qs) => {
					if (qs.value == type) {
						obj = qs.label
					}
				})
				return obj
			}
		},
		created() {
			this.$http
				.post(`/api/system/web/dict/dictionary?codes=MAINTAIN_TYPE`)
				.then((res) => {
					if (res.data.success) {
						this.wbType = res.data.data.MAINTAIN_TYPE
						this.onRefer()
					}
				})
		}
	}
</script>
<style scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-top {
		width: 100%;
		margin-bottom: 20px;
	}

	.page-bom {}
</style>
